import React from 'react';
import { convertNumberType } from '../../utils/publicFunctions';
import { svgIcons } from '../svgIcons/svgIcons';

export default function ProjectTile({ svg, title, value }) {
  return (
    <div className="py-[19px] px-[18px] bg-white md:px-[26px] rounded-lg md:py-6 grid md:flex md:items-center gap-[11px] md:gap-[17px] min-w-[150px] md:min-w-[233px]">
      <div className="text-[#B0C5DE] w-[21px] h-[18px] md:w-[28px] md:h-6">
        {svgIcons[svg]}
      </div>
      <div className="grid gap-[6px]">
        <div className="text-[#515868] text-[14px] font-normal leading-[18.2px]">
          {title}
        </div>
        <div className="font-semibld text-[18px] leading-[23.4px]">
          $&nbsp;{convertNumberType(parseFloat(value))}
        </div>
      </div>
    </div>
  );
}
