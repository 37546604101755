import React from 'react';
import Skeleton from 'react-loading-skeleton';

const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];

export default function NotificationSkeleton() {
  return (
    <div className="white-background skeleton-background grid gap-2">
      {arr.map((item) => {
        return (
          <div className="flex items-center h-[60px] border rounded" key={item}>
            <Skeleton
              containerClassName="ml-5"
              width={18}
              height={18}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
            <Skeleton
              containerClassName="ml-5 mr-2"
              width={40}
              height={40}
              circle
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
            <Skeleton
              containerClassName="w-full mr-12"
              height={20}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </div>
        );
      })}
    </div>
  );
}
