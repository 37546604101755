import { ModalCentered } from './ModalCentered';

export default function AcceptModal(props) {
  return (
    <ModalCentered
      {...props}
      dialogClassName="670w:w-[448px]"
      formGroup={
        <div className="grid gap-4 grid-rows-2 670w:gap-[12px] 670w:grid-cols-2 670w:grid-rows-1">
          <button
            className="flair-btn flair-btn-md flair-btn-secondary"
            onClick={props.handleClose}
          >
            Dismiss
          </button>
          <button
            className="flair-btn flair-btn-md flair-btn-primary"
            onClick={props.handleConfirm}
          >
            Accept
          </button>
        </div>
      }
    />
  );
}
