import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { apiAgent } from '../../../utils/apicall';
import SubscriptionsListComponent from './SubscriptionsListComponent';
import { validateEmail } from '../../../utils/string';

export default function Settings() {
  //

  const params = useParams();
  const propertyId = params.id;
  const [prefs, setPrefs] = useState([]);
  const [specialStatusPrefs, setSpecialStatusPrefs] = useState([]);
  const [specialRemindPrefs, setSpecialRemindPrefs] = useState([]);
  const [vacancyAlertPrefs, setVacancyAlertPrefs] = useState([]);

  const fetchPrefs = async () => {
    const response = await apiAgent.get({
      path: `/propertyemailpreferences`,
      params: { property_id: propertyId },
      options: apiAgent.popularOptions.onConfirmExceptDeclined,
    });
    const prefs_data = await response.json();
    setPrefs(prefs_data);
    // filter using type
    setSpecialStatusPrefs(
      prefs_data.filter((pref) => pref.type == 'PPROMO_STATUS'),
    );
    setSpecialRemindPrefs(
      prefs_data.filter((pref) => pref.type == 'PPROMO_REMIND'),
    );
    setVacancyAlertPrefs(
      prefs_data.filter((pref) => pref.type == 'PROPS_VACANCY'),
    );
  };

  const handleAddSpecialStatusPref = async (data, type) => {
    data['property'] = propertyId;
    data['type'] = type;
    if (!data['email']) {
      alert('Email address is required for a reminder subscription.');
      return;
    }
    if (!validateEmail(data['email'])) {
      alert('The email address format is invalid.');
      return;
    }
    if (type == 'PPROMO_REMIND' && !data['schedule']) {
      alert('A schedule is required for a reminder subscription.');
      return;
    }
    const response = await apiAgent.post({
      path: `/propertyemailpreferences`,
      data,
      options: apiAgent.popularOptions.onConfirmExceptDeclined,
    });
    await fetchPrefs();
  };

  const handleRemovePref = async (id) => {
    const response = await apiAgent.del({
      path: `/propertyemailpreferences/${id}`,
      options: apiAgent.popularOptions.onConfirmExceptDeclined,
    });
    await fetchPrefs();
  };

  const handleUpdatePref = async (data) => {
    const { id, ...restData } = data;
    const response = await apiAgent.patch({
      path: `/propertyemailpreferences/${id}`,
      data: restData,
      options: apiAgent.popularOptions.onConfirmExceptDeclined,
    });
    await fetchPrefs();
  };

  useEffect(() => {
    fetchPrefs();
    return () => {};
  }, []);

  // region rendering
  return (
    <div className="py-4 xs:py-10 grid gap-6 lg:flex justify-between items-center">
      <div className="w-full">
        <h5 className="mb-4">Settings</h5>
        <SubscriptionsListComponent
          uiTitle={'Instant Special Notifications'}
          uiDescription={
            'Sends an email when a new special is created for this property'
          }
          uiInputPlaceholder={
            'Type the emails of who you want to notify, press enter when done'
          }
          subscriptions={specialStatusPrefs}
          dataModel={{
            email: { type: 'email' },
          }}
          onAdd={(data) => {
            handleAddSpecialStatusPref(data, 'PPROMO_STATUS');
          }}
          onRemove={handleRemovePref}
          onItemUpdate={(updateData) => {
            console.log(updateData);
          }}
        />
        <SubscriptionsListComponent
          uiTitle={'Recurring Specials Notifications'}
          uiDescription={
            'Sends an email on a recurring basis when a special is active for this property'
          }
          uiInputPlaceholder={'Type the email and press enter'}
          subscriptions={specialRemindPrefs}
          dataModel={{
            email: { type: 'email' },
            schedule: {
              type: 'select',
              choices: {
                '0 0 1 * *': 'Monthly',
                '0 0 * * 4': 'Weekly',
              },
            },
          }}
          onAdd={(data) => {
            handleAddSpecialStatusPref(data, 'PPROMO_REMIND');
          }}
          onRemove={handleRemovePref}
          onItemUpdate={handleUpdatePref}
        />
        <SubscriptionsListComponent
          uiTitle={'Availability Notifications'}
          uiDescription={
            'Notify users when one or more floorplan type is unavailable (e.g., no more vacant 1-bedroom units)'
          }
          uiInputPlaceholder={
            'Type the email of who you want to notify, press enter when done'
          }
          subscriptions={vacancyAlertPrefs}
          dataModel={{
            email: { type: 'email' },
          }}
          onAdd={(data) => {
            handleAddSpecialStatusPref(data, 'PROPS_VACANCY');
          }}
          onRemove={handleRemovePref}
          onItemUpdate={(updateData) => {
            console.log(updateData);
          }}
        />
      </div>
    </div>
  );
}
