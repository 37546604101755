import React from 'react';
import Tag from './Tag';
import DropdownIndicator from './DropdownIndicator';

function TagsContainer({
  data,
  isOpen,
  onClickDropdown,
  onRemoveItem,
  placeholder,
  ...props
}) {
  return (
    <section
      className="flex overflow-hidden justify-between items-center p-3 text-base whitespace-nowrap bg-white rounded-lg border border-solid border-zinc-300 full-width"
      {...props}
    >
      <div className="flex flex-1 shrink gap-2 items-center self-stretch my-auto basis-0 min-w-[240px] mr-2">
        <div className="flex flex-1 shrink gap-2 items-center self-stretch my-auto w-full basis-0 min-w-[240px] flex-wrap">
          {data.map((tagData, index) => (
            <Tag key={index} data={tagData} onRemove={onRemoveItem} />
          ))}
          {data.length === 0 && (
            <div className="text-gray-400 text-sm">{placeholder || ''}</div>
          )}
        </div>
      </div>
      {onClickDropdown && (
        <DropdownIndicator
          isOpen={isOpen}
          onClick={(e) => {
            onClickDropdown(e);
          }}
        />
      )}
    </section>
  );
}

export default TagsContainer;
