import { useState, useEffect } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { svgIcons } from '../../../components/svgIcons/svgIcons';
import RightPanelModal from '../../../components/modalbootstrap/RightPanelModal';
import EditAssigneeModal from '../../../components/modalbootstrap/EditAssigneeModal';
import { apiAgent } from '../../../utils/apicall';
import { ProjectStatus } from '../../../utils/constants';
import Skeleton from 'react-loading-skeleton';
import {
  Button,
  Chip,
  Input,
  Radio,
  RadioGroup,
  Select,
  SelectItem,
  Tabs,
  Tab,
} from '@nextui-org/react';
import config from '../../../utils/config';
import copy from 'copy-to-clipboard';

export default function Integrations() {
  const params = useParams();
  const propertyId = params.id;
  const navigator = useNavigate();
  const [show, setShow] = useState();
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setName] = useState('');
  const [amount, setAmount] = useState('500.00');
  const [addFeeFlag, setAddFeeFlag] = useState(false);
  const [fees, setFees] = useState([]);
  const [editFee, setEditFee] = useState(-1);
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [gmbLoadingFlag, setGmbLoadingFlag] = useState(true);
  const [assigneeModal, setAssigneeModal] = useState(false);
  const [gbpId, setGbpId] = useState();
  const [gbpAccounts, setGbpAccounts] = useState([]);
  const [gbpLocations, setGbpLocations] = useState([]);
  const [selectedGbpLocations, setSelectedGbpLocations] = useState([]);
  const [property, setProperty] = useState();
  const [isGbpOpen, setIsGbpOpen] = useState(false);
  const [search, setSearch] = useState('');
  const [selectedItem, setSelectedItem] = useState();
  const [websitePricingStatus, setWebsitePricingStatus] = useState();
  const [googleAdsStatus, setGoogleAdsStatus] = useState();
  const [gmbStatus, setGmbStatus] = useState();
  const [selectedIntegration, setSelectedIntegration] = useState();
  const [active, setActive] = useState('All');
  const [copiedFlag, setCopiedFlag] = useState(false);

  useEffect(() => {
    setSelectedIntegration(searchParams.get('c'));
  });

  const fetchGmbData = async () => {
    setGmbLoadingFlag(true);
    const response = await apiAgent.get({
      path: `/properties/${propertyId}/get_gmb_info`,
      options: apiAgent.popularOptions.onConfirmExceptDeclined,
    });
    const responseBody = await response.json();
    setGbpLocations(responseBody.locations);
    setGbpAccounts(responseBody.accounts);
    setGmbLoadingFlag(false);
  };

  const fetchPropertyData = async () => {
    if (!propertyId) return;
    try {
      setLoadingFlag(true);
      const response = await apiAgent.get({
        path: `/properties/${propertyId}`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const propertyBody = await response.json();
      setProperty(propertyBody);
      if (
        propertyBody.gbp_access_token &&
        searchParams.get('c') == 'GoogleMyBusiness'
      ) {
        fetchGmbData();
      } else {
        setGbpLocations([]);
        setGbpAccounts([]);
        setGmbLoadingFlag(false);
      }
      setGmbStatus(propertyBody.is_gmb_integration);
      if (propertyBody.business_profile_id)
        setGbpId(
          propertyBody.business_profile_id.split('/').length == 1
            ? propertyBody.business_profile_id
            : propertyBody.business_profile_id.split('locations/')[1],
        );
      setFees(propertyBody.propertyadditionalfee_set);
      let websitePricingStatus = 'Not Set Up';
      let googleAdsStatus = false;
      let project_set = propertyBody.project_set;
      if (propertyBody.parent) {
        const response = await apiAgent.get({
          path: `/properties/${propertyBody.parent.id}`,
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        const result = await response.json();
        project_set = result.project_set;
      }
      project_set.map((item) => {
        if (
          item.ads_status == ProjectStatus.ACTIVE &&
          item.gad_customer_id.length == 10
        ) {
          googleAdsStatus = true;
        }
      });
      if (
        (propertyBody.patch_data_url.search('spreadsheets/d/') != -1 &&
          !propertyBody.is_scrape_directly) ||
        (propertyBody.property_website_url.length > 0 &&
          propertyBody.is_scrape_directly)
      )
        websitePricingStatus = 'Active';
      setWebsitePricingStatus(websitePricingStatus);
      setGoogleAdsStatus(googleAdsStatus);
      setLoadingFlag(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPropertyData();
  }, [propertyId]);

  const updateBusinessProfileId = async (profile_id) => {
    try {
      setGmbLoadingFlag(true);
      const response = await apiAgent.patch({
        path: `/properties/${propertyId}`,
        data: { business_profile_id: profile_id },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      setGmbLoadingFlag(false);
    } catch (error) {
      console.log(error);
    }
  };

  const activeGmbIntegration = async () => {
    try {
      setLoadingFlag(true);
      const response = await apiAgent.patch({
        path: `/properties/${propertyId}`,
        data: { is_gmb_integration: true },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      setLoadingFlag(false);
    } catch (error) {
      console.log(error);
    }
  };

  const handleAddFee = async () => {
    setFees([...fees, { id: -1, name: name, amount: amount }]);
  };

  const handleEditFees = async () => {
    try {
      setLoadingFlag(true);
      const response = await apiAgent.post({
        path: `/properties/${propertyId}/edit_fees`,
        data: { fees: [...fees] },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const propertyBody = await response.json();
      setProperty(propertyBody);
      setLoadingFlag(false);
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <>
      {selectedIntegration == 'GoogleMyBusiness' ? (
        ''
      ) : (
        <div className="py-4 xs:py-10 grid gap-6 lg:flex justify-between items-center">
          <div>
            <h5 className="mb-4">Integrations library</h5>
            <div>
              Manage your properties integrations here in a couple of clicks.
            </div>
          </div>
        </div>
      )}
      {selectedIntegration == 'GoogleMyBusiness' ? (
        gmbLoadingFlag ? (
          <div className="grid gap-8 mt-10">
            <div className="skeleton-background flex gap-4 rounded-xl p-6">
              <div className="rounded-lg flex items-center">
                <Skeleton
                  width={80}
                  height={80}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={12}
                />
              </div>
              <div className="grid grid-cols-1 gap-1">
                <Skeleton
                  width={185}
                  height={28}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={4}
                />
                <Skeleton
                  width={300}
                  height={24}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={4}
                />
              </div>
            </div>
            {/* <div className="text-3xl leading-9 font-semibold">
              <Skeleton
                width={188}
                height={36}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
                borderRadius={4}
              />
            </div> */}
            <div className="skeleton-background grid gap-6 xl:flex justify-between rounded-xl shadow-sm p-9">
              <div className="grid gap-1">
                <Skeleton
                  width={64}
                  height={24}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={4}
                />
                <Skeleton
                  width={247}
                  height={28}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={4}
                />
                <Skeleton
                  width={590}
                  height={48}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={4}
                />
              </div>
              <div className="my-auto">
                <Skeleton
                  width={482}
                  height={56}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={12}
                />
              </div>
            </div>
            <div className="skeleton-background grid gap-6 xl:flex justify-between rounded-xl shadow-sm p-9">
              <div className="grid gap-1">
                <Skeleton
                  width={64}
                  height={24}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={4}
                />
                <Skeleton
                  width={247}
                  height={28}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={4}
                />
                <Skeleton
                  width={590}
                  height={48}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={4}
                />
              </div>
              <div className="my-auto">
                <Skeleton
                  width={482}
                  height={56}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={12}
                />
              </div>
            </div>
            <div className="grid gap-8 xl:flex justify-between rounded-xl shadow-sm">
              <Skeleton
                containerClassName="w-full"
                height={450}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
                borderRadius={12}
              />
            </div>
            <div className="grid gap-4 xl:flex justify-between">
              <div className="flex gap-2 mb-2 text-large my-auto">
                <Skeleton
                  width={504}
                  height={28}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={8}
                />
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Skeleton
                  width={251}
                  height={52}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={8}
                />
                <Skeleton
                  width={251}
                  height={52}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                  borderRadius={8}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="grid gap-8 mt-10">
            <div className="flex gap-4 bg-white rounded-xl shadow-sm p-6">
              <div className="w-20 h-20 border rounded-lg flex items-center">
                <img
                  className="h-10 lg:w-[53px] object-cover rounded m-auto"
                  src="/../../assets/images/GMB.png"
                />
              </div>
              <div className="grid grid-cols-1 gap-1">
                <div className="text-xl leading-7 font-semibold">
                  Google My Business
                </div>
                <div className="text-base leading-6 font-normal">
                  Connect your Google My Business profile to Flair.
                </div>
              </div>
            </div>
            {/* <div className="text-3xl leading-9 font-semibold">
              Let's Connect
            </div> */}
            <div className="grid gap-6 xl:flex justify-between bg-white rounded-xl shadow-sm p-9">
              <div className="grid gap-1">
                <div className="w-16 h-6 rounded-md bg-[#E9EDFF] text-[#7384F5] text-sm leading-5 font-normal grid place-items-center">
                  Step I
                </div>
                <div className="text-lg leading-7 font-medium mb-1">
                  Connect Your Google Account
                </div>
                <div className="text-base leading-6 font-normal max-w-[590px]">
                  Sign into the Google My Business account you’d like to use for
                  this integration. Then, return to this page and click
                  “Authorize” to activate this integration.
                </div>
              </div>
              <div className="my-auto">
                {!property.gbp_access_token ? (
                  <Button
                    variant="solid"
                    radius="md"
                    onClick={() => {
                      window.location.href =
                        config.serverurl + `properties/${propertyId}/link_gmb`;
                    }}
                    className="w-[481px] h-[56px] bg-foreground-100 border-1 border-default-200 text-base leading-6 font-medium"
                    startContent={
                      <img src="/assets/images/google.png" width={24} alt="" />
                    }
                  >
                    Sign in with Google
                  </Button>
                ) : (
                  <div className="flex w-[481px] h-[56px] border-1 border-default-200 rounded-lg">
                    <div className="w-[392px] h-full text-center grid place-items-center text-base leading-6 font-medium">
                      <div className="flex gap-2">
                        {gbpAccounts &&
                          gbpAccounts.length > 0 &&
                          gbpAccounts[0].accountName}
                        <img
                          src="/assets/images/selected.png"
                          width={24}
                          alt=""
                        />
                      </div>
                    </div>
                    <div
                      onClick={() => {
                        window.location.href =
                          config.serverurl +
                          `properties/${propertyId}/link_gmb`;
                      }}
                      className="w-[90px] bg-foreground-100 text-primary grid place-items-center cursor-pointer border-l-1"
                    >
                      Change
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="grid gap-6 xl:flex justify-between bg-white rounded-xl shadow-sm p-9">
              <div className="grid gap-1">
                <div
                  className={`w-16 h-6 rounded-md text-sm leading-5 font-normal grid place-items-center ${
                    !property.gbp_access_token
                      ? 'bg-[#F4F4F5] text-default-500'
                      : 'bg-[#E9EDFF] text-[#7384F5]'
                  }`}
                >
                  Step II
                </div>
                <div className="text-lg leading-7 font-medium mb-1">
                  Select {property.name}’s GBP Location
                </div>
                <div className="text-base leading-6 font-normal max-w-[590px]">
                  All GBP profiles you have access to through your connected
                  Google account should appear. Select the one you’d like to
                  connect.
                </div>
              </div>
              <div className="my-auto w-[481px]">
                {!property.gbp_access_token && (
                  <div className="flex gap-2 mb-2 text-small">
                    <img src="/assets/images/danger.png" width={18} alt="" />
                    <div>Please connect your Google Account to proceed</div>
                  </div>
                )}
                <div>
                  <Select
                    className="max-w-xs mr-4"
                    classNames={{
                      trigger: 'min-h-unit-12 w-[481px]',
                      listboxWrapper: 'max-h-[436px] p-2',
                    }}
                    listboxProps={{
                      itemClasses: {
                        base: [
                          'data-[hover=true]:bg-white',
                          'data-[selectable=true]:focus:bg-white',
                        ],
                      },
                    }}
                    radius="sm"
                    variant="bordered"
                    aria-label="select"
                    placeholder={
                      gbpId &&
                      gbpLocations &&
                      gbpLocations.filter(
                        (item) => item.name.split('/')[1] == gbpId,
                      ).length > 0
                        ? `GBP Profile Name - ${
                            gbpLocations.filter(
                              (item) => item.name.split('/')[1] == gbpId,
                            )[0].title
                          }`
                        : 'Property GBP Location'
                    }
                    onClick={() => {
                      setSelectedGbpLocations(gbpLocations);
                      setSearch('');
                    }}
                    startContent={<div className="w-4">{svgIcons.search}</div>}
                    isOpen={isGbpOpen}
                    onOpenChange={(open) =>
                      open !== isGbpOpen && setIsGbpOpen(open)
                    }
                  >
                    <SelectItem
                      key="argentina"
                      textValue="a"
                      isReadOnly
                      hideSelectedIcon
                      className="hover:bg-white"
                    >
                      <div>
                        <div className="flex items-center">
                          <img
                            src="/assets/images/home.png"
                            width={24}
                            alt=""
                          />
                          <div className="text-base leading-6 font-medium ml-2">
                            Property GBP Location
                          </div>
                        </div>
                        <Input
                          classNames={{
                            base: 'max-w-full h-[52px] my-4',
                            mainWrapper: 'h-full',
                            input: 'text-small pl-9',
                            inputWrapper:
                              'h-full font-normal text-default-500 bg-white',
                          }}
                          variant="bordered"
                          value={search}
                          onChange={(e) => {
                            setSelectedGbpLocations(
                              gbpLocations.filter(
                                (item) =>
                                  e.target.value.length == 0 ||
                                  item.title
                                    .toLowerCase()
                                    .includes(e.target.value.toLowerCase()),
                              ),
                            );
                            setSearch(e.target.value);
                          }}
                          placeholder="Search your properties here"
                          startContent={
                            <div className="w-4">{svgIcons.search}</div>
                          }
                          type="search"
                        />
                        <RadioGroup
                          className="h-[188px] overflow-y-auto mb-4"
                          defaultValue={gbpId}
                        >
                          {selectedGbpLocations &&
                            selectedGbpLocations.map((item) => (
                              <Radio
                                classNames={{
                                  base: 'p-2',
                                }}
                                value={item.name.split('/')[1]}
                                onClick={() => setSelectedItem(item)}
                              >
                                {/* <h1 className="pt-6 text-lg">
                                  GBP Profile Name
                                </h1> */}
                                {item.title}
                              </Radio>
                            ))}
                        </RadioGroup>
                        <div className="grid grid-cols-2 gap-2 pt-4 border-t-1">
                          <Button
                            variant="bordered"
                            radius="sm"
                            onClick={() => setIsGbpOpen(false)}
                            className="h-[40px] text-base leading-6 font-medium"
                          >
                            Cancel
                          </Button>
                          <Button
                            variant="solid"
                            radius="sm"
                            onClick={() => {
                              updateBusinessProfileId(
                                `${selectedItem.account}/${selectedItem.name}`,
                              );
                              setGbpId(selectedItem.name.split('/')[1]);
                              setIsGbpOpen(false);
                            }}
                            className="h-[40px] bg-[#242424] text-white text-base leading-6 font-medium"
                          >
                            Apply
                          </Button>
                        </div>
                      </div>
                    </SelectItem>
                  </Select>
                </div>
              </div>
            </div>
            <div className="grid gap-8 xl:flex justify-between bg-white rounded-xl shadow-sm p-9">
              <div className="grid gap-1">
                <div
                  className={`w-16 h-6 rounded-md text-sm leading-5 font-normal grid place-items-center ${
                    !property.gbp_access_token ||
                    !gbpId ||
                    (gbpLocations &&
                      gbpLocations.filter(
                        (item) => item.name.split('/')[1] == gbpId,
                      ).length == 0)
                      ? 'bg-[#F4F4F5] text-default-500'
                      : 'bg-[#E9EDFF] text-[#7384F5]'
                  }`}
                >
                  Step III
                </div>
                <div className="text-lg leading-7 font-medium mb-1">
                  Add 5699220347 as a manager to this GBP location
                </div>
                <div className="text-base leading-6 font-normal max-w-[590px]">
                  Follow the steps below and hit “activate” when you’re done.
                </div>
                <div className="grid mt-6 gap-3">
                  <div className="flex gap-3 w-[589px] h-14 border-1 rounded-md px-4">
                    <div className="w-2 h-2 rounded-full bg-default-700 my-auto"></div>
                    <div className="my-auto">Go to business.google.com</div>
                  </div>
                  <div className="flex gap-3 w-[589px] h-14 border-1 rounded-md px-4">
                    <div className="w-2 h-2 rounded-full bg-default-700 my-auto"></div>
                    <div className="my-auto">
                      Select the profile you want to edit
                    </div>
                  </div>
                  <div className="flex gap-3 w-[589px] h-14 border-1 rounded-md px-4">
                    <div className="w-2 h-2 rounded-full bg-default-700 my-auto"></div>
                    <div className="my-auto">
                      Click on the 3 dots and select “business profile settings”
                    </div>
                  </div>
                  <div className="flex justify-between w-[589px] h-14 border-1 rounded-md px-4">
                    <div className="flex gap-3">
                      <div className="w-2 h-2 rounded-full bg-default-700 my-auto"></div>
                      <div className="my-auto">
                        Add{' '}
                        <span
                          className={
                            !property.gbp_access_token ||
                            !gbpId ||
                            (gbpLocations &&
                              gbpLocations.filter(
                                (item) => item.name.split('/')[1] == gbpId,
                              ).length == 0)
                              ? 'text-[#929292]'
                              : 'text-primary'
                          }
                        >
                          5699220347
                        </span>{' '}
                        as a manager
                      </div>
                    </div>
                    <div className="flex gap-2">
                      {copiedFlag ? (
                        <div className="text-xs leading-4 font-medium text-success my-auto">
                          Copied!
                        </div>
                      ) : (
                        ''
                      )}
                      <div
                        className="flex gap-4 w-[86px] h-[32px] bg-foreground-100 rounded-md my-auto px-3 cursor-pointer"
                        onClick={() => {
                          copy('5699220347');
                          setCopiedFlag(true);
                        }}
                      >
                        <div className="my-auto">Copy</div>
                        <div className="my-auto relative">
                          <div className="absolute top-[-6px] left-[-6px] w-[9.17px] h-[9.17px] border-2 border-[#242424]"></div>
                          <div className="absolute top-[-3px] left-[-3px] w-[9.17px] h-[9.17px] border-2 border-[#242424] bg-foreground-100"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="my-auto w-[481px]">
                <div className="w-[482px] h-[342px] rounded-lg bg-content2 border-1 border-default-200 grid place-items-center">
                  <img src="/assets/images/GBPUserGuide.gif" alt="" />
                </div>
                {/* <div className="text-small text-center text-default-600 mt-2">
                  gif animated video
                </div> */}
              </div>
            </div>
            <div className="grid gap-4 xl:flex justify-between border-t-1 pt-8">
              <div className="flex gap-2 mb-2 text-large my-auto">
                {(!property.gbp_access_token ||
                  !gbpId ||
                  (gbpLocations &&
                    gbpLocations.filter(
                      (item) => item.name.split('/')[1] == gbpId,
                    ).length == 0)) && (
                  <>
                    <img src="/assets/images/danger.png" width={24} alt="" />
                    <div>
                      Please connect your Google Account and complete all steps
                    </div>
                  </>
                )}
              </div>
              <div className="grid grid-cols-2 gap-4">
                <Button
                  variant="bordered"
                  radius="sm"
                  onClick={() => {
                    navigator(`/properties/${propertyId}/integrations`);
                  }}
                  className="w-[251px] h-[52px] text-base leading-6 font-medium"
                >
                  Cancel
                </Button>
                {!property.gbp_access_token ||
                !gbpId ||
                (gbpLocations &&
                  gbpLocations.filter(
                    (item) => item.name.split('/')[1] == gbpId,
                  ).length == 0) ? (
                  <Button
                    variant="solid"
                    radius="sm"
                    isDisabled
                    className="w-[251px] h-[52px] text-white text-base leading-6 font-medium bg-[#242424]"
                  >
                    Activate
                  </Button>
                ) : (
                  <Button
                    variant="solid"
                    radius="sm"
                    onClick={() => {
                      activeGmbIntegration();
                      setGmbStatus(true);
                      navigator(`/properties/${propertyId}/integrations`);
                    }}
                    className="w-[251px] h-[52px] text-white text-base leading-6 font-medium bg-[#242424]"
                  >
                    Activate
                  </Button>
                )}
              </div>
            </div>
          </div>
        )
      ) : loadingFlag ? (
        <div className="skeleton-background rounded-xl shadow-sm p-6">
          <div className="flex mb-6 pb-6">
            <Skeleton
              containerClassName="w-[390px]"
              height={44}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
              borderRadius={12}
            />
          </div>
          <div className="justify-between grid lg:grid-cols-2 gap-y-6 gap-x-5">
            <div className="flex min-w-[380px] rounded-lg cursor-pointer">
              <Skeleton
                containerClassName="w-full"
                height={128}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
                borderRadius={12}
              />
            </div>
            <div className="flex min-w-[380px] rounded-lg cursor-pointer">
              <Skeleton
                containerClassName="w-full"
                height={128}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
                borderRadius={12}
              />
            </div>
          </div>
        </div>
      ) : (
        <div className="bg-white rounded-xl shadow-sm p-6">
          <div className="flex mb-6 pb-6 border-b-1">
            <Tabs
              key="none"
              radius="sm"
              selectedKey={active}
              onSelectionChange={setActive}
              className="border h-[44px] rounded-xl text-base leading-6 font-medium"
              classNames={{
                tabList: 'p-[1px] gap-0',
              }}
            >
              <Tab key="All" title="All" className="w-[130px] h-10" />
              <Tab key="Source" title="Source" className="w-[130px] h-10" />
              <Tab
                key="Destination"
                title="Destination"
                className="w-[130px] h-10"
              />
            </Tabs>
          </div>
          <div className="justify-between grid lg:grid-cols-2 gap-y-6 gap-x-5">
            <div
              className={`p-3 pt-6 items-center min-w-[380px] hover:bg-default-50 rounded-lg cursor-pointer relative border ${
                active == 'Source' ? 'hidden' : ''
              }`}
              onClick={() => {
                setSelectedIntegration('GoogleMyBusiness');
                setSearchParams({
                  c: 'GoogleMyBusiness',
                });
                if (property.gbp_access_token) {
                  fetchGmbData();
                } else setGmbLoadingFlag(false);
                setCopiedFlag(false);
              }}
            >
              <div className="relative">
                {gmbStatus ? (
                  <Chip className="absolute w-15 h-6 top-[-12px] left-[6px] bg-success-50 border-1 border-success text-success">
                    Active
                  </Chip>
                ) : (
                  ''
                )}
                <div className="grid gap-2">
                  <div className="flex gap-4">
                    <div
                      className={`w-20 h-20 border rounded-lg flex items-center ${
                        gmbStatus ? 'border-success' : ''
                      }`}
                    >
                      <img
                        className="h-10 lg:w-[53px] object-cover rounded m-auto"
                        src="/../../assets/images/GMB.png"
                      />
                    </div>
                    <div className="grid grid-cols-1 gap-1">
                      <div className="text-xl leading-7 font-semibold">
                        Google My Business
                      </div>
                      <div className="text-base leading-6 font-normal">
                        Connect your Google My Business profile to Flair.
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row-reverse">
                    <div className="flex w-[99px] rounded-xl border-1 text-sm leading-5 font-normal">
                      <div className="w-2 h-2 rounded-full bg-[#7384F5] my-auto mx-1"></div>
                      Destination
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="gap-4 p-3 pt-6 items-center min-w-[380px] hover:bg-default-50 rounded-lg cursor-pointer border"
              onClick={() => {
                setAssigneeModal('gads');
              }}
            >
              <div className="relative">
                {googleAdsStatus ? (
                  <Chip className="absolute w-15 h-6 top-[-12px] left-[6px] bg-success-50 border-1 border-success text-success">
                    Active
                  </Chip>
                ) : (
                  ''
                )}
                <div className="grid gap-2">
                  <div className="flex gap-4">
                    <div
                      className={`w-20 h-20 border rounded-lg flex items-center ${
                        googleAdsStatus ? 'border-success' : ''
                      }`}
                    >
                      <img
                        className="h-10 lg:w-[32.08px] object-cover rounded m-auto"
                        src="/../../assets/images/GAds.png"
                      />
                    </div>
                    <div className="grid grid-cols-1 gap-1">
                      <div className="text-xl leading-7 font-semibold">
                        Google Ads
                      </div>
                      <div className="text-base leading-6 font-normal w-[476px]">
                        Connect your Google Ads account to Flair to manage
                        specials and pricing.
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row-reverse gap-2">
                    <div className="flex w-[73px] rounded-xl border-1 text-sm leading-5 font-normal">
                      <div className="w-2 h-2 rounded-full bg-[#FF8162] my-auto mx-1"></div>
                      Source
                    </div>
                    <div className="flex w-[99px] rounded-xl border-1 text-sm leading-5 font-normal">
                      <div className="w-2 h-2 rounded-full bg-[#7384F5] my-auto mx-1"></div>
                      Destination
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className={`gap-4 p-3 pt-6 items-center min-w-[380px] hover:bg-default-50 rounded-lg cursor-pointer border ${
                active == 'Destination' ? 'hidden' : ''
              }`}
              onClick={() => {
                setName('');
                setAmount('500.00');
                setFees(property.propertyadditionalfee_set);
                setShow(true);
              }}
            >
              <div className="relative">
                {websitePricingStatus == 'Active' ? (
                  <Chip className="absolute w-15 h-6 top-[-12px] left-[6px] bg-success-50 border-1 border-success text-success">
                    Active
                  </Chip>
                ) : (
                  ''
                )}
                <div className="grid gap-2">
                  <div className="flex gap-4">
                    <div
                      className={`w-20 h-20 border rounded-lg flex items-center ${
                        websitePricingStatus == 'Active' ? 'border-success' : ''
                      }`}
                    >
                      <img
                        className="h-[40px] w-[32.05px] object-cover rounded m-auto"
                        src="/../../assets/images/waved-fee.png"
                      />
                    </div>
                    <div className="grid grid-cols-1 gap-1">
                      <div className="text-xl leading-7 font-semibold">
                        Website Pricing
                      </div>
                      <div className="text-base leading-6 font-normal">
                        Pull in pricing from your website to use in your Google
                        Ads.
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row-reverse gap-2">
                    <div className="flex w-[73px] rounded-xl border-1 text-sm leading-5 font-normal">
                      <div className="w-2 h-2 rounded-full bg-[#FF8162] my-auto mx-1"></div>
                      Source
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="gap-4 p-3 pt-6 items-center min-w-[380px] hover:bg-default-50 rounded-lg cursor-pointer relative border hidden">
              <div className="relative">
                <div className="grid gap-2">
                  <div className="flex gap-4">
                    <div className="w-20 h-20 border rounded-lg flex items-center">
                      <img
                        className="h-[13.06px] lg:w-[66px] object-cover rounded m-auto"
                        src="/../../assets/images/CRM.png"
                      />
                    </div>
                    <div className="grid grid-cols-1 gap-1">
                      <div className="text-xl leading-7 font-semibold">
                        Funnel CRM
                      </div>
                      <div className="text-base leading-6 font-normal">
                        Send lead, tour, application, and lease data back to
                        Google Ads.
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-row-reverse gap-2">
                    <div className="flex w-[73px] rounded-xl border-1 text-sm leading-5 font-normal">
                      <div className="w-2 h-2 rounded-full bg-[#FF8162] my-auto mx-1"></div>
                      Source
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <RightPanelModal
        show={show}
        onHide={() => setShow(false)}
        onClose={() => setShow(false)}
        title="Website Pricing Integration"
        handleConfirm={() => {
          setShow(false);
          setAssigneeModal('fee');
        }}
        confirmTxt={'Save'}
        content={
          <>
            <div className="font-saans-trial">
              <div className="mt-8 leading-7">
                <div className="text-base leading-5 mt-5">
                  Check your integration status and add additional fees below.
                </div>
              </div>
              <div className="divide-y border rounded-lg mt-8">
                <div className="p-4 flex flex-wrap justify-between gap-2 text-lg leading-7 font-semibold">
                  <div className="grid">Integration Status</div>
                  {websitePricingStatus == 'Not Set Up' ? (
                    <Chip color="danger" variant="flat">
                      Not Set Up
                    </Chip>
                  ) : (
                    <Chip color="success" variant="flat">
                      Active
                    </Chip>
                  )}
                </div>
              </div>
              <div className="divide-y border rounded-lg my-8">
                <div className="p-4 flex flex-wrap gap-2 text-lg leading-7 font-semibold justify-between">
                  <div className="grid">Mandatory Fees (Monthly)</div>
                  {(addFeeFlag || fees.length > 0) && (
                    <Button
                      variant="solid"
                      radius="sm"
                      onClick={() => {
                        setAddFeeFlag(true);
                        setEditFee(-1);
                        setName('');
                        setAmount('500.00');
                      }}
                      className="w-[110px] h-8 bg-default-100 text-sm leading-5 font-medium"
                      startContent={
                        <img
                          src="/assets/images/add-circle-black.png"
                          width={20}
                          alt=""
                        />
                      }
                    >
                      New Fee
                    </Button>
                  )}
                </div>
                <div className="p-4 grid gap-3">
                  {loadingFlag}
                  {fees.length > 0 &&
                    fees.map((item, index) =>
                      index != editFee ? (
                        <div className="flex justify-between p-5 bg-default-50 rounded-lg">
                          <div className="grid gap-2 text-sm leading-5 font-medium">
                            <div>{item.name}</div>
                            <div>{item.amount}</div>
                          </div>
                          <div className="flex justify-between gap-4">
                            <img
                              src="/assets/images/edit-2.png"
                              className="m-auto cursor-pointer"
                              width={24}
                              alt=""
                              onClick={() => {
                                setName(item.name);
                                setAmount(item.amount);
                                setEditFee(index);
                                setAddFeeFlag(false);
                              }}
                            />
                            <img
                              src="/assets/images/trash.png"
                              className="m-auto cursor-pointer"
                              width={24}
                              alt=""
                              onClick={() => {
                                const index = fees.indexOf(item);
                                let results = [...fees];
                                results.splice(index, 1);
                                setFees(results);
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="p-6 pt-4 bg-default-50 rounded-lg grid">
                          <div className="flex justify-end">
                            <Button
                              isIconOnly
                              variant="light"
                              radius="full"
                              onClick={() => {
                                setEditFee(-1);
                              }}
                            >
                              <img
                                src="/assets/images/close.png"
                                width={32}
                                alt=""
                              />
                            </Button>
                          </div>
                          <div className="grid gap-3 mb-4">
                            <div className="text-sm leading-5 font-medium">
                              Fee Name
                            </div>
                            <Input
                              classNames={{
                                inputWrapper: 'bg-white',
                              }}
                              type="text"
                              radius="sm"
                              value={name}
                              onChange={(e) => {
                                setName(e.target.value);
                              }}
                              variant="bordered"
                              placeholder="Name your fee"
                            />
                          </div>
                          <div className="grid gap-3 mb-4">
                            <div className="text-sm leading-5 font-medium l">
                              Fee Amount (Monthly)
                            </div>
                            <Input
                              classNames={{
                                inputWrapper: 'bg-white',
                              }}
                              type="text"
                              radius="sm"
                              value={amount}
                              onChange={(e) => {
                                setAmount(e.target.value);
                              }}
                              variant="bordered"
                              placeholder="Name your fee"
                              startContent={
                                <div className="w-[56px] h-[52px] bg-default-100 flex items-center ml-[-12px] mt-[-1px] rounded-l-lg mr-2">
                                  <div className="m-auto">
                                    <img
                                      src="/assets/images/dollar.png"
                                      height={24}
                                      alt=""
                                    />
                                  </div>
                                </div>
                              }
                            />
                          </div>
                          <div className="border-t flex justify-between gap-4 pt-4">
                            <Button
                              variant="bordered"
                              radius="sm"
                              onClick={() => {
                                fees[index].name = name;
                                fees[index].amount = amount;
                                setFees(fees);
                                setEditFee(-1);
                              }}
                              className="w-full h-10 text-base leading-6 font-medium"
                            >
                              Edit
                            </Button>
                          </div>
                        </div>
                      ),
                    )}
                  {!addFeeFlag && fees.length == 0 && (
                    <Button
                      variant="solid"
                      radius="sm"
                      onClick={() => {
                        setAddFeeFlag(true);
                      }}
                      className="w-full h-12 bg-default-100 text-base leading-6 font-medium"
                      startContent={
                        <img
                          src="/assets/images/add-circle-black.png"
                          width={24}
                          alt=""
                        />
                      }
                    >
                      New Fee
                    </Button>
                  )}
                  {addFeeFlag && (
                    <div className="p-6 pt-4 bg-default-50 rounded-lg grid">
                      <div className="flex justify-end">
                        <Button
                          isIconOnly
                          variant="light"
                          radius="full"
                          onClick={() => setAddFeeFlag(false)}
                        >
                          <img
                            src="/assets/images/close.png"
                            width={32}
                            alt=""
                          />
                        </Button>
                      </div>
                      <div className="grid gap-3 mb-4">
                        <div className="text-sm leading-5 font-medium">
                          Fee Name
                        </div>
                        <Input
                          classNames={{
                            inputWrapper: 'bg-white',
                          }}
                          type="text"
                          radius="sm"
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                          variant="bordered"
                          placeholder="Name your fee"
                        />
                      </div>
                      <div className="grid gap-3 mb-4">
                        <div className="text-sm leading-5 font-medium l">
                          Fee Amount (Monthly)
                        </div>
                        <Input
                          classNames={{
                            inputWrapper: 'bg-white',
                          }}
                          type="text"
                          radius="sm"
                          value={amount}
                          onChange={(e) => {
                            setAmount(e.target.value);
                          }}
                          variant="bordered"
                          placeholder="Name your fee"
                          startContent={
                            <div className="w-[56px] h-[52px] bg-default-100 flex items-center ml-[-12px] mt-[-1px] rounded-l-lg mr-2">
                              <div className="m-auto">
                                <img
                                  src="/assets/images/dollar.png"
                                  height={24}
                                  alt=""
                                />
                              </div>
                            </div>
                          }
                        />
                      </div>
                      <div className="border-t flex justify-between gap-4 pt-4">
                        <div className="w-10 h-10 border rounded-lg flex items-center">
                          <img
                            src="/assets/images/trash.png"
                            className="m-auto"
                            width={24}
                            alt=""
                          />
                        </div>
                        <Button
                          variant="bordered"
                          radius="sm"
                          onClick={() => {
                            handleAddFee();
                          }}
                          className="w-full h-10 text-base leading-6 font-medium"
                        >
                          Add
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </>
        }
      />
      <EditAssigneeModal
        show={assigneeModal == 'fee'}
        onHide={() => setAssigneeModal(false)}
        onClose={() => setAssigneeModal(false)}
        contentClassName="p-10 rounded-2xl"
        content={
          <div className="font-saans-trial">
            <div className="text-2xl leading-8 font-semibold">
              Are you sure you want to add this fee?
            </div>
            <div className="text-lg leading-7 font-normal mt-2">
              Mandatory fees show higher monthly rental rates to comply with
              local laws. Are you sure you want to proceed?
            </div>
            <div className="p-5 mt-4 bg-default-50 text-lg leading-7 font-normal rounded-lg">
              A $2000 rent with a $100 mandatory monthly fee will be advertised
              as $2100.
            </div>
            <div className="flex gap-4 justify-between mt-6 border-t pt-6">
              <Button
                variant="bordered"
                radius="sm"
                onClick={() => {
                  setAssigneeModal(false);
                }}
                className="w-full h-[52px] text-base leading-6 font-medium"
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                radius="sm"
                onClick={() => {
                  handleEditFees();
                  setAssigneeModal(false);
                }}
                className="w-full h-[52px] bg-[#242424] text-white text-base leading-6 font-medium"
              >
                Proceed
              </Button>
            </div>
          </div>
        }
      />
      <EditAssigneeModal
        show={assigneeModal == 'gads'}
        onHide={() => setAssigneeModal(false)}
        onClose={() => setAssigneeModal(false)}
        contentClassName="p-10 rounded-2xl"
        content={
          <div className="font-saans-trial">
            <div className="grid gap-2">
              <div className="text-2xl leading-8 font-semibold">
                This is a Manual Integration
              </div>
              <div className="grid gap-1">
                <div className="text-lg leading-7 mt-2">
                  This integration is set up manually by a Flair team member. If
                  you have questions on this integrations status for your
                  property, please reach out to your
                </div>
                <div className="flex items-center gap-2">
                  <div className="flex-initial text-lg leading-7">
                    account manager or email
                  </div>
                  <div
                    className="flex flex-1 justify-center gap-4 rounded-xl px-2 py-1 bg-[#E9EDFF] cursor-pointer"
                    onClick={() => {
                      copy('integrations@getflair.io');
                    }}
                  >
                    <div className="text-[#7384F5] text-sm leading-5 font-medium">
                      integrations@getflair.io
                    </div>
                    <div className="my-auto relative">
                      <div className="absolute top-[-6px] left-[-6px] w-[8.17px] h-[8.17px] border-1 border-[#7384F5]"></div>
                      <div className="absolute top-[-3px] left-[-9px] w-[8.17px] h-[8.17px] border-1 border-[#7384F5] bg-[#E9EDFF]"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="flex mt-6 border-t pt-6">
              <Button
                variant="solid"
                radius="sm"
                onClick={() => {
                  setAssigneeModal(false);
                }}
                className="w-full h-[52px] bg-[#242424] text-white text-base leading-6 font-medium"
              >
                Ok
              </Button>
            </div>
          </div>
        }
      />
    </>
  );
}
