/**
 * returns user-friendly status name
 * @param {Project} project
 */
export const projectStatusName = (project) => {
  if (!project) {
    return null;
  }
  let status;
  if (project.gad_customer_id) {
    if (['4PAUS', '6TOAV'].includes(project.ads_status)) {
      status = 'Paused';
    } else if (['7ACTV', '5TOPS'].includes(project.ads_status)) {
      status = 'Live';
    } else {
      status = 'Pending';
    }
  } else {
    status = 'Pending';
  }
  return status;
};

const statusColorMap = {
  Paused: 'rgb(253, 246, 208)',
  Pending: 'rgb(253, 235, 231)',
  Live: 'rgb(235, 250, 243)',
};

export const projectStatusColor = (project) => {
  if (!project) return null;
  return statusColorMap[projectStatusName(project)];
};

export const monthlyBudget = (dailyBudget) => {
  return Math.round(dailyBudget * 30.4);
};
