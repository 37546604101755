import { store } from '../app/store';
import { showAlert, hideAlert } from '../features/uxdialog/uxalertSlice';
import { showConfirm, hideConfirm } from '../features/uxdialog/uxconfirmSlice';
import { hidePrompt, showPrompt } from '../features/uxdialog/uxpromptSlice';

class UxDialog {
  async showPrompt(request) {
    // TODO:
    throw new Error(
      'uxQueue.showPrompt() is not implemented. Please use uxQueue.prompt()',
    );
  }

  async prompt(request) {
    if (request.handlePrimary && request.handleSecondary) {
      // Callback style of getting response
      store.dispatch(showPrompt(request));
      return;
    } else {
      // Promise style of getting response.
      return new Promise((resolve, reject) => {
        const handlePrimary = (value) => {
          this.hidePrompt();
          resolve(value);
        };
        const handleSecondary = (value) => {
          this.hidePrompt();
          resolve(null);
        };
        request.handlePrimary = handlePrimary;
        request.handleSecondary = handleSecondary;
        request.handleClose = handleSecondary;
        store.dispatch(showPrompt(request));
      });
    }
  }

  hidePrompt() {
    store.dispatch(hidePrompt());
  }

  async confirm(request) {
    // Promise style of getting response.
    return new Promise((resolve, reject) => {
      const handleConfirm = () => {
        store.dispatch(hideConfirm());
        resolve(true);
      };
      const handleCancel = () => {
        store.dispatch(hideConfirm());
        resolve(false);
      };
      request.handleConfirm = handleConfirm;
      request.handleCancel = handleCancel;
      request.handleClose = handleCancel;
      store.dispatch(showConfirm(request));
    });
  }

  async alert(request) {
    // Promise style of getting response.
    return new Promise((resolve, reject) => {
      const handleClose = () => {
        store.dispatch(hideAlert());
        resolve();
      };
      request.handleClose = handleClose;
      store.dispatch(showAlert(request));
    });
  }
}

const uxDialog = new UxDialog();

export { UxDialog };
export default uxDialog;
