import React from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash-es';
import { noticeTypeColors } from '../../../src/utils/constants';
import { DateTime } from 'luxon';
// import { getXOffset } from '../../utils/publicFunctions';
// import { svgIcons } from '../svgIcons/svgIcons';

export const getNoticeItemColor = (notice) => {
  let color = null;
  color = noticeTypeColors[notice.notification.notice_type.label];
  if (notice.notification.tags.length > 0) {
    color = 'red';
  }
  color = color || 'gray';
  return color;
};

/**
 * returns notice checkout behavior information.
 * @param {Notice} notice
 * @returns
 * `{title:'Edit Property', path:'/properties/edit/10'}` | `null`
 *
 * `null` means no behavior defined.
 */
export const noticeCheckout = (notice) => {
  let title = 'view';
  let path = null;
  if (
    get(notice, 'notification.notice_type.label', '').startsWith('property/')
  ) {
    const targetId = get(notice, 'notification.target_object.id');
    if (!targetId) {
      return null;
    }
    path = `/properties/edit/${targetId}`;
  } else if (
    get(notice, 'notification.notice_type.label', '').startsWith('project/')
  ) {
    const targetId = get(notice, 'notification.target_object.id');
    if (!targetId) {
      return null;
    }
    path = `/campaign/account-overview?project=${targetId}`;
  } else if (
    get(notice, 'notification.notice_type.label', '').startsWith(
      'budgetrecommendation/',
    )
  ) {
    path = `/notifications?budgetrecommendation_id=${get(
      notice,
      'notification.target_object.id',
    )}`;
  } else {
    return null;
  }
  if (path) {
    return { title, path };
  } else {
    return null;
  }
};

// TODO: This is temporary measure.
const convertProjectToCampaigns = (strMessage) => {
  if (!strMessage) return ''; // no need to process empty or null
  if (strMessage.indexOf('with enabled project')) {
    strMessage = strMessage.replace('with enabled project', 'with campaigns');
  }
  if (strMessage.startsWith('Project ')) {
    strMessage = 'Campaigns ' + strMessage.substr(8);
    return strMessage.replace(' was ', ' were ');
  } else {
    return strMessage;
  }
};

export const prepareNoticesForDisplay = (_notices) => {
  _notices.forEach((notice) => {
    notice._message = convertProjectToCampaigns(
      notice.message || notice.notification.message,
    );
    if (notice.notification.notice_type.label.startsWith('company/')) {
      notice._highlight_photo = notice.notification.target_object.logo;
    } else if (notice.notification.notice_type.label.startsWith('user/')) {
      const name = notice.notification.target_object.name;
      let initial = '?';
      if (name.length > 0) {
        initial = name[0].toUpperCase();
      }
      notice._initial = initial;
    } else if (notice.notification.notice_type.label.startsWith('property/')) {
      if (notice.notification.target_object)
        notice._highlight_photo =
          notice.notification.target_object.highlight_photo;
    } else if (notice.notification.notice_type.label.startsWith('project/')) {
      if (
        notice.notification.target_object &&
        notice.notification.target_object.property
      )
        notice._highlight_photo =
          notice.notification.target_object.property.highlight_photo;
    } else if (notice.notification.notice_type.label.startsWith('stats/')) {
      if (
        notice.notification.target_object &&
        notice.notification.target_object.property
      )
        notice._highlight_photo =
          notice.notification.target_object.property.highlight_photo;
    } else {
      // TODO: REPORT
    }
    notice._checkout = noticeCheckout(notice);
    notice._color = getNoticeItemColor(notice);
  });
};

export function makeGroup(values, decideGroup) {
  let result = {};
  for (const value of values) {
    const groupid = decideGroup(value);
    const group = result[groupid] ? result[groupid] : [];
    group.push(value);
    result[groupid] = group;
  }
  return result;
}

export default function Notificationlistcomponent({
  items = [],
  onCheckEvent,
  selectedIds,
}) {
  const itemGroups = makeGroup(items || [], (item) => {
    if (item.notification.notice_type.label.startsWith('company/')) {
      return 'company-' + item._company_id;
    } else if (item.notification.notice_type.label.startsWith('user/')) {
      return 'user-' + item.notification.target_object.id;
    } else if (
      item.notification.notice_type.label.startsWith('propperty/') ||
      item.notification.notice_type.label.startsWith('project/')
    ) {
      return 'property-' + item._property_id;
    } else {
      return 'unclassified';
    }
  });

  return (
    <>
      {Object.keys(itemGroups).map((groupid) => {
        const group = itemGroups[groupid];
        return (
          <div
            className="white-background grid gap-2"
            key={`notice-group-${groupid}`}
          >
            {group.map((item) => {
              return (
                <div
                  className="flex items-center"
                  key={`notice-item-${item.id}`}
                >
                  <label className="check-container ml-3 mr-5">
                    <input
                      type="checkbox"
                      checked={selectedIds.indexOf(item.id) >= 0}
                      onChange={(e) => onCheckEvent(item.id, e.target.checked)}
                      className="checkbox"
                    />
                    <span className="checkmark"></span>
                  </label>
                  <div
                    className="w-full px-3 xs:px-6 py-2 flex gap-6"
                    style={{
                      border: '1px solid rgba(22, 29, 55, 0.05)',
                      borderLeft: '5px solid',
                      borderLeftColor: getNoticeItemColor(item),
                    }}
                  >
                    <div className="relative">
                      <div className="w-10 h-10 text-center font-bold text-[18px] leading-[40px] rounded-full bg-[#F2F5F9]">
                        ?
                      </div>
                      {
                        item._highlight_photo ? (
                          <img
                            src={item._highlight_photo}
                            alt=""
                            onError={(e) => {
                              e.target.onerror = null;
                              e.target.src = '/assets/images/empty.png';
                            }}
                            className="absolute top-0 left-0 object-cover w-10 h-10 rounded-full"
                          />
                        ) : item._initial ? (
                          <div className="absolute top-0 left-0 w-10 h-10 bg-[#FF7F5F] text-white text-center font-bold text-[18px] leading-[40px] rounded-full">
                            {item._initial}
                          </div>
                        ) : (
                          <></>
                        ) // not possible
                      }
                      {!item.is_read && (
                        <div className="absolute -top-1 -left-1 w-[18px] h-[18px] rounded-full bg-[#EE380D] border-3 border-white " />
                      )}
                    </div>
                    <div className="w-full flex flex-wrap justify-between items-center">
                      <p className="">
                        {item._message}&nbsp;&nbsp;
                        {item._checkout && (
                          <Link
                            to={item._checkout.path}
                            className="text-[#687BFE] underline"
                          >
                            {item._checkout.title}&nbsp;&nbsp;&gt;
                          </Link>
                        )}
                      </p>
                      <div className="font-normal text-[14px] leading-[18.2px] text-[#8C94A3]">
                        {DateTime.fromISO(item['created_at']).toLocaleString(
                          DateTime.DATETIME_MED,
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
}
