import { DateTime } from 'luxon';
import { TimeZones } from './constants';

export const getTimeZones = () => {
  const result = [];
  for (const timeZone of TimeZones) {
    const nowInTz = DateTime.now().setZone(timeZone);
    result.push({
      value: timeZone,
      name: nowInTz.zone.zoneName,
      offset: nowInTz.offset,
      now: nowInTz,
      now_str: nowInTz.toLocaleString(DateTime.TIME_SIMPLE),
      label: nowInTz.toFormat('ZZZZZ (ZZ) - t'),
    });
  }
  return result;
};

export const formatPeriod = (dtFrom, dtTo) => {
  // Expect the hour,min,second,millisecond set as zero.
  if (dtFrom.ts === dtTo.ts) {
    // Single Day
    return dtFrom.toLocaleString(DateTime.DATE_MED);
  } else if (dtFrom.year === dtTo.year) {
    return `${dtFrom.toFormat('LLL d')} - ${dtTo.toFormat('LLL d, y')}`;
  } else {
    return `${dtFrom.toFormat('LLL d, y')} - ${dtTo.toFormat('LLL d, y')}`;
  }
};
