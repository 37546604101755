import { isNumber } from 'lodash-es';
import { svgIcons } from '../components/svgIcons/svgIcons';
import { convertNumberType } from './publicFunctions';

function styleDiff(num1, num2) {
  if ((num1.toFixed(2) - num2.toFixed(2)).toFixed(2) === 0) return '';
  return convertNumberType((num1 - num2).toFixed(2));
}

const decorator = (prefix, value, postfix) =>
  `${prefix || ''}${prefix ? ' ' : ''}${value}${postfix || ''}`;

export function ChartDiff({
  valuesLength,
  firstVal,
  secondVal,
  isPositive,
  prefix,
  postfix,
}) {
  const isShowFirstVal = valuesLength > 0;
  const isShowDiff =
    valuesLength > 1 &&
    isNumber(firstVal) &&
    isNumber(secondVal) &&
    (firstVal - secondVal).toFixed(2) !== '0.00';
  const isShowSecondVal =
    valuesLength > 1 && isNumber(firstVal) ^ isNumber(secondVal);

  let colorClass = '';
  let iconDir = '';
  if (firstVal - secondVal < 0) {
    if (isPositive) {
      colorClass = 'text-[#EE380D]';
      iconDir = 'down';
    } else {
      colorClass = 'text-[#EE380D]';
      iconDir = 'down';
    }
  } else {
    if (!isPositive) {
      colorClass = 'text-[#EE380D]';
      iconDir = 'up';
    } else {
      colorClass = 'text-[#EE380D]';
      iconDir = 'up';
    }
  }

  return (
    <div className="flex gap-[6.6px] items-end">
      <div className="font-semibold text-[22px] leading-[28.6px]">
        {isShowFirstVal ? (
          isNaN(firstVal) || !isNumber(firstVal) ? (
            'N/A'
          ) : (
            decorator(prefix, convertNumberType(firstVal), postfix)
          )
        ) : (
          <></>
        )}
      </div>
      <div>
        {isShowDiff ? (
          <div className={'flex ' + colorClass}>
            <div className="w-[10.37px] h-[10.67px]">{svgIcons[iconDir]}</div>
            <div className="font-semibold text-[14px] leading-4 indent-[2.82px]">
              {decorator(
                prefix,
                Math.abs(styleDiff(firstVal, secondVal)),
                postfix,
              )}
            </div>
          </div>
        ) : isShowSecondVal ? (
          !isNumber(secondVal) || isNaN(secondVal) ? (
            'N/A'
          ) : (
            convertNumberType(secondVal)
          )
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
