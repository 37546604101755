import { useEffect } from 'react';
import { createSearchParams, useNavigate, useParams } from 'react-router-dom';

import uxDialog from '../../../utils/uxdialog';
import { apiAgent } from '../../../utils/apicall';
export default function SLink() {
  const params = useParams();
  const navigator = useNavigate();

  useEffect(() => {
    (async function () {
      try {
        const response = await apiAgent.get({
          path: '/reports/decode_token',
          params: {
            token: params.token,
          },
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        const responseData = await response.json();
        if (responseData.result === 'failed') {
          await uxDialog.alert({
            text: 'url is not correct',
          });
          navigator('failed');
          return;
        }
        navigator({
          pathname: responseData.data.page,
          search: `?${createSearchParams({
            period: responseData.data.period.join('~'),
          })}`,
        });
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return <></>;
}
