import { useEffect } from 'react';
// custom
import config from '../../utils/config';
import uxDialog from '../../utils/uxdialog';

export default function Extra() {
  const serverErr = new URLSearchParams(window.location.search).get('error');
  const token = new URLSearchParams(window.location.search).get('token');

  const handleErr = async () => {
    // if (window.location.href !== 'sign') return;
    // display error that invitation is accepted
    if (token) {
      if (token === 'expired')
        await uxDialog.alert({
          text: 'This invitation has expired. Please request a new one.',
        });
      if (token === 'used')
        await uxDialog.alert({
          text: 'This invitation has been used already.',
        });
      if (token === 'invalid')
        await uxDialog.alert({
          text: 'This invitation is invalid.',
        });
    }
    // display server error when sign up or sign in
    if (serverErr) {
      await uxDialog.alert({
        text: serverErr,
      });
    }
    if (token || serverErr) {
      window.location.href =
        config.serverurl + 'users/redirect_auth0_login_page/';
    } else {
      window.location.href = '/properties';
    }
  };

  useEffect(() => {
    handleErr();
  }, []);
  return <></>;
}
