import React from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto'; // important
import { map, zip } from 'lodash-es';

// export const footerOption = (tooltipItems) => {
//   let sum = 0;

//   tooltipItems.forEach(function (tooltipItem) {
//     sum += tooltipItem.parsed.y;
//   });
//   return 'Average: ' + sum / tooltipItems.length + '%';
// };

const options = {
  responsive: true,
  maintainAspectRatio: false,
  pointBackgroundColor: 'red',
  interaction: {
    mode: 'index',
  },
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
      labels: {
        usePointStyle: true,
        boxWidth: 10,
      },
    },
    tooltip: {
      // callbacks: {
      //   footer: footerOption,
      // },
      backgroundColor: 'white',
      titleColor: '#515868',
      titleFont: {
        weight: 500,
        size: 14,
      },
      bodyFont: {
        weight: 500,
        size: 14,
      },
      bodyColor: '#353E4F',
      footerFont: {
        weight: 500,
        size: 12,
      },
      footerAlign: 'right',
      footerColor: '#161D37',
      borderColor: '#E9EBF1',
      borderWidth: 1,
      padding: 16,
    },
  },
};

export default function LineChartClicks({ setlabels, labels, datasets }) {
  // const dummyData = {
  //   labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun'],
  //   datasets: [
  //     {
  //       label: 'Today',
  //       data: [33, 25, 35, 51, 54, 76],
  //       fill: false,
  //       borderColor: 'rgba(204, 12, 2, 1)'
  //     }
  //   ]
  // }
  let data = {
    labels,
    datasets: map(zip(setlabels, datasets), ([setlabel, dataset], index) => ({
      label: setlabel,
      data: dataset,
      fill: index === 1,
      borderColor:
        index === 0 ? 'rgba(234, 126, 50, 1)' : 'rgba(233, 236, 241, 1)',
      pointBackgroundColor:
        index === 0 ? 'rgba(234, 126, 50, 1)' : 'rgba(233, 236, 241, 1)',
    })),
  };
  return <Line options={options} data={data} />;
}
