import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import { showLoading } from '../../../features/loading/loadingSlice';
import ConfirmModal from '../../../components/modalbootstrap/ConfirmModal';
import SuccessModal from '../../../components/modalbootstrap/SuccessModal';
import { apiAgent } from '../../../utils/apicall';
import { Link, useParams } from 'react-router-dom';
import UploadHighlightPhoto from '../../../components/imageupload/UploadHighlightPhoto';
import UploadPhoto from '../../../components/imageupload/UploadPhoto';
import {
  PhotoCategoryList,
  // StateNames,
  // ProvinceNames,
} from '../../../utils/constants';
import { getTimeZones } from '../../../utils/times';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import uxDialog from '../../../utils/uxdialog';
import { svgIcons } from '../../../components/svgIcons/svgIcons';
import Skeleton from 'react-loading-skeleton';
let body;
let selectedItem;
let pageTitle = null;

export default function EditProperty() {
  const dispatch = useDispatch();
  const params = useParams();
  const propertyId = params.id;
  // UI Ref
  const formElement = useRef(null);
  // UI States
  const [property, setProperty] = useState([]);
  const [updateFlags, setUpdateFlags] = useState({});
  // const [timeZoneArr, setTimeZoneArr] = useState([])
  // const [provinceArray, setProvinceArray] = useState([])
  // const [modalDeletePhotoShow, setModalDeletePhotoShow] = useState(false)
  // const [modalDeleteAmenityShow, setModalDeleteAmenityShow] = useState(false)
  // const [modalDeleteUnitShow, setModalDeleteUnitShow] = useState(false)
  // const [modalSaveShow, setModalSaveShow] = useState(false)
  // const [modalResetShow, setModalResetShow] = useState(false)
  const [modal, setModal] = useState(null);
  const [infoLoaded, setInfoLoaded] = useState(false);

  // Utility Function
  const fetchProperty = async () => {
    // fetch property and update property state
    try {
      setInfoLoaded(false);
      const response = await apiAgent.get({
        path: `/properties/${propertyId}`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      body = await response.json();
      setProperty(body);
      // Set page title and save navigation history
      pageTitle = `Edit Property - ${body.name}`;
      window.document.title = `${pageTitle}`;

      let _updateFlags = {};
      for (const key in body) {
        if (Object.hasOwnProperty.call(body, key)) {
          if (key.endsWith('_set')) {
            _updateFlags[key] = [];
            for (const item of body[key]) {
              let flagItem = {};
              for (const itemkey in item) {
                if (Object.hasOwnProperty.call(item, itemkey)) {
                  if (itemkey === 'id') {
                    flagItem['pk'] = true;
                  } else if (
                    ['property', 'created_at', 'updated_at'].indexOf(itemkey) <
                    0
                  ) {
                    flagItem[itemkey] = false;
                  }
                }
              }
              _updateFlags[key].push(flagItem);
            }
          } else {
            _updateFlags[key] = false;
          }
        }
      }
      setUpdateFlags(_updateFlags);
      let _timezones = getTimeZones();
      const companyTimeZone = _timezones.find(
        (timezone) => timezone.value === body.company.time_zone,
      );
      _timezones = [
        { value: '', label: 'Default: ' + companyTimeZone.label },
      ].concat(_timezones);
      // setTimeZoneArr(_timezones)
      // setProvinceArray(ProvinceNames[body.country_code])
      setInfoLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  // Event Handlers
  useEffect(() => {
    pageTitle = null;
    window.document.title = `Edit Property - Loading...`;
    fetchProperty(propertyId);
  }, [propertyId]);

  // useEffect(() => {
  //   if (property)
  //     setProvinceArray(ProvinceNames[property.country_code])
  // }, [property])

  // UI Event Handlers
  const handleSubmit = async (e) => {
    let isHavePhoto = true;
    e.preventDefault();
    let _updateNewPhotoFlags = updateFlags['propertyphoto_set'];
    let propOwn;
    for (const item of _updateNewPhotoFlags) {
      propOwn = Object.getOwnPropertyNames(item);
      if (propOwn.length === 3 && item['photo'] === false) isHavePhoto = false;
    }
    if (!isHavePhoto)
      await uxDialog.alert({ text: 'Property Photo file is required!' });
    else setModal('Save');
  };
  //
  const [nextNewPhotoId, setNextNewPhotoId] = useState(-1);
  const handleAddPropertyPhoto = () => {
    let photoSet = property.propertyphoto_set;
    photoSet.push({
      id: nextNewPhotoId,
      name: '',
      photo: '',
      category: '*-*',
    });
    setProperty({
      ...property,
      propertyphoto_set: photoSet,
    });
    let propertyPhotoUpdateFlags = updateFlags.propertyphoto_set;
    propertyPhotoUpdateFlags.push({
      name: false,
      category: true,
      photo: false,
    });
    setUpdateFlags({
      ...updateFlags,
      propertyphoto_set: propertyPhotoUpdateFlags,
    });
    setNextNewPhotoId(nextNewPhotoId + 1);
  };

  const [nextNewAmenityId, setNextNewAmenityId] = useState(-1);
  const handleAddPropertyAmenity = () => {
    let amenitySet = property.propertyamenity_set;
    amenitySet.push({
      id: nextNewAmenityId,
      name: '',
      description: '',
    });
    setProperty({
      ...property,
      propertyamenity_set: amenitySet,
    });
    let amenityUpdateFlags = updateFlags.propertyamenity_set;
    amenityUpdateFlags.push({
      name: false,
      description: false,
    });
    setUpdateFlags({ ...updateFlags, propertyamenity_set: amenityUpdateFlags });
    setNextNewAmenityId(nextNewAmenityId + 1);
  };

  const [nextNewUnitId, setNextNewUnitId] = useState(-1);
  const handleAddPropertyUnit = () => {
    let unitSet = property.propertyunit_set;
    unitSet.push({
      id: nextNewUnitId,
      bedrooms: '',
      bathrooms: '',
      floor_area: '',
      starting_rent: '',
    });
    setProperty({
      ...property,
      propertyunit_set: unitSet,
    });
    let unitUpdateFlags = updateFlags.propertyunit_set;
    unitUpdateFlags.push({
      bedrooms: false,
      bathrooms: false,
      floor_area: false,
      starting_rent: false,
    });
    setUpdateFlags({ ...updateFlags, propertyunit_set: unitUpdateFlags });
    setNextNewUnitId(nextNewUnitId + 1);
  };

  const handleDeletePhoto = () => {
    let photoSet = property.propertyphoto_set;
    const index = photoSet.indexOf(selectedItem);
    photoSet.splice(index, 1);
    setProperty({ ...property, propertyphoto_set: photoSet });
    let updatePhotoSetFlag = updateFlags.propertyphoto_set;
    updatePhotoSetFlag.splice(index, 1);
    setUpdateFlags({ ...updateFlags, propertyphoto_set: updatePhotoSetFlag });
  };

  const handleDeleteAmenity = () => {
    let amenitySet = property.propertyamenity_set;
    const index = amenitySet.indexOf(selectedItem);
    amenitySet.splice(index, 1);
    setProperty({ ...property, propertyamenity_set: amenitySet });
    let updateAmenitySetFlag = updateFlags.propertyamenity_set;
    updateAmenitySetFlag.splice(index, 1);
    setUpdateFlags({
      ...updateFlags,
      propertyamenity_set: updateAmenitySetFlag,
    });
  };

  const handleDeleteUnit = () => {
    let unitSet = property.propertyunit_set;
    const index = unitSet.indexOf(selectedItem);
    unitSet.splice(index, 1);
    setProperty({ ...property, propertyunit_set: unitSet });
    let updateUnitSetFlag = updateFlags.propertyunit_set;
    updateUnitSetFlag.splice(index, 1);
    setUpdateFlags({ ...updateFlags, propertyunit_set: updateUnitSetFlag });
  };

  const handleDeletePhotoConfirm = () => {
    handleDeletePhoto();
    setModal(null);
  };

  const handleDeleteAmenityConfirm = () => {
    handleDeleteAmenity();
    setModal(null);
  };

  const handleDeleteUnitConfirm = () => {
    handleDeleteUnit();
    setModal(null);
  };

  const handleResetConfirm = () => {
    fetchProperty();
    setModal(null);
  };

  // const handleCancel = () => {
  //   setModal('Reset');
  // };

  const getUpdateFlag = (formFieldName, updateFlagData) => {
    if (!updateFlagData) {
      updateFlagData = updateFlags;
    }
    const idxBracket = formFieldName.indexOf('[');
    if (idxBracket < 0) {
      return updateFlagData[formFieldName];
    } else if (idxBracket === 0) {
      const idxClosingBracket = formFieldName.indexOf(']');
      const index = parseInt(formFieldName.substring(1, idxClosingBracket));
      return getUpdateFlag(
        formFieldName.substring(idxClosingBracket + 1),
        updateFlagData[index],
      );
    } else {
      // idxBracket > 0
      return getUpdateFlag(
        formFieldName.substring(idxBracket),
        updateFlagData[formFieldName.substring(0, idxBracket)],
      );
    }
  };

  const handleSaveConfirm = async () => {
    dispatch(showLoading('Saving your edits...'));
    let formData = new FormData(formElement.current);
    setModal(null);
    let deleteFields = [];
    for (const key of formData.keys()) {
      // determine if to remove the key
      // 1. get update flag
      const needSubmit = getUpdateFlag(key);
      // 2. if update flag === false, remove
      if (!needSubmit) {
        deleteFields.push(key);
      }
    }
    for (const key of deleteFields) {
      formData.delete(key);
    }
    if (updateFlags.pet_friendly) {
      if (!formData.has('pet_friendly')) {
        formData.set('pet_friendly', 'false');
      }
    }
    try {
      const response = await apiAgent.patch({
        path: `/properties/${propertyId}`,
        data: formData,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      console.log(response);
      setModal('ConfirmSaved');
    } catch (error) {
      console.log(error);
    }
    dispatch(showLoading(false));
  };

  return infoLoaded ? (
    <div className="px-0 xs:px-10">
      <Link
        to="#"
        onClick={() => {
          window.history.back();
        }}
        className="px-5 xs:px-0 py-8 font-semibold text-base leading-[17.6px] flex items-center"
      >
        <div className="w-[6px] h-[10px]">{svgIcons.left_arrow}</div>
        <div className="indent-3">Back</div>
      </Link>
      <div className="grid gap-4 px-5 xs:px-0">
        <h5>Edit property</h5>
        <p>
          You can edit property details here. Changes will be reflected in your
          campaigns.
        </p>
      </div>

      <form onSubmit={handleSubmit} ref={formElement}>
        <Tabs forceRenderTabPanel={true}>
          <TabList className="xs:hidden pl-5 font-medium text-[14px] leading-[18.2px] flex gap-8 mt-6 border-b border-b-[#E9ECF1] whitespace-nowrap overflow-scroll">
            <Tab
              className="pb-5 bg-transparent"
              selectedClassName="border-b-4 border-b-[#687BFE]"
            >
              General
            </Tab>
            <Tab
              className="pb-5 bg-transparent"
              selectedClassName="border-b-4 border-b-[#687BFE]"
            >
              Photos
            </Tab>
            <Tab
              className="pb-5 bg-transparent"
              selectedClassName="border-b-4 border-b-[#687BFE]"
            >
              Community Amenities
            </Tab>
            <Tab
              className="pb-5 bg-transparent"
              selectedClassName="border-b-4 border-b-[#687BFE]"
            >
              Property Floorplans
            </Tab>
          </TabList>

          <TabPanel
            className="tab-panel white-background mt-[6px] xs:mt-6"
            selectedClassName="block"
          >
            <UploadHighlightPhoto
              title="Highlight photo"
              btnCaption="Change"
              imgSrc={
                property.highlight_photo || '/assets/images/tile-noimg.svg'
              }
              name="highlight_photo"
              onChooseFile={() => {
                setUpdateFlags({
                  ...updateFlags,
                  highlight_photo: true,
                });
              }}
            />
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8 border-t pt-8 border-t-[#E9ECF1]">
              <div className="grid gap-2">
                <div className="text-[14px] leading-[18.2px]">
                  Property name
                </div>
                <input
                  className="flair-input"
                  type="text"
                  value={property.name || ''}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      name: e.target.value,
                    });
                    setUpdateFlags({ ...updateFlags, name: true });
                  }}
                  name="name"
                />
              </div>
            </div>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8 border-t pt-8 border-t-[#E9ECF1]">
              <div className="grid gap-2">
                <div className="text-[14px] leading-[18.2px]">
                  Homepage site link
                </div>
                <input
                  className="flair-input"
                  placeholder="http://"
                  type="text"
                  value={property.homepage_link || ''}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      homepage_link: e.target.value,
                    });
                    setUpdateFlags({
                      ...updateFlags,
                      homepage_link: true,
                    });
                  }}
                  name="homepage_link"
                />
              </div>
              <div className="grid gap-2">
                <div className="text-[14px] leading-[18.2px]">
                  Tracking phone number
                </div>
                <input
                  className="flair-input"
                  type="text"
                  value={property.tracking_phone || ''}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      tracking_phone: e.target.value,
                    });
                    setUpdateFlags({
                      ...updateFlags,
                      tracking_phone: true,
                    });
                  }}
                  name="tracking_phone"
                />
              </div>
            </div>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8 border-t pt-8 border-t-[#E9ECF1]">
              <div className="grid gap-2">
                <div className="text-[14px] leading-[18.2px]">Concessions</div>
                <input
                  className="flair-input"
                  type="text"
                  value={property.concessions || ''}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      concessions: e.target.value,
                    });
                    setUpdateFlags({
                      ...updateFlags,
                      concessions: true,
                    });
                  }}
                  name="concessions"
                />
              </div>
            </div>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8 border-t pt-8 border-t-[#E9ECF1]">
              <label className="check-container text-base leading-5 font-normal mb-0">
                Pet friendly
                <input
                  type="checkbox"
                  className="checkbox"
                  id="pet-friendly"
                  checked={property ? property.pet_friendly : false}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      pet_friendly: e.target.checked,
                    });
                    setUpdateFlags({
                      ...updateFlags,
                      pet_friendly: true,
                    });
                  }}
                  name="pet_friendly"
                />
                <span className="checkmark" />
              </label>
            </div>
          </TabPanel>

          <TabPanel
            className="tab-panel white-background px-0"
            selectedClassName="block"
          >
            <div className="font-bold text-[20px] leading-[26px] px-5 xs:px-8 mb-6">
              Photos
            </div>
            {property.propertyphoto_set.map((item, index) => {
              return (
                <div key={'property-photo~' + item.id}>
                  {parseInt(item.id) > 0 ? (
                    <input
                      type="hidden"
                      name={`propertyphoto_set[${index}]pk`}
                      value={item.id}
                    />
                  ) : (
                    <></>
                  )}
                  <div className="bg-[#F2F5F9] border-t border-t-[#E9ECF1] px-5 xs:px-8 py-2 flex justify-between items-center">
                    <p>
                      <span className="text-[#707787]">Property photo: </span>
                      &nbsp;
                      {property &&
                        `${item.name} (${
                          PhotoCategoryList[
                            property.propertyphoto_set[index]['category']
                          ]
                        })`}
                    </p>
                    <div className="flex gap-4">
                      {item.id >= 0 ? (
                        <a
                          href={item.photo}
                          style={{ whiteSpace: 'nowrap' }}
                          target="_blank"
                          rel="noreferrer"
                          download={item.photo.substring(
                            item.photo.lastIndexOf('/') + 1,
                          )}
                          className="font-semibold text-[14px] leading-[15.4px] flex indent-2"
                        >
                          <div className="w-4 h-[14px]">{svgIcons.upload}</div>
                          Download
                        </a>
                      ) : (
                        <></>
                      )}
                      {property.propertyphoto_set[index]['category'] ===
                      '*-*' ? (
                        <Link
                          to={'#'}
                          className="font-semibold text-[14px] leading-[15.4px] flex indent-2 text-[#EE380D]"
                          onClick={() => {
                            selectedItem = item;
                            setModal('DeletePhoto');
                          }}
                        >
                          <div className="w-[15px] h-[15px]">
                            {svgIcons.delete}
                          </div>
                          Delete
                        </Link>
                      ) : (
                        <></>
                      )}
                    </div>
                  </div>
                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 border-t px-5 xs:px-8 py-10 border-t-[#E9ECF1]">
                    <div>
                      <div
                        className="grid gap-2"
                        style={
                          property.propertyphoto_set[index]['category'] !==
                            '*-*' && item.id >= 0
                            ? { pointerEvents: 'none' }
                            : { pointerEvents: 'auto' }
                        }
                      >
                        <div className="text-[14px] leading-[18.2px]">
                          Photo name
                        </div>
                        <input
                          className="flair-input"
                          type="text"
                          value={item.name}
                          onChange={(e) => {
                            let photoSet = property.propertyphoto_set;
                            photoSet[index].name = e.target.value;
                            setProperty({
                              ...property,
                              propertyphoto_set: photoSet,
                            });
                            let updatePhotoSetFlag =
                              updateFlags.propertyphoto_set || [];
                            updatePhotoSetFlag[index].name = true;
                            setUpdateFlags({
                              ...updateFlags,
                              propertyphoto_set: updatePhotoSetFlag,
                            });
                          }}
                          name={`propertyphoto_set[${index}]name`}
                        />
                      </div>
                    </div>
                    <div className="photos-input-category-container" hidden>
                      <div className="text-[14px] leading-[18.2px]">
                        Image category
                      </div>
                      <input
                        className="flair-input"
                        type="text"
                        value="*-*"
                        onChange={(e) => {}}
                        name={`propertyphoto_set[${index}]category`}
                      />
                    </div>
                    <div
                      className="md:col-start-2 md:col-end-4 grid gap-2"
                      style={
                        property.propertyphoto_set[index]['category'] !==
                          '*-*' && item.id >= 0
                          ? { pointerEvents: 'none' }
                          : { pointerEvents: 'auto' }
                      }
                    >
                      <div className="text-[14px] leading-[18.2px]">
                        Photo file
                      </div>
                      <div className="relative">
                        <div className="flex items-center absolute top-0 left-4 h-full ">
                          <div className="w-[15px] text-[#8C94A3]">
                            {svgIcons.noimg}
                          </div>
                        </div>
                        <UploadPhoto
                          btnCaption="Browse..."
                          imageName={item.photo.substring(
                            item.photo.lastIndexOf('/') + 1,
                          )}
                          onChooseFile={() => {
                            let photoUpdateFlags =
                              updateFlags.propertyphoto_set;
                            photoUpdateFlags[index].photo = true;
                            setUpdateFlags({
                              ...updateFlags,
                              propertyphoto_set: photoUpdateFlags,
                            });
                          }}
                          name={`propertyphoto_set[${index}]photo`}
                        />
                      </div>
                      <div className="font-normal text-[14px] leading-[18.2px] text-[#707787]">
                        Supports PNG, JPEG, GIF files
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="px-5 xs:px-8 pt-6 border-t border-t-[#E9ECF1]">
              <button
                className="flex items-center indent-2 text-[#687BFE]"
                onClick={handleAddPropertyPhoto}
                type="button"
              >
                <div className="w-9 h-9 rounded-full bg-[#687BFE]/[0.2] text-[#687BFE] flex items-center justify-center indent-2">
                  <div className="w-[9px] h-[9px]">{svgIcons.plus}</div>
                </div>
                Add another property photo
              </button>
            </div>
          </TabPanel>

          <TabPanel
            className="tab-panel white-background px-0"
            selectedClassName="block"
          >
            <div className="font-bold text-[20px] leading-[26px] px-5 xs:px-8 mb-6">
              Community Amenities
            </div>
            {property.propertyamenity_set.map((item, index) => {
              return (
                <div className="" key={'property-amenity~' + item.id}>
                  {parseInt(item.id) > 0 ? (
                    <input
                      type="hidden"
                      name={`propertyamenity_set[${index}]pk`}
                      value={item.id}
                    />
                  ) : (
                    <></>
                  )}
                  <div className="bg-[#F2F5F9] border-t border-t-[#E9ECF1] px-5 xs:px-8 py-2 flex justify-between items-center">
                    <p>
                      <span className="text-[#707787]">Property amenity: </span>
                      &nbsp;
                      {property && item.name}
                    </p>
                    <Link
                      to={'#'}
                      className="font-semibold text-[14px] leading-[15.4px] flex indent-2 text-[#EE380D]"
                      onClick={() => {
                        selectedItem = item;
                        setModal('DeleteAmenity');
                      }}
                    >
                      <div className="w-[15px] h-[15px]">{svgIcons.delete}</div>
                      Delete
                    </Link>
                  </div>
                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 border-t px-5 xs:px-8 py-8 border-t-[#E9ECF1]">
                    <div className="grid gap-2">
                      <div className="text-[14px] leading-[18.2px]">
                        Amenity name
                      </div>
                      <input
                        className="flair-input"
                        type="text"
                        value={item.name}
                        onChange={(e) => {
                          let amenitySet = property.propertyamenity_set;
                          amenitySet[index].name = e.target.value;
                          setProperty({
                            ...property,
                            propertyamenity_set: amenitySet,
                          });
                          let updateAmenitySetFlag =
                            updateFlags.propertyamenity_set || [];
                          updateAmenitySetFlag[index].name = true;
                          setUpdateFlags({
                            ...updateFlags,
                            propertyamenity_set: updateAmenitySetFlag,
                          });
                        }}
                        name={`propertyamenity_set[${index}]name`}
                      />
                    </div>
                    <div className="grid gap-2">
                      <div className="text-[14px] leading-[18.2px]">
                        Amenity description
                      </div>
                      <input
                        className="flair-input"
                        type="text"
                        value={item.description}
                        onChange={(e) => {
                          let amenitySet = property.propertyamenity_set;
                          amenitySet[index].description = e.target.value;
                          setProperty({
                            ...property,
                            propertyamenity_set: amenitySet,
                          });
                          let updateAmenitySetFlag =
                            updateFlags.propertyamenity_set || [];
                          updateAmenitySetFlag[index].description = true;
                          setUpdateFlags({
                            ...updateFlags,
                            propertyamenity_set: updateAmenitySetFlag,
                          });
                        }}
                        name={`propertyamenity_set[${index}]description`}
                      />
                    </div>
                  </div>
                </div>
              );
            })}

            <div className="px-5 xs:px-8 pt-6 border-t border-t-[#E9ECF1]">
              <button
                className="flex items-center indent-2 text-[#687BFE]"
                onClick={handleAddPropertyAmenity}
                type="button"
              >
                <div className="w-9 h-9 rounded-full bg-[#687BFE]/[0.2] text-[#687BFE] flex items-center justify-center indent-2">
                  <div className="w-[9px] h-[9px]">{svgIcons.plus}</div>
                </div>
                Add another property amenity
              </button>
            </div>
          </TabPanel>

          <TabPanel
            className="tab-panel white-background px-0"
            selectedClassName="block"
          >
            <div className="font-bold text-[20px] leading-[26px] px-5 xs:px-8 mb-2">
              Floor Plans
            </div>
            <p className="mb-6 px-5 xs:px-8">
              Insert your floor plan types here.
            </p>
            {property.propertyunit_set.map((item, index) => {
              return (
                <div key={'property-photo~' + item.id}>
                  {parseInt(item.id) > 0 ? (
                    <input
                      type="hidden"
                      name={`propertyunit_set[${index}]pk`}
                      value={item.id}
                    />
                  ) : (
                    <></>
                  )}
                  <div className="bg-[#F2F5F9] border-t border-t-[#E9ECF1] px-5 xs:px-8 py-2 flex justify-between items-center">
                    <p>
                      <span className="text-[#707787]">
                        Property floorplan:{' '}
                      </span>
                      &nbsp;
                      {property && `floorplan: #${index + 1}`}
                    </p>
                    <Link
                      to={'#'}
                      className="font-semibold text-[14px] leading-[15.4px] flex indent-2 text-[#EE380D]"
                      onClick={() => {
                        selectedItem = item;
                        setModal('DeleteUnit');
                      }}
                    >
                      <div className="w-[15px] h-[15px]">{svgIcons.delete}</div>
                      Delete
                    </Link>
                  </div>
                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 border-t px-5 xs:px-8 py-8 border-t-[#E9ECF1]">
                    <div className="grid gap-2">
                      <div className="text-[14px] leading-[18.2px]">
                        Bedrooms
                      </div>
                      <input
                        className="flair-input"
                        type="text"
                        value={item.bedrooms}
                        onChange={(e) => {
                          let unitSet = property.propertyunit_set;
                          unitSet[index].bedrooms = e.target.value;
                          setProperty({
                            ...property,
                            propertyunit_set: unitSet,
                          });
                          let updateUnitSet =
                            updateFlags.propertyunit_set || [];
                          updateUnitSet[index].bedrooms = true;
                          setUpdateFlags({
                            ...updateFlags,
                            propertyunit_set: updateUnitSet,
                          });
                        }}
                        name={`propertyunit_set[${index}]bedrooms`}
                      />
                    </div>
                    <div className="grid gap-2">
                      <div className="text-[14px] leading-[18.2px]">
                        Bathrooms
                      </div>
                      <input
                        className="flair-input"
                        type="text"
                        value={item.bathrooms}
                        onChange={(e) => {
                          let unitSet = property.propertyunit_set;
                          unitSet[index].bathrooms = e.target.value;
                          setProperty({
                            ...property,
                            propertyunit_set: unitSet,
                          });
                          let updateUnitSet =
                            updateFlags.propertyunit_set || [];
                          updateUnitSet[index].bathrooms = true;
                          setUpdateFlags({
                            ...updateFlags,
                            propertyunit_set: updateUnitSet,
                          });
                        }}
                        name={`propertyunit_set[${index}]bathrooms`}
                      />
                    </div>
                    <div className="grid gap-2">
                      <div className="text-[14px] leading-[18.2px]">
                        Floor area (sq.ft.)
                      </div>
                      <input
                        className="flair-input"
                        type="text"
                        value={item.floor_area}
                        onChange={(e) => {
                          let unitSet = property.propertyunit_set;
                          unitSet[index].floor_area = e.target.value;
                          setProperty({
                            ...property,
                            propertyunit_set: unitSet,
                          });
                          let updateUnitSet =
                            updateFlags.propertyunit_set || [];
                          if (updateUnitSet)
                            updateUnitSet[index].floor_area = true;
                          setUpdateFlags({
                            ...updateFlags,
                            propertyunit_set: updateUnitSet,
                          });
                        }}
                        name={`propertyunit_set[${index}]floor_area`}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
            <div className="px-5 xs:px-8 pt-6 border-t border-t-[#E9ECF1]">
              <button
                className="flex items-center indent-2 text-[#687BFE]"
                onClick={handleAddPropertyUnit}
                type="button"
              >
                <div className="w-9 h-9 rounded-full bg-[#687BFE]/[0.2] text-[#687BFE] flex items-center justify-center indent-2">
                  <div className="w-[9px] h-[9px]">{svgIcons.plus}</div>
                </div>
                Add another property floorplan
              </button>
            </div>
          </TabPanel>
        </Tabs>
        <div className="px-5 xs:px-0 pt-10 pb-12 grid gap-4 xs:flex xs:flex-row-reverse">
          <button
            type="submit"
            className="flair-btn flair-btn-primary flair-btn-lg xs:w-[220px]"
          >
            Save
          </button>
          <Link
            to="#"
            onClick={() => {
              window.history.back();
            }}
            className="flair-btn flair-btn-secondary flair-btn-lg xs:w-[220px]"
          >
            {/* <div className="w-[6px] h-[10px]">{svgIcons.left_arrow}</div> */}
            <div className="indent-3">Cancel</div>
          </Link>
          {/* <button
            className="flair-btn flair-btn-secondary flair-btn-lg xs:w-[220px]"
            type="button"
            onClick={() => {
              handleCancel();
              // setModal('Reset');
            }}
          >
            Cancel
          </button> */}
        </div>
      </form>
      <ConfirmModal
        show={modal === 'DeletePhoto'}
        onHide={() => setModal(null)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Delete Property Photo"
        txt="Are you sure you want to delete?"
        // btn1='Confirm'
        // btn2='Cancel'
        handleConfirm={handleDeletePhotoConfirm}
        // handleCancel={() => setModal(null)}
      />
      <ConfirmModal
        show={modal === 'DeleteAmenity'}
        onHide={() => setModal(null)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Delete Property Amenity"
        txt="Are you sure you want to delete?"
        // btn1='Confirm'
        // btn2='Cancel'
        handleConfirm={handleDeleteAmenityConfirm}
        // handleCancel={() => setModal(null)}
      />
      <ConfirmModal
        show={modal === 'DeleteUnit'}
        onHide={() => setModal(null)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Delete Property Unit"
        txt="Are you sure you want to delete?"
        // btn1='Confirm'
        // btn2='Cancel'
        handleConfirm={handleDeleteUnitConfirm}
        // handleCancel={() => setModal(null)}
      />
      <ConfirmModal
        show={modal === 'Save'}
        onHide={() => setModal(null)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Save Edits"
        txt="Are you sure you want to save? Edits to property information can change live ads."
        // btn1='Confirm'
        // btn2='Cancel'
        handleConfirm={handleSaveConfirm}
        // handleCancel={() => setModal(null)}
      />
      <SuccessModal
        show={modal === 'ConfirmSaved'}
        onHide={() => setModal(null)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.success,
          parentClass: 'text-[#38D086] bg-[#38D086]/10',
          childClass: 'w-[30px] h-[17px]',
        }}
        title="Success"
        txt="Your property details were saved successfully"
        modalConfirmBtn="Back to Properties"
        handleSuccess={() => {
          window.location.href = '/properties';
        }}
      />
      <ConfirmModal
        show={modal === 'Reset'}
        onHide={() => setModal(null)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Reset Property"
        txt="Are you sure you want to reset?"
        // btn1='Confirm'
        // btn2='Cancel'
        handleConfirm={handleResetConfirm}
        // handleCancel={() => setModal(null)}
      />
    </div>
  ) : (
    <div className="px-0 xs:px-10">
      <div className="px-5 xs:px-0 py-8 font-semibold text-base leading-[17.6px] flex items-center">
        <Skeleton
          width={180}
          baseColor="rgba(202, 220, 237, 0.7)"
          highlightColor="rgba(219, 230, 242, 1)"
        />
      </div>
      <div className="grid gap-4 px-5 xs:px-0">
        <h5>
          <Skeleton
            width={202}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </h5>
        <p>
          <Skeleton
            width={583}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </p>
      </div>

      <div>
        <Tabs forceRenderTabPanel={true}>
          <TabList className="xs:hidden pl-5 font-medium text-[14px] leading-[18.2px] flex gap-8 mt-6 border-b whitespace-nowrap overflow-scroll">
            <Tab
              className="pb-5 bg-transparent"
              selectedClassName="border-b-4 border-b-[#687BFE]"
            >
              <Skeleton
                width={52}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </Tab>
            <Tab
              className="pb-5 bg-transparent"
              selectedClassName="border-b-4 border-b-[#687BFE]"
            >
              <Skeleton
                width={46}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </Tab>
            <Tab
              className="pb-5 bg-transparent"
              selectedClassName="border-b-4 border-b-[#687BFE]"
            >
              <Skeleton
                width={145}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </Tab>
            <Tab
              className="pb-5 bg-transparent"
              selectedClassName="border-b-4 border-b-[#687BFE]"
            >
              <Skeleton
                width={150}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </Tab>
          </TabList>

          <TabPanel
            className="tab-panel white-background skeleton-background mt-[6px] xs:mt-6"
            selectedClassName="block"
          >
            <div className="flex flex-wrap gap-6 items-center">
              <Skeleton
                width={110}
                height={82}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />

              <div className="flex flex-col justify-between">
                <div className="font-bold text-[20px] leading-[26px]">
                  <Skeleton
                    width={150}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <p className="text-[#707787] mt-2">
                  <Skeleton
                    width={300}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                  <Skeleton
                    width={200}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
              </div>
              <Skeleton
                width={140}
                borderRadius={32}
                height={42}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8 border-t pt-8">
              <div className="grid gap-2">
                <div className="text-[14px] leading-[18.2px]">
                  <Skeleton
                    width={97}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <Skeleton
                  containerClassName="w-full"
                  className="w-full"
                  height={46}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            </div>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8 border-t pt-8">
              <div className="grid gap-2">
                <div className="text-[14px] leading-[18.2px]">
                  <Skeleton
                    width={97}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <Skeleton
                  containerClassName="w-full"
                  className="w-full"
                  height={46}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
              <div className="grid gap-2">
                <div className="text-[14px] leading-[18.2px]">
                  <Skeleton
                    width={97}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <Skeleton
                  containerClassName="w-full"
                  className="w-full"
                  height={46}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            </div>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8 border-t pt-8">
              <div className="grid gap-2">
                <div className="text-[14px] leading-[18.2px]">
                  <Skeleton
                    width={97}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <Skeleton
                  containerClassName="w-full"
                  className="w-full"
                  height={46}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            </div>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8 border-t pt-8">
              <Skeleton
                width={97}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
          </TabPanel>

          <TabPanel
            className="tab-panel white-background skeleton-background px-0"
            selectedClassName="block"
          >
            <div className="font-bold text-[20px] leading-[26px] px-5 xs:px-8 mb-6">
              <Skeleton
                width={77}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
            <div>
              <div className="bg-[#F2F5F9] border-t px-5 xs:px-8 py-2 flex justify-between items-center">
                <p>
                  <Skeleton
                    width={97}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <div className="flex gap-4">
                  <div className="font-semibold text-[14px] leading-[15.4px] flex indent-2">
                    <Skeleton
                      width={88}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
              </div>
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 border-t px-5 xs:px-8 py-10">
                <div>
                  <div className="grid gap-2">
                    <div className="text-[14px] leading-[18.2px]">
                      <Skeleton
                        width={84}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </div>
                    <Skeleton
                      containerClassName="w-full"
                      className="w-full"
                      height={46}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
                <div className="flex items-center w-full gap-4 col-start-2 col-end-4">
                  <div className="md:col-start-2 md:col-end-4 grid gap-2 w-full">
                    <div className="text-[14px] leading-[18.2px]">
                      <Skeleton
                        width={84}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </div>
                    <Skeleton
                      containerClassName="w-full"
                      className="w-full"
                      height={46}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                    <div className="font-normal text-[14px] leading-[18.2px] text-[#707787]">
                      <Skeleton
                        width={184}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </div>
                  </div>
                  <Skeleton
                    width={100}
                    height={42}
                    borderRadius={32}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="bg-[#F2F5F9] border-t px-5 xs:px-8 py-2 flex justify-between items-center">
                <p>
                  <Skeleton
                    width={97}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <div className="flex gap-4">
                  <div className="font-semibold text-[14px] leading-[15.4px] flex indent-2">
                    <Skeleton
                      width={88}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
              </div>
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 border-t px-5 xs:px-8 py-10">
                <div>
                  <div className="grid gap-2">
                    <div className="text-[14px] leading-[18.2px]">
                      <Skeleton
                        width={84}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </div>
                    <Skeleton
                      containerClassName="w-full"
                      className="w-full"
                      height={46}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
                <div className="flex items-center w-full gap-4 col-start-2 col-end-4">
                  <div className="md:col-start-2 md:col-end-4 grid gap-2 w-full">
                    <div className="text-[14px] leading-[18.2px]">
                      <Skeleton
                        width={84}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </div>
                    <Skeleton
                      containerClassName="w-full"
                      className="w-full"
                      height={46}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                    <div className="font-normal text-[14px] leading-[18.2px] text-[#707787]">
                      <Skeleton
                        width={184}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </div>
                  </div>
                  <Skeleton
                    width={100}
                    height={42}
                    borderRadius={32}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="bg-[#F2F5F9] border-t px-5 xs:px-8 py-2 flex justify-between items-center">
                <p>
                  <Skeleton
                    width={97}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <div className="flex gap-4">
                  <div className="font-semibold text-[14px] leading-[15.4px] flex indent-2">
                    <Skeleton
                      width={88}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
              </div>
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 border-t px-5 xs:px-8 py-10">
                <div>
                  <div className="grid gap-2">
                    <div className="text-[14px] leading-[18.2px]">
                      <Skeleton
                        width={84}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </div>
                    <Skeleton
                      containerClassName="w-full"
                      className="w-full"
                      height={46}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
                <div className="flex items-center w-full gap-4 col-start-2 col-end-4">
                  <div className="md:col-start-2 md:col-end-4 grid gap-2 w-full">
                    <div className="text-[14px] leading-[18.2px]">
                      <Skeleton
                        width={84}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </div>
                    <Skeleton
                      containerClassName="w-full"
                      className="w-full"
                      height={46}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                    <div className="font-normal text-[14px] leading-[18.2px] text-[#707787]">
                      <Skeleton
                        width={184}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </div>
                  </div>
                  <Skeleton
                    width={100}
                    height={42}
                    borderRadius={32}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
              </div>
            </div>
            <div className="px-5 xs:px-8 pt-6 border-t flex gap-4 items-center">
              <Skeleton
                borderRadius={9999}
                width={36}
                height={36}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <Skeleton
                width={236}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
          </TabPanel>

          <TabPanel
            className="tab-panel white-background skeleton-background px-0"
            selectedClassName="block"
          >
            <div className="font-bold text-[20px] leading-[26px] px-5 xs:px-8 mb-6">
              <Skeleton
                width={136}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
            <div>
              <div className="bg-[#F2F5F9] border-t px-5 xs:px-8 py-2 flex justify-between items-center">
                <p>
                  <Skeleton
                    width={297}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <div className="flex gap-4">
                  <div className="font-semibold text-[14px] leading-[15.4px] flex indent-2">
                    <Skeleton
                      width={88}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
              </div>
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 border-t px-5 xs:px-8 py-8">
                <div className="grid gap-2">
                  <div className="text-[14px] leading-[18.2px]">
                    <Skeleton
                      width={84}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <Skeleton
                    containerClassName="w-full"
                    className="w-full"
                    height={46}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <div className="grid gap-2">
                  <div className="text-[14px] leading-[18.2px]">
                    <Skeleton
                      width={84}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <Skeleton
                    containerClassName="w-full"
                    className="w-full"
                    height={46}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
              </div>
            </div>

            <div>
              <div className="bg-[#F2F5F9] border-t px-5 xs:px-8 py-2 flex justify-between items-center">
                <p>
                  <Skeleton
                    width={297}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <div className="flex gap-4">
                  <div className="font-semibold text-[14px] leading-[15.4px] flex indent-2">
                    <Skeleton
                      width={88}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
              </div>
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 border-t px-5 xs:px-8 py-8">
                <div className="grid gap-2">
                  <div className="text-[14px] leading-[18.2px]">
                    <Skeleton
                      width={84}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <Skeleton
                    containerClassName="w-full"
                    className="w-full"
                    height={46}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <div className="grid gap-2">
                  <div className="text-[14px] leading-[18.2px]">
                    <Skeleton
                      width={84}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <Skeleton
                    containerClassName="w-full"
                    className="w-full"
                    height={46}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
              </div>
            </div>
            <div className="px-5 xs:px-8 pt-6 border-t flex gap-4 items-center">
              <Skeleton
                borderRadius={9999}
                width={36}
                height={36}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <Skeleton
                width={236}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
          </TabPanel>

          <TabPanel
            className="tab-panel white-background skeleton-background px-0"
            selectedClassName="block"
          >
            <div className="font-bold text-[20px] leading-[26px] px-5 xs:px-8 mb-2">
              <Skeleton
                width={90}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
            <p className="mb-6 px-5 xs:px-8">
              <Skeleton
                width={290}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </p>
            <div>
              <div className="bg-[#F2F5F9] border-t px-5 xs:px-8 py-2 flex justify-between items-center">
                <p>
                  <Skeleton
                    width={97}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <div className="flex gap-4">
                  <div className="font-semibold text-[14px] leading-[15.4px] flex indent-2">
                    <Skeleton
                      width={88}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
              </div>
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 border-t px-5 xs:px-8 py-8">
                <div className="grid gap-2">
                  <div className="text-[14px] leading-[18.2px]">
                    <Skeleton
                      width={84}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <Skeleton
                    containerClassName="w-full"
                    className="w-full"
                    height={46}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <div className="grid gap-2">
                  <div className="text-[14px] leading-[18.2px]">
                    <Skeleton
                      width={84}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <Skeleton
                    containerClassName="w-full"
                    className="w-full"
                    height={46}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <div className="grid gap-2">
                  <div className="text-[14px] leading-[18.2px]">
                    <Skeleton
                      width={84}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <Skeleton
                    containerClassName="w-full"
                    className="w-full"
                    height={46}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="bg-[#F2F5F9] border-t px-5 xs:px-8 py-2 flex justify-between items-center">
                <p>
                  <Skeleton
                    width={97}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <div className="flex gap-4">
                  <div className="font-semibold text-[14px] leading-[15.4px] flex indent-2">
                    <Skeleton
                      width={88}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
              </div>
              <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 border-t px-5 xs:px-8 py-8">
                <div className="grid gap-2">
                  <div className="text-[14px] leading-[18.2px]">
                    <Skeleton
                      width={84}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <Skeleton
                    containerClassName="w-full"
                    className="w-full"
                    height={46}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <div className="grid gap-2">
                  <div className="text-[14px] leading-[18.2px]">
                    <Skeleton
                      width={84}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <Skeleton
                    containerClassName="w-full"
                    className="w-full"
                    height={46}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <div className="grid gap-2">
                  <div className="text-[14px] leading-[18.2px]">
                    <Skeleton
                      width={84}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <Skeleton
                    containerClassName="w-full"
                    className="w-full"
                    height={46}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
              </div>
            </div>
            <div className="px-5 xs:px-8 pt-6 border-t flex gap-4 items-center">
              <Skeleton
                borderRadius={9999}
                width={36}
                height={36}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <Skeleton
                width={236}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
          </TabPanel>
        </Tabs>
        <div className="px-5 xs:px-0 pt-10 pb-12 grid gap-4 xs:flex xs:flex-row-reverse">
          <Skeleton
            width={220}
            height={52}
            borderRadius={32}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
          <Skeleton
            width={220}
            height={52}
            borderRadius={32}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
      </div>
    </div>
  );
}
