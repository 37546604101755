import React, { useState, useEffect, memo } from 'react';
import { useOutletContext } from 'react-router-dom';

import Select, { components } from 'react-select';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ReactTooltip from 'react-tooltip';
import Skeleton from 'react-loading-skeleton';

import { apiAgent } from '../../../utils/apicall';
import OffsetPaginator from '../../../components/paginate/OffsetPaginator';
import { svgIcons } from '../../../components/svgIcons/svgIcons';

const KeywordPerformance = () => {
  const [project, period, property] = useOutletContext();

  const [responseLists, setResponseLists] = useState({});

  const [campaignList, setCampaignList] = useState([]);
  const [adGroupList, setAdGroupList] = useState([]);

  const [campaign, setCampaign] = useState({ value: null, label: null });
  const [adGroup, setAdGroup] = useState({ value: null, label: null });
  const [device, setDevice] = useState({ value: 'All', label: 'All Devices' });

  const [dataByQS, setDataByQS] = useState({ clicks: [], cost: [] });
  const [dataByKeywordMatchType, setDataByKeywordMatchType] = useState({
    clicks: [],
    cost: [],
  });
  const [dataPerformance, setDataPerformance] = useState([]);

  // pagination
  const [total, setTotal] = useState(0);
  const [tableOffset, setTableOffset] = useState(0);
  const [tableRowCount, setTableRowCount] = useState(10);
  const [tableSort, setTableSort] = useState({ key: null, direction: true });

  const [infoLoaded, setInfoLoaded] = useState(false);

  useEffect(() => {
    if (project) fetchList();
  }, [project]);
  const fetchList = async () => {
    try {
      setInfoLoaded(false);
      var path = '/projects/';
      if (
        window.location.href.search('/s/') >= 0 ||
        window.location.href.search('/share/') >= 0
      )
        path = '/reports/';
      let params = {};
      if (
        project.algorithm == 'RTPricingPropertyPooled' ||
        project.algorithm.version == 'RTPricingPropertyPooled'
      ) {
        params.property = property.id;
      }
      const response = await apiAgent.get({
        path: path + `${project.id}/get_gadp_campaigns_adgroups/`,
        params,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const lists = await response.json();
      setResponseLists(lists);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      responseLists.hasOwnProperty('campaignList') &&
      responseLists.hasOwnProperty('adGroupList')
    )
      setCampaignList(
        [{ value: 'All', label: 'All Campaigns' }].concat(
          responseLists.campaignList,
        ),
      );
  }, [responseLists]);

  useEffect(() => {
    if (campaignList.length)
      setCampaign({ value: 'All', label: 'All Campaigns' });
  }, [campaignList]);

  useEffect(() => {
    if (!campaign.value) return;
    if (campaign.value === 'All')
      setAdGroupList(
        [{ value: 'All', label: 'All Ad Groups' }].concat(
          responseLists.adGroupList,
        ),
      );
    else
      setAdGroupList(
        [{ value: 'All', label: 'All Ad Groups' }].concat(
          responseLists.adGroupList.filter(
            (item) => item.campaign === campaign.value,
          ),
        ),
      );
  }, [campaign]);

  useEffect(() => {
    if (adGroupList.length)
      setAdGroup({ value: 'All', label: 'All Ad Groups' });
  }, [adGroupList]);

  useEffect(() => {
    if (period.length && adGroup.value && device.value) fetchOverview();
  }, [period, device, adGroup]);

  useEffect(() => {
    if (tableSort.key)
      setDataPerformance((prev) => {
        let temp = Array.from(prev);
        temp.sort((a, b) => {
          let booleanResult;
          if (a[tableSort.key] === null) booleanResult = -1;
          else if (b[tableSort.key] === null) booleanResult = 1;
          else if (
            Number.isFinite(a[tableSort.key]) ||
            Number.isFinite(b[tableSort.key])
          ) {
            booleanResult = a[tableSort.key] - b[tableSort.key];
          } else {
            booleanResult =
              a[tableSort.key].toUpperCase() < b[tableSort.key].toUpperCase()
                ? -1
                : 1;
          }
          return tableSort.direction ? booleanResult : -1 * booleanResult;
        });
        return temp;
      });
  }, [tableSort]);

  const fetchOverview = async () => {
    try {
      setInfoLoaded(false);
      var path = '/projects/';
      if (
        window.location.href.search('/s/') >= 0 ||
        window.location.href.search('/share/') >= 0
      )
        path = '/reports/';

      let params = {
        period,
        campaign: campaign.value,
        adGroup: adGroup.value,
        device: device.value,
      };
      if (
        project.algorithm == 'RTPricingPropertyPooled' ||
        project.algorithm.version == 'RTPricingPropertyPooled'
      ) {
        params.property = property.id;
      }
      const responseKeyword = await apiAgent.get({
        path: path + `${project.id}/keyword_performance/`,
        params,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const data = await responseKeyword.json();

      const _dataByQS = { clicks: Array(11).fill(0), cost: Array(11).fill(0) },
        _dataByKeywordMatchType = {
          clicks: {},
          cost: {},
        },
        _dataPerformance = [];

      for (const item of data.keyword_view) {
        if (item.adGroupCriterion.qualityInfo) {
          _dataByQS.cost[item.adGroupCriterion.qualityInfo.qualityScore] +=
            item.metrics.costMicros / 1000000 || 0;
          _dataByQS.clicks[item.adGroupCriterion.qualityInfo.qualityScore] +=
            item.metrics.clicks / 1 || 0;
        }

        if (
          _dataByKeywordMatchType.clicks.hasOwnProperty(
            item.adGroupCriterion.keyword.matchType,
          )
        )
          _dataByKeywordMatchType.clicks[
            item.adGroupCriterion.keyword.matchType
          ] += item.metrics.clicks / 1;
        else
          _dataByKeywordMatchType.clicks[
            item.adGroupCriterion.keyword.matchType
          ] = 0;
        if (
          _dataByKeywordMatchType.cost.hasOwnProperty(
            item.adGroupCriterion.keyword.matchType,
          )
        )
          _dataByKeywordMatchType.cost[
            item.adGroupCriterion.keyword.matchType
          ] += item.metrics.costMicros / 1000000;
        else
          _dataByKeywordMatchType.cost[
            item.adGroupCriterion.keyword.matchType
          ] = 0;
        let _flag = true;
        for (let index = 0; index < _dataPerformance.length; index++) {
          if (
            _dataPerformance[index].keywordText ===
              item.adGroupCriterion.keyword.text &&
            _dataPerformance[index].keywordMatchType ===
              item.adGroupCriterion.keyword.matchType
          ) {
            _dataPerformance[index].clicks += item.metrics.clicks / 1 || 0;
            _dataPerformance[index].impressions +=
              item.metrics.impressions / 1 || 0;
            _dataPerformance[index].ctrs.push(item.metrics.ctr || 0);
            _dataPerformance[index].cost +=
              item.metrics.costMicros / 1000000 || 0;
            _dataPerformance[index].conversions +=
              item.metrics.conversions || 0;
            _dataPerformance[index].costPerConversion +=
              item.metrics.costPerConversion / 1000000 || 0;
            _flag = false;
            break;
          }
        }
        if (_flag)
          _dataPerformance.push({
            QS: item.adGroupCriterion.qualityInfo
              ? item.adGroupCriterion.qualityInfo.qualityScore
              : null,
            keywordText: item.adGroupCriterion.keyword.text,
            keywordMatchType: item.adGroupCriterion.keyword.matchType,
            clicks: item.metrics.clicks / 1 || 0,
            impressions: item.metrics.impressions / 1 || 0,
            ctrs: [item.metrics.ctr || 0],
            cost: item.metrics.costMicros / 1000000 || 0,
            conversions: item.metrics.conversions || 0,
            costPerConversion: item.metrics.costPerConversion / 1000000 || 0,
          });
      }
      setDataByQS(_dataByQS);
      setDataByKeywordMatchType(_dataByKeywordMatchType);
      setDataPerformance(() =>
        _dataPerformance.map((item) => {
          return {
            QS: item.QS,
            keywordText: item.keywordText,
            keywordMatchType: item.keywordMatchType,
            clicks: item.clicks,
            impressions: item.impressions,
            ctr:
              item.ctrs.reduce((sum, elem) => (sum += elem)) / item.ctrs.length,
            cost: item.cost,
            conversions: item.conversions,
            costPerConversion: item.costPerConversion,
          };
        }),
      );
      setTotal(_dataPerformance.length);
      setInfoLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  return project && infoLoaded ? (
    <div className="mt-6">
      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {/* <div className="">
          <Select
            placeholder="Account"
            styles={customStyles}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            clearable={true}
          />
        </div> */}
        <div className="">
          <Select
            options={campaignList}
            onChange={setCampaign}
            placeholder="Campaign"
            styles={customStyles}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            value={campaign}
            clearable={true}
          />
        </div>
        <div className="">
          <Select
            options={adGroupList}
            onChange={setAdGroup}
            value={adGroup}
            placeholder="Ad Group"
            styles={customStyles}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            clearable={true}
          />
        </div>
        <div className="">
          <Select
            options={[
              { value: 'All', label: 'All Devices' },
              { value: 'CONNECTED_TV', label: 'CONNECTED_TV' },
              { value: 'DESKTOP', label: 'DESKTOP' },
              { value: 'MOBILE', label: 'MOBILE' },
              { value: 'OTHER', label: 'OTHER' },
              { value: 'TABLET', label: 'TABLET' },
              { value: 'UNKNOWN', label: 'UNKNOWN' },
            ]}
            onChange={setDevice}
            value={device}
            placeholder="Device"
            styles={customStyles}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            clearable={true}
          />
        </div>
      </div>
      <div id="keyword-performance">
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
          <div className="rounded-xl border bg-white mt-6 px-8 py-7">
            <div className="mb-6 flex justify-between items-start flex-wrap gap-3">
              <div className="text-[18px] font-semibold flex gap-2 items-center">
                <div>
                  Cost-Per-Click
                  <span className="text-[#8C94A3]">(By Quality Score)</span>
                </div>
                <div
                  className="w-4 text-[#B7BCC8]"
                  data-for="chart-info"
                  data-tip="How well your ad quality compares to other advertisers"
                >
                  {svgIcons.exclamation}
                </div>
              </div>
              <div className="ml-auto">
                <p className="text-[18px] font-semibold text-right">
                  $
                  {parseFloat(
                    (
                      dataByQS.cost.reduce(
                        (sum, item) => (sum += item || 0),
                        0,
                      ) /
                        dataByQS.clicks.reduce(
                          (sum, item) => (sum += item || 0),
                          0,
                        ) || 0
                    ).toFixed(2),
                  ).toLocaleString()}
                </p>
                <p className="font-medium text-[12px] leading-[15.6px] capitalize text-right">
                  CPC
                </p>
              </div>
            </div>
            <div className="h-52">
              <div className="flex ml-3">
                <div className="text-[#EA7E32] w-3">{svgIcons.dollar}</div>
              </div>
              <Bar
                options={{
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      borderColor: '#E9EBF1',
                      borderWidth: 1,
                      borderRadius: 4,
                      displayColors: true,
                      bodySpacing: 10,
                      backgroundColor: 'white',
                      bodyColor: '#515868',
                      titleColor: '#161D37',
                      usePointStyle: true,
                      callbacks: {
                        labelPointStyle: function (context) {
                          return {
                            pointStyle: 'circle',
                          };
                        },
                      },
                    },
                  },
                  interaction: {
                    intersect: false,
                    mode: 'index',
                  },
                }}
                data={{
                  labels: Array.from(Array(11).keys()),
                  datasets: [
                    {
                      label: 'CPC',
                      backgroundColor: '#EA7E32',
                      borderColor: '#EA7E32',
                      borderRadius: 24,
                      barPercentage: 0.6,
                      categoryPercentage: 0.6,
                      data: Array.from(Array(11).keys()).map(
                        (item) =>
                          (dataByQS.cost[item] / dataByQS.clicks[item]).toFixed(
                            2,
                          ) || 0,
                      ),
                    },
                  ],
                }}
              />
            </div>
          </div>
          <div className="rounded-xl border bg-white mt-6 px-8 py-7">
            <div className="mb-8 flex justify-between items-start">
              <div className="grid gap-1">
                <div className="text-[18px] font-semibold flex gap-2 items-center">
                  <div>
                    Clicks, Cost
                    <span className="text-[#8C94A3]">
                      (By keyword match type)
                    </span>
                  </div>
                  <div
                    className="w-4 text-[#B7BCC8]"
                    data-for="chart-info"
                    data-tip="Which searches cause your ads to appear in results"
                  >
                    {svgIcons.exclamation}
                  </div>
                </div>
                <div className="flex gap-7">
                  <div className="flex gap-1 ml-1 items-center">
                    <div className="bg-[#F6D214] w-[10px] h-[10px] rounded-full"></div>
                    <div className="text-xs font-medium">Clicks</div>
                  </div>
                  <div className="flex gap-1 ml-1 items-center">
                    <div className="bg-[#FF7F5F] w-[10px] h-[10px] rounded-full"></div>
                    <div className="text-xs font-medium">Cost</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="h-52">
              <Bar
                plugins={[ChartDataLabels]}
                options={{
                  maintainAspectRatio: false,
                  indexAxis: 'y',
                  elements: {
                    bar: {
                      borderWidth: 2,
                    },
                  },
                  responsive: true,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      borderColor: '#E9EBF1',
                      borderWidth: 1,
                      borderRadius: 4,
                      displayColors: true,
                      bodySpacing: 10,
                      backgroundColor: 'white',
                      bodyColor: '#515868',
                      titleColor: '#161D37',
                      usePointStyle: true,
                      callbacks: {
                        labelPointStyle: function (context) {
                          return {
                            pointStyle: 'circle',
                          };
                        },
                      },
                    },
                  },
                }}
                data={{
                  labels: Object.keys(dataByKeywordMatchType.clicks),
                  datasets: [
                    {
                      label: 'clicks',
                      backgroundColor: '#F6D214',
                      borderColor: '#F6D214',
                      borderRadius: 24,
                      barPercentage: 0.6,
                      categoryPercentage: 0.6,
                      data: Object.values(dataByKeywordMatchType.clicks),
                      datalabels: {
                        align: 'end',
                        anchor: 'center',
                      },
                    },
                    {
                      label: 'cost',
                      backgroundColor: '#FF7F5F',
                      borderColor: '#FF7F5F',
                      borderRadius: 24,
                      barPercentage: 0.6,
                      categoryPercentage: 0.6,
                      data: Object.values(dataByKeywordMatchType.cost).map(
                        (item) => item.toFixed(2),
                      ),
                      datalabels: {
                        align: 'end',
                        anchor: 'center',
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 rounded-xl border bg-white mt-6 xs:mt-10 py-4">
          <div className=" text-[18px] leading-[23.4px] font-semibold px-4">
            Keyword performance overview
          </div>
          <div className="overflow-auto p-4">
            <table className="w-full mt-6">
              <thead>
                <tr className="text-[#707787] bg-[#F2F5F966]">
                  {Object.entries({
                    keywordText: 'search keyword',
                    QS: 'keyword quality score',
                    keywordMatchType: 'search keyword match type',
                    clicks: 'clicks',
                    impressions: 'impressions',
                    ctr: 'ctr',
                    cost: 'cost',
                    conversions: 'conversions',
                    costPerConversion: 'cost/conv',
                  }).map((item, idx) => (
                    <th key={idx}>
                      <button
                        className="text-xs font-medium text-left uppercase px-2 py-4 flex gap-2 justify-between items-center"
                        onClick={() =>
                          setTableSort((prev) => {
                            return {
                              key: item[0],
                              direction:
                                prev.key !== item[0] ? true : !prev.direction,
                            };
                          })
                        }
                      >
                        <div>{item[1]}</div>
                        <div className="w-2">
                          {tableSort.key === item[0] &&
                            (tableSort.direction
                              ? svgIcons.dropup
                              : svgIcons.dropdown)}
                        </div>
                      </button>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {dataPerformance
                  .slice(tableOffset, tableOffset + tableRowCount)
                  .map((item, idx) => (
                    <tr key={idx} className="border-b-[#E9ECF1]">
                      <td className="text-[14px] font-medium px-2 py-5">
                        {item.keywordText}
                      </td>
                      <td className="text-[14px] font-medium px-2 py-5">
                        {item.QS}
                      </td>
                      <td className="text-[14px] font-medium px-2 py-5">
                        {item.keywordMatchType}
                      </td>
                      <td className="text-[14px] font-medium px-2 py-5">
                        {item.clicks.toLocaleString()}
                      </td>
                      <td className="text-[14px] font-medium px-2 py-5">
                        {item.impressions.toLocaleString()}
                      </td>
                      <td className="text-[14px] font-medium px-2 py-5">
                        {(item.ctr * 100).toFixed(2)}%
                      </td>
                      <td className="text-[14px] font-medium px-2 py-5">
                        ${parseFloat(item.cost.toFixed(2)).toLocaleString()}
                      </td>
                      <td className="text-[14px] font-medium px-2 py-5">
                        {parseFloat(
                          item.conversions.toFixed(2),
                        ).toLocaleString()}
                      </td>
                      <td className="text-[14px] font-medium px-2 py-5">
                        $
                        {item.conversions
                          ? parseFloat(
                              (item.cost / item.conversions).toFixed(2),
                            ).toLocaleString()
                          : 0}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>

          <div className="px-12" id="keyword-performance-pag">
            <OffsetPaginator
              onSelectPage={({ offset: _offset, size: _size }) => {
                setTableOffset(_offset);
                setTableRowCount(_size);
              }}
              total={total}
            />
          </div>
        </div>
      </div>
      <ReactTooltip
        id="chart-info"
        place="right"
        type="light"
        effect="solid"
        multiline={true}
        className="w-40"
      />
    </div>
  ) : (
    <div className="my-6">
      <div className="grid gap-6 grid-cols-1 sm:grid-cols-2 lg:grid-cols-3">
        {/* <div className="">
          <Skeleton
            height={38}
            borderRadius={2}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div> */}
        <div className="">
          <Skeleton
            height={38}
            borderRadius={2}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
        <div className="">
          <Skeleton
            height={38}
            borderRadius={2}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
        <div className="">
          <Skeleton
            height={38}
            borderRadius={2}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className="skeleton-background mt-6 px-8 py-7">
          <div className="mb-6 flex justify-between items-start flex-wrap gap-3">
            <div className="text-[18px]">
              <Skeleton
                width={250}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
            <div className="grid gap-1 ml-auto">
              <p className="text-[18px] flex justify-end">
                <Skeleton
                  width={50}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </p>
              <p className="font-medium text-[12px] leading-[15.6px] capitalize flex justify-end">
                <Skeleton
                  width={30}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </p>
            </div>
          </div>
          <Skeleton
            height={200}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
        <div className="skeleton-background mt-6 px-8 py-7">
          <div className="mb-8 flex justify-between items-end">
            <div className="grid gap-1">
              <div className="text-[18px] font-semibold">
                <Skeleton
                  width={250}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
              <div className="flex gap-7">
                <div className="flex gap-1 ml-1 items-center">
                  <Skeleton
                    width={70}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <div className="flex gap-1 ml-1 items-center">
                  <Skeleton
                    width={50}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
              </div>
            </div>
          </div>
          <Skeleton
            height={200}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
      </div>
      <div className="skeleton-background mt-6 xs:mt-10 overflow-hidden py-4 grid grid-cols-1">
        <Skeleton
          width={280}
          borderRadius={2}
          baseColor="rgba(202, 220, 237, 0.7)"
          highlightColor="rgba(219, 230, 242, 1)"
          containerClassName="px-4"
        />
        <table className="w-full">
          <thead>
            <tr>
              {Array.from(Array(9).keys()).map((tdElement) => (
                <th
                  key={tdElement}
                  className="px-2 py-6 text-xs border-b-[#E9ECF1] border-b"
                >
                  <Skeleton
                    width={70}
                    borderRadius={2}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from(Array(10).keys()).map((trElement) => (
              <tr key={trElement} className="">
                {Array.from(Array(9).keys()).map((tdElement) => (
                  <td
                    key={tdElement}
                    className="py-4 px-2 w-12 text-xs border-b-[#E9ECF1] border-b"
                  >
                    <Skeleton
                      width={60}
                      borderRadius={2}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const customStyles = {
  control: (styles) => ({
    ...styles,
    border: '1px solid #E9ECF1',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
  }),
  DropdownIndicator: (base) => ({
    ...base,
  }),
};
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props} className="pr-4 w-9 text-black">
      {svgIcons.down_arrow}
    </components.DropdownIndicator>
  );
};

export default memo(KeywordPerformance);
