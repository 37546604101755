// sample api call

import { useState, useEffect, memo } from 'react';
import { useOutletContext } from 'react-router-dom';

import Select, { components } from 'react-select';
import { Bar } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import ReactTooltip from 'react-tooltip';
import Skeleton from 'react-loading-skeleton';

import { apiAgent } from '../../../utils/apicall';
import { svgIcons } from '../../../components/svgIcons/svgIcons';

const DemographicPerformance = () => {
  const [project, period, property] = useOutletContext();

  const [responseLists, setResponseLists] = useState({});

  const [campaignList, setCampaignList] = useState([]);
  const [adGroupList, setAdGroupList] = useState([]);

  const [campaign, setCampaign] = useState({ value: null, label: null });
  const [adGroup, setAdGroup] = useState({ value: null, label: null });
  const [device, setDevice] = useState({
    value: 'All',
    label: 'All Devices',
  });

  const [conversionsByGender, setConversionsByGender] = useState({});
  const [conversionsByAge, setConversionsByAge] = useState({});

  const [infoLoaded, setInfoLoaded] = useState(false);

  useEffect(() => {
    if (project) fetchList();
  }, [project]);
  const fetchList = async () => {
    try {
      setInfoLoaded(false);
      var path = '/projects/';
      if (
        window.location.href.search('/s/') >= 0 ||
        window.location.href.search('/share/') >= 0
      )
        path = '/reports/';
      let params = {};
      if (
        project.algorithm == 'RTPricingPropertyPooled' ||
        project.algorithm.version == 'RTPricingPropertyPooled'
      ) {
        params.property = property.id;
      }
      const response = await apiAgent.get({
        path: path + `${project.id}/get_gadp_campaigns_adgroups/`,
        params,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const lists = await response.json();
      setResponseLists(lists);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    if (
      responseLists.hasOwnProperty('campaignList') &&
      responseLists.hasOwnProperty('adGroupList')
    )
      setCampaignList(
        [{ value: 'All', label: 'All Campaigns' }].concat(
          responseLists.campaignList,
        ),
      );
  }, [responseLists]);

  useEffect(() => {
    if (campaignList.length)
      setCampaign({ value: 'All', label: 'All Campaigns' });
  }, [campaignList]);

  useEffect(() => {
    if (!campaign.value) return;
    if (campaign.value === 'All')
      setAdGroupList(
        [{ value: 'All', label: 'All Ad Groups' }].concat(
          responseLists.adGroupList,
        ),
      );
    else
      setAdGroupList(
        [{ value: 'All', label: 'All Ad Groups' }].concat(
          responseLists.adGroupList.filter(
            (item) => item.campaign === campaign.value,
          ),
        ),
      );
  }, [campaign]);

  useEffect(() => {
    if (adGroupList.length)
      setAdGroup({ value: 'All', label: 'All Ad Groups' });
  }, [adGroupList]);

  useEffect(() => {
    if (period.length && adGroup.value && device.value) fetchOverview();
  }, [period, device, adGroup]);

  const fetchOverview = async () => {
    try {
      setInfoLoaded(false);
      var path = '/projects/';
      if (
        window.location.href.search('/s/') >= 0 ||
        window.location.href.search('/share/') >= 0
      )
        path = '/reports/';

      let params = {
        period,
        campaign: campaign.value,
        adGroup: adGroup.value,
        device: device.value,
      };
      if (
        project.algorithm == 'RTPricingPropertyPooled' ||
        project.algorithm.version == 'RTPricingPropertyPooled'
      ) {
        params.property = property.id;
      }

      const response = await apiAgent.get({
        path: path + `${project.id}/demographic_performance/`,
        params,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const data = await response.json();
      setConversionsByGender(() =>
        data.gender_view.reduce(
          (obj, item) => {
            obj[item.adGroupCriterion.gender.type] +=
              item.metrics.conversions || 0;
            return obj;
          },
          Object.create({
            FEMALE: 0,
            MALE: 0,
            UNDETERMINED: 0,
            UNKNOWN: 0,
            UNSPECIFIED: 0,
          }),
        ),
      );
      setConversionsByAge(() =>
        data.age_range_view.reduce(
          (obj, item) => {
            obj[item.adGroupCriterion.ageRange.type] +=
              item.metrics.conversions || 0;
            return obj;
          },
          Object.create({
            AGE_RANGE_18_24: 0,
            AGE_RANGE_25_34: 0,
            AGE_RANGE_35_44: 0,
            AGE_RANGE_45_54: 0,
            AGE_RANGE_55_64: 0,
            AGE_RANGE_65_UP: 0,
            AGE_RANGE_UNDETERMINED: 0,
            UNKNOWN: 0,
          }),
        ),
      );
      setInfoLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  return project && infoLoaded ? (
    <div className="mt-6">
      <div className="grid grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {/* <div className="">
          <Select
            styles={customStyles}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            clearable={true}
          />
        </div> */}
        <div className="">
          <Select
            // defaultValue={{ value: 'All', label: 'All Campaign' }}
            options={campaignList}
            value={campaign}
            onChange={setCampaign}
            placeholder="Campaign"
            styles={customStyles}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            clearable={true}
          />
        </div>
        <div className="">
          <Select
            // defaultValue={{ value: 'All', label: 'All Ad Group' }}
            options={adGroupList}
            value={adGroup}
            onChange={setAdGroup}
            placeholder="Ad Group"
            styles={customStyles}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            clearable={true}
          />
        </div>
        <div className="">
          <Select
            // defaultValue={{ value: 'All', label: 'All Device' }}
            options={[
              { value: 'All', label: 'All Devices' },
              { value: 'CONNECTED_TV', label: 'CONNECTED_TV' },
              { value: 'DESKTOP', label: 'DESKTOP' },
              { value: 'MOBILE', label: 'MOBILE' },
              { value: 'OTHER', label: 'OTHER' },
              { value: 'TABLET', label: 'TABLET' },
              { value: 'UNKNOWN', label: 'UNKNOWN' },
            ]}
            value={device}
            onChange={setDevice}
            placeholder="Device"
            styles={customStyles}
            components={{
              DropdownIndicator,
              IndicatorSeparator: () => null,
            }}
            clearable={true}
          />
        </div>
      </div>
      <div id="demographic-performance">
        <div className="grid lg:grid-cols-2 gap-6">
          <div className="rounded-xl border bg-white px-8 py-7 mt-6">
            <div className="mb-8 grid gap-1">
              <div className="text-[18px] leading-[23.4px] font-semibold flex items-center gap-2">
                <div>
                  Conversions
                  <span className="text-[#8C94A3]"> (By gender)</span>
                </div>
                <div
                  className="text-[#B7BCC8] w-4"
                  data-for="chart-info"
                  data-tip="Determines the number of conversions by gender"
                >
                  {svgIcons.exclamation}
                </div>
              </div>
              <div className="text-xs font-medium">Gender</div>
            </div>
            <div className="h-52">
              <Bar
                plugins={[ChartDataLabels]}
                options={options}
                data={{
                  labels: Object.keys(conversionsByGender).sort((a, b) =>
                    a > b ? 1 : -1,
                  ),
                  datasets: [
                    {
                      label: 'Conversions',
                      backgroundColor: '#A8B1EF',
                      borderColor: '#A8B1EF',
                      borderRadius: 24,
                      barPercentage: 0.6,
                      categoryPercentage: 0.6,
                      data: Object.keys(conversionsByGender)
                        .sort((a, b) => (a > b ? 1 : -1))
                        .map((item) => conversionsByGender[item].toFixed(2)),
                      datalabels: {
                        align: 'end',
                        anchor: 'center',
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
          <div className="rounded-xl border bg-white px-8 py-7 mt-6">
            <div className="mb-8 grid gap-1">
              <div className="text-[18px] leading-[23.4px] font-semibold flex items-center gap-2">
                Conversions by Age
                <div
                  className="text-[#B7BCC8] w-4"
                  data-for="chart-info"
                  data-tip="Determines the age of people who converted"
                >
                  {svgIcons.exclamation}
                </div>
              </div>
              <div className="text-xs font-medium">Age</div>
            </div>
            <div className="h-52">
              <Bar
                plugins={[ChartDataLabels]}
                options={options}
                data={{
                  labels: Object.keys(conversionsByAge)
                    .sort((a, b) => (a > b ? 1 : -1))
                    .map((item) => item.split('AGE_RANGE_')[1]),
                  datasets: [
                    {
                      label: 'Conversions',
                      backgroundColor: '#EA7E32',
                      borderColor: '#EA7E32',
                      borderRadius: 24,
                      barPercentage: 0.6,
                      categoryPercentage: 0.6,
                      data: Object.keys(conversionsByAge)
                        .sort((a, b) => (a > b ? 1 : -1))
                        .map((item) => conversionsByAge[item].toFixed(2)),
                      datalabels: {
                        align: 'end',
                        anchor: 'center',
                      },
                    },
                  ],
                }}
              />
            </div>
          </div>
        </div>
      </div>
      <ReactTooltip
        id="chart-info"
        place="right"
        type="light"
        effect="solid"
        multiline={true}
        className="w-40"
      />
    </div>
  ) : (
    <div className="my-6">
      <div className="grid gap-6 sm:grid-cols-2 lg:grid-cols-3">
        {/* <div className="">
          <Skeleton
            height={38}
            borderRadius={2}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div> */}
        <div className="">
          <Skeleton
            height={38}
            borderRadius={2}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
        <div className="">
          <Skeleton
            height={38}
            borderRadius={2}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
        <div className="">
          <Skeleton
            height={38}
            borderRadius={2}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6">
        <div className=" skeleton-background px-8 py-7 mt-6">
          <div className="mb-8 grid gap-1">
            <div className="text-[18px]">
              <Skeleton
                width={170}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
            <div className="text-xs">
              <Skeleton
                width={40}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
          </div>
          <div>
            <Skeleton
              height={200}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </div>
        </div>
        <div className=" skeleton-background px-8 py-7 mt-6">
          <div className="mb-8 grid gap-1">
            <div className="text-[18px]">
              <Skeleton
                width={180}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
            <div className="text-xs">
              <Skeleton
                width={30}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
          </div>
          <div>
            <Skeleton
              height={200}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const options = {
  maintainAspectRatio: false,
  indexAxis: 'y',
  elements: {
    bar: {
      borderWidth: 2,
    },
  },
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      borderColor: '#E9EBF1',
      borderWidth: 1,
      borderRadius: 4,
      displayColors: true,
      bodySpacing: 10,
      backgroundColor: 'white',
      bodyColor: '#515868',
      titleColor: '#161D37',
      usePointStyle: true,
      callbacks: {
        labelPointStyle: function (context) {
          return {
            pointStyle: 'circle',
          };
        },
      },
    },
  },
};

const customStyles = {
  control: (styles) => ({
    ...styles,
    border: '1px solid #E9ECF1',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
  }),
  DropdownIndicator: (base) => ({
    ...base,
  }),
};
const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props} className="pr-4 w-9 text-black">
      {svgIcons.down_arrow}
    </components.DropdownIndicator>
  );
};

export default memo(DemographicPerformance);
