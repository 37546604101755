import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  Outlet,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import LinesEllipsis from 'react-lines-ellipsis';
import { svgIcons } from '../../../components/svgIcons/svgIcons';
import { apiAgent } from '../../../utils/apicall';
import { setSelectedInfo } from '../../../features/userInfo/userInfoSlice';
import { styleGoogleCustomerId } from '../../../utils/publicFunctions';
import Skeleton from 'react-loading-skeleton';
import {
  Button,
  Select,
  SelectItem,
  Input,
  RadioGroup,
  Radio,
  Tabs,
  Tab,
} from '@nextui-org/react';

export default function Concession() {
  const params = useParams();
  const propertyId = params.id;
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [property, setProperty] = useState();
  const [projectSet, setProjectSet] = useState([]);
  const [propertyList, setPropertyList] = useState([]);
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(true);

  const [search, setSearch] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const [active, setActive] = useState('specials');

  useEffect(() => {
    setActive(
      window.location.href.split('properties/')[1].split('/')[1].split('?')[0],
    );
  });

  useEffect(() => {
    setLoadingFlag(true);
    (async () => {
      const response = await apiAgent.get({
        path: `/properties/`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      setPropertyList(await response.json());
    })();

    const handleKeyDown = (event) => {
      if (event.target.type === 'search' && event.key === ' ') {
        event.stopPropagation(); // Prevent global handler from interfering
      }
    };

    document.addEventListener('keydown', handleKeyDown, true);

    return () => {
      document.removeEventListener('keydown', handleKeyDown, true);
    };
  }, []);

  const fetchPropertyData = async () => {
    try {
      const response = await apiAgent.get({
        path: `/properties/${propertyId}`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const propertyBody = await response.json();
      window.document.title = `${propertyBody.name} Specials | Flair`;
      setProperty(propertyBody);
      let project_set = propertyBody.project_set;
      if (propertyBody.parent) {
        const response = await apiAgent.get({
          path: `/properties/${propertyBody.parent.id}`,
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        const result = await response.json();
        project_set = result.project_set;
      }
      setProjectSet(project_set);
      dispatch(
        setSelectedInfo({
          project: project_set.length > 0 ? project_set[0].id : null,
          property: parseInt(propertyId),
        }),
      );
      let propertypromotion_set = propertyBody.propertypromotion_set;
      propertypromotion_set.sort(
        (a, b) => a.promotion_type[0] - b.promotion_type[0],
      );
      setLoadingFlag(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPropertyData();
  }, [propertyId, loadingFlag]);

  return (
    <>
      <div className="pt-10 flex justify-between cursor-pointer">
        <div
          onClick={() => {
            if (searchParams.get('c') == 'GoogleMyBusiness')
              navigator(`/properties/${propertyId}/integrations`);
            else navigator('/properties');
          }}
          className="w-fit font-semibold text-sm leading-[15.4px] block flex items-center"
        >
          <div className="inline-block w-1.5">{svgIcons.left_arrow}</div>
          <span className="ml-3">Back to All</span>
        </div>
        {loadingFlag ? (
          <Skeleton
            containerClassName="flex justify-end"
            width={320}
            height={56}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
            borderRadius={4}
          />
        ) : (
          <Select
            className="max-w-xs bg-white rounded-xl"
            classNames={{
              trigger: 'min-h-unit-12',
              listboxWrapper: 'max-h-[436px] p-2',
            }}
            listboxProps={{
              itemClasses: {
                base: [
                  'data-[hover=true]:bg-white',
                  'data-[selectable=true]:focus:bg-white',
                ],
              },
            }}
            radius="sm"
            variant="bordered"
            aria-label="a"
            placeholder={property.name}
            onClick={() => {
              setSelectedProperties(propertyList);
              setSearch('');
            }}
            startContent={<div className="w-4">{svgIcons.search}</div>}
            isOpen={isOpen}
            onOpenChange={(open) => open !== isOpen && setIsOpen(open)}
          >
            <SelectItem
              key="argentina"
              textValue="a"
              isReadOnly
              hideSelectedIcon
              className="hover:bg-white"
            >
              <div>
                <div className="flex items-center">
                  <img src="/assets/images/home.png" width={24} alt="" />
                  <div className="text-base leading-6 font-medium ml-2">
                    Properties
                  </div>
                </div>
                <Input
                  classNames={{
                    base: 'max-w-full h-[52px] my-4',
                    mainWrapper: 'h-full',
                    input: 'text-small pl-9',
                    inputWrapper:
                      'h-full font-normal text-default-500 bg-white',
                  }}
                  variant="bordered"
                  value={search}
                  onChange={(e) => {
                    setSelectedProperties(
                      propertyList.filter(
                        (item) =>
                          e.target.value.length == 0 ||
                          item.name
                            .toLowerCase()
                            .includes(e.target.value.toLowerCase()),
                      ),
                    );
                    setSearch(e.target.value);
                  }}
                  placeholder="Search your properties here"
                  startContent={<div className="w-4">{svgIcons.search}</div>}
                  type="search"
                />
                <RadioGroup
                  className="h-[188px] overflow-y-auto mb-4"
                  defaultValue={parseInt(propertyId)}
                >
                  {selectedProperties.map((item) => (
                    <Radio
                      className="h-8"
                      classNames={{
                        base: 'm-0 p-2',
                      }}
                      value={item.id}
                      onClick={() => setSelectedItem(item)}
                    >
                      {item.name}
                    </Radio>
                  ))}
                </RadioGroup>
                <div className="grid grid-cols-2 gap-2 pt-4 border-t-1">
                  <Button
                    variant="bordered"
                    radius="sm"
                    onClick={() => setIsOpen(false)}
                    className="h-[40px] text-base leading-6 font-medium"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="solid"
                    radius="sm"
                    onClick={() => {
                      setLoadingFlag(true);
                      if (searchParams.get('c') == 'GoogleMyBusiness')
                        navigator(
                          `/properties/${selectedItem.id}/integrations?c=GoogleMyBusiness`,
                        );
                      else
                        navigator(`/properties/${selectedItem.id}/${active}`);
                      setIsOpen(false);
                    }}
                    className="h-[40px] bg-[#242424] text-white text-base leading-6 font-medium"
                  >
                    Apply
                  </Button>
                </div>
              </div>
            </SelectItem>
          </Select>
        )}
      </div>
      {searchParams.get('c') != 'GoogleMyBusiness' && (
        <div className="grid xs:mt-10">
          {loadingFlag ? (
            <div className="skeleton-background border py-6 px-5 xs:px-8 grid gap-6 rounded-lg">
              <div className="grid lg:flex gap-6">
                <div className="w-full h-20 lg:w-20 object-cover rounded">
                  <Skeleton
                    containerClassName="w-full"
                    height={80}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                    borderRadius={4}
                  />
                </div>
                <div className="w-full overflow-hidden grid content-between">
                  <div className="flex flex-wrap justify-between items-center gap-6">
                    <Skeleton
                      width={350}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <div>
                    <div className="mb-[2px] leading-5 text-sm flex">
                      <div>
                        <Skeleton
                          width={67}
                          baseColor="rgba(202, 220, 237, 0.7)"
                          highlightColor="rgba(219, 230, 242, 1)"
                        />
                      </div>
                      <div className="px-2">
                        <Skeleton
                          width={67}
                          baseColor="rgba(202, 220, 237, 0.7)"
                          highlightColor="rgba(219, 230, 242, 1)"
                        />
                      </div>
                    </div>
                    <div className="leading-5 text-sm flex">
                      <div>
                        <Skeleton
                          width={67}
                          baseColor="rgba(202, 220, 237, 0.7)"
                          highlightColor="rgba(219, 230, 242, 1)"
                        />
                      </div>
                      <div className="px-2">
                        <Skeleton
                          width={274}
                          baseColor="rgba(202, 220, 237, 0.7)"
                          highlightColor="rgba(219, 230, 242, 1)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex pt-6 border-t-1">
                <Skeleton
                  containerClassName="w-full"
                  height={48}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            </div>
          ) : (
            <div className="bg-danger-foreground border py-6 px-5 xs:px-8 grid gap-6 rounded-lg">
              <div className="grid lg:flex gap-6">
                <img
                  className="w-full h-20 lg:w-20 object-cover rounded"
                  src={
                    property &&
                    (property.highlight_photo ||
                      '/../../assets/images/tile-noimg.svg')
                  }
                  alt=""
                  onError={(e) => {
                    e.target.onerror = null;
                    e.target.src = '/../../assets/images/tile-noimg.svg';
                  }}
                />
                <div className="w-full overflow-hidden grid content-between">
                  <div className="flex flex-wrap justify-between items-center gap-6">
                    <div>
                      <LinesEllipsis
                        text={property && property.name}
                        className="text-xl leading-7 font-semibold"
                        component="div"
                        maxLine="2"
                        ellipsis="…"
                        trimRight
                      />
                    </div>
                  </div>
                  <div className="flex">
                    <div className="whitespace-nowrap text-sm">
                      <div className="mb-[2px]">
                        Google ID:&nbsp;
                        <span className="leading-5 text-sm font-normal">
                          {property &&
                            (projectSet.length > 0
                              ? styleGoogleCustomerId(
                                  projectSet[0].gad_customer_id,
                                )
                              : styleGoogleCustomerId(''))}
                        </span>
                      </div>
                      <div>
                        Address:&nbsp;
                        <span className="leading-5 text-sm font-normal">
                          {property &&
                            `${property.address}, ${property.city}, ${property.state} ${property.postal_code}, ${property.country_code}`}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex pt-6 border-t-1">
                <Tabs
                  key="none"
                  radius="sm"
                  selectedKey={active}
                  onSelectionChange={(e) => {
                    if (active != e) {
                      setActive(e);
                      navigator(e);
                    }
                  }}
                  className="border h-[52px] rounded-xl text-base leading-6 font-medium w-full"
                  classNames={{
                    tabList: 'p-[1px] gap-0 w-full',
                  }}
                >
                  <Tab key="specials" title="Specials" className="h-12" />
                  <Tab
                    key="integrations"
                    title="Integrations"
                    className="h-12"
                  />
                  <Tab key="reporting" title="Reporting" className="h-12" />
                  <Tab key="settings" title="Settings" className="h-12" />
                </Tabs>
              </div>
            </div>
          )}
        </div>
      )}
      <Outlet />
    </>
  );
}
