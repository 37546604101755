import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';
import { fetchUnreadNotificationsCount } from '../../features/notifications/unreadNotificationsSlice';
import { svgIcons } from '../svgIcons/svgIcons';

import { fetchMaintenanceShow } from '../../features/uxdialog/uxmaintenanceSlice';
const ConditionalLink = ({ to, children, disabled, ...props }) => {
  const handleClick = (e) => {
    if (disabled) {
      e.preventDefault(); // Prevent navigation
    }
  };

  return (
    <Link
      to={to}
      onClick={handleClick}
      style={{
        // color: disabled ? 'gray' : 'blue',
        cursor: disabled ? 'not-allowed' : 'pointer',
        pointerEvents: disabled ? 'none' : 'auto', // Optionally disable pointer events
      }}
      {...props}
    >
      {children}
    </Link>
  );
};

export default function Prosidebar(props) {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const showMaintenance = useSelector(fetchMaintenanceShow);
  const dispatch = useDispatch();
  const currentLocation = useLocation();

  useEffect(() => {
    dispatch(fetchUnreadNotificationsCount());
    const intervalID = setInterval(() => {
      dispatch(fetchUnreadNotificationsCount());
    }, 60_000);
    return () => {
      clearInterval(intervalID);
    };
  }, []);

  return (
    userInfo && (
      <>
        <div className="flex justify-between w-[1280px] mx-auto">
          <div className="flex gap-12 items-center">
            <div>
              <ConditionalLink
                color="foreground"
                to="/properties"
                disabled={showMaintenance}
              >
                <img src="/assets/images/new-logo.svg" alt="" />
              </ConditionalLink>
            </div>
            <div className="hidden lg:flex">
              <div className="py-6">
                <ConditionalLink
                  color="foreground"
                  to="/properties"
                  className="flex"
                  disabled={showMaintenance}
                >
                  {/* {svgIcons.frame} */}
                  Properties
                </ConditionalLink>
              </div>
            </div>
          </div>
          <div className="flex items-center py-6 gap-5">
            <ConditionalLink to="/settings" disabled={showMaintenance}>
              {currentLocation.pathname == '/settings' ? (
                <img
                  src="/assets/images/setting-2-selected.png"
                  width={24}
                  alt=""
                />
              ) : (
                svgIcons.setting_2
              )}
            </ConditionalLink>
            <ConditionalLink to="/notifications" disabled={showMaintenance}>
              {svgIcons.notification}
            </ConditionalLink>
            <div className="flex gap-2">
              <div className="flex w-[40px] text-white h-[40px] bg-[#687BFE] rounded-full items-center leading-[50px] font-bold text-base xs:text-[12px]">
                {userInfo.photo ? (
                  <img
                    src={userInfo.photo}
                    className="w-10 h-10 rounded-full"
                  />
                ) : userInfo.first_name ? (
                  <p className="w-full text-center">{userInfo.first_name[0]}</p>
                ) : (
                  <p className="w-full text-center">{userInfo.name[0]}</p>
                )}
              </div>
              <div className="text-[#707787] justify-center">
                <div className="font-bold xs:font-semibold text-base xs:text-[14px] leading-[20.8px] xs:leading-[15.4px] xs:text-[#161D37]">
                  {userInfo.first_name
                    ? userInfo.first_name + ' ' + userInfo.last_name
                    : userInfo.email}
                </div>
                <div className="text-[#A1A1AA] text-[14px] leading-5">
                  {userInfo.permission_role ? userInfo.permission_role : ''}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    )
  );
}
