import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Button } from '@restart/ui';
import { apiAgent } from '../../../utils/apicall';
import DayPickerNoti from '../../../components/daypicker/DayPickerNoti';
import OffsetPaginator from '../../../components/paginate/OffsetPaginator';
import Collapsible from 'react-collapsible';
import {
  noticeTypeLabels,
  BudgetRecommendationStatus,
} from '../../../utils/constants';
import Notificationlistcomponent, {
  prepareNoticesForDisplay,
} from '../../../components/notification/notificationlistcomponent';
import { monthlyBudget } from '../../../utils/project';
import { capitalizeFirstLetter } from '../../../utils/publicFunctions';
import NotificationSkeleton from '../../../components/skeleton/NotificationSkeleton';
import { fetchUnreadNotificationsCount } from '../../../features/notifications/unreadNotificationsSlice';
import {
  fetchSearchPhrase,
  setSearchPhrase,
} from '../../../features/searchphrase/searchPhraseSlice';
import uxDialog from '../../../utils/uxdialog';
import { svgIcons } from '../../../components/svgIcons/svgIcons';
import Skeleton from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';

import { get } from 'lodash-es';

import AcceptModal from '../../../components/modalbootstrap/AcceptModal';
import AcceptBudgetModal from '../../../components/modalbootstrap/AcceptBudgetModal';
import SuccessModal from '../../../components/modalbootstrap/SuccessModal';

let propsSearchFetchedIdx = 0;

export default function NotificationList() {
  const location = useLocation();
  const searchPhrase = useSelector(fetchSearchPhrase);
  const dispatch = useDispatch();
  // UI States
  //  Date Filtering
  const [startDate, setStartDate] = useState(null); // ISO string
  const [endDate, setEndDate] = useState(null); // ISO string
  //  Other Filtering
  const [choiceCompaniesType, setChoiceCompaniesType] = useState(true);
  const [choiceUsersType, setChoiceUsersType] = useState(true);
  const [choicePropsType, setChoicePropsType] = useState(true);
  const [choiceAllProps, setChoiceAllProps] = useState(true);
  const [propertyFilterSearch, setPropertyFilterSearch] = useState('');
  const [propertySearchLastRequest, setPropertySearchLastRequest] = useState(0);
  const [selectedPropertyIds, setSelectedPropertyIds] = useState([]);
  const [candidatePropertyFilterItems, setCandidatePropertyFilterItems] =
    useState([]);
  const [checkedPropertyFilterItems, setCheckedPropertyFilterItems] = useState(
    [],
  );
  const [choiceActorFilter, setChoiceActorFilter] = useState('all');
  const [choiceNoticeStatus, setChoiceNoticeStatus] = useState('all');

  //  Sorting
  const [choiceSortByName, setChoiceSortByName] = useState('Time'); // time - means ignore name sorting
  const [choiceSortByTime, setChoiceSortByTime] = useState('-'); // '-' : desc '' : asc

  // Selecting Notices
  const [selectedNoticeIds, setSelectedNoticeIds] = useState([]);

  //  Used in Fetching
  const [filtering, setFiltering] = useState({});
  const [offset, setOffset] = useState(0);
  const [size, setSize] = useState(15); // size: count of items per page
  const [ordering, setOrdering] = useState('-created_at');
  const [notiLoaded, setNotiLoaded] = useState(false);

  //  Fetched
  const [notices, setNotices] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchedProperties, setSearchedProperties] = useState([]);

  // Modal View
  const [modalShow, setModalShow] = useState('close');
  const [noticeId, setNoticeId] = useState();
  const [notification, setNotification] = useState({});

  // Utility Functions

  const updateFiltering = () => {
    let _filter = {};
    if (startDate) {
      _filter.created_at__gte = startDate;
    }
    if (endDate) {
      _filter.created_at_lt = endDate;
    }
    if (choiceNoticeStatus === 'read') {
      _filter.is_read = true;
    } else if (choiceNoticeStatus === 'unread') {
      _filter.is_read = false;
    }
    let _noticeTypeLabels = [];
    if (choiceCompaniesType) {
      _noticeTypeLabels = _noticeTypeLabels.concat(noticeTypeLabels.company);
    }
    if (choiceUsersType) {
      _noticeTypeLabels = _noticeTypeLabels.concat(noticeTypeLabels.user);
    }
    if (choicePropsType) {
      if (!choiceAllProps) {
        if (selectedPropertyIds.length > 0) {
          _filter['propertyid__in'] = selectedPropertyIds.join(',');
          _noticeTypeLabels = _noticeTypeLabels.concat(
            noticeTypeLabels.property,
          );
          _noticeTypeLabels = _noticeTypeLabels.concat(
            noticeTypeLabels.project,
          );
        }
      } else {
        _noticeTypeLabels = _noticeTypeLabels.concat(noticeTypeLabels.property);
        _noticeTypeLabels = _noticeTypeLabels.concat(noticeTypeLabels.project);
        _noticeTypeLabels = _noticeTypeLabels.concat(noticeTypeLabels.stats);
        _noticeTypeLabels = _noticeTypeLabels.concat(noticeTypeLabels.report);
        _noticeTypeLabels = _noticeTypeLabels.concat(
          noticeTypeLabels.budgetrecommendation,
        );
      }
    }
    _filter['notification__notice_type__label__in'] =
      _noticeTypeLabels.join(',');
    setFiltering(_filter);
  };

  const fetchNotices = async () => {
    if (offset === null || size === null) return;
    if (!filtering['notification__notice_type__label__in']) {
      setNotices([]);
      setSelectedNoticeIds([]);
      setTotal(0);
      return;
    }
    let searchPhrases = searchPhrase.split(/[\s,]+/);
    searchPhrases = searchPhrases.map((phrase) => {
      phrase = phrase.toLowerCase();
      if (phrase === 'campaign' || phrase === 'campaigns') {
        return 'project';
      } else {
        return phrase;
      }
    });
    let params = {
      ordering,
      search: searchPhrases.join(' '),
      offset,
      limit: size,
    };
    params = Object.assign(params, filtering);
    try {
      // setNotiLoaded(false);
      const response = await apiAgent.get({
        path: '/notices',
        options: apiAgent.popularOptions.onConfirm,
        params: params,
      });
      let noticesBody = await response.json();
      let _notices = noticesBody.results;

      // remove viewed budget recommended notification
      var i = 0;
      while (i < _notices.length) {
        let _status = get(_notices[i], 'notification.target_object.status');
        if (_status && _status != BudgetRecommendationStatus.SUGGESTED) {
          _notices.splice(i, 1);
        } else {
          ++i;
        }
      }

      prepareNoticesForDisplay(_notices);
      setNotices(_notices);
      setSelectedNoticeIds([]);
      setTotal(noticesBody.count);
      setNotiLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProperties = async (requestIndex, searchText) => {
    try {
      const response = await apiAgent.get({
        path: '/properties',
        options: apiAgent.popularOptions.onConfirm,
        params: {
          name__icontains: searchText,
          limit: 10,
          offset: 0,
        },
      });
      if (requestIndex <= propsSearchFetchedIdx) return;
      propsSearchFetchedIdx = requestIndex;
      let _searchedProperties = (await response.json()).results;
      setSearchedProperties(_searchedProperties);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchRecommendedBudget = async () => {
    try {
      let p = window.location.href;
      let id = parseInt(p.split('budgetrecommendation_id=')[1]);
      setNoticeId(id);

      const response = await apiAgent.get({
        path: `/recommendations/${id}`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const body = await response.json();
      let dict = {
        fromBudget: monthlyBudget(body.project.budget_plan),
        toBudget: monthlyBudget(body.budget),
        name: body.project.property.name,
      };
      setNotification(dict);
      setModalShow('BudgetRecommendation');
    } catch (error) {
      console.log(error);
    }
  };
  // Hooks
  useEffect(() => {
    fetchProperties();
    window.document.title = 'Notifications | Flair';
  }, []);

  // Hooks windows.location.href
  useEffect(() => {
    if (window.location.href.search('budgetrecommendation_id') != -1)
      fetchRecommendedBudget();
  }, [location]);

  useEffect(() => {
    let _ordering = [];
    if (choiceSortByName === 'AtoZ') {
      _ordering.push('_property_name');
    } else if (choiceSortByName === 'ZtoA') {
      _ordering.push('-_property_name');
    }
    _ordering.push(choiceSortByTime + 'created_at');
    _ordering = _ordering.join(',');
    setOrdering(_ordering);
  }, [choiceSortByName, choiceSortByTime]);

  useEffect(() => {
    updateFiltering();
  }, [startDate, endDate]);

  useEffect(() => {
    let _candidatePropertyFilterItems = [];
    for (const property of searchedProperties) {
      if (selectedPropertyIds.indexOf(property.id) < 0) {
        _candidatePropertyFilterItems.push(property);
      }
    }

    let _checkedPropertyFilterItems = [];
    let _checkedItemIds = [];
    for (const property of searchedProperties) {
      if (selectedPropertyIds.indexOf(property.id) >= 0) {
        _checkedPropertyFilterItems.push(property);
        _checkedItemIds.push(property.id);
      }
    }
    for (const property of checkedPropertyFilterItems) {
      if (_checkedItemIds.indexOf(property.id) >= 0) continue;
      if (selectedPropertyIds.indexOf(property.id) >= 0) {
        _checkedPropertyFilterItems.push(property);
      }
    }
    for (const property of candidatePropertyFilterItems) {
      if (_checkedItemIds.indexOf(property.id) >= 0) continue;
      if (selectedPropertyIds.indexOf(property.id) >= 0) {
        _checkedPropertyFilterItems.push(property);
      }
    }

    _candidatePropertyFilterItems = _candidatePropertyFilterItems.sort(
      (prop1, prop2) =>
        prop1.name.localeCompare(prop2.name, 'en', { sensitivity: 'base' }),
    );
    _checkedPropertyFilterItems = _checkedPropertyFilterItems.sort(
      (prop1, prop2) =>
        prop1.name.localeCompare(prop2.name, 'en', { sensitivity: 'base' }),
    );
    setCandidatePropertyFilterItems(_candidatePropertyFilterItems);
    setCheckedPropertyFilterItems(_checkedPropertyFilterItems);
  }, [searchedProperties, selectedPropertyIds]);

  useEffect(() => {
    const requestIndex = propertySearchLastRequest + 1;
    setPropertySearchLastRequest(requestIndex);
    fetchProperties(requestIndex, propertyFilterSearch);
  }, [propertyFilterSearch]);

  useEffect(() => {
    fetchNotices();
  }, [searchPhrase, offset, size, filtering, ordering]);

  // Event Handlers
  const handleSelectPage = ({ offset: _offset, size: _size }) => {
    setOffset(_offset);
    setSize(_size);
  };

  const handleNotificationSelectAll = (e) => {
    let _selectedNoticeIds = [];
    if (e.target.checked) {
      for (const notice of notices) {
        _selectedNoticeIds.push(notice.id);
      }
    }
    setSelectedNoticeIds(_selectedNoticeIds);
  };

  const markNoticeRead = async (noticeId, isRead) => {
    try {
      const response = await apiAgent.patch({
        path: `/notices/${noticeId}`,
        data: { is_read: isRead },
        options: apiAgent.popularOptions.straight,
      });
      const body = await response.json();
      return body.is_read;
    } catch (error) {
      console.log(error);
    }
  };

  const markNoticesRead = async (isRead) => {
    const _noticeIds = selectedNoticeIds.slice();
    const markRequests = _noticeIds.map((id) => markNoticeRead(id, isRead));
    const response = await Promise.all(markRequests);

    // update unread notifications badge
    dispatch(fetchUnreadNotificationsCount());

    if (filtering['is_read'] === true || filtering['is_read'] === false) {
      return fetchNotices();
    }
    const _notices = notices.slice();
    let _failures = 0;
    for (let i = 0; i < response.length; i++) {
      const noticeId = _noticeIds[i];
      const index = _notices.findIndex((item) => item.id === noticeId);
      if (index < 0) continue;
      if (response[i] !== null) {
        _notices[index].is_read = response[i];
      } else {
        _failures += 1;
      }
    }
    setNotices(_notices);
    if (_failures) {
      await uxDialog.alert({
        text: `We're having some difficulty updating your notification statuses. Please check back later.`,
      });
    }
  };

  const handleDismiss = async (userInput) => {
    handleSuccess();
    try {
      const response = await apiAgent.patch({
        path: `/recommendations/${noticeId}/apply_status`,
        data: { status: BudgetRecommendationStatus.DECLINED }, // DECLINED
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
    } catch (error) {
      console.log(error);
    }
  };

  const handleAccept = async (userInput) => {
    if (parseInt(userInput) == notification.toBudget) {
      setModalShow('Pending');
      try {
        const response = await apiAgent.patch({
          path: `/recommendations/${noticeId}/apply_status`,
          data: { status: BudgetRecommendationStatus.ACCEPTED }, // ACCEPTED
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const handleSuccess = () => {
    setModalShow('close');
    window.location.href = '/notifications';
  };

  return notiLoaded ? (
    <div className="pt-10 flex flex-col justify-between">
      <div>
        <SuccessModal
          show={modalShow === 'Pending'}
          onHide={handleSuccess}
          onClose={handleSuccess}
          mark={{
            icon: svgIcons.success,
            parentClass: 'text-[#38D086] bg-[#38D086]/10',
            childClass: 'w-[30px] h-[17px]',
          }}
          title="Success"
          txt="Your budget was successfully updated."
          modalConfirmBtn="Back to notifications"
          handleSuccess={handleSuccess}
        />
        <AcceptBudgetModal
          show={modalShow === 'AcceptBudget'}
          onClose={() => setModalShow('BudgetRecommendation')}
          mark={{
            icon: svgIcons.warning,
            parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
            childClass: 'w-[28px] h-[28px]',
          }}
          value={notification.toBudget}
          title="Confirm New Budget"
          txt={`To confirm, please, enter “${notification.toBudget}”`}
          inputBackImg="/assets/images/modal-dollar.svg"
          handleAccept={(value) => {
            handleAccept(value);
          }}
        />
        <AcceptModal
          show={modalShow === 'BudgetRecommendation'}
          onClose={handleSuccess}
          mark={{
            icon: <img src="/assets/images/accept_dismiss.png" />,
            parentClass: 'text-[#687BFE] bg-[#687BFE]/10',
            childClass: 'w-[23.54px] h-[23.54px]',
          }}
          handleClose={handleDismiss}
          headTitle="Budget Recommendations"
          txt={`We recommend ${
            notification.fromBudget > notification.toBudget
              ? 'reducing'
              : 'increasing'
          } your monthly budget
            from $${notification.fromBudget} to $${notification.toBudget} at ${
            notification.name
          }`}
          txt_down="Would you like to accept or dismiss this recommendation?"
          handleConfirm={() => setModalShow('AcceptBudget')}
        />
        <div className="px-5 xs:px-0 grid gap-6">
          <h5>Notifications</h5>
          <div className="flex flex-wrap gap-4 justify-between items-end">
            <div className="w-full md:max-w-[580px] relative">
              <input
                className="flair-search"
                placeholder='Search to view notifications e.g. "Jackson Heights Apartments User Added"'
                type="text"
                value={searchPhrase}
                onChange={(e) => dispatch(setSearchPhrase(e.target.value))}
              />
              <div className="absolute top-0 left-[21px] h-full flex items-center">
                <div className="w-[17px] h-[17px]">{svgIcons.search}</div>
              </div>
            </div>
            <div className="ml-auto mr-0 flex gap-4 flex-wrap">
              <div className="relative h-full">
                {/* Calendar Dropdown for Date Range Selection */}
                <Dropdown>
                  <Dropdown.Toggle>
                    {(props) => (
                      <Button
                        {...props}
                        className="flair-btn flair-btn-md flair-btn-quaternary font-normal text-base justify-end w-[140px] gap-2"
                        style={
                          props['aria-expanded']
                            ? { border: '1px solid #687BFE', color: '#687BFE' }
                            : {}
                        }
                      >
                        <div
                          className="w-[15px] opacity-50"
                          style={
                            props['aria-expanded'] ? { color: '#687BFE' } : {}
                          }
                        >
                          {svgIcons.calendar_filled}
                        </div>
                        <div className="leading-[15px]">Date</div>
                        <div className="w-2 mx-4">
                          {props['aria-expanded']
                            ? svgIcons.dropup
                            : svgIcons.dropdown}
                        </div>
                      </Button>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {(menuProps, meta) => (
                      <ul
                        {...menuProps}
                        className="fixed w-full xs:w-auto bottom-0 xs:absolute xs:bottom-auto xs:top-10 right-0 z-10 rounded-lg bg-white border border-[#E9ECF1] shadow-[0_20.3px_37.71px_rgba(183, 188, 200, 0.11)] whitespace-nowrap flex"
                        style={{
                          boxShadow:
                            '0px 20.3px 37.71px rgba(183, 188, 200, 0.11)',
                          transition:
                            'visibility 500ms, opacity 500ms, transform 500ms',
                          visibility: meta.show ? 'visible' : 'hidden',
                          opacity: meta.show ? '1' : '0',
                          transform: meta.show
                            ? 'translateY(6px)'
                            : 'translateY(-4px)',
                        }}
                      >
                        <DayPickerNoti
                          onClickApply={(startDate, endDate) => {
                            setStartDate(startDate);
                            setEndDate(endDate);
                            meta.toggle();
                          }}
                          onClickCancel={() => {
                            meta.toggle();
                          }}
                        />
                      </ul>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              {/* FILTER DROPDOWN */}
              <div className="relative h-full">
                <Dropdown>
                  <Dropdown.Toggle>
                    {(props) => (
                      <Button
                        {...props}
                        className="flair-btn flair-btn-md flair-btn-quaternary font-normal text-[16px] leading-[20.8px] justify-end w-[140px] gap-2"
                        style={
                          props['aria-expanded']
                            ? { border: '1px solid #687BFE', color: '#687BFE' }
                            : {}
                        }
                      >
                        <div
                          className="w-[12px] opacity-50"
                          style={
                            props['aria-expanded'] ? { color: '#687BFE' } : {}
                          }
                        >
                          {svgIcons.filter_filled}
                        </div>
                        <div>Filters</div>
                        <div className="w-2 ml-3 mr-4">
                          {props['aria-expanded']
                            ? svgIcons.dropup
                            : svgIcons.dropdown}
                        </div>
                      </Button>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {(menuProps, meta) => (
                      <ul
                        {...menuProps}
                        className="fixed w-full xs:w-auto bottom-0 xs:absolute xs:bottom-auto xs:top-10 right-0 z-10 rounded-lg gap-[14px] py-5 bg-white border border-[#E9ECF1] shadow-[0_20.3px_37.71px_rgba(183, 188, 200, 0.11)] whitespace-nowrap"
                        style={{
                          boxShadow:
                            '0px 20.3px 37.71px rgba(183, 188, 200, 0.11)',
                          transition:
                            'visibility 500ms, opacity 500ms, transform 500ms',
                          visibility: meta.show ? 'visible' : 'hidden',
                          opacity: meta.show ? '1' : '0',
                          transform: meta.show
                            ? 'translateY(6px)'
                            : 'translateY(-4px)',
                        }}
                      >
                        <div className="grid gap-1 px-5">
                          <label className="check-container font-medium text-[16px] leading-4">
                            Company Notifications
                            <input
                              type="checkbox"
                              id="noti-filter-companyevent-checkbox"
                              checked={choiceCompaniesType}
                              onChange={(e) =>
                                setChoiceCompaniesType(e.target.checked)
                              }
                              className="checkbox"
                            />
                            <span className="checkmark -top-[2px]"></span>
                          </label>
                          <label className="check-container font-medium text-[16px] leading-4">
                            User Notifications
                            <input
                              type="checkbox"
                              id="noti-filter-userevent-checkbox"
                              checked={choiceUsersType}
                              onChange={(e) =>
                                setChoiceUsersType(e.target.checked)
                              }
                              className="checkbox"
                            />
                            <span className="checkmark -top-[2px]"></span>
                          </label>
                        </div>
                        <div className="grid gap-1 px-5 pt-5 mt-2 border-t border-[#E9ECF1]">
                          <label className="check-container font-medium text-[16px] leading-4">
                            Property Notifications
                            {!choicePropsType
                              ? ''
                              : choiceAllProps
                              ? '(All)'
                              : `(${selectedPropertyIds.length})`}
                            <input
                              type="checkbox"
                              id="noti-filter-property-events-checkbox"
                              checked={choicePropsType}
                              onChange={(e) =>
                                setChoicePropsType(e.target.checked)
                              }
                              className="checkbox"
                            />
                            <span className="checkmark -top-[2px]"></span>
                          </label>
                          {choicePropsType ? (
                            <div className=" ml-6">
                              <label className="check-container font-medium text-[16px] leading-4">
                                All properties
                                <input
                                  type="checkbox"
                                  id="noti-filter-property-events-all-checkbox"
                                  checked={choiceAllProps}
                                  onChange={(e) =>
                                    setChoiceAllProps(e.target.checked)
                                  }
                                  className="checkbox"
                                />
                                <span className="checkmark -top-[2px]"></span>
                              </label>
                              {choiceAllProps ? (
                                <></>
                              ) : (
                                <div className="grid gap-4">
                                  <div className="relative">
                                    <input
                                      className="flair-search"
                                      placeholder="Search properties..."
                                      value={propertyFilterSearch}
                                      onChange={(e) =>
                                        setPropertyFilterSearch(e.target.value)
                                      }
                                    />
                                    <div className="absolute top-0 left-[21px] h-full flex items-center">
                                      <div className="w-[17px] h-[17px]">
                                        {svgIcons.search}
                                      </div>
                                    </div>
                                  </div>
                                  <div className="overflow-y-scroll h-[200px]">
                                    {checkedPropertyFilterItems &&
                                      checkedPropertyFilterItems.map(
                                        (item, index) => (
                                          <label
                                            className="check-container"
                                            key={`checked-property-${item.id}`}
                                          >
                                            {
                                              checkedPropertyFilterItems[index]
                                                .name
                                            }
                                            <input
                                              type="checkbox"
                                              id={`noti-filter-property-checkbox-${item.id}`}
                                              checked
                                              onChange={(e) => {
                                                const idx =
                                                  selectedPropertyIds.indexOf(
                                                    item.id,
                                                  );
                                                if (idx < 0) return;
                                                const _selectedPropertyIds =
                                                  selectedPropertyIds.slice();
                                                _selectedPropertyIds.splice(
                                                  idx,
                                                  1,
                                                );
                                                setSelectedPropertyIds(
                                                  _selectedPropertyIds,
                                                );
                                              }}
                                              className="checkbox"
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                        ),
                                      )}
                                    {/* TODO: Separator */}
                                    {candidatePropertyFilterItems &&
                                      candidatePropertyFilterItems.map(
                                        (item, index) => (
                                          <label
                                            className="check-container"
                                            key={`unchecked-property-${item.id}`}
                                          >
                                            {
                                              candidatePropertyFilterItems[
                                                index
                                              ].name
                                            }
                                            <input
                                              type="checkbox"
                                              id={`noti-filter-property-checkbox-${item.id}`}
                                              onChange={(e) => {
                                                const idx =
                                                  selectedPropertyIds.indexOf(
                                                    item.id,
                                                  );
                                                if (idx >= 0) return;
                                                const _selectedPropertyIds =
                                                  selectedPropertyIds.slice();
                                                _selectedPropertyIds.push(
                                                  item.id,
                                                );
                                                setSelectedPropertyIds(
                                                  _selectedPropertyIds,
                                                );
                                              }}
                                              className="checkbox"
                                            />
                                            <span className="checkmark"></span>
                                          </label>
                                        ),
                                      )}
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="grid gap-1 px-5 pt-3 mt-2 border-t border-[#E9ECF1]">
                          <Collapsible
                            className="font-medium text-[16px]"
                            triggerClassName="flex justify-between items-center"
                            triggerOpenedClassName="flex justify-between items-center"
                            trigger={[
                              `Status (${capitalizeFirstLetter(
                                choiceNoticeStatus,
                              )})`,
                              <div className="w-[12.73px]">
                                {svgIcons.down_arrow}
                              </div>,
                            ]}
                            triggerWhenOpen={[
                              `Status (${capitalizeFirstLetter(
                                choiceNoticeStatus,
                              )})`,
                              <div className="w-[12.73px]">
                                {svgIcons.up_arrow}
                              </div>,
                            ]}
                          >
                            <div className="mt-3 grid gap-1">
                              <div className="radiobox2-container">
                                <input
                                  type="radio"
                                  name="status"
                                  id="noti-filter-choice-status-all"
                                  checked={choiceNoticeStatus === 'all'}
                                  onChange={(e) => {
                                    if (e.target.checked)
                                      setChoiceNoticeStatus('all');
                                  }}
                                />
                                <label htmlFor="noti-filter-choice-status-all">
                                  All
                                </label>
                                <span className="radiomark2"></span>
                              </div>
                              <div className="radiobox2-container">
                                <input
                                  type="radio"
                                  name="status"
                                  id="noti-filter-choice-status-read"
                                  checked={choiceNoticeStatus === 'read'}
                                  onChange={(e) => {
                                    if (e.target.checked)
                                      setChoiceNoticeStatus('read');
                                  }}
                                />
                                <label htmlFor="noti-filter-choice-status-read">
                                  Read
                                </label>
                                <span className="radiomark2"></span>{' '}
                              </div>
                              <div className="radiobox2-container">
                                <input
                                  type="radio"
                                  name="status"
                                  id="noti-filter-choice-status-unread"
                                  checked={choiceNoticeStatus === 'unread'}
                                  onChange={(e) => {
                                    if (e.target.checked)
                                      setChoiceNoticeStatus('unread');
                                  }}
                                />
                                <label htmlFor="noti-filter-choice-status-unread">
                                  Unread
                                </label>
                                <span className="radiomark2"></span>{' '}
                              </div>
                            </div>
                          </Collapsible>
                        </div>
                        <div className="grid gap-1 px-5 pt-3 mt-3 border-t border-[#E9ECF1]">
                          <Collapsible
                            className="font-medium text-[16px]"
                            triggerClassName="flex justify-between items-center"
                            triggerOpenedClassName="flex justify-between items-center"
                            trigger={[
                              `By (${capitalizeFirstLetter(
                                choiceActorFilter,
                              )})`,
                              <div className="w-[12.73px]">
                                {svgIcons.down_arrow}
                              </div>,
                            ]}
                            triggerWhenOpen={[
                              `By (${capitalizeFirstLetter(
                                choiceActorFilter,
                              )})`,
                              <div className="w-[12.73px]">
                                {svgIcons.up_arrow}
                              </div>,
                            ]}
                          >
                            <div className="mt-3 grid gap-1">
                              <div className="radiobox2-container">
                                <input
                                  type="radio"
                                  name="by"
                                  id="noti-filter-actor-radio-all"
                                  checked={choiceActorFilter === 'all'}
                                  onChange={(e) => {
                                    if (e.target.checked)
                                      setChoiceActorFilter('all');
                                  }}
                                />
                                <label htmlFor="noti-filter-actor-radio-all">
                                  All
                                </label>
                                <span className="radiomark2"></span>
                              </div>
                              <div className="radiobox2-container">
                                <input
                                  type="radio"
                                  name="by"
                                  id="noti-filter-actor-radio-client"
                                  checked={choiceActorFilter === 'client'}
                                  onChange={(e) => {
                                    if (e.target.checked)
                                      setChoiceActorFilter('client');
                                  }}
                                />
                                <label htmlFor="noti-filter-actor-radio-client">
                                  From Client
                                </label>
                                <span className="radiomark2"></span>{' '}
                              </div>
                              <div className="radiobox2-container">
                                <input
                                  type="radio"
                                  name="by"
                                  id="noti-filter-actor-radio-staff"
                                  checked={choiceActorFilter === 'staff'}
                                  onChange={(e) => {
                                    if (e.target.checked)
                                      setChoiceActorFilter('staff');
                                  }}
                                />
                                <label htmlFor="noti-filter-actor-radio-staff">
                                  From Staff
                                </label>
                                <span className="radiomark2"></span>{' '}
                              </div>
                            </div>
                          </Collapsible>
                        </div>

                        <div className="flex px-5 pt-4 mt-3 border-t border-[#E9ECF1] ml-auto mr-0 w-max">
                          <button
                            className="flair-btn flair-btn-tertiary underline flair-btn-sm w-28"
                            onClick={() => {
                              setChoiceCompaniesType(false);
                              setChoiceUsersType(false);
                              setChoicePropsType(false);
                              setChoiceAllProps(false);
                              setSelectedPropertyIds([]);
                            }}
                          >
                            Clear all
                          </button>
                          <button
                            className="flair-btn flair-btn-primary flair-btn-sm w-28"
                            onClick={() => {
                              updateFiltering();
                              meta.toggle();
                            }}
                          >
                            Apply
                          </button>
                        </div>
                      </ul>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="relative h-full">
                <Dropdown>
                  <Dropdown.Toggle>
                    {(props) => (
                      <Button
                        {...props}
                        className="flair-btn flair-btn-md flair-btn-quaternary font-normal text-[16px] leading-[20.8px] justify-end w-[140px] gap-2"
                        style={
                          props['aria-expanded']
                            ? { border: '1px solid #687BFE', color: '#687BFE' }
                            : {}
                        }
                      >
                        <div
                          className="w-[15px] opacity-50"
                          style={
                            props['aria-expanded'] ? { color: '#687BFE' } : {}
                          }
                        >
                          {svgIcons.sortby}
                        </div>
                        <div>Sort By</div>
                        <div className="w-2 ml-2 mr-4">
                          {props['aria-expanded']
                            ? svgIcons.dropup
                            : svgIcons.dropdown}
                        </div>
                      </Button>
                    )}
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {(menuProps, meta) => (
                      <ul
                        {...menuProps}
                        className="fixed w-full xs:w-auto bottom-0 xs:absolute xs:bottom-auto xs:top-10 right-0 z-10 rounded-lg gap-[14px] py-5 bg-white border border-[#E9ECF1] shadow-[0_20.3px_37.71px_rgba(183, 188, 200, 0.11)] whitespace-nowrap"
                        style={{
                          boxShadow:
                            '0px 20.3px 37.71px rgba(183, 188, 200, 0.11)',
                          transition:
                            'visibility 800ms, opacity 800ms, transform 800ms',
                          visibility: meta.show ? 'visible' : 'hidden',
                          opacity: meta.show ? '1' : '0',
                          transform: meta.show
                            ? 'translateY(6px)'
                            : 'translateY(-4px)',
                        }}
                      >
                        <div className="px-5 pb-3 grid gap-2">
                          <div className="font-normal text-[14px] leading-[18.2px] text-[#8C94A3]">
                            Sort by name
                          </div>
                          <p className="radio-container">
                            <input
                              id="choice-sort-by-name-az"
                              type="radio"
                              name="sortByProperty"
                              value="AtoZ"
                              checked={choiceSortByName === 'AtoZ'}
                              onChange={(e) =>
                                setChoiceSortByName(e.target.value)
                              }
                            />
                            <label
                              onClick={() => meta.toggle()}
                              htmlFor="choice-sort-by-name-az"
                            >
                              Property name (A to Z)
                            </label>
                            <span className="radiomark" />
                          </p>
                          <p className="radio-container">
                            <input
                              id="choice-sort-by-name-za"
                              type="radio"
                              className="radiobox"
                              name="sortByProperty"
                              value="ZtoA"
                              checked={choiceSortByName === 'ZtoA'}
                              onChange={(e) =>
                                setChoiceSortByName(e.target.value)
                              }
                            />
                            <label
                              onClick={() => meta.toggle()}
                              htmlFor="choice-sort-by-name-za"
                            >
                              Property name (Z to A)
                            </label>
                            <span className="radiomark" />
                          </p>
                        </div>
                        <div className="px-5 pt-3 grid gap-2 border-t border-t-[#E9ECF1]">
                          <div className="font-normal text-[14px] leading-[18.2px] text-[#8C94A3]">
                            Sort by date
                          </div>
                          <p className="radio-container">
                            <input
                              type="radio"
                              id="sort-by-time-desc"
                              name="sortByDate"
                              value="-"
                              checked={choiceSortByTime === '-'}
                              onChange={(e) =>
                                setChoiceSortByTime(e.target.value)
                              }
                            />
                            <label
                              onClick={() => meta.toggle()}
                              htmlFor="sort-by-time-desc"
                            >
                              Newest to oldest
                            </label>
                            <span className="radiomark" />
                          </p>
                          <p className="radio-container">
                            <input
                              type="radio"
                              id="sort-by-time-asc"
                              name="sortByDate"
                              value=""
                              checked={choiceSortByTime === ''}
                              onChange={(e) =>
                                setChoiceSortByTime(e.target.value)
                              }
                            />
                            <label
                              onClick={() => meta.toggle()}
                              htmlFor="sort-by-time-asc"
                            >
                              Oldest to newest
                            </label>
                            <span className="radiomark" />
                          </p>
                        </div>
                      </ul>
                    )}
                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
          </div>
        </div>
        <Notificationlistcomponent
          items={notices}
          selectedIds={selectedNoticeIds}
          onCheckEvent={(id, checked) => {
            if (checked) {
              if (selectedNoticeIds.indexOf(id) < 0) {
                let _selectedNoticeIds = selectedNoticeIds.slice();
                _selectedNoticeIds.push(id);
                setSelectedNoticeIds(_selectedNoticeIds);
              }
            } else {
              if (selectedNoticeIds.indexOf(id) >= 0) {
                const index = selectedNoticeIds.indexOf(id);
                let _selectedNoticeIds = selectedNoticeIds.slice();
                _selectedNoticeIds.splice(index, 1);
                setSelectedNoticeIds(_selectedNoticeIds);
              }
            }
          }}
        />
        <div className="flex flex-wrap gap-4 justify-between items-center pt-6 px-5 xs:px-10">
          {notices.length > 0 ? (
            <label className="check-container ml-1">
              {selectedNoticeIds.length === notices.length
                ? 'Deselect All'
                : 'Select All'}
              <input
                type="checkbox"
                id="selectall"
                checked={selectedNoticeIds.length === notices.length}
                onChange={handleNotificationSelectAll}
                className="checkbox"
              />
              <span className="checkmark"></span>
            </label>
          ) : (
            <div className="w-[10px]" />
          )}
          <div className="flex gap-4">
            <button
              className="flair-btn flair-btn-secondary flair-btn-sm w-36"
              onClick={(e) => markNoticesRead(false)}
              disabled={selectedNoticeIds.length === 0}
            >
              Mark as unread
            </button>
            <button
              className="flair-btn flair-btn-primary flair-btn-sm w-36"
              onClick={(e) => markNoticesRead(true)}
              disabled={selectedNoticeIds.length === 0}
            >
              Mark as read
            </button>
          </div>
        </div>
      </div>
      <OffsetPaginator onSelectPage={handleSelectPage} total={total} />
    </div>
  ) : (
    <div className="xs:px-20 pt-10 flex flex-col justify-between">
      <div>
        <div className="px-5 xs:px-0 grid gap-6">
          <h5>
            <Skeleton
              width={240}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </h5>
          <div className="flex flex-wrap gap-4 justify-between items-end">
            <div className="w-full md:max-w-[580px]">
              <Skeleton
                height={46}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
            <div className="ml-auto mr-0 flex gap-4">
              <Skeleton
                width={140}
                height={42}
                borderRadius={32}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <Skeleton
                width={140}
                height={42}
                borderRadius={32}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <Skeleton
                width={140}
                height={42}
                borderRadius={32}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
          </div>
        </div>
        <NotificationSkeleton />
        <div className="flex flex-wrap gap-4 justify-between items-center pt-6 px-5 xs:px-10">
          <div className="flex gap-3">
            <Skeleton
              containerClassName="ml-3"
              width={18}
              height={18}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
            <Skeleton
              containerClassName="ml-3"
              width={100}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </div>
          <div className="flex gap-4">
            <Skeleton
              width={140}
              height={36}
              borderRadius={32}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
            <Skeleton
              width={140}
              height={36}
              borderRadius={32}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
