import React from 'react';
import { useSelector } from 'react-redux';
import Lottie from 'react-lottie';
import * as animationData from './Loader.json';

const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: animationData,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export default function Loading() {
  const loadingText = useSelector((state) => state.loading.text);
  return loadingText ? (
    <div className="fixed top-0 left-0 z-50 w-screen h-screen bg-[#222335CC] flex items-center justify-center">
      <div>
        <Lottie options={defaultOptions} width={40} height={40} />
        <div className="mt-2 text-white">{loadingText}</div>
      </div>
    </div>
  ) : (
    <></>
  );
}
