import { createSlice } from '@reduxjs/toolkit';

export const uxMaintenanceSlice = createSlice({
  name: 'uxMaintenance',

  initialState: {
    show: false,
  },

  reducers: {
    show: (state) => {
      state.show = true;
    },
    hide: (state) => {
      state.show = false;
    },
  },
});

export const { show, hide } = uxMaintenanceSlice.actions;
export default uxMaintenanceSlice.reducer;

export const fetchMaintenanceShow = (state) => {
  return state.uxMaintenance.show;
};
