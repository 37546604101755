import React, { useState, useRef } from 'react';
import { Select, SelectItem, Input } from '@nextui-org/react';
import { validateEmail } from '../../../utils/string';
export default function SubscriptionsListComponent({
  subscriptions,
  dataModel,
  onRemove,
  onAdd,
  onItemUpdate,
  uiTitle,
  uiDescription,
  uiInputPlaceholder,
}) {
  const [email, setEmail] = React.useState('');
  const isInvalid = React.useMemo(() => {
    if (email === '') return true;

    return validateEmail(email) ? false : true;
  }, [email]);

  // Add Subscription UI
  const emailInputRef = useRef(null);
  const scheduleInputRef = useRef(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddSubscription = async (event) => {
    if (isLoading) return;
    if (event.key === 'Enter') {
      let newSubscription = {};
      if (emailInputRef) {
        newSubscription.email = email;
      }
      if (dataModel.schedule) {
        if (scheduleInputRef) {
          newSubscription.schedule = Object.keys(dataModel.schedule.choices)[
            scheduleInputRef.current.selectedIndex - 1
          ];
        }
      }
      if (onAdd) {
        setIsLoading(true);
        await onAdd(newSubscription);
        setEmail('');
        setIsLoading(false);
      }
    }
  };

  return (
    <div className="flex overflow-hidden flex-col rounded-xl border border-solid w-full my-[16px]">
      {/* Heading and Input
       */}
      <div className="flex flex-col p-6 w-full bg-white max-md:px-5 max-md:max-w-full">
        <div className="flex gap-6 items-center w-full max-w-[1232px] min-h-[57px] max-md:max-w-full">
          <div className="flex gap-4 items-center self-stretch my-auto min-w-[240px] max-md:max-w-full">
            <div className="flex flex-col self-stretch my-auto min-w-[240px] max-md:max-w-full">
              <div className="text-lg font-semibold leading-loose text-ellipsis text-neutral-800">
                {uiTitle}
              </div>
              <div className="gap-2 self-stretch mt-1 text-base text-ellipsis text-zinc-700 max-md:max-w-full">
                {uiDescription}
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-wrap gap-4 items-start mt-6 w-full text-base max-md:max-w-full">
          <Input
            type="email"
            className="flex flex-col flex-1 shrink n-w-[240px] max-md:max-w-full"
            placeholder={uiInputPlaceholder}
            variant="bordered"
            radius="sm"
            value={email}
            onValueChange={setEmail}
            color={isInvalid ? 'danger' : 'success'}
            ref={emailInputRef}
            onKeyDown={handleAddSubscription}
            disabled={isLoading}
          />
          {dataModel.schedule ? (
            <>
              <Select
                className="flex flex-col min-w-[240px] w-[334px]"
                variant="bordered"
                radius="sm"
                defaultSelectedKeys={[
                  Object.keys(dataModel.schedule.choices)[0],
                ]}
                ref={scheduleInputRef}
              >
                {Object.entries(dataModel.schedule.choices).map(
                  ([key, value]) => (
                    <SelectItem key={key} value={key}>
                      {value}
                    </SelectItem>
                  ),
                )}
              </Select>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>
      {/* Content */}
      {Array.isArray(subscriptions) && subscriptions.length > 0 ? (
        <div className="flex flex-col px-6 pb-6 w-full text-base whitespace-nowrap bg-white max-md:px-5 max-md:max-w-full">
          <div className="flex flex-col p-6 w-full rounded-lg bg-neutral-50 max-md:px-5 max-md:max-w-full">
            {/* {subscriptions === undefined || subscriptions === null ? (
            <div className="text-center">No subscription</div>
          ) : (
            <></>
          )} */}
            {Array.isArray(subscriptions) && subscriptions.length > 0 ? (
              subscriptions.map((subscription, index) => {
                return (
                  <>
                    {index != 0 ? (
                      <div className="border-b-1 my-[16px]"></div>
                    ) : (
                      <></>
                    )}
                    <div className="items-center flex flex-wrap w-full max-md:max-w-full">
                      <div className="flex-1 flex items-center align-middle shrink self-stretch min-w-[240px] text-neutral-800">
                        {subscription['email']}
                      </div>
                      {dataModel.schedule ? (
                        <>
                          <div className="h-[48px] flex flex-1 shrink justify-center items-center self-start min-w-[240px] place-content-center">
                            <Select
                              className="max-w-[264px]"
                              variant="bordered"
                              radius="sm"
                              size="sm"
                              selectedKeys={[subscription.schedule]}
                              onSelectionChange={(keys) => {
                                if (keys && keys.size > 0) {
                                  const selectedKey = Array.from(keys)[0];
                                  if (onItemUpdate) {
                                    onItemUpdate({
                                      id: subscription['id'],
                                      schedule: selectedKey,
                                    });
                                  }
                                }
                              }}
                            >
                              {Object.entries(dataModel.schedule.choices).map(
                                ([key, value]) => (
                                  <SelectItem key={key} value={key}>
                                    {value}
                                  </SelectItem>
                                ),
                              )}
                            </Select>
                          </div>
                        </>
                      ) : (
                        <></>
                      )}
                      <div className="h-[48px] flex items-center justify-end flex-1 shrink self-stretch font-medium text-rose-800 min-w-[240px]">
                        <a
                          href="#"
                          onClick={(e) => {
                            e.preventDefault();
                            if (onRemove) onRemove(subscription['id']);
                          }}
                        >
                          Remove
                        </a>
                      </div>
                    </div>
                  </>
                );
              })
            ) : (
              <></>
            )}
          </div>
        </div>
      ) : (
        <></>
      )}
    </div>
  );
}
