import Modal from 'react-bootstrap/Modal';

export default function DeleteModal(props) {
  return (
    <Modal
      {...props}
      centered
      backdropClassName="opacity-30"
      dialogClassName="drop-shadow-[0_10px_20px_rgba(116,116,126,0.1)] w-full m-0 xs:m-auto xs:max-w-[500px]"
      contentClassName="border-0 rounded-2xl "
    >
      <div className="p-10">
        <h5 className="text-center text-xl leading-7 font-semibold">
          Are you sure you want to remove {props.username}?
        </h5>
        <div className="mt-5 grid justify-items-center">
          <div className="grid gap-3 xs:flex w-full xs:w-[250px]">
            <button
              onClick={props.onClose}
              className="flair-btn flair-btn-md bg-white w-full h-[52px] xs:w-[115px] rounded-lg border"
            >
              Cancel
            </button>
            <button
              className="flair-btn flair-btn-md bg-[#242424] w-full h-[52px] xs:w-[124px] rounded-lg border text-white"
              onClick={props.handleConfirm}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    </Modal>
  );
}
