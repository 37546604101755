import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { apiAgent } from '../../../utils/apicall';
import { Dropdown, Button } from '@restart/ui';
import { useParams } from 'react-router-dom';
import { projectStatusName } from '../../../utils/project';
import { svgIcons } from '../../../components/svgIcons/svgIcons';
import Skeleton from 'react-loading-skeleton';

// import CarouselPreview from '../../../components/carousel/CarouselPreview'

let _amenityNames = [];
let pageTitle = null;

export default function Preview() {
  const param = useParams();
  const propertyId = param.id;
  const [property, setProperty] = useState(null);
  const [properties, setProperties] = useState(null);
  const [project, setProject] = useState(null);
  // const [photos, setPhotos] = useState([])
  const [amenityNames, setAmenityNames] = useState([]);
  const [propertyFilterSearch, setPropertyFilterSearch] = useState('');
  const [selectedId, setSelectedId] = useState(param.id);

  const fetchProperties = async (searchText) => {
    try {
      const response = await apiAgent.get({
        path: '/properties/',
        params: { ordering: 'name', name__icontains: searchText },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const _properties = await response.json();
      setProperties(_properties);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchProperty = async (propertyId) => {
    try {
      const response = await apiAgent.get({
        path: `/properties/${propertyId}`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const body = await response.json();
      setProperty(body);
      pageTitle = `Preview Property - ${body.name}`;
      window.document.title = `${pageTitle}`;
      setSelectedId(body.id);
      if (body.project_set && body.project_set !== [])
        setProject(body.project_set[0]);
      let _photos = [];
      for (let i = 0; i < body.propertyphoto_set.length; i++) {
        _photos.push(body.propertyphoto_set[i].photo);
      }
      // setPhotos(_photos)
      _amenityNames = body.propertyamenity_set.map((item) => {
        return item.name;
      });
      _amenityNames.sort((a, b) => a.length - b.length);
      setAmenityNames(_amenityNames);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    // Set page title and save navigation history
    pageTitle = null;
    window.document.title = `Preview Property - Loading...`;
    fetchProperties('');
    fetchProperty(propertyId);
  }, [propertyId]);

  useEffect(() => {
    fetchProperties(propertyFilterSearch);
  }, [propertyFilterSearch]);

  return property ? (
    <div className="px-0 xs:px-10">
      <Link
        to="#"
        onClick={() => {
          window.history.back();
        }}
        className="px-5 xs:px-0 py-8 font-semibold text-base leading-[17.6px] flex items-center"
      >
        <div className="w-[6px] h-[10px]">{svgIcons.left_arrow}</div>
        <div className="indent-3">Back</div>
      </Link>
      <div className="flex flex-wrap gap-4 justify-between px-5 xs:px-0">
        <h5>Preview property</h5>
        <div className="relative">
          <Dropdown>
            <Dropdown.Toggle>
              {(props) => (
                <Button
                  {...props}
                  className="flair-btn flair-btn-md flair-btn-quaternary font-normal text-base leading-[20.8px] justify-end w-[200px]"
                  style={
                    props['aria-expanded']
                      ? { border: '1px solid #687BFE', color: '#687BFE' }
                      : {}
                  }
                >
                  <div
                    className="w-4 opacity-50"
                    style={props['aria-expanded'] ? { color: '#687BFE' } : {}}
                  >
                    {svgIcons.property_filled}
                  </div>
                  <div>Select property</div>
                  <div className="w-2 ml-[6px] mr-4">
                    {props['aria-expanded']
                      ? svgIcons.dropup
                      : svgIcons.dropdown}
                  </div>
                </Button>
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {(menuProps, meta) => (
                <ul
                  {...menuProps}
                  className="fixed w-full xs:w-auto bottom-0 xs:absolute xs:bottom-auto xs:top-10 right-0 z-10 rounded-lg gap-[14px] py-5 bg-white border border-[#E9ECF1] shadow-[0_20.3px_37.71px_rgba(183, 188, 200, 0.11)] whitespace-nowrap"
                  style={{
                    boxShadow: '0px 20.3px 37.71px rgba(183, 188, 200, 0.11)',
                    transition:
                      'visibility 700ms, opacity 700ms, transform 700ms',
                    visibility: meta.show ? 'visible' : 'hidden',
                    opacity: meta.show ? '1' : '0',
                    transform: meta.show
                      ? 'translateY(6px)'
                      : 'translateY(-4px)',
                  }}
                >
                  <div className="px-5 pb-3 grid gap-2">
                    <div className="relative">
                      <div className="absolute top-0 left-[21px] h-full flex items-center">
                        <div className="w-[17px] h-[17px]">
                          {svgIcons.search}
                        </div>
                      </div>
                      <input
                        className="flair-search"
                        placeholder="Search properties..."
                        type="text"
                        value={propertyFilterSearch}
                        onChange={(e) =>
                          setPropertyFilterSearch(e.target.value)
                        }
                      />
                    </div>
                    <div className="h-64 overflow-y-scroll overflow-x-hidden">
                      {properties &&
                        properties.map((item) => (
                          <p
                            className="radiobox2-container mt-2"
                            key={`div-property-${item.id}`}
                          >
                            <input
                              type="radio"
                              id={`radio-property-${item.id}`}
                              value={item.id}
                              checked={
                                parseInt(item.id) === parseInt(selectedId)
                              } //not '==='
                              name="property-name"
                              onChange={(e) => {
                                setSelectedId(e.target.value);
                              }}
                            />
                            <label htmlFor={`radio-property-${item.id}`}>
                              {item.name}
                            </label>
                            <span className="radiomark2 top-[1px]" />
                          </p>
                        ))}
                    </div>
                  </div>
                  <div className="px-5 pt-3 grid gap-2 border-t border-t-[#E9ECF1] xs:flex w-full xs:w-max ml-auto mr-0">
                    <button
                      // className={
                      //   parseInt(selectedId) !== parseInt(propertyId)
                      //     ? 'filter-clear-btn'
                      //     : 'disable-cancel-btn'
                      // }
                      className="flair-btn flair-btn-tertiary flair-btn-sm w-32 underline"
                      onClick={() => {
                        setPropertyFilterSearch('');
                        setSelectedId(property.id);
                      }}
                      disabled={parseInt(selectedId) === parseInt(propertyId)}
                    >
                      Cancel
                    </button>
                    <Link to={`/properties/preview/${selectedId}`}>
                      <button
                        className="flair-btn flair-btn-primary flair-btn-sm w-32"
                        onClick={() => {
                          meta.toggle();
                        }}
                      >
                        Confirm
                      </button>
                    </Link>
                  </div>
                </ul>
              )}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </div>

      <div className="white-background grid gap-10">
        <div className="grid gap-10 lg:flex">
          <div className="w-full lg:w-min">
            <div className="relative aspect-[10/9] lg:w-[380px]">
              <img
                src={property.highlight_photo || '/assets/images/empty.png'}
                alt=""
                className="absolute top-0 left-0 w-full h-full rounded object-cover"
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/assets/images/empty.png';
                }}
              />
              <div className="h-full flex items-center justify-center border rounded border-[#CED3DB] border-dashed bg-[#F2F5F9]">
                <div className="w-10 text-white">{svgIcons.noimg}</div>
              </div>
            </div>
            <div></div>
          </div>
          <div className="lg:w-full grid gap-10 lg:gap-5">
            <div className="border-t lg:border-0 border-[#E9ECF1] pt-8 lg:pt-0 flex flex-wrap gap-6 justify-between items-center">
              <div className="grid gap-[18px] max-w-[350px]">
                <div className="font-semibold text-[22px] leading-[28.6px]">
                  {property.name}
                </div>
                <p className="text-[#707787]">{property.address}</p>
              </div>
              <Link
                to={`/properties/edit/${propertyId}`}
                className="flair-btn flair-btn-md flair-btn-secondary w-[140px]"
              >
                Edit
              </Link>
            </div>
            <div className="border-t border-[#E9ECF1] pt-8 lg:pt-7 flex flex-wrap gap-6">
              <div className="grid gap-6">
                <div className="grid gap-4">
                  <div className="font-semibold leading-[17.6px]">
                    Contact number
                  </div>
                  <p className="">{property.phone}</p>
                </div>
                <div className="grid gap-4">
                  <div className="font-semibold leading-[17.6px]">
                    Property site
                  </div>
                  <a href={property.homepage_link}>{property.homepage_link}</a>
                </div>

                <div className="flex gap-2 items-center">
                  <div className="font-semibold leading-[17.6px]">
                    Campaigns
                  </div>
                  <img
                    src={
                      !project || projectStatusName(project) === 'Pending'
                        ? '/assets/images/disabled-tile.svg'
                        : `/assets/images/${projectStatusName(
                            project,
                          ).toLowerCase()}-tile.svg`
                    }
                    alt=""
                  />
                  {!project || projectStatusName(project) === 'Pending' ? (
                    <div className="font-medium leading-[20.8px]">Pending</div>
                  ) : (
                    <div className="">{projectStatusName(project)}</div>
                  )}
                  <Link
                    to={project ? `/project/${project.id}` : '#'}
                    style={
                      !project
                        ? { pointerEvents: 'none', opacity: '0.6' }
                        : { pointerEvents: 'auto' }
                    }
                  >
                    Open
                  </Link>
                </div>
              </div>
              <div className="">
                <div className="font-semibold text-4 leading-[17.6px] pb-2">
                  Property floorplans
                </div>
                {property.propertyunit_set.map((item, index) => {
                  return (
                    <li className="ml-4 mt-2" key={`floorplan-${index}`}>
                      {Number(item.bedrooms)} Bedroom, &nbsp;&nbsp;
                      {Number(item.bathrooms)} Bathroom
                    </li>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-10 flex-wrap border-t border-[#E9ECF1] pt-8">
          <div className="grid gap-5">
            <div className="font-semibold text-4 leading-[17.6px]">
              Community amenities
            </div>
            <div className="flex flex-wrap gap-3 w-[335px]">
              {amenityNames.map((item, index) => {
                return (
                  <div
                    className="font-semibold text-[14px] leading-4 px-5 py-[10px] rounded-3xl bg-[#E6EFFB99]"
                    key={`amenity-${index}`}
                  >
                    {item}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="font-semibold text-4 leading-[17.6px]">
              Concessions
            </div>
            <p>{property.concessions}</p>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="px-0 xs:px-[40px]">
      <div className="px-5 xs:px-0 py-8 font-semibold text-base leading-[17.6px] flex items-center">
        <Skeleton
          width={180}
          baseColor="rgba(202, 220, 237, 0.7)"
          highlightColor="rgba(219, 230, 242, 1)"
        />
      </div>
      <div className="flex flex-wrap gap-4 justify-between px-5 xs:px-0">
        <h5>
          <Skeleton
            width={240}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </h5>
        <Skeleton
          borderRadius={32}
          width={202}
          height={42}
          baseColor="rgba(202, 220, 237, 0.7)"
          highlightColor="rgba(219, 230, 242, 1)"
        />
      </div>
      <div className="white-background skeleton-background grid gap-10">
        <div className="grid gap-10 lg:flex">
          <div className="w-full lg:w-min">
            <div className="aspect-[10/9] lg:w-[380px]">
              <Skeleton
                containerClassName="w-full h-full"
                className="w-full h-full"
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
            <div></div>
          </div>
          <div className="lg:w-full grid gap-10 lg:gap-5">
            <div className="border-t lg:border-0 border-[#E9ECF1] pt-8 lg:pt-0 flex flex-wrap gap-6 justify-between items-center">
              <div className="grid gap-[18px] max-w-[350px]">
                <div className="font-semibold text-[22px] leading-[28.6px]">
                  <Skeleton
                    width={264}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
                <p className="text-[#707787]">
                  <Skeleton
                    width={399}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
              </div>
              <Skeleton
                borderRadius={32}
                width={140}
                height={42}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
            <div className="border-t border-[#E9ECF1] pt-8 lg:pt-7 flex flex-wrap gap-6">
              <div className="grid gap-6">
                <div className="grid gap-4">
                  <div className="font-semibold leading-[17.6px]">
                    <Skeleton
                      width={122}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <p>
                    <Skeleton
                      width={166}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </p>
                </div>
                <div className="grid gap-4">
                  <div className="font-semibold leading-[17.6px]">
                    <Skeleton
                      width={98}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <Skeleton
                    width={187}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>

                <div className="flex gap-2 items-center">
                  <div className="font-semibold leading-[17.6px]">
                    <Skeleton
                      width={144}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <Skeleton
                    width={35}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>
              </div>
              <div>
                <div className="font-semibold text-4 leading-[17.6px] pb-2">
                  <Skeleton
                    width={147}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </div>

                <li className="ml-4 mt-2">
                  <Skeleton
                    width={147}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </li>
                <li className="ml-4 mt-2">
                  <Skeleton
                    width={147}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </li>
                <li className="ml-4 mt-2">
                  <Skeleton
                    width={147}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </li>
                <li className="ml-4 mt-2">
                  <Skeleton
                    width={147}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </li>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-10 flex-wrap border-t border-[#E9ECF1] pt-8">
          <div className="grid gap-5">
            <div className="font-semibold text-4 leading-[17.6px]">
              <Skeleton
                width={165}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
            <div className="flex flex-wrap gap-3 w-[335px]">
              <Skeleton
                borderRadius={24}
                width={71}
                height={36}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <Skeleton
                borderRadius={24}
                width={98}
                height={36}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <Skeleton
                borderRadius={24}
                width={67}
                height={36}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <Skeleton
                borderRadius={24}
                width={212}
                height={36}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <div className="font-semibold text-4 leading-[17.6px]">
              <Skeleton
                width={95}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
            <p>
              <Skeleton
                width={195}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <Skeleton
                width={195}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
