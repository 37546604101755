import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { map, zip } from 'lodash-es';
// import { footerOption } from '../charts/LineChart';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  // scales['x'].ticks.display: false,
  scales: {
    xAxis: {},
    yAxis: {
      // suggestedMin: 10,
      // suggestedMax: 40,
      // min: 0,
      // max: 50,
      weight: 20,

      title: {
        display: false,
        text: 'Money Spent',
        padding: 11,
      },

      ticks: {
        display: true,
        padding: 8,
        backdropPadding: 3,
        callback: function (value, index, values) {
          return index % 2 === 0 ? this.getLabelForValue(value) : '';
        },
      },

      grid: {
        // borderColor: 'rgba(1, 1, 1, 1)',
        borderWidth: 1,
        borderDash: [1],
        display: true,
        drawTicks: false,
        lineWidth: 2,
        tickWidth: 2,
        tickColor: 'rgb(0, 255, 0)',
        // tickLength: '8',
        z: '6',
      },
    },
  },
  interaction: {
    mode: 'index',
  },
  plugins: {
    legend: {
      position: 'bottom',
      align: 'start',
    },
    title: {
      display: false,
      text: 'Money Spent',
    },
    tooltip: {
      // callbacks: {
      //   footer: footerOption,
      // },
      backgroundColor: 'white',
      titleColor: '#515868',
      titleFont: {
        weight: 500,
        size: 14,
      },
      bodyFont: {
        weight: 500,
        size: 14,
      },
      bodyColor: '#353E4F',
      footerFont: {
        weight: 500,
        size: 12,
      },
      footerAlign: 'right',
      footerColor: '#161D37',
      borderColor: '#E9EBF1',
      borderWidth: 1,
      padding: 16,
    },
  },
};

// const dummyData = {
//   monthLabels,
//   datasets: [
//     {
//       label: 'Data1',
//       // barThickness: 25,
//       borderRadius: 24,
//       barPercentage: 0.6,
//       categoryPercentage: 0.6,
//       data: [35, 29, 40, 31, 26, 35, 40, 24, 39, 25, 30, 40,],
//       backgroundColor: '#A8B1EF',
//     },
//     {
//       label: 'Data2',
//       // barThickness: 25,
//       borderRadius: 24,
//       barPercentage: 0.6,
//       categoryPercentage: 0.6,

//       data: [31, 46, 35, 40, 35, 29, 20, 41, 36, 15, 40, 31,],
//       backgroundColor: '#E0ECF1',
//     },
//   ],
// };

export default function BarChart({ setlabels, labels, datasets }) {
  let data = {
    labels,
    datasets: map(zip(setlabels, datasets), ([setlabel, dataset], index) => ({
      // data
      label: setlabel,
      data: dataset,
      // styles
      borderRadius: 24,
      barPercentage: 0.6,
      categoryPercentage: 0.6,
      borderColor:
        index === 0 ? 'rgba(168, 177, 239, 1)' : 'rgba(233, 236, 241, 1)',
      backgroundColor:
        index === 0 ? 'rgba(168, 177, 239, 1)' : 'rgba(233, 236, 241, 1)',
    })),
  };
  return <Bar options={options} data={data} />;
}
