import React from 'react';
import { IoMdCloseCircle } from 'react-icons/io';

function Tag({ data, onRemove }) {
  const color = data['color'] || 'black';
  const label = data['label'] || 'N/A';
  return (
    <div
      className={
        'flex justify-center items-center self-stretch px-1 my-auto rounded-2xl border-2 border-solid min-h-[28px]'
      }
      style={{ color: color, borderColor: color }}
    >
      <div className="self-stretch px-2 my-auto min-h-[28px]">{label}</div>
      {onRemove && (
        <a href="#">
          <IoMdCloseCircle
            onClick={(e) => {
              if (onRemove) {
                onRemove(e, data);
              }
            }}
          />
        </a>
      )}
    </div>
  );
}

export default Tag;
