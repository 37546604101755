export const saveUserInfo = (userInfo) => {
  localStorage.setItem('userInfo', JSON.stringify(userInfo));
};

export const getUserInfo = () => {
  const strUserInfo = localStorage.getItem('userInfo') || 'null';
  try {
    return JSON.parse(strUserInfo);
  } catch (error) {
    console.log(error);
    return null;
  }
};
