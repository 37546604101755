import { createSlice } from '@reduxjs/toolkit';

export const uxAlertSlice = createSlice({
  name: 'uxAlert',
  initialState: {
    show: false,
    request: {},
  },
  reducers: {
    showAlert: (state, action) => {
      state.request = action.payload;
      state.show = true;
    },
    hideAlert: (state) => {
      state.show = false;
    },
  },
});

export const { showAlert, hideAlert } = uxAlertSlice.actions;

export default uxAlertSlice.reducer;

export const fetchAlertShow = (state) => {
  return state.uxAlert.show;
};

export const fetchAlertRequest = (state) => {
  return state.uxAlert.request;
};
