import React, { useEffect, useRef, useState } from 'react';
import {
  Popper,
  Paper,
  TextField,
  MenuList,
  MenuItem,
  Chip,
  Input,
} from '@mui/material';
import Tag from './Tag';
import { el } from 'date-fns/locale';
import { FaPencilAlt } from 'react-icons/fa';
// import { Input } from '@nextui-org/react';
import { getUserInfo } from '../../utils/user';

const TagsSelect = ({
  data,
  anchorEl,
  onClose,
  onSelectItem,
  onClickEditList,
  multiCompanyMode,
}) => {
  const loginUser = getUserInfo();
  if (!loginUser.company && multiCompanyMode === undefined) {
    multiCompanyMode = true;
  }
  const companies = [{ id: 0, name: 'All companies' }];
  data.forEach((tag) => {
    if (!companies.map((company) => company.id).includes(tag.company.id)) {
      companies.push(tag.company);
    }
  });
  const [filterText, setFilterText] = useState('');
  const [filteredData, setFilteredData] = useState(data);
  const [selectedCompanyId, setSelectedCompanyId] = useState(0);
  useEffect(() => {
    let filtered = data.filter((tag) =>
      tag.label.toLowerCase().includes(filterText.toLowerCase()),
    );
    if (multiCompanyMode && parseInt(selectedCompanyId) != 0) {
      filtered = filtered.filter((tag) => tag.company.id == selectedCompanyId);
    }
    setFilteredData(filtered);
  }, [filterText, data, selectedCompanyId, multiCompanyMode]);

  const popperRef = useRef(null);

  // const filteredData = data.filter((tag) =>
  //   tag.label.toLowerCase().includes(inputValue.toLowerCase()),
  // );

  useEffect(() => {
    function handleClickOutside(event) {
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        onClose(event);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Popper
      open={Boolean(anchorEl)}
      anchorEl={anchorEl}
      placement="bottom-start"
      ref={popperRef}
      style={{ zIndex: 10000 }}
      disablePortal={true}
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      {/* padding 4 pixel all sides */}
      <Paper elevation={3} className="rounded-[6px] overflow-hidden">
        <div>
          {multiCompanyMode && (
            <select
              className="border-b flex px-4 py-3 gap-3 items-center self-stretch bg-white w-full"
              value={selectedCompanyId}
              onChange={(e) => setSelectedCompanyId(e.target.value)}
            >
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {company.name}
                </option>
              ))}
            </select>
          )}
        </div>
        <div>
          <Input
            className="border-b flex px-4 py-3 gap-3 items-center self-stretch bg-white "
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            placeholder="Search tags"
            autoFocus={true}
          />
        </div>
        <div>
          <p className="p-3 text-[#71717A] px-4 pb-1 pt-2">
            Select a tag or create a one
          </p>
        </div>
        <MenuList className="max-h-[204px] overflow-y-auto">
          {filteredData.map((tagData, index) => {
            if (tagData === 'heading') {
              return (
                <MenuItem key={index} disabled>
                  <p className="text-[#71717A] px-4 py-1">
                    Select a tag or create a one
                  </p>
                </MenuItem>
              );
            } else {
              return (
                <MenuItem
                  key={tagData.id}
                  onClick={(e) => {
                    if (onSelectItem) {
                      onSelectItem(e, tagData);
                    }
                  }}
                >
                  <Tag data={tagData} />
                  {multiCompanyMode && selectedCompanyId == 0 && (
                    <>
                      <span className="text-[#71717A] italic text-sm">
                        &nbsp;&nbsp;{tagData.company.name}
                      </span>
                    </>
                  )}
                </MenuItem>
              );
            }
          })}
        </MenuList>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '8px 12px',
            color: '#6366F1', // Tailwind blue-500 color
          }}
          onClick={onClickEditList}
        >
          <FaPencilAlt style={{ marginRight: '8px' }} />
          <span>Add & Edit options</span>
        </div>
      </Paper>
    </Popper>
  );
};

export default TagsSelect;
