import React, { useState, useRef } from 'react';

export default function UploadPhoto({
  btnCaption,
  name,
  imageName: _imageName,
  onChooseFile,
}) {
  const inputFile = useRef(null);
  const [imageName, setImageName] = useState(_imageName || '');

  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      setImageName(files[0].name);
      onChooseFile();
    }
  };

  return (
    <div className="flex gap-4">
      <input
        className="flair-input px-11"
        type="text"
        value={imageName}
        readOnly
      />
      <button
        className="flair-btn flair-btn-secondary flair-btn-md w-[100px] min-w-[100px]"
        onClick={() => {
          inputFile.current.click();
        }}
        type="button"
      >
        {btnCaption}
      </button>
      <input
        className="hidden"
        accept=".PNG,.png, .JPEG, .jpeg, .JPG, .jpg, .GIF, .gif"
        ref={inputFile}
        onChange={handleFileUpload}
        type="file"
        name={name}
      />
    </div>
  );
}
