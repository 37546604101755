import Modal from 'react-bootstrap/Modal';
import { Button } from '@nextui-org/react';

export default function RightPanelModal(props) {
  return (
    <Modal
      {...props}
      backdropClassName="opacity-30"
      dialogClassName={
        'animate-slide-in-right absolute w-[494px] h-full right-0 m-auto drop-shadow-[0_10px_20px_rgba(116,116,126,0.1)] bg-black'
      }
      contentClassName={'border-0 rounded-0 h-full'}
    >
      <Button
        isIconOnly
        variant="light"
        radius="full"
        onClick={props.onClose}
        className={'absolute top-5 right-5'}
      >
        <img src="/assets/images/close.png" width={40} alt="" />
      </Button>
      <div className="font-[Saans TRIAL] min-h-full grid content-between pt-20">
        <div className="px-10 bg-white">
          <div className="text-3xl leading-9 font-semibold">{props.title}</div>
          {props.content}
        </div>
        <div className="border h-[100px] px-10 py-6 flex gap-2 bg-white z-10">
          <Button
            variant="bordered"
            radius="sm"
            onClick={props.onClose}
            className="h-[52px] w-[201px] text-base leading-6 font-medium"
          >
            Cancel
          </Button>
          <Button
            variant="solid"
            radius="sm"
            onClick={props.handleConfirm}
            className="h-[52px] w-[201px] bg-[#242424] text-white text-base leading-6 font-medium"
          >
            {props.confirmTxt ? props.confirmTxt : 'Send Invite'}
          </Button>
        </div>
      </div>
    </Modal>
  );
}
