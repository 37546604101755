import React, { useEffect, useState, useRef } from 'react';
import { RxDragHandleDots2 } from 'react-icons/rx';
import { IoAddCircle, IoReturnDownBack } from 'react-icons/io5';
import { MdClose } from 'react-icons/md';
import {
  IconButton,
  List,
  ListItem,
  Paper,
  ListItemIcon,
  Input,
  Popper,
} from '@mui/material';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { calcDisplayOrder, generateNextTagColor } from './tagUtils';
import Tag from './Tag';
import { FaTrash } from 'react-icons/fa';
import { BiEditAlt } from 'react-icons/bi';
import { apiAgent } from '../../utils/apicall';
import { getUserInfo } from '../../utils/user';

const mockit = false;
const mockTags = [
  { id: 1, label: 'Chip 1', color: 'red', display_order: 100 },
  { id: 2, label: 'Chip 2', color: 'blue', display_order: 300 },
  { id: 3, label: 'Chip 3', color: 'green', display_order: 200 },
];
const mockCompanies = null;

const DragAndDropChips = ({ anchorEl, onClose, onUpdateTag }) => {
  // states
  const [companies, setCompanies] = useState(null);
  const [tags, setTags] = useState([]); // existing tags data
  const [draggable, setDraggable] = useState(true);
  const [focusId, setFocusId] = useState(null);
  const [editing, setEditing] = useState(false);
  const loginUser = getUserInfo();
  const [companyId, setCompanyId] = useState(null);
  const labelBeforeEdit = useRef('');

  const popperRef = useRef(null);

  // related to DnD
  const listRef = useRef(null);
  const handleDragStart = () => {
    if (listRef.current) {
      // Store the initial scroll position
      listRef.current.scrollPosition = listRef.current.scrollTop;
    }
  };
  const handleDragUpdate = (update) => {
    // if (listRef.current) {
    //   const { destination } = update;

    //   // Adjust the dragging position by subtracting the scroll offset
    //   console.log(
    //     'dnd update listRef.current.scrollPosition',
    //     listRef.current.scrollPosition,
    //     'listRef.current.scrollTop',
    //     listRef.current.scrollTop,
    //   );
    //   if (destination) {
    //     const scrollOffset =
    //       listRef.current.scrollTop - listRef.current.scrollPosition;
    //     update.destination.index -= Math.round(scrollOffset / 50); // Adjust as needed based on your item height
    //   }
    // }
    const scrollOffset =
      listRef.current.scrollTop - listRef.current.scrollPosition;
    // update.transform.y -= scrollOffset;
  };

  // methods
  const reload = async () => {
    setDraggable(false);
    setEditing(false);
    setFocusId(null);
    await loadTags();
    setDraggable(true);
    if (!loginUser.company) {
      await loadCompanies();
    }
  };
  const loadCompanies = async () => {
    // mock up
    let loadedCompanies;
    if (mockit) {
      loadedCompanies = companies.length ? companies : mockCompanies;
    } else {
      const response = await apiAgent.get({
        path: '/companies',
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      if (response.ok) {
        loadedCompanies = await response.json();
      } else {
        return;
      }
    }
    if (!companyId && loadedCompanies.length > 0) {
      setCompanyId(loadedCompanies[0].id);
    }
    setCompanies(loadedCompanies);
  };
  const loadTags = async () => {
    // mock up
    let loadedTags;
    let filterParam = {};
    if (companyId) {
      filterParam = { company_id: companyId };
    }
    if (mockit) {
      loadedTags = tags.length ? tags : mockTags;
    } else {
      if (!companyId) {
        return;
      }
      const response = await apiAgent.get({
        path: '/companytags',
        params: filterParam,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      if (response.ok) {
        loadedTags = await response.json();
        loadedTags = loadedTags.filter((tag) => tag.company.id == companyId);
        loadedTags.forEach((tag) => {
          tag.display_order = parseFloat(tag.display_order);
        });
      } else {
        return;
      }
    }
    // order loadedTags with display_order ascending
    loadedTags.sort((a, b) => a.display_order - b.display_order);
    setTags(loadedTags);
  };
  const createTag = async () => {
    if (mockit) {
      const newTag = tags.find((tag) => tag.id === null);
      const maxId = Math.max(
        ...tags
          .filter((tag) => !isNaN(parseInt(tag.id)))
          .map((tag) => parseInt(tag.id)),
      );
      newTag.id = maxId ? maxId + 1 : 1;
      setTags([...tags]);
    } else {
      if (!companyId) {
        alert('Company ID is not set.');
        throw new Error('Company ID is not set.');
      }
      // real flow
      setDraggable(false);
      // save new tag into database
      const newTag = tags.find((tag) => tag.id === null);
      await apiAgent.post({
        path: '/companytags',
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
        data: {
          company: companyId,
          label: newTag.label,
          color: newTag.color,
          display_order: newTag.display_order,
        },
      });
      await loadTags();
      setDraggable(true);
    }
  };
  const updateTag = async (id, data) => {
    // real flow
    if (mockit) {
    } else {
      if (!companyId) {
        alert('Company ID is not set.');
        throw new Error('Company ID is not set.');
      }
      setDraggable(false);
      // save updated tag into database
      try {
        await apiAgent.patch({
          path: '/companytags/' + id,
          data: {
            company: companyId,
            label: data.label,
            color: data.color,
            display_order: data.display_order,
          },
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
      } catch (e) {
        setDraggable(true);
        throw e;
      }

      await loadTags();
      setDraggable(true);
      if (onUpdateTag) onUpdateTag();
    }
  };
  const deleteTag = async (id) => {
    if (mockit) {
      const updatedTags = tags.filter((tag) => tag.id !== id);
      setTags(updatedTags);
    } else {
      const confirmResult = window.confirm(
        'Are you sure you want to delete this tag?\nDeleting this tag will remove it from all associated properties.',
      );
      if (!confirmResult) return;
      setDraggable(false);
      // save updated tag into database
      try {
        setDraggable(false);
        await apiAgent.del({
          path: '/companytags/' + id,
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
      } catch (e) {
        setDraggable(true);
        throw e;
      }
      await loadTags();
      setDraggable(true);
      if (onUpdateTag) onUpdateTag();
    }
  };
  const setTagLabel = (id, label) => {
    const updatedTags = tags.map((tag) => {
      if (tag.id === id) {
        tag.label = label;
      }
      return tag;
    });
    setTags(updatedTags);
  };

  const submitTagLabel = async (id) => {
    const tag = tags.find((tag) => tag.id === id);
    // TODO: compare with prev value
    setDraggable(false);
    if (id) {
      await updateTag(id, {
        label: tag.label,
        display_order: tag.display_order,
      });
    } else {
      await createTag();
    }
    await reload();
    setDraggable(true);
  };

  // event handlers
  // on init
  useEffect(() => {
    setCompanyId(loginUser.company);
    reload();
  }, []);

  useEffect(() => {
    reload();
  }, [companyId]);

  // useEffect(() => {
  //   console.log('companyId', companyId);
  //   console.log('focusId', focusId);
  //   console.log('editing', editing);
  //   console.log('tags', JSON.stringify(tags));
  // }, [companyId, focusId, editing, tags]);

  // on click outside
  useEffect(() => {
    function handleClickOutside(event) {
      if (popperRef.current && !popperRef.current.contains(event.target)) {
        onClose(event);
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(tags);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);
    const dst_index = result.destination.index;
    const prevTag = dst_index - 1 >= 0 ? reorderedItems[dst_index - 1] : null;
    const nextTag =
      dst_index + 1 < reorderedItems.length
        ? reorderedItems[dst_index + 1]
        : null;
    movedItem.display_order = calcDisplayOrder(
      prevTag ? prevTag.display_order : null,
      nextTag ? nextTag.display_order : null,
      movedItem.display_order,
    );

    setTags(reorderedItems);
    if (!editing) {
      updateTag(movedItem.id, movedItem);
    }
  };

  return (
    <Popper
      open={true}
      anchorEl={anchorEl}
      placement="bottom-start"
      className="bg-white"
      ref={popperRef}
      style={{ zIndex: 10000 }}
      disablePortal={true}
    >
      <Paper elevation={3} sx={{ width: 300 }}>
        <header
          className="flex gap-10 justify-between items-center px-4 py-3 w-full text-sm font-medium leading-none bg-white border-b border-zinc-200 text-zinc-900"
          style={{ fontSize: '14px' }}
        >
          <h2 className="self-stretch my-auto  text-sm">Edit Options</h2>
          {Array.isArray(companies) && (
            <select
              onChange={(e) => {
                setCompanyId(e.target.value);
              }}
            >
              {companies.map((company) => (
                <option key={company.id} value={company.id}>
                  {' '}
                  {company.name}
                </option>
              ))}
            </select>
          )}
          <a
            href="#"
            className="text-sm"
            onClick={(e) => {
              e.preventDefault();
              if (onClose) {
                onClose(e, true);
              }
            }}
          >
            <span> </span>
            <MdClose />
            <span> </span>
          </a>
        </header>
        <DragDropContext
          onDragEnd={handleDragEnd}
          onDragStart={handleDragStart}
          onDragUpdate={handleDragUpdate}
        >
          <Droppable droppableId="chipsList">
            {(provided) => (
              <List
                {...provided.droppableProps}
                ref={(node) => {
                  listRef.current = node;
                  provided.innerRef(node);
                }}
                sx={{ maxHeight: 200, overflowY: 'auto', position: 'static' }}
              >
                {tags.map((item, index) => (
                  <Draggable
                    key={String(item.id)}
                    draggableId={String(item.id)}
                    index={index}
                    isDragDisabled={!draggable}
                  >
                    {(provided, snapshot) => {
                      const style = {
                        ...provided.draggableProps.style,
                        // ...(snapshot.isDragging && listRef.current
                        //   ? {
                        //       transform: `translate(${
                        //         provided.draggableProps.style.transform.split(
                        //           ',',
                        //         )[0]
                        //       }, ${
                        //         parseFloat(
                        //           provided.draggableProps.style.transform.split(
                        //             ',',
                        //           )[1],
                        //         ) - listRef.current.scrollTop
                        //       }px)`,
                        //     }
                        //   : {}),
                      };
                      // popperRef current position from window viewport
                      const rect = popperRef.current.getBoundingClientRect();
                      if (style.top !== null && style.top !== undefined) {
                        style.top -= rect.top;
                      }
                      return (
                        <ListItem
                          key={String(item.id)}
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={style}
                          sx={
                            focusId === item.id && !editing
                              ? {
                                  display: 'flex',
                                  alignItems: 'center',
                                  left: 'auto !important',
                                  top: 'auto',
                                  backgroundColor: '#F4F4F5',
                                }
                              : {
                                  display: 'flex',
                                  alignItems: 'center',
                                  left: 'auto !important',
                                  top: 'auto',
                                }
                          }
                          onMouseEnter={() => {
                            if (!editing) {
                              setFocusId(item.id);
                            }
                          }}
                          onMouseLeave={() => {
                            if (!editing) {
                              setFocusId(null);
                            }
                          }}
                        >
                          <ListItemIcon>
                            <IconButton disabled>
                              <RxDragHandleDots2 />
                            </IconButton>
                          </ListItemIcon>
                          {(!editing || focusId !== item.id) && (
                            <Tag data={item} />
                          )}

                          {editing && focusId === item.id && (
                            <>
                              <Input
                                id={'tag-input-' + String(item.id)}
                                placeholder="Option name"
                                value={item.label}
                                onChange={(e) => {
                                  setTagLabel(item.id, e.target.value);
                                }}
                                onKeyDown={(e) => {
                                  if (e.key === 'Enter') {
                                    // Handle Enter key press
                                    e.stopPropagation();
                                    e.preventDefault();
                                    submitTagLabel(item.id);
                                  }
                                  if (e.key === 'Escape') {
                                    // Handle Escape key press
                                    setTagLabel(
                                      item.id,
                                      labelBeforeEdit.current,
                                    );
                                    if (focusId == null) {
                                      // remove the new tag
                                      setTags(
                                        tags.filter((tag) => tag.id !== null),
                                      );
                                    }
                                    setFocusId(null);
                                    setEditing(false);
                                  }
                                }}
                              />
                              <span style={{ fontSize: '20px' }}>
                                <IoReturnDownBack />
                              </span>
                            </>
                          )}

                          {focusId === item.id && !editing ? (
                            <>
                              <span style={{ flexGrow: 1 }}> </span>
                              <IconButton
                                onClick={() => {
                                  const inputId =
                                    'tag-input-' + String(item.id);
                                  labelBeforeEdit.current = item.label;
                                  setTimeout(() => {
                                    const input =
                                      document.getElementById(inputId);
                                    if (input) {
                                      input.focus();
                                    }
                                  }, 100);
                                  setEditing(true);
                                }}
                                sx={{ fontSize: '16px', color: 'black' }}
                              >
                                <BiEditAlt />
                              </IconButton>
                              <IconButton
                                onClick={() => {
                                  deleteTag(item.id);
                                }}
                                sx={{ fontSize: '16px', color: 'black' }}
                              >
                                <FaTrash />
                              </IconButton>
                            </>
                          ) : (
                            <></>
                          )}
                        </ListItem>
                      );
                    }}
                  </Draggable>
                ))}
                {provided.placeholder}
              </List>
            )}
          </Droppable>
        </DragDropContext>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            cursor: 'pointer',
            padding: '8px 12px',
            color: '#6366F1', // Tailwind blue-500 color
          }}
          onClick={(e) => {
            e.preventDefault();
            if (editing) {
              alert(
                'Please finish editing the current tag before adding a new one.',
              );
              return;
            }
            let lastTag = null;
            if (tags.length > 0) {
              lastTag = tags[tags.length - 1];
            }
            const newTag = {
              id: null,
              label: '',
              color: generateNextTagColor(tags.map((tag) => tag.color)),
              display_order: calcDisplayOrder(
                lastTag ? lastTag.display_order : -100,
              ),
            };
            setTags([...tags, newTag]);
            setFocusId(null);
            setEditing(true);
            const inputId = 'tag-input-' + String(null);
            setTimeout(() => {
              const input = document.getElementById(inputId);
              if (input) {
                input.focus();
              }
            }, 100);
          }}
        >
          <IoAddCircle style={{ marginRight: '8px' }} />
          <span>Add option</span>
        </div>
      </Paper>
    </Popper>
  );
};

export default DragAndDropChips;
