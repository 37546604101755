import { createSlice } from '@reduxjs/toolkit';

export const searchPhraseSlice = createSlice({
  name: 'searchPhrases',

  initialState: {
    searchPhrase: '',
  },

  reducers: {
    setSearchPhrase: (state, action) => {
      state.searchPhrase = action.payload;
    },
  },
});

export const { setSearchPhrase } = searchPhraseSlice.actions;

export default searchPhraseSlice.reducer;

export const fetchSearchPhrase = (state) => {
  return state.searchPhrases.searchPhrase;
};
