import { components } from 'react-select';
import { svgIcons } from '../svgIcons/svgIcons';

export const DropdownIndicator = (state) => {
  return (
    <components.DropdownIndicator {...state}>
      <div
        className={`text-[#161D37] w-2 transition-all duration-50 ease-linear ${
          state.selectProps.menuIsOpen && 'rotate-180'
        }`}
      >
        {svgIcons.dropdown}
      </div>
    </components.DropdownIndicator>
  );
};
