import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const arr = [0, 1, 2];

export default function DashboardNotificationSkeleton() {
  return arr.map((item) => {
    return (
      <div
        className="flex items-center h-[60px] skeleton-background rounded"
        key={item}
      >
        <Skeleton
          containerClassName="ml-5 mr-2"
          width={40}
          height={40}
          circle
          baseColor="rgba(202, 220, 237, 0.7)"
          highlightColor="rgba(219, 230, 242, 1)"
        />
        <Skeleton
          containerClassName="w-full mr-12"
          height={20}
          baseColor="rgba(202, 220, 237, 0.7)"
          highlightColor="rgba(219, 230, 242, 1)"
        />
      </div>
    );
  });
}
