import { createSlice } from '@reduxjs/toolkit';
import { apiAgent } from '../../utils/apicall';

export const unreadNotificationsSlice = createSlice({
  name: 'unreadNotifications',

  initialState: {
    count: 0, // null means unfetched
  },

  reducers: {
    setCount: (state, action) => {
      state.count = action.payload;
      // return state
    },
  },
});

export const { setCount } = unreadNotificationsSlice.actions;

export default unreadNotificationsSlice.reducer;

export const fetchUnreadNotificationsCount = () => async (dispatch) => {
  try {
    const response = await apiAgent.get({
      path: '/notices',
      options: apiAgent.popularOptions.inBackground,
      params: { is_read: false, offset: 10000, limit: 1 }, // only to fetch count
    });
    const body = await response.json();
    dispatch(setCount(body.count));
  } catch (error) {
    console.log(error);
  }
};

export const selectCount = (state) => {
  return state.unreadNotifications.count;
};
