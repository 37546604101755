import React from 'react';
import ModalBase from '../modalbootstrap/ModalBase';
import { useSelector } from 'react-redux';
import {
  fetchConfirmShow,
  fetchConfirmRequest,
} from '../../features/uxdialog/uxconfirmSlice';

export default function ConfirmDialog() {
  const show = useSelector(fetchConfirmShow);
  const request = useSelector(fetchConfirmRequest);

  return (
    <ModalBase
      show={show}
      backdrop="static"
      contentClassName="p-[20px]"
      onClose={() => request.handleCancel()}
      content={
        <>
          {/* <h2 className="flex leading-4 mb-4">Flair</h2> */}
          <p className="mt-6">{request.text}</p>
          <div className="mt-4 flex justify-end">
            <button
              className="flair-btn flair-btn-sm flair-btn-primary w-[100px]"
              onClick={() => request.handleConfirm()}
            >
              OK
            </button>
            <button
              className="flair-btn flair-btn-sm flair-btn-secondary w-[100px] ml-2"
              onClick={() => request.handleCancel()}
            >
              Cancel
            </button>
          </div>
        </>
      }
    />
  );
}
