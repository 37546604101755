import { createSlice } from '@reduxjs/toolkit';

export const uxConfirmSlice = createSlice({
  name: 'uxConfirm',

  initialState: {
    show: false,
    request: {},
  },

  reducers: {
    showConfirm: (state, action) => {
      state.show = true;
      state.request = action.payload;
    },
    hideConfirm: (state) => {
      state.show = false;
    },
  },
});

export const { showConfirm, hideConfirm } = uxConfirmSlice.actions;

export default uxConfirmSlice.reducer;

export const fetchConfirmShow = (state) => {
  return state.uxConfirm.show;
};

export const fetchConfirmRequest = (state) => {
  return state.uxConfirm.request;
};
