import { ModalCentered } from './ModalCentered';

export default function SuccessModal(props) {
  return (
    <ModalCentered
      {...props}
      dialogClassName="670w:w-[432px]"
      formGroup={
        <div className="grid gap-4 670w:grid-cols-2">
          <button
            type="button"
            className="flair-btn flair-btn-md flair-btn-primary"
            onClick={props.handleSuccess}
          >
            {props.modalConfirmBtn}
          </button>
        </div>
      }
    />
  );
}
