import { Bar } from 'react-chartjs-2';
import { svgIcons } from '../svgIcons/svgIcons';
import { commafy } from '../../utils/publicFunctions';

export const ContainerWithBar = (props) => {
  return (
    <div
      className={`bg-white px-8 py-7 border border-[#E9ECF1] rounded ${props.className}`}
    >
      <div className="mb-3 flex justify-between items-start flex-wrap gap-2">
        <div className="font-semibold text-[18px] capitalize flex items-center gap-2">
          {props.title}
          <div
            className="text-[#B7BCC8] w-4"
            data-for="chart-info"
            data-tip={props['data-tip']}
          >
            {svgIcons.exclamation}
          </div>
        </div>
        <div className="ml-auto">
          <div className="text-[18px] font-semibold text-right">
            {parseFloat(props.statistics)
              ? `${props.prefix || ''}${commafy(props.statistics)}${
                  props.suffix || ''
                }`
              : 'N/A'}
          </div>
          <div className="font-medium text-xs leading-[15.6px] capitalize text-right">
            {props['sub-title']}
          </div>
        </div>
      </div>
      <div className="h-52 xl:h-72">
        <Bar
          data={{
            labels: props['chart-data-labels'],
            datasets: [
              {
                label: props['chart-label'],
                backgroundColor: props['color'],
                borderColor: props['color'],
                borderRadius: 24,
                barPercentage: 0.6,
                categoryPercentage: 0.6,
                data: props['chart-data'],
              },
            ],
          }}
          options={{
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                borderColor: '#E9EBF1',
                borderWidth: 1,
                borderRadius: 4,
                displayColors: true,
                bodySpacing: 10,
                backgroundColor: 'white',
                bodyColor: '#515868',
                titleColor: '#161D37',
                usePointStyle: true,
                callbacks: {
                  labelPointStyle: function (context) {
                    return {
                      pointStyle: 'circle',
                    };
                  },
                },
              },
            },
            interaction: {
              intersect: false,
              mode: 'index',
            },
            scales: {
              yAxes: {
                ticks: {
                  beginAtZero: true,
                  callback: function (value) {
                    return `${props.prefix || ''}${value}${props.suffix || ''}`;
                  },
                },
              },
            },
          }}
        />
      </div>
    </div>
  );
};
