import Modal from 'react-bootstrap/Modal';
import { svgIcons } from '../svgIcons/svgIcons';

export default function ModalBase(props) {
  return (
    <Modal
      {...props}
      backdropClassName="opacity-30"
      dialogClassName={
        'w-[335px] drop-shadow-[0_10px_20px_rgba(116,116,126,0.1)] m-auto ' +
        props.dialogClassName
      }
      contentClassName={'border-0 rounded-0 ' + props.contentClassName}
    >
      <button
        className={
          'absolute text-[#707787] top-5 right-5 hover:text-red-600 w-3'
        }
        onClick={props.onClose}
      >
        {svgIcons.cross}
      </button>
      {props.content}
    </Modal>
  );
}
