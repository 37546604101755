import { useEffect, useRef, useState } from 'react';
import {
  Outlet,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom';
import copy from 'copy-to-clipboard';
import uxDialog from '../../../utils/uxdialog';
import { HTTP_STATUS, apiAgent } from '../../../utils/apicall';
import { svgIcons } from '../../../components/svgIcons/svgIcons';
import LinkModal from '../../../components/modalbootstrap/LinkModal';
import DatePickerDropdown from '../../../components/dropdown/DatePickerDropdown';
import ReportSidebar from '../../../components/prosidebar/ReportSidebar';
import { REPORT_LIST } from '../../../utils/constants';
import config from '../../../utils/config';

export default function S() {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const location = useLocation();
  const navigator = useNavigate();

  const projectRef = useRef();
  const [period, setPeriod] = useState([]);

  const [toggle, setToggle] = useState(false);

  const [shareUrl, setShareUrl] = useState();

  const [downloadFlag, setDownloadFlag] = useState(false);

  useEffect(() => {
    (async function () {
      try {
        const response = await apiAgent.get({
          path: '/reports/decode_token',
          params: {
            token: params.token,
          },
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        const responseData = await response.json();
        if (responseData.result === 'failed') {
          await uxDialog.alert({
            text: 'url is not correct',
          });
          navigator('failed');
          return;
        }
        projectRef.current = responseData.data.project;
        setPeriod(
          searchParams.get('period')
            ? searchParams.get('period').split('~')
            : responseData.data.period,
        );
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  useEffect(() => {
    if (period.length) setSearchParams({ period: period.join('~') });
  }, [period]);

  const onShareUrl = async () => {
    try {
      const response = await apiAgent.get({
        path: '/reports/create_report_token',
        params: {
          project_id: projectRef.current.id,
          page_number: REPORT_LIST.findIndex(
            (item) => item === location.pathname.split('/').pop(),
          ),
          start_date: period[0],
          end_date: period[1],
        },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const responseData = await response.json();
      setShareUrl(`${window.location.origin}/share/${responseData.token}`);
    } catch (error) {
      console.log(error);
    }
  };

  const onDownloadPDF = async () => {
    uxDialog.alert({
      text: "We're preparing your PDF report, it will appear in your downloads shortly.",
    });
    if (!downloadFlag) {
      try {
        setDownloadFlag(true);
        const response = await apiAgent.get({
          path: '/reports/make_file',
          params: {
            project_id: projectRef.current.id,
            start_date: period[0],
            end_date: period[1],
            url: `${window.location.origin}/s/?link=`,
          },
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        if (HTTP_STATUS.isSuccess(response.status)) {
          const responseData = await response.json();
          const link = document.createElement('a');
          link.setAttribute('download', '');
          link.href = `${config.serverurl}reports/make_file/?get_file=${responseData.name}&project_id=${projectRef.current.id}`;
          link.click();
          setDownloadFlag(false);
        }
      } catch (error) {
        console.log(error);
        setDownloadFlag(false);
      }
    }
  };

  return (
    <div className="bg-[#F5FAFF]">
      <div className="sticky top-0 z-20 bg-white px-5 py-4 md:px-6 md:py-5 flex items-center justify-between">
        <div className="flex items-center gap-10">
          <img width={72} src="/assets/images/logo.png" alt="" />
          <div className="leading-[28.6px] text-[22px] font-bold hidden md:block">
            {projectRef.current && projectRef.current.name}
          </div>
        </div>
        <div className="flex items-center gap-1.5">
          <button
            className="flair-btn flair-btn-primary flair-btn-sm md:h-[42px] md:text-[14px] w-24 md:w-[137px]"
            onClick={onShareUrl}
          >
            <div className="w-4 md:w-5 h-4 md:h-5 flex items-center justify-center">
              <div className="w-[12.67px] md:w-[15.83px]">{svgIcons.share}</div>
            </div>
            Share
          </button>
          <button
            className="md:hidden w-6 h-6 flex items-center justify-center"
            onClick={() => {
              setToggle(true);
            }}
          >
            <div className="w-[18px] h-4">{svgIcons.hamburger}</div>
          </button>
        </div>
      </div>
      <div className="md:flex">
        <ReportSidebar
          toggle={toggle}
          location={location}
          onClose={() => {
            setToggle(false);
          }}
          onShare={onShareUrl}
        />
        <h1 className="md:hidden pt-[52px] pb-8 px-5 font-bold text-[28px] leading-[36.4px] ">
          {projectRef.current && projectRef.current.name}
        </h1>
        <div className="md:p-10 w-full">
          <div className="bg-white px-5 md:px-8 py-8 md:py-10 shadow-[0_20.303030014038086px_37.70562744140625px_0px_rgba(183,188,200,0.11)]">
            <div className="flex flex-wrap items-center justify-between gap-6 pb-6 border-b border-[#E9ECF1]">
              <div className="text-[22px] leading-[28.6px] font-bold capitalize">
                {location.pathname.split('/').pop().replace('-', ' ')}
              </div>
              <div className="flex items-center gap-6 flex-wrap w-full md:w-auto">
                <DatePickerDropdown
                  period={period}
                  onChange={setPeriod}
                  className="w-full min-w-[300px] md:w-fit"
                />
                <button
                  onClick={onDownloadPDF}
                  className="flair-btn flair-btn-secondary flair-btn-md leading-[15.4px] md:w-[188px]"
                >
                  <div className="w-5 h-5 flex items-center justify-center">
                    <div className="w-[16.67px]">{svgIcons.download}</div>
                  </div>
                  Download PDF
                </button>
              </div>
            </div>
            <Outlet
              context={[projectRef.current && projectRef.current.id, period]}
            />
          </div>
        </div>
      </div>
      <LinkModal
        show={shareUrl}
        onHide={() => setShareUrl(null)}
        onClose={() => setShareUrl(null)}
        linkUrl={shareUrl}
        mark={{
          icon: <img src="/assets/images/copy_link_mark.png" alt="" />,
          parentClass: 'text-[#687BFE] bg-[#687BFE]/10',
          childClass: 'w-[23.54px] h-[23.54px]',
        }}
        handleClose={() => setShareUrl(null)}
        headTitle="Get a link to this report"
        txt="Anyone on the internet with the link can view."
        handleConfirm={() => copy(shareUrl)}
      />
    </div>
  );
}
