import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// import { getXOffset } from '../../utils/publicFunctions';

export default function DashboardNotiComponent({
  isCheckable,
  isChecked,
  notice,
}) {
  const [checked, setChecked] = useState(false);
  let dt = DateTime.fromISO(notice.created_at);
  let timeDisplay = dt.toLocaleString(DateTime.TIME_SIMPLE);
  return (
    <div
      style={
        isCheckable
          ? { display: 'flex', alignItems: 'center' }
          : { display: 'block' }
      }
    >
      {isCheckable && (
        <label className="check-container ml-3 mr-5">
          <input
            type="checkbox"
            id="check"
            checked={isChecked || checked}
            onChange={(e) => setChecked(e.target.checked)}
            className="checkbox"
          />
          <span className="checkmark"></span>
        </label>
      )}
      <div
        className="w-full px-3 xs:px-6 py-2 flex gap-6"
        style={{
          border: '1px solid rgba(22, 29, 55, 0.05)',
          borderLeft: '5px solid',
          borderLeftColor: notice._color,
        }}
      >
        <div className="relative">
          <div className="w-10 h-10 text-center font-bold text-[18px] leading-[40px] rounded-full bg-[#F2F5F9]">
            ?
          </div>
          {
            notice._highlight_photo ? (
              <img
                src={notice._highlight_photo}
                alt=""
                onError={(e) => {
                  e.target.onerror = null;
                  e.target.src = '/assets/images/empty.png';
                }}
                className="absolute top-0 left-0 object-cover w-10 h-10 rounded-full"
              />
            ) : notice._initial ? (
              <div className="absolute top-0 left-0 bg-[#FF7F5F] text-white text-center font-bold text-[18px] leading-[40px] rounded-full">
                {notice._initial}
              </div>
            ) : (
              <></>
            ) // not possible
          }
          {!notice.is_read && (
            <div className="absolute -top-1 -left-1 w-[18px] h-[18px] rounded-full bg-[#EE380D] border-3 border-white " />
          )}
        </div>
        <div className="w-full flex flex-wrap justify-between items-center">
          <p className="">
            {notice._message}&nbsp;&nbsp;
            {notice._checkout && (
              <Link
                to={notice._checkout.path}
                className="text-[#687BFE] underline"
              >
                {notice._checkout.title}&nbsp;&nbsp;&gt;
              </Link>
            )}
          </p>
          <div className="font-normal text-[14px] leading-[18.2px] text-[#8C94A3]">
            {timeDisplay}
          </div>
        </div>
      </div>
    </div>
  );
}
