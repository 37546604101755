const rgbToHsl = (r, g, b) => {
  r /= 255;
  g /= 255;
  b /= 255;
  const max = Math.max(r, g, b),
    min = Math.min(r, g, b);
  let h,
    s,
    l = (max + min) / 2;

  if (max === min) {
    h = s = 0; // achromatic
  } else {
    const d = max - min;
    s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
    switch (max) {
      case r:
        h = (g - b) / d + (g < b ? 6 : 0);
        break;
      case g:
        h = (b - r) / d + 2;
        break;
      case b:
        h = (r - g) / d + 4;
        break;
    }
    h /= 6;
  }

  return [h * 360, s * 100, l * 100];
};

const hslToRgb = (h, s, l) => {
  s /= 100;
  l /= 100;
  h /= 360;

  let r, g, b;

  if (s === 0) {
    r = g = b = l; // achromatic
  } else {
    const hue2rgb = (p, q, t) => {
      if (t < 0) t += 1;
      if (t > 1) t -= 1;
      if (t < 1 / 6) return p + (q - p) * 6 * t;
      if (t < 1 / 2) return q;
      if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
      return p;
    };

    const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
    const p = 2 * l - q;
    r = hue2rgb(p, q, h + 1 / 3);
    g = hue2rgb(p, q, h);
    b = hue2rgb(p, q, h - 1 / 3);
  }

  return [r * 255, g * 255, b * 255];
};

export const generateNextTagColor = (previousColors) => {
  // Convert english color names to HEX
  previousColors = previousColors.map((color) => {
    switch (color.toLowerCase()) {
      case 'red':
        return '#FF0000';
      case 'green':
        return '#00FF00';
      case 'blue':
        return '#0000FF';
      case 'yellow':
        return '#FFFF00';
      case 'cyan':
        return '#00FFFF';
      case 'magenta':
        return '#FF00FF';
      case 'black':
        return '#000000';
      case 'white':
        return '#FFFFFF';
      default:
        return color;
    }
  });
  // Convert previous colors to HSL and extract hues
  const previousHues = previousColors.map((color) => {
    const [r, g, b] = color.match(/\w\w/g).map((c) => parseInt(c, 16));
    return rgbToHsl(r, g, b)[0];
  });

  // Sort previous hues
  previousHues.sort((a, b) => a - b);

  // Calculate largest gap between adjacent hues
  let maxGap = 0;
  let newHue = 0;

  for (let i = 0; i < previousHues.length - 1; i++) {
    const gap = previousHues[i + 1] - previousHues[i];
    if (gap > maxGap) {
      maxGap = gap;
      newHue = (previousHues[i + 1] + previousHues[i]) / 2;
    }
  }

  // Also check the gap between the last and first hue (circular hue space)
  const endGap = 360 - previousHues[previousHues.length - 1] + previousHues[0];
  if (endGap > maxGap) {
    newHue =
      ((previousHues[previousHues.length - 1] + previousHues[0] + 360) / 2) %
      360;
  }

  // Generate a new HSL color
  const [r, g, b] = hslToRgb(newHue, 70, 50); // 70% saturation, 50% lightness

  // Convert RGB to HEX
  const hex = `#${(
    (1 << 24) +
    (Math.round(r) << 16) +
    (Math.round(g) << 8) +
    Math.round(b)
  )
    .toString(16)
    .slice(1)}`;
  return hex;
};

export const calcDisplayOrder = (prev, next, curr) => {
  const validNumber = (num) => typeof num === 'number' && !isNaN(num);
  let calc = 0;

  if (validNumber(prev) && validNumber(next)) {
    if (validNumber(curr) && curr > prev && curr < next) {
      calc = curr;
    } else {
      calc = (prev + next) / 2;
    }
  }
  if (validNumber(prev) && !validNumber(next)) {
    if (validNumber(curr) && curr > prev) {
      calc = curr;
    } else {
      calc = prev + 100;
    }
  }
  if (!validNumber(prev) && validNumber(next)) {
    if (validNumber(curr) && curr < next) {
      calc = curr;
    } else {
      calc = next - 100;
    }
  }
  // only 10 digits after decimal
  calc = Math.round(calc * 10000000000) / 10000000000;
  return calc;
};

// Example usage
const previousColors = ['#FF5733', '#33FF57', '#3357FF'];
const newColor = generateNextTagColor(previousColors);
console.log(newColor); // Logs a color that is visually distinct from the previous ones
