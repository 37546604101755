import { useEffect, useRef } from 'react';

export const CheckBoxWithIndeterminate = (props) => {
  return (
    <button
      style={{ width: props.width, height: props.width }}
      onClick={props.onClick}
      className={
        'text-white rounded' +
        (props.value ? ' bg-[#687BFE]' : ' border-[#8C94A3] border-2')
      }
    >
      {(props.value === true && (
        <div className="w-2.5 h-1.5 border-[#e9ecf1] border-l-[3px] border-b-[3px] mb-1 ml-1 -rotate-45"></div>
      )) ||
        (props.value === 'indeterminate' && (
          <div className="w-2.5 h-0.5 bg-white align-middle m-auto"></div>
        ))}
    </button>
  );
};

export const CheckBox = (props) => {
  const checkboxRef = useRef();

  useEffect(() => {
    checkboxRef.current.indeterminate =
      props.state == 'indeterminate' ? true : false;
    checkboxRef.current.checked = props.state;
  }, [props.state]);

  return (
    <label className="checkbox">
      <input
        type="checkbox"
        ref={checkboxRef}
        onChange={props.onChange}
        disabled={props.disabled}
      />
      <span></span>
      {props.label}
    </label>
  );
};
