import Modal from 'react-bootstrap/Modal';

export default function EditAssigneeModal(props) {
  return (
    <Modal
      {...props}
      backdropClassName="opacity-30"
      dialogClassName={
        'w-[500px] drop-shadow-[0_10px_20px_rgba(116,116,126,0.1)] m-auto h-full w-full flex items-center justify-center ' +
        props.dialogClassName
      }
      contentClassName={'border-0 ' + props.contentClassName}
    >
      {props.content}
    </Modal>
  );
}
