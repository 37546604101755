export const serverFileNameFromURL = (url) => {
  const parsedUrl = new URL(url);
  const pathSegments = parsedUrl.pathname.split('/').slice(2); // skip the first empty and 'media' segments
  return pathSegments.join('/');
};

export const validateEmail = (value) => {
  // Simple
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(value);
};
