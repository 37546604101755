import React from 'react';
import Helmet from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import { DateTime } from 'luxon';
// import { formatPeriod } from '../../utils/times';

const _timeOffset = DateTime.now().toISOTime();
const timeOffset = _timeOffset.substring(_timeOffset.length - 6);

const currentYear = new Date().getFullYear();
const fromMonth = new Date(currentYear - 2, 0);
const toMonth = new Date(currentYear + 2, 11);
const todayDate = moment();
const yesterdayDate = moment().subtract(1, 'days');
const last7Date = moment().subtract(6, 'days');
const last14Date = moment().subtract(13, 'days');
const last30Date = moment().subtract(29, 'days');
const lastWeekFirstDate = moment().subtract(1, 'week').startOf('week');
const lastWeekLastDate = moment().subtract(1, 'week').endOf('week');
const lastMonthFirstDate = moment().subtract(1, 'month').startOf('month');
const lastMonthLastDate = moment().subtract(1, 'month').endOf('month');

function makeDate(str) {
  const dateArray = str.split('/');
  if (dateArray[0].length < 2) dateArray[0] = '0' + dateArray[0];
  if (dateArray[1].length < 2) dateArray[1] = '0' + dateArray[1];
  return dateArray[2] + '-' + dateArray[0] + '-' + dateArray[1] + 'T';
}

function YearMonthForm({ date, localeUtils, onChange }) {
  //month, year dropdown
  const months = localeUtils.getMonths();
  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = function handleChange(e) {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select
        name="month"
        onChange={handleChange}
        value={date.getMonth()}
        style={{ marginRight: '28px', height: '24px', fontWeight: 'bold' }}
      >
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select
        name="year"
        onChange={handleChange}
        value={date.getFullYear()}
        style={{ height: '24px', fontWeight: 'bold' }}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
}

export default class DayPickerNoti extends React.Component {
  constructor(props) {
    super(props);
    if (props.onClickApply) {
      this.handleClickApply = props.onClickApply;
    }
    if (props.onClickCancel) {
      this.handleClickCancel = props.onClickCancel;
    }
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayCursorEnter = this.handleDayCursorEnter.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.handleYearMonthChange = this.handleYearMonthChange.bind(this);
    this.setDateRange = this.setDateRange.bind(this); //added by arasen
    this.invertNumOfMons = this.invertNumOfMons.bind(this);
    this.updateHighlightedRange = this.updateHighlightedRange.bind(this);

    this.state = {
      from: null,
      to: null,
      enteredTo: null,
      periodDateShow: true,
      numOfMons: 2,
      isPickPossible: false,
      isCloseDropdown: false,
      isToday: false,
      isYesterday: false,
      isLast7: false,
      isLast14: false,
      isLast30: false,
      isLastWeek: false,
      isLastMonth: false,
      isAllPeriod: true,
      isCustom: false,
      focusOnSecond: false, // try not manage as state
      startDate: '',
      endDate: '',
    };

    this.picker2 = React.createRef();
  }

  getInitialState() {
    return {
      from: null,
      to: null,
      enteredTo: null,
    };
  }

  handleYearMonthChange(month) {
    this.setState({ month });
  }

  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  handleDayCursorEnter(day) {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  }

  invertNumOfMons(num) {
    if (num > 1) return 1;
    else return 2;
  }

  handleDayClick(day) {
    this.setState({ focusOnSecond: false });
    this.setState({
      // isFixedRange: true,
      // periodDateShow: false,
      // isCustom: true,
      from: day,
      to: day,
      enteredTo: day,
      periodDateShow: true,
      month: day,
      isToday: false,
      isYesterday: false,
      isLast7: false,
      isLast14: false,
      isLast30: false,
      isLastWeek: false,
      isLastMonth: false,
      isAllPeriod: false,
      startDate: makeDate(day.toLocaleDateString()) + '00:00:00' + timeOffset,
      endDate: makeDate(day.toLocaleDateString()) + '23:59:59' + timeOffset,
    });
    // if (this.isSelectingFirstDay(from, to, day)) {
    //   firstTmp = day.toLocaleDateString()
    //   this.setState({
    //     from: day,
    //     to: null,
    //     enteredTo: null,
    //     isFixedRange: true,
    //     isToday: firstTmp === todayDateStr,
    //     isYesterday: firstTmp === yesterdayDateStr,
    //     isLast7: false,
    //     isLast14: false,
    //     isLast30: false,
    //     isLastWeek: false,
    //     isLastMonth: false,
    //   })
    //   this.setState({periodDate: firstTmp})
    // } else {
    //   secondTmp = day.toLocaleDateString()
    //   this.setState({
    //     to: day,
    //     enteredTo: day,
    //     isToday: false,
    //     isYesterday: false,
    //     isLast7: firstTmp === last7DateStr && secondTmp === todayDateStr,
    //     isLast14: firstTmp === last14DateStr && secondTmp === todayDateStr,
    //     isLast30: firstTmp === last30DateStr && secondTmp === todayDateStr,
    //     isLastWeek: firstTmp === lastWeekFirstDateStr && secondTmp === lastWeekLastDateStr,
    //     isLastMonth: firstTmp === lastMonthFirstDateStr && secondTmp === lastMonthLastDateStr,
    //   })
    //   this.setState({periodDate: firstTmp + ' to ' + secondTmp})
    //   if (this.onPicker1ChangeRange) {
    //     const {from, to} = this.state
    //     this.onPicker1ChangeRange({from, to})
    //   }
    // }
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }

  updateHighlightedRange() {
    // selected range of focused picker
    let from, to;
    if (!this.state.focusOnSecond) {
      from = this.state.from;
      to = this.state.to;
    } else {
      from = this.picker2.current.state.from;
      to = this.picker2.current.state.to;
    }
    let rangeHighlightOptions = [
      'isToday',
      'isYesterday',
      'isLast7',
      'isLast14',
      'isLast30',
      'isLastWeek',
      'isLastMonth',
      'isAllPeriod',
    ];
    for (const option of rangeHighlightOptions) {
      const dictval = {};
      dictval[option] = false;
      this.setState(dictval);
    }
    // update state
    if (from) {
      from = moment(from)._d;
    }
    if (to) {
      to = moment(to)._d;
    }
    if (from === todayDate._d) {
      this.setState({ isToday: true });
    } else if (from === yesterdayDate._d) {
      this.setState({ isYesterday: true });
    } else if (from === last7Date._d && to === todayDate._d) {
      this.setState({ isLast7: true });
    } else if (from === last14Date._d && to === todayDate._d) {
      this.setState({ isLast14: true });
    } else if (from === last30Date._d && to === todayDate._d) {
      this.setState({ isLast30: true });
    } else if (from === lastWeekFirstDate._d && to === lastWeekLastDate._d) {
      this.setState({ isLastWeek: true });
    } else if (from === lastMonthFirstDate._d && to === lastMonthLastDate._d) {
      this.setState({ isLastMonth: true });
    } else {
      this.setState({ isAllPeriod: true });
    }
  }
  setDateRange(e) {
    this.setState({ isPickPossible: true });
    switch (e.target.value) {
      case 'Today':
        this.setState({
          isFixedRange: true,
          from: todayDate._d,
          to: todayDate._d,
          enteredTo: todayDate._d,
          periodDateShow: true,
          month: todayDate._d,
          isToday: true,
          isYesterday: false,
          isLast7: false,
          isLast14: false,
          isLast30: false,
          isLastWeek: false,
          isLastMonth: false,
          isAllPeriod: false,
          startDate:
            makeDate(todayDate._d.toLocaleDateString()) +
            '00:00:00' +
            timeOffset,
          endDate:
            makeDate(todayDate._d.toLocaleDateString()) +
            '23:59:59' +
            timeOffset,
        });
        break;
      case 'Yesterday':
        this.setState({
          isFixedRange: true,
          from: yesterdayDate._d,
          to: yesterdayDate._d,
          enteredTo: yesterdayDate._d,
          periodDateShow: true,
          month: yesterdayDate._d,
          isToday: false,
          isYesterday: true,
          isLast7: false,
          isLast14: false,
          isLast30: false,
          isLastWeek: false,
          isLastMonth: false,
          isAllPeriod: false,
          startDate:
            makeDate(yesterdayDate._d.toLocaleDateString()) +
            '00:00:00' +
            timeOffset,
          endDate:
            makeDate(yesterdayDate._d.toLocaleDateString()) +
            '23:59:59' +
            timeOffset,
        });
        break;
      case 'Last7':
        this.setState({
          isFixedRange: true,
          from: last7Date._d,
          to: todayDate._d,
          enteredTo: todayDate._d,
          periodDateShow: true,
          month: last7Date._d,
          isToday: false,
          isYesterday: false,
          isLast7: true,
          isLast14: false,
          isLast30: false,
          isLastWeek: false,
          isLastMonth: false,
          isAllPeriod: false,
          startDate:
            makeDate(last7Date._d.toLocaleDateString()) +
            '00:00:00' +
            timeOffset,
          endDate:
            makeDate(todayDate._d.toLocaleDateString()) +
            '23:59:59' +
            timeOffset,
        });
        break;
      case 'Last14':
        this.setState({
          isFixedRange: true,
          from: last14Date._d,
          to: todayDate._d,
          enteredTo: todayDate._d,
          periodDateShow: true,
          month: last14Date._d,
          isToday: false,
          isYesterday: false,
          isLast7: false,
          isLast14: true,
          isLast30: false,
          isLastWeek: false,
          isLastMonth: false,
          isAllPeriod: false,
          startDate:
            makeDate(last14Date._d.toLocaleDateString()) +
            '00:00:00' +
            timeOffset,
          endDate:
            makeDate(todayDate._d.toLocaleDateString()) +
            '23:59:59' +
            timeOffset,
        });
        break;
      case 'Last30':
        this.setState({
          isFixedRange: true,
          from: last30Date._d,
          to: todayDate._d,
          enteredTo: todayDate._d,
          periodDateShow: true,
          month: last30Date._d,
          isToday: false,
          isYesterday: false,
          isLast7: false,
          isLast14: false,
          isLast30: true,
          isLastWeek: false,
          isLastMonth: false,
          isAllPeriod: false,
          startDate:
            makeDate(last30Date._d.toLocaleDateString()) +
            '00:00:00' +
            timeOffset,
          endDate:
            makeDate(todayDate._d.toLocaleDateString()) +
            '23:59:59' +
            timeOffset,
        });
        break;
      case 'LastWeek':
        this.setState({
          isFixedRange: true,
          from: lastWeekFirstDate._d,
          to: lastWeekLastDate._d,
          enteredTo: lastWeekLastDate._d,
          periodDateShow: true,
          month: lastWeekFirstDate._d,
          isToday: false,
          isYesterday: false,
          isLast7: false,
          isLast14: false,
          isLast30: false,
          isLastWeek: true,
          isLastMonth: false,
          isAllPeriod: false,
          startDate:
            makeDate(lastWeekFirstDate._d.toLocaleDateString()) +
            '00:00:00' +
            timeOffset,
          endDate:
            makeDate(lastWeekLastDate._d.toLocaleDateString()) +
            '23:59:59' +
            timeOffset,
        });
        break;
      case 'LastMonth':
        this.setState({
          isFixedRange: true,
          from: lastMonthFirstDate._d,
          to: lastMonthLastDate._d,
          enteredTo: lastMonthLastDate._d,
          periodDateShow: true,
          month: lastMonthFirstDate._d,
          isToday: false,
          isYesterday: false,
          isLast7: false,
          isLast14: false,
          isLast30: false,
          isLastWeek: false,
          isLastMonth: true,
          isAllPeriod: false,
          startDate:
            makeDate(lastMonthFirstDate._d.toLocaleDateString()) +
            '00:00:00' +
            timeOffset,
          endDate:
            makeDate(lastMonthLastDate._d.toLocaleDateString()) +
            '23:59:59' +
            timeOffset,
        });
        break;
      case 'AllPeriod':
        this.setState({
          isFixedRange: true,
          from: null,
          to: null,
          enteredTo: null,
          periodDateShow: true,
          month: todayDate._d,
          isToday: false,
          isYesterday: false,
          isLast7: false,
          isLast14: false,
          isLast30: false,
          isLastWeek: false,
          isLastMonth: false,
          isAllPeriod: true,
          startDate: '',
          endDate: '',
        });
        break;
      default:
        return;
    }
  }

  onTrigger = () => {
    this.props.onClickApply(this.state.startDate, this.state.endDate);
  };

  render() {
    const {
      from,
      // to,
      enteredTo,
      // periodDateShow,
      isToday,
      isYesterday,
      isLast7,
      isLast14,
      isLast30,
      isLastWeek,
      isLastMonth,
      isAllPeriod,
    } = this.state;
    const modifiers = { start: from, end: enteredTo };
    const selectedDays = [from, { from, to: enteredTo }];
    return (
      <>
        <div className="p-6 pr-12 border-r border-r-[#E9ECF1]">
          <div className="grid gap-3">
            {/* <div className='picker-noti-radio-group-title-container'>
              <img src='/assets/images/setdate.svg' alt='' />
              <div className='picker-noti-radio-group-title'>Set date</div>
            </div> */}
            <div className="radio-container pr-0 pl-6">
              <input
                onChange={this.setDateRange}
                type="radio"
                checked={isToday}
                id="today"
                value="Today"
                name="select_range"
              />
              <label htmlFor="today">Today</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            <div className="radio-container pr-0 pl-6">
              <input
                onChange={this.setDateRange}
                type="radio"
                checked={isYesterday}
                id="yesterday"
                value="Yesterday"
                name="select_range"
              />
              <label htmlFor="yesterday">Yesterday</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            <div className="radio-container pr-0 pl-6">
              <input
                onChange={this.setDateRange}
                type="radio"
                checked={isLast7}
                id="last7"
                value="Last7"
                name="select_range"
              />
              <label htmlFor="last7">Last 7 days</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            <div className="radio-container pr-0 pl-6">
              <input
                onChange={this.setDateRange}
                type="radio"
                checked={isLast14}
                id="last14"
                value="Last14"
                name="select_range"
              />
              <label htmlFor="last14">Last 14 days</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            <div className="radio-container pr-0 pl-6">
              <input
                onChange={this.setDateRange}
                type="radio"
                checked={isLast30}
                id="last30"
                value="Last30"
                name="select_range"
              />
              <label htmlFor="last30">Last 30 days</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            <div className="radio-container pr-0 pl-6">
              <input
                onChange={this.setDateRange}
                type="radio"
                checked={isLastWeek}
                id="lastweek"
                value="LastWeek"
                name="select_range"
              />
              <label htmlFor="lastweek">Last week</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            <div className="radio-container pr-0 pl-6">
              <input
                onChange={this.setDateRange}
                type="radio"
                checked={isLastMonth}
                id="lastmonth"
                value="LastMonth"
                name="select_range"
              />
              <label htmlFor="lastmonth">Last month</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            <div className="radio-container pr-0 pl-6">
              <input
                onChange={this.setDateRange}
                type="radio"
                checked={isAllPeriod}
                id="allperiod"
                value="AllPeriod"
                name="select_range"
              />
              <label htmlFor="allperiod">All Time</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            {/* <div className='picker-container'>
              <input onChange={this.setDateRange} type='radio' 
                    checked={!(isT-notioday | isYesterday) & !(isLast7 | isLast14 | isLast30 | isLastWeek | isLastMonth) & isCustom} 
                    className='radiobox' id='custom' value='Custom' name='select_range' />
              <label className='radiobox-label' htmlFor='custom'>Custom</label>
              <span className='radiomark left-0 right-auto after:left-0 after:right-auto' />
            </div> */}
          </div>
        </div>

        <div className="p-1">
          <DayPicker
            fixedWeeks
            className="SelectableNoti"
            selectedDays={selectedDays}
            modifiers={modifiers}
            onDayClick={this.handleDayClick}
            month={this.state.month}
            fromMonth={fromMonth} //First month of total range
            toMonth={toMonth}
            onDayCursorEnter={this.handleDayCursorEnter}
            captionElement={({ date, localeUtils }) => (
              <YearMonthForm
                date={date}
                localeUtils={localeUtils}
                onChange={this.handleYearMonthChange}
              />
            )}
          />

          <Helmet>
            <style>
              {`
                .SelectableNoti .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                  background-color: #f0f8ff !important;
                  color: #4a90e2;
                }
                
                .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
                  border-radius: 50px !important;
                }

                .SelectableNoti .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                  background-color: rgba(255, 128, 95, 0.2) !important;
                  color: #161D37 !important;
                  border-radius: 3px !important;
                }                

                .SelectableNoti .DayPicker-Day {
                  border-radius: 0 !important;
                }
                .SelectableNoti .DayPicker-Day--start {
                  // border-bottom-left-radius: 22px !important;
                  // border-top-left-radius: 22px !important;
                  border-radius: 8px !important;
                }
                .SelectableNoti .DayPicker-Day--end {
                  // border-bottom-right-radius: 22px !important;
                  // border-top-right-radius: 22px !important;
                  border-radius: 8px !important;
                }
                .SelectableNoti .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
                  background-color: rgba(255, 128, 95, 0.7);
                }
              `}
            </style>
          </Helmet>
          {/* <div className='period-container'>
            <div 
              className='period-title' 
              style={(periodDateShow)? {display: 'block'} : {display: 'none'}}
            >
              Period:
            </div>
            <div className='picker-note'>
              <div 
                style={periodDateShow? {display: 'block'} : {display: 'none'}}
              >
              {
                from ?
                to ?
                formatPeriod(DateTime.fromJSDate(from), DateTime.fromJSDate(to))
                :
                `${DateTime.fromJSDate(from).toFormat('LLL d, y')}`
                : 
                'Please select period'
              }
              </div>
            </div>
          </div> */}

          <div className="grid xs:flex gap-3 pb-6 px-6">
            <button
              className="flair-btn flair-btn-secondary flair-btn-sm"
              onClick={this.handleClickCancel}
            >
              Cancel
            </button>
            <button
              className="flair-btn flair-btn-primary flair-btn-sm"
              onClick={this.onTrigger}
            >
              Apply
            </button>
          </div>
        </div>
      </>
    );
  }
}
