import { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Link,
  Outlet,
  useParams,
  useSearchParams,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import moment from 'moment';
import { DateTime } from 'luxon';
import { HTTP_STATUS, apiAgent } from '../../../utils/apicall';
import DatePickerDropdown from '../../../components/dropdown/DatePickerDropdown';
import LinkModal from '../../../components/modalbootstrap/LinkModal';
import copy from 'copy-to-clipboard';
import { setSelectedInfo } from '../../../features/userInfo/userInfoSlice';
import uxDialog from '../../../utils/uxdialog';
import config from '../../../utils/config';
import { REPORT_LIST } from '../../../utils/constants';
import { Button, Tabs, Tab } from '@nextui-org/react';

export default function Campaign() {
  const params = useParams();
  const propertyId = params.id;
  const selectedInfo = useSelector((state) => state.userInfo.selectedInfo);
  const projectId = selectedInfo.project;
  const dispatch = useDispatch();
  const navigator = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [project, setProject] = useState();
  const [property, setProperty] = useState();
  const [period, setPeriod] = useState([]);
  const [selectedProject, setSelectedProject] = useState();
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [modalAction, setModalAction] = useState();
  const [shareLink, setShareLink] = useState();
  const [downloadFlag, setDownloadFlag] = useState(false);

  const share = async () => {
    try {
      const response = await apiAgent.get({
        path: '/reports/create_report_token',
        params: {
          project_id: project.id,
          property_id: property.id,
          page_number: REPORT_LIST.findIndex(
            (item) => item === location.pathname.split('/').pop(),
          ),
          start_date: period[0],
          end_date: period[1],
        },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const responseData = await response.json();
      setShareLink(`${window.location.origin}/share/${responseData.token}`);
    } catch (error) {
      console.log(error);
    }
  };

  const download = async () => {
    uxDialog.alert({
      text: "We're preparing your PDF report, it will appear in your downloads shortly.",
    });
    if (downloadFlag === false) {
      try {
        setDownloadFlag(true);
        const response = await apiAgent.get({
          path: '/reports/make_file',
          params: {
            project_id: project.id,
            property_id: property.id,
            start_date: period[0],
            end_date: period[1],
            url: `${window.location.origin}/s/?link=`,
          },
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        if (HTTP_STATUS.isSuccess(response.status)) {
          const responseData = await response.json();
          const link = document.createElement('a');
          link.setAttribute('download', '');
          link.href = `${config.serverurl}reports/make_file/?get_file=${responseData.name}&project_id=${project.id}&property_id=${property.id}`;
          link.click();
          setDownloadFlag(false);
        }
      } catch (error) {
        console.log(error);
        setDownloadFlag(false);
      }
    }
  };

  const fetchProjectData = async () => {
    try {
      const response = await apiAgent.get({
        path: `/projects/${projectId}`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const projectBody = await response.json();
      dispatch(
        setSelectedInfo({
          project: parseInt(projectId),
          property: projectBody.property.id,
        }),
      );
      setProject(projectBody);
      setSelectedProject(projectBody);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchPropertyData = async (propertyId) => {
    if (!propertyId) return;
    const response = await apiAgent.get({
      path: `/properties/${propertyId}`,
      options: apiAgent.popularOptions.onConfirmExceptDeclined,
    });
    const data = await response.json();
    setProperty(data);
    window.document.title = `${data.name} Report | Flair`;
  };

  const fetchData = async () => {
    // fetch property and project
    await fetchPropertyData(propertyId);
    await fetchProjectData();
  };

  useEffect(() => {
    setPeriod(
      searchParams.get('period')
        ? searchParams.get('period').split('~')
        : [
            DateTime.fromJSDate(
              moment().subtract(1, 'month').startOf('month')._d,
            ).toFormat('yyyy-MM-dd'),
            DateTime.fromJSDate(
              moment().subtract(1, 'month').endOf('month')._d,
            ).toFormat('yyyy-MM-dd'),
          ],
    );
  }, []);

  useEffect(() => {
    if (projectId) fetchData();
  }, [projectId]);

  useEffect(() => {
    if (selectedProject && period.length) {
      setSearchParams({
        project: selectedProject && selectedProject.id,
        period: period.join('~'),
      });
    }
  }, [selectedProject, period]);

  return (
    <>
      <div>
        <div className="flex items-center justify-between gap-6 flex-wrap py-8">
          <div>
            <div className="text-3xl leading-[41.6px] font-bold">Reporting</div>
          </div>
        </div>
        {projectId && (
          <div className="bg-white flex flex-wrap justify-between items-center gap-6 p-4 rounded-xl shadow-sm">
            <div className="flex">
              <Tabs
                key="none"
                radius="sm"
                onSelectionChange={(e) => {
                  setTimeout(() => {
                    // quickfix: sub routes not recognized on first render (deployed env)
                    navigator(e + location.search);
                  }, 500);
                }}
                className="border rounded-xl text-base leading-6 font-medium"
                classNames={{
                  tabList: 'p-[1px] gap-0',
                }}
              >
                <Tab
                  key="account-overview"
                  title="Account"
                  className="h-[52px]"
                />
                <Tab
                  key="budget-overview"
                  title="Budget"
                  className="h-[52px]"
                />
                <Tab
                  key="conversion-overview"
                  title="Conversions"
                  className="h-[52px]"
                />
                <Tab
                  key="keyword-performance"
                  title="Keywords"
                  className="h-[52px]"
                />
                <Tab
                  key="demographic-performance"
                  title="Demographics"
                  className="h-[52px]"
                />
              </Tabs>
            </div>
            <div className="hidden xl:flex w-[1px] h-[43px] bg-default-200"></div>
            <div className="grid w-full xs:w-fit xs:flex flex-wrap items-center gap-6">
              <DatePickerDropdown
                className="w-full min-w-[300px] xs:w-fit"
                period={period}
                onChange={setPeriod}
              />
              <div className="grid xs:flex xs:flex-wrap items-center gap-4 xs:gap-6">
                <Button
                  variant="solid"
                  radius="md"
                  onClick={download}
                  className="w-[175px] h-[52px] bg-default-100 text-base leading-6 font-medium"
                  startContent={
                    <img
                      src="/assets/images/document-download.png"
                      width={24}
                      alt=""
                    />
                  }
                >
                  Download PDF
                </Button>
                <Button
                  variant="solid"
                  radius="md"
                  onClick={share}
                  className="w-[111px] h-[52px] bg-default-100 text-base leading-6 font-medium"
                  startContent={
                    <img src="/assets/images/send-2.png" width={24} alt="" />
                  }
                >
                  Share
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      {projectId ? (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mt-6 border-t border-[#6F728B21] bg-[#F5FAFF]">
          <div className="md:col-span-2">
            <Outlet context={[project, period, property]} />
          </div>
        </div>
      ) : (
        <div className="grid place-items-center">
          <div className="absolute rounded-md w-[488px] h-[140px] bg-white border-2 grid place-items-center">
            <div className="flex gap-2">
              <div>
                <img
                  className="w-full items-center"
                  src="/assets/images/danger.png"
                  alt=""
                />
              </div>
              <div className="grid">
                <div>Please connect your Google Account</div>
                <Link
                  to={`/properties/${propertyId}/integrations`}
                  className="text-primary underline"
                >
                  Click Here for Integrations
                </Link>
              </div>
            </div>
          </div>
          <img
            className="w-full items-center"
            src="/assets/images/chart blcok.png"
            alt=""
          />
        </div>
      )}
      <LinkModal
        show={shareLink}
        onHide={() => setShareLink(false)}
        onClose={() => setShareLink(false)}
        linkUrl={shareLink}
        mark={{
          icon: <img src="/assets/images/copy_link_mark.png" alt="" />,
          parentClass: 'text-[#687BFE] bg-[#687BFE]/10',
          childClass: 'w-[23.54px] h-[23.54px]',
        }}
        handleClose={() => setShareLink(false)}
        headTitle="Get a link to this report"
        txt="Anyone on the internet with the link can view."
        handleConfirm={() => copy(shareLink)}
      />
    </>
  );
}
