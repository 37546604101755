import { Dropdown, Button } from '@restart/ui';
import { DateTime } from 'luxon';
import { svgIcons } from '../svgIcons/svgIcons';
import DayPickerBody from '../daypicker/DayPickerBody';
import { convertDateTime } from '../../utils/publicFunctions';
import uxDialog from '../../utils/uxdialog';
import { memo } from 'react';

const DatePickerDropdown = (props) => {
  return (
    <div className={props.className}>
      <Dropdown>
        <Dropdown.Toggle>
          {(toggleProps) => (
            <Button
              {...toggleProps}
              className={`flex items-center justify-between gap-3 w-full px-3 py-[10px] border border-[#CED3DB] rounded hover:border-[#687BFE] ${
                toggleProps['aria-expanded'] &&
                'border-[#687BFE] shadow-[0_0_0_4px_rgba(104,123,254,0.25)]'
              }`}
            >
              <div className="flex items-center gap-2">
                <div className="flex justify-center items-center w-5 h-5">
                  <div className="w-[16.67px]">{svgIcons.calendar_line}</div>
                </div>
                <p>
                  {props.period
                    .map((item) => {
                      return DateTime.fromSQL(item).toFormat('LLL d, yyyy'); // except with compared
                    })
                    .join(' to ')}
                </p>
              </div>

              <div className="flex items-center justify-center w-5 h-5">
                <div className="w-[10.61px]">
                  {toggleProps['aria-expanded']
                    ? svgIcons.arrow_up_s_line
                    : svgIcons.arrow_down_s_line}
                </div>
              </div>
            </Button>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {(menuProps, meta) => (
            <div className="relative">
              <div
                {...menuProps}
                className="fixed w-full xs:w-auto bottom-0 xs:absolute xs:bottom-auto xs:top-0 right-0 z-10 rounded-lg bg-white border border-[#E9ECF1] shadow-[0_20.3px_37.71px_rgba(183, 188, 200, 0.11)] whitespace-nowrap flex"
                style={{
                  transition:
                    'visibility 500ms, opacity 500ms, transform 500ms',
                  visibility: meta.show ? 'visible' : 'hidden',
                  opacity: meta.show ? '1' : '0',
                  transform: meta.show ? 'translateY(6px)' : 'translateY(-4px)',
                }}
              >
                <DayPickerBody
                  onClickApply={async ({ from, to }) => {
                    try {
                      meta.toggle();
                      if (from == null && to == null)
                        throw 'Please select period.';
                      props.onChange(
                        from === to
                          ? convertDateTime(from).toFormat('yyyy-MM-dd')
                          : [from, to].map((item) =>
                              convertDateTime(item).toFormat('yyyy-MM-dd'),
                            ),
                      );
                    } catch (error) {
                      await uxDialog.alert({ text: error });
                    }
                  }}
                  onClickCancel={() => meta.toggle()}
                />
              </div>
            </div>
          )}
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default memo(DatePickerDropdown);
