import Modal from 'react-bootstrap/Modal';

export default function ConfirmDeletionModal(props) {
  return (
    <Modal
      {...props}
      backdropClassName="opacity-30"
      dialogClassName={
        'w-[497px] drop-shadow-[0_10px_20px_rgba(116,116,126,0.1)] m-auto h-full w-full flex items-center justify-center ' +
        props.dialogClassName
      }
      contentClassName={'border-0 ' + props.contentClassName}
    >
      <button
        className={
          'absolute text-[#707787] top-5 right-5 hover:text-red-600 w-10'
        }
        onClick={props.onClose}
      >
        <img src="/assets/images/close.png" width={40} alt="" />
      </button>
      {props.content}
    </Modal>
  );
}
