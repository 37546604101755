import { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { svgIcons } from '../../../components/svgIcons/svgIcons';
import ConfirmModal from '../../../components/modalbootstrap/ConfirmModal';
// import PropertyStateEditModal from '../../../components/modalbootstrap/PropertyStateEditModal';
import { CheckBox } from '../../../components/input/InputType';
import { apiAgent } from '../../../utils/apicall';
import { ProjectStatus } from '../../../utils/constants';
import { Link } from 'react-router-dom';
import { styleGoogleCustomerId } from '../../../utils/publicFunctions';
import Paginater from '../../../components/paginate/Paginater';
import Skeleton from 'react-loading-skeleton';
import LinesEllipsis from 'react-lines-ellipsis';
import {
  Avatar,
  Button,
  Input,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownTrigger,
} from '@nextui-org/react';
import RightPanelModal from '../../../components/modalbootstrap/RightPanelModal';
import AllSetModal from '../../../components/modalbootstrap/AllSetModal';
import EditAssigneeModal from '../../../components/modalbootstrap/EditAssigneeModal';
// import Tag from '../../../components/select/Tag';
import TagsContainer from '../../../components/select/TagsContainer';
import TagsSimpleContainer from '../../../components/select/TagsSimpleContainer';
import TagsSelect from '../../../components/select/TagsSelect.jsx';
import TagsListEdit from '../../../components/select/TagsListEdit.jsx';
// import TagSelector from '../../../components/select/ChipSelector/TagSelector.jsx';

const orderParams = ['name', 'project__gad_customer_id', ''];
const bgColors = ['bg-[#C0FFE6]', 'bg-[#FFF4DE]', 'bg-[#FFF0E8]'];
const textColors = ['text-[#13B184]', 'text-[#DCA12A]', 'text-[#FF7350]'];

export default function Properties() {
  const [filteringTags, setFilteringTags] = useState([]);
  const [filteringTagIds, setFilteringTagIds] = useState([]);
  const [propertyCompanyTags, setPropertyCompanyTags] = useState([]);
  const [companyTags, setCompanyTags] = useState([]);
  const [companyTagsChoose, setCompanyTagsChoose] = useState([]); // companyTags - filteringTags
  // Property List
  const [focusPropertyId, setFocusPropertyId] = useState(null);
  const [tagsPopupState, setTagsPopupState] = useState('NotOpen'); // NotOpen | SelTags | EditList

  // Popup
  const [anchorEl, setAnchorEl] = useState(null);
  const resetTagsPopup = () => {
    setFocusPropertyId(null);
    setTagsPopupState('NotOpen');
    setAnchorEl(null);
  };
  const loadCompanyTags = async () => {
    let loadedTags = [];
    const response = await apiAgent.get({
      path: '/companytags',
      // params: filterParam,
      options: apiAgent.popularOptions.onConfirmExceptDeclined,
    });
    if (response.ok) {
      loadedTags = await response.json();
      loadedTags.forEach((tag) => {
        tag.display_order = parseFloat(tag.display_order);
      });
    } else {
      return;
    }
    loadedTags.sort((a, b) => a.display_order - b.display_order);
    setCompanyTags(loadedTags);
  };
  const loadPropertyCompanyTags = async () => {
    let loadedTags = [];
    const response = await apiAgent.get({
      path: '/propertycompanytags',
      // params: filterParam,
      options: apiAgent.popularOptions.onConfirmExceptDeclined,
    });
    if (response.ok) {
      loadedTags = await response.json();
      for (let ptag of loadedTags) {
        ptag.label = ptag.tag.label;
        ptag.color = ptag.tag.color;
      }
    } else {
      return;
    }

    setPropertyCompanyTags(loadedTags);
  };
  const loadTagsData = async () => {
    await loadCompanyTags();
    await loadPropertyCompanyTags();
  };
  const refreshFilteringTags = () => {
    const filteringTagsIds = filteringTags.map((tag) => tag.id);
    console.log('filteringTagsIds', filteringTagsIds);
    const newFilteringTags = filteringTags
      .filter((tag) => companyTags.map((ctag) => ctag.id).indexOf(tag.id) >= 0)
      .map((tag) => {
        return companyTags.filter((ctag) => ctag.id == tag.id)[0];
      });
    setFilteringTags(newFilteringTags);
  };
  const getTagsSelect = (companyTags, propertyCompanyTags, propertyId) => {
    // company id from property id
    const companyId = properties.filter(
      (property) => property.id == propertyId,
    )[0].company_id;

    return companyTags.filter((tag) => {
      const propertyTagIds = propertyCompanyTags
        .filter((ptag) => ptag.property.id == propertyId)
        .map((ptag) => ptag.tag.id);
      return propertyTagIds.indexOf(tag.id) < 0 && tag.company.id == companyId;
    });
  };
  const addPropertyCompanyTag = async (propertyId, tagId) => {
    const response = await apiAgent.post({
      path: '/propertycompanytags',
      data: {
        property: propertyId,
        tag: tagId,
      },
      options: apiAgent.popularOptions.onConfirmExceptDeclined,
    });
  };

  // useEffect(() => {
  //   console.log('tagsFilterOpen', tagsFilterOpen);
  //   console.log('focusPropertyId', focusPropertyId);
  //   console.log('anchorEl', anchorEl);
  //   console.log('companyTagsChoose', companyTagsChoose);
  //   console.log('propertyCompanyTags', propertyCompanyTags);
  // }, [
  //   tagsFilterOpen,
  //   focusPropertyId,
  //   anchorEl,
  //   companyTagsChoose,
  //   propertyCompanyTags,
  // ]);

  useEffect(() => {
    setFilteringTags(
      filteringTagIds
        .filter((id) => companyTags.map((tag) => tag.id).indexOf(id) >= 0)
        .map((id) => companyTags.filter((tag) => tag.id == id)[0]),
    );
    const tags = companyTags.filter(
      (tag) => filteringTagIds.indexOf(tag.id) < 0,
    );
    setCompanyTagsChoose(tags);
  }, [companyTags, filteringTagIds]);

  const handleCloseTagsEditList = (e, byButton) => {
    if (byButton) {
      loadTagsData();
      setTagsPopupState('SelTags');
    } else {
      loadTagsData();
      setTagsPopupState('NotOpen');
      setAnchorEl(null);
    }
  };

  const handleRemovePropertyTag = async (e, tag) => {
    e.preventDefault();
    await apiAgent.del({
      path: '/propertycompanytags/' + tag.id + '/',
      options: apiAgent.popularOptions.onConfirmExceptDeclined,
    });
    await loadTagsData();
  };

  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const showTagging = ['admin', 'agency'].includes(userInfo.permission_role);
  // checkbox state
  const [checkboxAllState, setCheckboxAllState] = useState(false);
  const [checkboxList, setCheckboxList] = useState([]);
  // handle check
  useEffect(() => {
    const checkCount = checkboxList.reduce(
      (count, item) => (count += item ? 1 : 0),
      0,
    );
    setCheckboxAllState(
      checkCount === checkboxList.length && checkboxList.length !== 0
        ? true
        : checkCount === 0
        ? false
        : 'indeterminate',
    );
  }, [checkboxList]);

  // order
  const [ordering, setOrdering] = useState('-created_at');
  // filter
  const [pageIndex, setPageIndex] = useState(0);
  const [pageLimit, setPageLimit] = useState(10);
  const [total, setTotal] = useState(0);
  const [search, setSearch] = useState('');
  const [filtering, setFiltering] = useState({
    project__ads_status__in: Object.values(ProjectStatus).join(','),
  });

  const [properties, setProperties] = useState([]);
  const [loadingFlag, setLoadingFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [inviteModal, setInviteModal] = useState(false);
  const [assigneeModal, setAssigneeModal] = useState(false);
  const [email, setEmail] = useState('');
  const [userProperty, setUserProperty] = useState([]);
  const [users, setUsers] = useState([]);
  const dispatch = useDispatch();
  const currentRequestRef = useRef(null);

  const loadProperties = async (
    pageIndex,
    pageLimit,
    ordering,
    filtering,
    search,
    tagIds,
  ) => {
    setLoadingFlag(true);
    const requestId = Date.now();
    currentRequestRef.current = requestId;

    try {
      let params = {
        ordering,
        search,
        offset: pageIndex * pageLimit,
        limit: pageLimit,
      };
      if (tagIds.length > 0) {
        params.tags = tagIds.join(',');
      }
      params = Object.assign(params, filtering);
      const response1 = await apiAgent.get({
        path: `/properties/`,
        params,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const responseResult1 = await response1.json();
      if (currentRequestRef.current !== requestId) {
        return;
      }
      setTotal(responseResult1.count);
      setCheckboxList(Array(responseResult1.results.length).fill(false));
      setProperties(responseResult1.results);
      const response2 = await apiAgent.get({
        path: `/users_properties/`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const responseResult2 = await response2.json();
      if (currentRequestRef.current !== requestId) {
        return;
      }
      setUserProperty(responseResult2);
      const response3 = await apiAgent.get({
        path: `/users/`,
        params: { is_active: true },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const responseResult3 = await response3.json();
      if (currentRequestRef.current !== requestId) {
        return;
      }
      setUsers(responseResult3);
      setLoadingFlag(false);
    } catch (error) {
      if (currentRequestRef.current === requestId) {
        setLoadingFlag(false);
      }
    }
  };

  useEffect(() => {}, [filteringTags]);

  useEffect(() => {
    window.document.title = 'Properties | Flair';
    loadTagsData();
  }, []);

  useEffect(() => {
    loadProperties(
      pageIndex,
      pageLimit,
      ordering,
      filtering,
      search,
      filteringTagIds,
    );
  }, [pageIndex, pageLimit, ordering, filtering, search, filteringTagIds]);

  const [modal, setModal] = useState(null);
  const [assignees, setAssignees] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(0);
  const [selectedAssignee, setSelectedAssignee] = useState(0);
  const [assignTo, setAssignTo] = useState('');
  const [selectedAssignees, setSelectedAssignees] = useState([]);

  const [checkboxAllStateInvite, setCheckboxAllStateInvite] = useState(false);
  const [checkboxListInvite, setCheckboxListInvite] = useState([]);
  // handle check
  useEffect(() => {
    const checkCount = checkboxListInvite.reduce(
      (count, item) => (count += item ? 1 : 0),
      0,
    );
    setCheckboxAllStateInvite(
      checkCount === checkboxListInvite.length &&
        checkboxListInvite.length !== 0
        ? true
        : checkCount === 0
        ? false
        : 'indeterminate',
    );
  }, [checkboxListInvite]);

  const [orderingInvite, setOrderingInvite] = useState('-created_at');
  const [propertiesInvite, setPropertiesInvite] = useState([]);
  const [loadingFlagInvite, setLoadingFlagInvite] = useState(false);
  const [searchInvite, setSearchInvite] = useState('');
  const [pageIndexInvite, setPageIndexInvite] = useState(0);
  const [pageLimitInvite, setPageLimitInvite] = useState(10);
  const [totalInvite, setTotalInvite] = useState(0);

  useEffect(() => {
    setLoadingFlagInvite(true);
  }, [pageIndexInvite, pageLimitInvite, orderingInvite, filtering]);

  useEffect(() => {
    if (!loadingFlagInvite) return;
    (async () => {
      try {
        let params = {
          ordering: orderingInvite,
          search: searchInvite,
          offset: pageIndexInvite * pageLimitInvite,
          limit: pageLimitInvite,
        };
        params = Object.assign(params, filtering);
        const response1 = await apiAgent.get({
          path: `/properties/`,
          params,
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        const responseResult1 = await response1.json();
        setTotalInvite(responseResult1.count);
        setCheckboxListInvite(
          Array(responseResult1.results.length).fill(false),
        );
        setPropertiesInvite(responseResult1.results);
        setLoadingFlagInvite(false);
      } catch (error) {
        setLoadingFlagInvite(false);
      }
    })();
  }, [loadingFlagInvite]);

  const getAssignees = (property) => {
    const user_ids = userProperty
      .filter((item) => item.property == property.id)
      .map((item) => item.user);
    return users.filter((item) => user_ids.includes(item.id));
  };

  const handleDeleteAssignee = async () => {
    if (userInfo.permission_role === 'reader') {
      setModal('PermissionRole');
    } else {
      try {
        const singleDeleteId = userProperty.filter(
          (item) =>
            item.user == selectedAssignee.id &&
            item.property == selectedProperty.id,
        )[0].id;
        const response = await apiAgent.del({
          path: `/users_properties/${singleDeleteId}/`,
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        loadProperties(
          pageIndex,
          pageLimit,
          ordering,
          filtering,
          search,
          filteringTags,
        );
      } catch (error) {}
    }
  };

  const handleMultiDeleteAssignee = async () => {
    let id_group = userProperty
      .filter((item) => item.property == selectedProperty.id)
      .map((item) => item.id);
    if (userInfo.permission_role === 'reader') {
      setModal('PermissionRole');
    } else {
      try {
        const respones = await apiAgent.del({
          path: `users_properties/batch_delete`,
          data: {
            id_group,
          },
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        loadProperties(
          pageIndex,
          pageLimit,
          ordering,
          filtering,
          search,
          filteringTags,
        );
      } catch (error) {}
    }
  };

  const handleMultiInviteManager = async () => {
    if (email.length == 0) return;
    let id_group = propertiesInvite
      .filter((item, index) => checkboxListInvite[index])
      .map((item) => item.id);
    if (userInfo.permission_role === 'reader') {
      setModal('PermissionRole');
    } else {
      try {
        const response = await apiAgent.post({
          path: `invitations/batch_add_invitation`,
          data: {
            id_group,
            email,
          },
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        setLoadingFlag(true);
        setInviteModal(true);
      } catch (error) {}
    }
  };

  const handleAssignTo = async () => {
    if (userInfo.permission_role === 'reader') {
      setModal('PermissionRole');
    } else {
      try {
        let formData = {
          user: selectedAssignee.id,
          property: selectedProperty.id,
          access_to_property: true,
        };
        setLoadingFlag(true);
        const response = await apiAgent.post({
          path: `/users_properties/`,
          data: formData,
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        await loadProperties(
          pageIndex,
          pageLimit,
          ordering,
          filtering,
          search,
          filteringTags,
        );
        setLoadingFlag(false);
      } catch (error) {}
    }
  };

  return (
    <>
      <div className="pt-4 xs:pt-10 mb-6 flex justify-between">
        <h5 className="">Properties</h5>
        {/* {['admin', 'agency'].includes(userInfo.permission_role) && (
          <Button
            variant="solid"
            radius="sm"
            onClick={() => {
              setCheckboxListInvite(Array(propertiesInvite.length).fill(false));
              setEmail('');
              setShow(true);
            }}
            className="w-[176px] h-[52px] bg-default-900 text-white text-base leading-6 font-medium"
            startContent={
              <img src="/assets/images/add-square.png" width={24} alt="" />
            }
          >
            Invite Manager
          </Button>
        )} */}
      </div>
      {
        <div className="bg-white grid grid-cols-1 rounded-xl shadow-md">
          {/* header */}
          <div className="px-8 pt-8 pb-6 flex items-center justify-between gap-4">
            {checkboxAllState ? (
              <>
                {/* <button
                  onClick={() => {
                    setModalAction('multi');
                    setSelectedProperties(() =>
                      properties.filter((item, index) => checkboxList[index]),
                    );
                  }}
                  className="w-[119px] h-[42px] py-6 px-8 text-[#687BFE] bg-[#F5FAFF] rounded-[32px] leading-5 flex items-center"
                >
                  <div className="w-[15px] inline-block mr-[10px]">
                    {svgIcons.edit}
                  </div>
                  Edit
                </button> */}
                <p className="whitespace-nowrap">
                  {checkboxList.reduce(
                    (count, item) => (item ? count + 1 : count),
                    0,
                  )}{' '}
                  of {checkboxList.length} items are selected
                </p>
              </>
            ) : (
              <>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setPageIndex(0);
                    loadProperties(
                      pageIndex,
                      pageLimit,
                      ordering,
                      filtering,
                      search,
                      filteringTags,
                    );
                  }}
                  className="w-full xs:w-[400px] relative"
                >
                  <Input
                    classNames={{
                      base: 'max-w-full h-[52px]',
                      mainWrapper: 'h-full',
                      input: 'text-small',
                      inputWrapper:
                        'h-full font-normal text-default-500 bg-white',
                    }}
                    variant="bordered"
                    value={search}
                    onChange={(e) => {
                      setSearch(e.target.value);
                    }}
                    placeholder="Search your properties here"
                    startContent={<div className="w-4">{svgIcons.search}</div>}
                    type="search"
                  />
                </form>
                {showTagging && (
                  <div className=" max-w-[411px]">
                    <TagsContainer
                      id="properties-filter-tags"
                      placeholder={'Filter by tags'}
                      data={filteringTags}
                      onRemoveItem={(e, tag) => {
                        setFilteringTagIds(
                          filteringTagIds.filter((id) => id !== tag.id),
                        );
                      }}
                      isOpen={!focusPropertyId && tagsPopupState != 'NotOpen'}
                      onClickDropdown={(e) => {
                        if (
                          !(!focusPropertyId && tagsPopupState != 'NotOpen')
                        ) {
                          // close other
                          resetTagsPopup();
                          // open dropdown
                          setAnchorEl(
                            document.getElementById('properties-filter-tags'),
                          );
                          setTagsPopupState('SelTags');
                        } else {
                          resetTagsPopup();
                        }
                      }}
                    />
                  </div>
                )}
                {showTagging &&
                  !focusPropertyId &&
                  tagsPopupState == 'SelTags' && (
                    <TagsSelect
                      anchorEl={anchorEl}
                      data={companyTagsChoose}
                      onClose={(e) => {
                        if (anchorEl.contains(e.target)) {
                          return;
                        }
                        resetTagsPopup();
                      }}
                      onClickEditList={(e) => {
                        setTagsPopupState('EditList');
                      }}
                      onSelectItem={(e, tag) => {
                        setFilteringTagIds([...filteringTagIds, tag.id]);
                      }}
                    />
                  )}
                {showTagging &&
                  !focusPropertyId &&
                  tagsPopupState == 'EditList' && (
                    <TagsListEdit
                      anchorEl={anchorEl}
                      onClose={handleCloseTagsEditList}
                      onUpdateTag={async () => {
                        await loadTagsData();
                      }}
                    />
                  )}
              </>
            )}
          </div>
          {/* body */}
          {loadingFlag ? (
            <>
              <div className="w-full overflow-x-hidden">
                <table className="border-collapse w-full min-w-fit relative">
                  <thead>
                    <tr className="bg-[#F2F5F966] border-t border-b border-[#E9ECF1]">
                      <th className="w-10 xs:w-16 px-3 xs:px-4 xs:pl-8 leading-3">
                        <Skeleton
                          width={16}
                          height={16}
                          baseColor="rgba(202, 220, 237, 0.7)"
                          highlightColor="rgba(219, 230, 242, 1)"
                        />
                      </th>
                      {[64, 88, 73].map((item, index) => (
                        <th key={index} className="last:xs:pr-4">
                          <div className="text-xs font-medium block px-3 xs:px-4 py-3 w-full text-left group">
                            <Skeleton
                              width={item}
                              baseColor="rgba(202, 220, 237, 0.7)"
                              highlightColor="rgba(219, 230, 242, 1)"
                            />
                          </div>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {[...Array(10).keys()].map((row, i) => (
                      <tr
                        key={i}
                        className="even:bg-[#F2F5F966] border-t border-b border-[#E9ECF1]"
                      >
                        <td className="w-10 xs:w-16 px-3 xs:px-4 xs:pl-8 text-[14px] leading-3">
                          <Skeleton
                            width={16}
                            height={16}
                            baseColor="rgba(202, 220, 237, 0.7)"
                            highlightColor="rgba(219, 230, 242, 1)"
                          />
                        </td>
                        <td className="w-[349px]">
                          <div className="px-3 xs:px-4 text-[14px] font-normal flex items-center gap-3">
                            <Skeleton
                              width={30}
                              height={30}
                              baseColor="rgba(202, 220, 237, 0.7)"
                              highlightColor="rgba(219, 230, 242, 1)"
                            />
                            <Skeleton
                              width={Math.random() * 100 + 50}
                              baseColor="rgba(202, 220, 237, 0.7)"
                              highlightColor="rgba(219, 230, 242, 1)"
                            />
                          </div>
                        </td>
                        <td className="px-3 xs:px-4 py-4 text-[14px] font-normal">
                          <Skeleton
                            width={102}
                            height={28}
                            baseColor="rgba(202, 220, 237, 0.7)"
                            highlightColor="rgba(219, 230, 242, 1)"
                          />
                        </td>
                        <td className="px-3 xs:px-4 py-4 text-[14px] font-normal">
                          <Skeleton
                            width={50}
                            baseColor="rgba(202, 220, 237, 0.7)"
                            highlightColor="rgba(219, 230, 242, 1)"
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mr-5 xs:mx-8 flex flex-col lg:flex-row justify-center mt-4 lg:mt-0 md:justify-between items-center">
                <p>
                  <Skeleton
                    width={253}
                    baseColor="rgba(202, 220, 237, 0.7)"
                    highlightColor="rgba(219, 230, 242, 1)"
                  />
                </p>
                <div className="pt-8 pb-12 flex flex-col gap-4 md:flex-row md:flex-wrap md:justify-end items-center">
                  <div className="text-[14px] leading-[1.3] font-normal">
                    <Skeleton
                      width={200}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <div className="flex items-center">
                    <div className="md:pl-4 pr-4 md:border-l border-l-[#E9ECF1] font-medium text-[14px] leading-[18.2px]">
                      <Skeleton
                        width={71}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </div>
                    <Skeleton
                      width={40}
                      height={38}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                    <div className="px-4 border-r mr-4">
                      <Skeleton
                        width={37}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </div>
                    <Skeleton
                      width={59}
                      height={38}
                      borderRadius={4}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="w-full xs:overflow-x-auto">
                <table className="border-collapse whitespace-nowrap w-full xs:min-w-fit relative">
                  <thead>
                    <tr className="bg-[#F2F5F966] border-t border-b border-[#E9ECF1]">
                      <th className="w-10 xs:w-16 px-3 xs:px-4 xs:pl-8 leading-3 hidden xs:table-cell">
                        <CheckBox
                          state={checkboxAllState}
                          onChange={(e) => {
                            setCheckboxList((prev) =>
                              prev.map((item, index) => e.target.checked),
                            );
                          }}
                        />
                      </th>
                      {(['admin', 'agency'].includes(userInfo.permission_role)
                        ? ['property', 'google id', 'tags', 'assignees']
                        : ['property', 'google id']
                      ).map((item, index) => (
                        <th
                          key={index}
                          className={`last:xs:pr-4 ${
                            ![0, 2].includes(index) && 'hidden'
                          } xs:table-cell`}
                        >
                          <button
                            onClick={() =>
                              ['property', 'google id'].includes(item) &&
                              setOrdering((prev) =>
                                prev.includes(orderParams[index])
                                  ? orderParams[index] === prev
                                    ? `-${orderParams[index]}`
                                    : orderParams[index]
                                  : orderParams[index],
                              )
                            }
                            className="uppercase text-[#707787] text-xs font-medium block px-3 xs:px-4 py-3 w-full text-left group"
                          >
                            {item}
                            <div
                              className={
                                ordering === `-${orderParams[index]}`
                                  ? 'ml-2 inline-block w-1.5 align-middle pb-[3px] text-[#687BFE]'
                                  : 'ml-2 inline-block w-1.5 align-middle pb-[3px] text-[#CED3DB] invisible group-hover:visible'
                              }
                            >
                              {svgIcons.up_vector}
                            </div>
                            <div
                              className={
                                ordering === `${orderParams[index]}`
                                  ? 'inline-block w-1.5 align-middle pb-[3px] text-[#687BFE]'
                                  : 'inline-block w-1.5 align-middle pb-[3px] text-[#CED3DB] invisible group-hover:visible'
                              }
                            >
                              {svgIcons.down_vector}
                            </div>
                          </button>
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {properties.map((item, index) => (
                      <tr
                        className={`${
                          tagsPopupState == 'NotOpen'
                            ? 'even:bg-[#F2F5F966] hover:bg-[#687BFE14]'
                            : focusPropertyId == item.id
                            ? 'bg-[#687BFE14]'
                            : 'even:bg-[#F2F5F966]'
                        }  border-t border-b border-[#E9ECF1]`}
                        key={index}
                      >
                        <td className="w-10 xs:w-16 px-3 xs:px-4 xs:pl-8 text-[14px] leading-3 hidden xs:table-cell">
                          <CheckBox
                            state={checkboxList[index]}
                            onChange={(e) =>
                              setCheckboxList((prev) =>
                                prev.map((item, id) =>
                                  id === index ? e.target.checked : item,
                                ),
                              )
                            }
                          />
                        </td>
                        <td
                          className={`w-[349px] border-l-4 xs:border-0 ${
                            [
                              ProjectStatus.ACTIVE,
                              ProjectStatus.PAUSING,
                            ].includes(item._ads_status)
                              ? 'border-[#38D086]'
                              : [
                                  ProjectStatus.RESUMING,
                                  ProjectStatus.PAUSED,
                                ].includes(item._ads_status)
                              ? 'border-[#F6D214]'
                              : 'border-[#FF7F5F]'
                          }`}
                        >
                          <Link
                            to={`/properties/${item.id}/specials`}
                            className="cursor-default px-3 xs:px-4 text-[14px] font-normal flex items-center gap-3 truncate ..."
                          >
                            <img
                              src={
                                item.highlight_photo
                                  ? item.highlight_photo
                                  : './assets/images/tile-noimg.svg'
                              }
                              onError={({ currentTarget }) => {
                                currentTarget.onerror = null; // prevents looping
                                currentTarget.src =
                                  './assets/images/tile-noimg.svg';
                              }}
                              className="w-[30px] h-[30px] object-cover"
                              alt="alter"
                            />
                            <LinesEllipsis
                              text={item.name}
                              component="span"
                              maxLine="1"
                              ellipsis="…"
                              trimRight
                            />
                          </Link>
                        </td>
                        <td className="px-3 xs:px-4 py-4 text-[14px] font-normal hidden xs:table-cell">
                          {item._gad_customer_id ? (
                            styleGoogleCustomerId(item._gad_customer_id)
                          ) : (
                            <>-</>
                          )}
                        </td>
                        {showTagging && (
                          <td
                            className="px-3 xs:px-4 py-4 text-[14px] font-normal hidden xs:table-cell lg:flex"
                            onMouseEnter={() => {
                              if (tagsPopupState == 'NotOpen') {
                                setFocusPropertyId(item.id);
                              }
                            }}
                            onMouseLeave={() => {
                              if (tagsPopupState == 'NotOpen') {
                                setFocusPropertyId(null);
                              }
                            }}
                          >
                            <TagsSimpleContainer
                              key={item.id}
                              id={item.id}
                              placeholder={'No tags assigned'}
                              data={propertyCompanyTags.filter(
                                (ptag) => ptag.property.id == item.id,
                              )}
                              showEdit={focusPropertyId == item.id}
                              onClickEdit={(id, event, element) => {
                                event.preventDefault();
                                if (tagsPopupState != 'NotOpen') {
                                  setTagsPopupState('NotOpen');
                                  setAnchorEl(null);
                                } else {
                                  setTagsPopupState('SelTags');
                                  setAnchorEl(element);
                                }
                              }}
                              onRemoveItem={
                                focusPropertyId == item.id &&
                                tagsPopupState == 'SelTags'
                                  ? handleRemovePropertyTag
                                  : null
                              }
                            />
                            <span className="flex-grow"></span>
                            {focusPropertyId == item.id &&
                              tagsPopupState == 'SelTags' && (
                                <TagsSelect
                                  anchorEl={anchorEl}
                                  data={getTagsSelect(
                                    companyTags,
                                    propertyCompanyTags,
                                    item.id,
                                  )}
                                  onClose={(e) => {
                                    if (anchorEl.contains(e.target)) {
                                      return;
                                    }
                                    setTagsPopupState('NotOpen');
                                    setAnchorEl(null);
                                  }}
                                  onClickEditList={(e) => {
                                    setTagsPopupState('EditList');
                                  }}
                                  onSelectItem={async (e, tag) => {
                                    await addPropertyCompanyTag(
                                      item.id,
                                      tag.id,
                                    );
                                    await loadTagsData();
                                  }}
                                />
                              )}
                            {focusPropertyId == item.id &&
                              tagsPopupState == 'EditList' && (
                                <TagsListEdit
                                  anchorEl={anchorEl}
                                  onClose={handleCloseTagsEditList}
                                  onUpdateTag={async () => {
                                    await loadTagsData();
                                  }}
                                />
                              )}
                          </td>
                        )}
                        <td
                          className={`px-3 xs:px-4 py-4 text-[14px] font-normal hidden ${
                            ['admin', 'agency'].includes(
                              userInfo.permission_role,
                            )
                              ? 'xs:table-cell'
                              : ''
                          }`}
                        >
                          <div className="flex pl-2 items-center">
                            <div className="flex items-center">
                              {getAssignees(item)
                                .slice(0, 3)
                                .map((assignee, index_color) => (
                                  <div
                                    className={`flex w-7 h-7 rounded-full items-center cursor-pointer border-[2px] border-white mr-[-8px] ${bgColors[index_color]}`}
                                  >
                                    <p
                                      className={`w-full text-center text-xs leading-4 font-semibold ${textColors[index_color]}`}
                                    >
                                      {assignee.name.length > 0
                                        ? assignee.name[0].toUpperCase()
                                        : assignee.username[0].toUpperCase()}
                                    </p>
                                  </div>
                                ))}
                              {getAssignees(item).length == 0 && (
                                <Dropdown
                                  placement="bottom-start"
                                  className="rounded-md w-[224px] p-0"
                                >
                                  <DropdownTrigger
                                    onClick={() => {
                                      setSelectedProperty(item);
                                      setSelectedAssignees(users);
                                      setAssignTo('');
                                    }}
                                  >
                                    <img
                                      src="/assets/images/no-assignee.png"
                                      className="cursor-pointer"
                                      width={26}
                                    />
                                  </DropdownTrigger>
                                  <DropdownMenu
                                    aria-label="Single selection example"
                                    variant="light"
                                    disallowEmptySelection
                                    selectionMode="single"
                                    selectedKeys={['no-assignee']}
                                    className="pb-2 pt-0"
                                  >
                                    <DropdownItem
                                      isReadOnly
                                      hideSelectedIcon
                                      className="p-0 m-0"
                                    >
                                      <Input
                                        type="email"
                                        radius="none"
                                        value={assignTo}
                                        onChange={(e) => {
                                          setSelectedAssignees(
                                            users.filter((item) =>
                                              e.target.value.length == 0 ||
                                              item.name.length > 0
                                                ? item.name
                                                    .toLowerCase()
                                                    .includes(
                                                      e.target.value.toLowerCase(),
                                                    )
                                                : item.username
                                                    .toLowerCase()
                                                    .includes(
                                                      e.target.value.toLowerCase(),
                                                    ),
                                            ),
                                          );
                                          setAssignTo(e.target.value);
                                        }}
                                        variant="underlined"
                                        placeholder="Assign to..."
                                      />
                                    </DropdownItem>
                                    {assignTo.length == 0 && (
                                      <DropdownItem key="no-assignee">
                                        <div className="flex gap-2 items-center">
                                          <img
                                            src="/assets/images/no-assignee.png"
                                            width={24}
                                          />
                                          <div>No assignee</div>
                                        </div>
                                      </DropdownItem>
                                    )}
                                    {selectedAssignees
                                      .slice(0, 2)
                                      .map((assignee, index_color) => (
                                        <DropdownItem
                                          key={assignee.email}
                                          onClick={() => {
                                            setSelectedAssignee(assignee);
                                            setAssigneeModal('assign');
                                          }}
                                        >
                                          <div className="flex gap-2 items-center">
                                            <Avatar
                                              showFallback
                                              className={`w-6 h-6 ${bgColors[index_color]} ${textColors[index_color]}`}
                                              name={
                                                assignee.name.length > 0
                                                  ? assignee.name[0].toUpperCase()
                                                  : assignee.username[0].toUpperCase()
                                              }
                                            />
                                            <div>
                                              {assignee.name.length > 0
                                                ? assignee.name
                                                : assignee.username}
                                            </div>
                                          </div>
                                        </DropdownItem>
                                      ))}
                                  </DropdownMenu>
                                </Dropdown>
                              )}
                            </div>
                            {getAssignees(item).length > 0 && (
                              <div className="opacity-0 hover:opacity-100 cursor-pointer ml-3">
                                <img
                                  src="/assets/images/edit-assign.png"
                                  width={16}
                                  alt=""
                                  onClick={() => {
                                    setSelectedProperty(item);
                                    setAssignees(getAssignees(item));
                                    setAssigneeModal('edit');
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <div className="mr-5 xs:mx-8 flex flex-col lg:flex-row justify-center mt-4 lg:mt-0 md:justify-between items-center">
                <p className="whitespace-nowrap text-[#707787]">
                  Displaying {checkboxList.length} properties out of {total}{' '}
                  total
                </p>
                <Paginater
                  total={total}
                  pageIndex={pageIndex}
                  pageLimit={pageLimit}
                  onChangePageIndex={setPageIndex}
                  onChangePageLimit={setPageLimit}
                />
              </div>
            </>
          )}
        </div>
      }
      <RightPanelModal
        show={show}
        onHide={() => setShow(false)}
        onClose={() => setShow(false)}
        title="Invite a Manager"
        handleConfirm={() => {
          handleMultiInviteManager();
          setShow(false);
        }}
        confirmTxt={'Invite & Assign'}
        content={
          <>
            <div className="font-saans-trial">
              <div className="mt-8 leading-7">
                <div className="text-base leading-5 mt-5">
                  Enter email addresses of the person you’d like to invite below
                </div>
                <Input
                  type="email"
                  value={email}
                  placeholder="Email"
                  onChange={(e) => {
                    setEmail(e.target.value);
                  }}
                  variant="bordered"
                  classNames={{
                    base: 'w-full mt-4 mb-6',
                    inputWrapper: 'h-[52px] text-base leading-6 font-normal',
                  }}
                />
              </div>
              <div className="items-center border-t-1 pt-2">
                <div className="text-base leading-5 mb-2">
                  Select the properties you’d like them to manage
                </div>
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    setPageIndexInvite(0);
                    setLoadingFlagInvite(true);
                  }}
                  className="w-full relative"
                >
                  <Input
                    classNames={{
                      base: 'max-w-full h-[52px]',
                      mainWrapper: 'h-full',
                      input: 'text-small pl-9',
                      inputWrapper:
                        'h-full font-normal text-default-500 bg-white',
                    }}
                    variant="bordered"
                    value={searchInvite}
                    onChange={(e) => dispatch(setSearchInvite(e.target.value))}
                    placeholder="Search your properties here"
                    startContent={<div className="w-4">{svgIcons.search}</div>}
                    type="search"
                  />
                </form>
              </div>
              {loadingFlagInvite ? (
                <div className="skeleton-background grid grid-cols-1 mt-4 mb-4 border rounded-lg">
                  <div className="w-full overflow-x-hidden">
                    <table className="border-collapse w-full min-w-fit relative">
                      <thead>
                        <tr className="bg-[#F2F5F966] border-t border-b border-[#E9ECF1]">
                          <th className="w-10 px-6 leading-3">
                            <Skeleton
                              width={16}
                              height={16}
                              baseColor="rgba(202, 220, 237, 0.7)"
                              highlightColor="rgba(219, 230, 242, 1)"
                            />
                          </th>
                          <th className="last:xs:pr-4">
                            <div className="text-xs font-medium px-3 xs:px-4 py-3 w-full text-left">
                              <Skeleton
                                width={64}
                                baseColor="rgba(202, 220, 237, 0.7)"
                                highlightColor="rgba(219, 230, 242, 1)"
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {[...Array(5).keys()].map((row, i) => (
                          <tr
                            key={i}
                            className="even:bg-[#F2F5F966] border-t border-b border-[#E9ECF1]"
                          >
                            <td className="w-16 px-6 text-[14px] leading-3">
                              <Skeleton
                                width={16}
                                height={16}
                                baseColor="rgba(202, 220, 237, 0.7)"
                                highlightColor="rgba(219, 230, 242, 1)"
                              />
                            </td>
                            <td className="w-[349px] flex gap-2 p-2 items-center">
                              <Skeleton
                                width={30}
                                height={30}
                                baseColor="rgba(202, 220, 237, 0.7)"
                                highlightColor="rgba(219, 230, 242, 1)"
                              />
                              <Skeleton
                                width={Math.random() * 100 + 50}
                                baseColor="rgba(202, 220, 237, 0.7)"
                                highlightColor="rgba(219, 230, 242, 1)"
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-2">
                    <p>
                      <Skeleton
                        width={253}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                      />
                    </p>
                    <div className="pt-8 pb-12 flex flex-col gap-4 md:flex-row md:flex-wrap md:justify-center items-center">
                      <div className="text-[14px] leading-[1.3] font-normal">
                        <Skeleton
                          width={200}
                          baseColor="rgba(202, 220, 237, 0.7)"
                          highlightColor="rgba(219, 230, 242, 1)"
                        />
                      </div>
                      <div className="flex items-center">
                        <div className="md:pl-4 pr-4 md:border-l border-l-[#E9ECF1] font-medium text-[14px] leading-[18.2px]">
                          <Skeleton
                            width={71}
                            baseColor="rgba(202, 220, 237, 0.7)"
                            highlightColor="rgba(219, 230, 242, 1)"
                          />
                        </div>
                        <Skeleton
                          width={40}
                          height={38}
                          baseColor="rgba(202, 220, 237, 0.7)"
                          highlightColor="rgba(219, 230, 242, 1)"
                        />
                        <div className="px-4 border-r mr-4">
                          <Skeleton
                            width={37}
                            baseColor="rgba(202, 220, 237, 0.7)"
                            highlightColor="rgba(219, 230, 242, 1)"
                          />
                        </div>
                        <Skeleton
                          width={59}
                          height={38}
                          borderRadius={4}
                          baseColor="rgba(202, 220, 237, 0.7)"
                          highlightColor="rgba(219, 230, 242, 1)"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="shadow-[0_0_20.303px_37.7056px_rgba(183,188,200,0.11)] grid grid-cols-1 mt-4 mb-4 border rounded-lg">
                  <div className="w-full">
                    <table className="border-collapse whitespace-nowrap w-full xs:min-w-fit relative">
                      <thead>
                        <tr className="bg-[#F2F5F966] border-t border-b border-[#E9ECF1]">
                          <th className="w-10 px-6 leading-3">
                            <CheckBox
                              state={checkboxAllStateInvite}
                              onChange={(e) => {
                                setCheckboxListInvite((prev) =>
                                  prev.map((item, index) => e.target.checked),
                                );
                              }}
                            />
                          </th>
                          {['property'].map((item, index) => (
                            <th key={index} className="last:xs:pr-4">
                              <button
                                onClick={() =>
                                  setOrderingInvite((prev) =>
                                    prev.includes(orderParams[index])
                                      ? orderParams[index] === prev
                                        ? `-${orderParams[index]}`
                                        : orderParams[index]
                                      : orderParams[index],
                                  )
                                }
                                className="uppercase text-[#707787] text-xs font-medium block px-3 xs:px-4 py-3 w-full text-left group"
                              >
                                {item}
                                <div
                                  className={
                                    orderingInvite === `-${orderParams[index]}`
                                      ? 'ml-2 inline-block w-1.5 align-middle pb-[3px] text-[#687BFE]'
                                      : 'ml-2 inline-block w-1.5 align-middle pb-[3px] text-[#CED3DB] invisible group-hover:visible'
                                  }
                                >
                                  {svgIcons.up_vector}
                                </div>
                                <div
                                  className={
                                    orderingInvite === `${orderParams[index]}`
                                      ? 'inline-block w-1.5 align-middle pb-[3px] text-[#687BFE]'
                                      : 'inline-block w-1.5 align-middle pb-[3px] text-[#CED3DB] invisible group-hover:visible'
                                  }
                                >
                                  {svgIcons.down_vector}
                                </div>
                              </button>
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {propertiesInvite.map((item, index) => (
                          <tr
                            className="even:bg-[#F2F5F966] hover:bg-[#687BFE14] border-t border-b border-[#E9ECF1]"
                            key={index}
                          >
                            <td className="w-16 px-6 text-[14px] leading-3">
                              <CheckBox
                                state={checkboxListInvite[index]}
                                onChange={(e) =>
                                  setCheckboxListInvite((prev) =>
                                    prev.map((item, id) =>
                                      id === index ? e.target.checked : item,
                                    ),
                                  )
                                }
                              />
                            </td>
                            <td className="w-[349px] flex gap-2 p-2 items-center">
                              <img
                                src={
                                  item.highlight_photo
                                    ? item.highlight_photo
                                    : './assets/images/tile-noimg.svg'
                                }
                                onError={({ currentTarget }) => {
                                  currentTarget.onerror = null; // prevents looping
                                  currentTarget.src =
                                    './assets/images/tile-noimg.svg';
                                }}
                                className="w-[30px] h-[30px] object-cover"
                                alt="alter"
                              />
                              <LinesEllipsis
                                text={item.name}
                                component="span"
                                maxLine="1"
                                ellipsis="…"
                                trimRight
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                  <div className="flex flex-col justify-center items-center mt-2">
                    <p className="whitespace-nowrap text-[#707787]">
                      Displaying {checkboxListInvite.length} properties out of{' '}
                      {totalInvite} total
                    </p>
                    <Paginater
                      total={totalInvite}
                      pageIndex={pageIndexInvite}
                      pageLimit={pageLimitInvite}
                      onChangePageIndex={setPageIndexInvite}
                      onChangePageLimit={setPageLimitInvite}
                    />
                  </div>
                </div>
              )}
            </div>
          </>
        }
      />
      <AllSetModal
        show={inviteModal}
        onHide={() => setInviteModal(false)}
        onClose={() => setInviteModal(false)}
        contentClassName="px-10 py-12 rounded-2xl"
        content={
          <div className="font-saans-trial">
            <img src="/assets/images/confirm.png" width={80} alt="" />
            <div className="text-3xl leading-9 font-semibold mt-6">
              All Set!
            </div>
            <div className="text-lg leading-7 mt-4">
              <span className="font-semibold">{email}</span> was successfully
              invited. You can track their invitation status in{' '}
              <Link to="/settings">
                <span className="font-semibold underline underline-offset-1">
                  Settings
                </span>
              </Link>
            </div>
            <div className="grid grid-cols-2 gap-2 mt-8">
              <Button
                variant="bordered"
                radius="sm"
                onClick={() => setInviteModal(false)}
                className="h-[52px] text-base leading-6 font-medium"
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                radius="sm"
                onClick={() => setInviteModal(false)}
                className="h-[52px] bg-[#242424] text-white text-base leading-6 font-medium"
              >
                Back to Properties
              </Button>
            </div>
          </div>
        }
      />
      <EditAssigneeModal
        show={assigneeModal == 'edit'}
        onHide={() => setAssigneeModal(false)}
        onClose={() => setAssigneeModal(false)}
        contentClassName="p-10 rounded-2xl"
        content={
          <div className="font-saans-trial">
            <div className="text-xl leading-7 font-semibold">
              Edit assignees
            </div>
            <div className="text-lg leading-7 mt-2">
              Easily review and manage assignees here. Delete any or all with a
              few clicks.
            </div>
            <div className="shadow-[0_0_20.303px_37.7056px_rgba(183,188,200,0.11)] grid grid-cols-1 mt-4 mb-4 py-1 border rounded-lg">
              <table className="border-collapse whitespace-nowrap w-full xs:min-w-fit relative">
                <tbody>
                  {assignees.map((assignee, index) => (
                    <tr
                      className="odd:bg-default-50 hover:bg-[#687BFE14]"
                      key={index}
                    >
                      <td className="p-4">
                        <div className="flex justify-between">
                          <div className="flex items-center">
                            <Avatar
                              showFallback
                              className={`w-7 h-7 ${bgColors[index % 3]} ${
                                textColors[index % 3]
                              } cursor-pointer`}
                              name={
                                assignee.name.length > 0
                                  ? assignee.name[0].toUpperCase()
                                  : assignee.username[0].toUpperCase()
                              }
                            />
                            <div className="ml-4">{assignee.name}</div>
                          </div>
                          <Button
                            className="text-[#FF8162]"
                            variant="light"
                            onClick={() => {
                              setSelectedAssignee(assignee);
                              setAssigneeModal('delete');
                            }}
                          >
                            Delete
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="grid grid-cols-2 gap-2 mt-8">
              <Button
                variant="bordered"
                radius="sm"
                onClick={() => setAssigneeModal(false)}
                className="h-[52px] text-base leading-6 font-medium"
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                radius="sm"
                onClick={() => {
                  handleMultiDeleteAssignee();
                  setAssigneeModal(false);
                }}
                className="h-[52px] bg-[#242424] text-white text-base leading-6 font-medium"
              >
                Delete all
              </Button>
            </div>
          </div>
        }
      />
      <EditAssigneeModal
        show={assigneeModal == 'delete'}
        onHide={() => setAssigneeModal(false)}
        onClose={() => setAssigneeModal(false)}
        contentClassName="p-10 rounded-2xl"
        content={
          <div className="font-saans-trial">
            <div className="text-xl leading-7 font-semibold">
              Are you sure you want to remove {selectedAssignee.name}?
            </div>
            <div className="text-lg leading-7 mt-2">
              They won’t be able to access this property anymore.
            </div>
            <div className="flex justify-end gap-2 mt-8">
              <Button
                variant="bordered"
                radius="sm"
                onClick={() => setAssigneeModal('edit')}
                className="w-[124px] h-[52px] text-base leading-6 font-medium"
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                radius="sm"
                onClick={() => {
                  handleDeleteAssignee();
                  setAssigneeModal(false);
                }}
                className="w-[124px] h-[52px] bg-[#242424] text-white text-base leading-6 font-medium"
              >
                Confirm
              </Button>
            </div>
          </div>
        }
      />
      <EditAssigneeModal
        show={assigneeModal == 'assign'}
        onHide={() => setAssigneeModal(false)}
        onClose={() => setAssigneeModal(false)}
        contentClassName="p-10 rounded-2xl"
        content={
          <div className="font-saans-trial">
            <div className="text-xl leading-7 font-semibold">
              Are you sure you want to invite {selectedAssignee.email}?
            </div>
            <div className="text-lg leading-7 mt-2">
              By inviting this person, they will gain access to the specified
              property.
            </div>
            <div className="flex justify-end gap-2 mt-8">
              <Button
                variant="bordered"
                radius="sm"
                onClick={() => setAssigneeModal(false)}
                className="w-[124px] h-[52px] text-base leading-6 font-medium"
              >
                Cancel
              </Button>
              <Button
                variant="solid"
                radius="sm"
                onClick={() => {
                  handleAssignTo();
                  setAssigneeModal(false);
                }}
                className="w-[124px] h-[52px] bg-[#242424] text-white text-base leading-6 font-medium"
              >
                Confirm
              </Button>
            </div>
          </div>
        }
      />
      <ConfirmModal
        show={modal === 'PermissionRole'}
        onHide={() => setModal(null)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Wanning"
        txt="This action isn't available to View-only users. Reach out to your admin to request new permissions."
        handleConfirm={() => {
          setModal(null);
        }}
      />
      {/* <PropertyStateEditModal
        properties={selectedProperties}
        action={modalAction}
        updateTable={() => setLoadingFlag(true)}
        onClose={() => {
          setSelectedProperties([]);
        }}
      /> */}
    </>
  );
}
