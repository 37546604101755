import { createSlice } from '@reduxjs/toolkit';

export const loadingSlice = createSlice({
  name: 'loading',
  initialState: {
    text: '',
  },
  reducers: {
    showLoading: (state, action) => {
      state.text = action.payload;
    },
  },
});

export const { showLoading } = loadingSlice.actions;

export default loadingSlice.reducer;
