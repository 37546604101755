import ModalBase from './ModalBase';
import { svgIcons } from '../svgIcons/svgIcons';

export function ModalCentered(props) {
  return (
    <ModalBase
      {...props}
      centered
      contentClassName="p-10"
      content={
        <>
          <div
            className={
              'w-[72px] h-[72px] flex justify-center items-center rounded-full ' +
              props.mark.parentClass
            }
          >
            <div className={props.mark.childClass}>{props.mark.icon}</div>
          </div>
          {props.headTitle && <h2 className="mt-[24px]">{props.headTitle}</h2>}
          <h2 className="mt-[24px]">{props.title}</h2>
          <p className="mt-[8px]">{props.txt}</p>
          {props.txtDown && (
            <p className="mt-[8px]">
              <br />
              {props.txtDown}
            </p>
          )}
          <div className="mt-8">{props.formGroup}</div>
        </>
      }
    />
  );
}
