import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { Dropdown } from '@restart/ui';
import { apiAgent } from '../../../utils/apicall';
import ConfirmDeletionModal from '../../../components/modalbootstrap/ConfirmDeletionModal';
import EditAssigneeModal from '../../../components/modalbootstrap/EditAssigneeModal';
import { setSelectedInfo } from '../../../features/userInfo/userInfoSlice';
import {
  CheckboxGroup,
  Checkbox,
  Select,
  SelectItem,
  Chip,
  Progress,
} from '@nextui-org/react';
import {
  PromotionType,
  GiftCardType,
  TimeUnit,
  PromotionExtensionType,
} from '../../../utils/constants';
import Skeleton from 'react-loading-skeleton';
import moment from 'moment';
import { ProjectStatus } from '../../../utils/constants';
import { serverFileNameFromURL } from '../../../utils/string';
import { Button, Switch, Tabs, Tab } from '@nextui-org/react';
import { svgIcons } from '../../../components/svgIcons/svgIcons';
import SpecialModal from '../../../components/modalbootstrap/SpecialModal';

export default function Concession() {
  const dispatch = useDispatch();
  const params = useParams();
  const propertyId = params.id;
  const todayDate = moment();
  // Get the current date and time in the "America/New_York" time zone
  const now = new Date();
  const formatter = new Intl.DateTimeFormat('en-US', {
    timeZone: 'America/New_York',
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  });

  const [{ value: month }, , { value: day }, , { value: year }] =
    formatter.formatToParts(now);
  const strToday = `${year}-${month}-${day}`;

  const navigator = useNavigate();
  const [property, setProperty] = useState();
  const [loadingFlag, setLoadingFlag] = useState(true);
  const [promotionLoadingFlag, setPromotionLoadingFlag] = useState(false);
  const [active, setActive] = useState('All');
  const [show, setShow] = useState(false);
  const [template, setTemplate] = useState(1);
  const [modal, setModal] = useState(false);
  const [amount, setAmount] = useState(1);
  const [amountFocus, setAmountFocus] = useState(false);

  const [specialPage, setSpecialPage] = useState(1);
  const [specialChannel, setSpecialChannel] = useState([]);
  const [disabledChannels, setDisabledChannels] = useState([]);
  const [specialType, setSpecialType] = useState('');
  const [typeWarning, setTypeWarning] = useState(false);
  const [channelWarning, setChannelWarning] = useState(false);
  const [dateWarning, setDateWarning] = useState(0);

  const [freeRentType, setFreeRentType] = useState('Month');
  const [specialAmount, setSpecialAmount] = useState(0);
  const [specialName, setSpecialName] = useState('');
  const [floorPlans, setFloorPlans] = useState([
    'Studio',
    '1 BR',
    '2 BR',
    '3 BR',
    '4 BR',
    '5 BR',
  ]);
  const [freeRentDuration, setFreeRentDuration] = useState(1);
  const [freeRentDurationFocused, setFreeRentDurationFocused] = useState(false);
  const [allFloorplanChecked, setAllFloorplanChecked] = useState(true);
  const [selectedPlans, setSelectedPlans] = useState([
    'Studio',
    '1 BR',
    '2 BR',
    '3 BR',
    '4 BR',
    '5 BR',
  ]);
  const [imageName, setImageName] = useState('');
  const [imageSize, setImageSize] = useState(0);
  const [uploadImage, setUploadImage] = useState();
  const [gbpPhotoFile, setGbpPhotoFile] = useState();
  const [clearGbpPostFile, setClearGbpPostFile] = useState(false);
  const [loadingProgress, setLoadingProgress] = useState(0);

  const [gAdURLFocused, setGAdURLFocused] = useState(false);
  const [gBpURLFocused, setGBpURLFocused] = useState(false);
  const [adText, setAdText] = useState('');
  const [isAdTextFocused, setIsAdTextFocused] = useState(false);
  const [itemText, setItemText] = useState('');
  const [postTitle, setPostTitle] = useState('');
  const [isPostTitleFocused, setIsPostTitleFocused] = useState(false);
  const [details, setDetails] = useState('');
  const [moreDetailSwitch, setMoreDetailSwitch] = useState(false);
  const [destinationURL, setDestinationURL] = useState('');
  const [promotionExtension, setPromotionExtension] = useState();
  const [promotionExtensionFocus, setPromotionExtensionFocus] = useState(false);
  const [promotionExtensionType, setPromotionExtensionType] = useState('PD');
  const [gbpCtaButton, setGbpCtaButton] = useState('');

  const [checkboxListPromotionGAdsFlag, setCheckboxListPromotionGAdsFlag] =
    useState([]);
  const [promotionGAdsList, setPromotionGAdsList] = useState([]);
  const [checkboxListPromotionGBPFlag, setCheckboxListPromotionGBPFlag] =
    useState([]);
  const [promotionGBPList, setPromotionGBPList] = useState([]);
  const [promotions, setPromotions] = useState([]);
  const [period, setPeriod] = useState('week');
  const [startDate, handleStartDateChange] = useState('');
  const [endDate, handleEndDateChange] = useState('');
  const [selectedPromotion, setSelectedPromotion] = useState();
  const [editFlag, setEditFlag] = useState(false);
  const [assigneeModal, setAssigneeModal] = useState(false);
  const [checkboxListConcession, setCheckboxListConcession] = useState([]);

  const fileSizeFormatter = (size) => {
    if (size < 1024) return `${size} B`;
    if (size < 1024 * 1024) return `${Math.round(size / 1024)} KB`;
    if (size < 1024 * 1024 * 1024)
      return `${Math.round(size / 1024 / 1024)} MB`;
    if (size < 1024 * 1024 * 1024 * 1024)
      return `${Math.round(size / 1024 / 1024 / 1024)} GB`;
  };

  const getImageData = (url) => {
    return fetch(url, {
      mode: 'no-cors',
      cache: 'no-cache',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
    })
      .then((response) => {
        const contentLength = response.headers.get('content-length');
        if (contentLength) {
          return parseInt(contentLength, 10);
        } else {
          throw new Error('Content-Length header not found');
        }
      })
      .catch((error) => {
        console.error('Error:', error);
        return null; // Return null or handle the error as needed
      });
  };

  const InitializeSpecialModal = () => {
    setSpecialChannel([]);
    setSpecialType('');
    setTypeWarning(false);
    setChannelWarning(false);

    setDateWarning(0);
    setSpecialName('');
    handleStartDateChange('');
    handleEndDateChange('');
    setFreeRentType('Month');
    setFreeRentDuration(1);
    setFreeRentDurationFocused(false);
    setAllFloorplanChecked(true);
    setFloorPlans(['Studio', '1 BR', '2 BR', '3 BR', '4 BR', '5 BR']);
    setSelectedPlans(['Studio', '1 BR', '2 BR', '3 BR', '4 BR', '5 BR']);
    setSpecialAmount(0);

    setAdText('');
    setPromotionExtension(0);
    setPromotionExtensionFocus(false);
    setPromotionExtensionType('PD');
    setGbpCtaButton('');
    setItemText('');
    if (property && property.homepage_link) {
      if (property.homepage_link.includes('://'))
        setDestinationURL(property.homepage_link.split('://')[1]);
    } else {
      setDestinationURL('');
    }
    setPostTitle('');
    setMoreDetailSwitch(false);
    setDetails('');
    if (property && property.highlight_photo) {
      setImageName(property.highlight_photo.split('/').pop());
      setUploadImage(property.highlight_photo);
    } else {
      setImageName('');
      setUploadImage();
    }
    setGbpPhotoFile();
    setImageSize(0);
    setLoadingProgress(0);
  };

  const handleChange = (event) => {
    setAmount(event.target.value);
  };

  const fetchPropertyData = async () => {
    try {
      setLoadingFlag(true);
      const response = await apiAgent.get({
        path: `/properties/${propertyId}`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const propertyBody = await response.json();
      window.document.title = `${propertyBody.name} Specials | Flair`;
      setProperty(propertyBody);

      if (propertyBody && propertyBody.highlight_photo) {
        setImageName(propertyBody.highlight_photo.split('/').pop());
        setUploadImage(propertyBody.highlight_photo);
      }
      if (propertyBody && propertyBody.homepage_link) {
        if (propertyBody.homepage_link.includes('://'))
          setDestinationURL(propertyBody.homepage_link.split('://')[1]);
      }

      let project_set = propertyBody.project_set;
      if (propertyBody.parent) {
        const response = await apiAgent.get({
          path: `/properties/${propertyBody.parent.id}`,
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        const result = await response.json();
        project_set = result.project_set;
      }
      let disabledChannels = [];
      let gAdStatus = false;
      project_set.map((item) => {
        if (item.ads_status == ProjectStatus.ACTIVE) gAdStatus = true;
      });
      if (!gAdStatus) disabledChannels.push('ads');
      if (!propertyBody.is_gmb_integration) disabledChannels.push('gmb');
      setDisabledChannels(disabledChannels);
      dispatch(
        setSelectedInfo({
          project: project_set.length > 0 ? project_set[0].id : null,
          property: parseInt(propertyId),
        }),
      );
      let propertypromotion_set = propertyBody.propertypromotion_set;
      propertypromotion_set.sort(
        (a, b) => a.promotion_type[0] - b.promotion_type[0],
      );
      setPromotions(propertypromotion_set);
      setCheckboxListConcession(
        Array(propertypromotion_set.length).fill(false),
      );
      setCheckboxListPromotionGAdsFlag(
        Array(propertypromotion_set.length).fill(false),
      );
      setPromotionGAdsList(Array(propertypromotion_set.length).fill(false));
      setCheckboxListPromotionGBPFlag(
        Array(propertypromotion_set.length).fill(false),
      );
      setPromotionGBPList(Array(propertypromotion_set.length).fill(false));
      setLoadingFlag(false);
      setAllFloorplanChecked(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchPropertyData();
  }, [propertyId]);

  // useEffect(() => {
  //   if (property && property.highlight_photo) {
  //     setImageName(property.highlight_photo.split('/').pop());
  //     setUploadImage(property.highlight_photo);
  //   }
  // }, [property]);

  useEffect(() => {
    if (specialType !== '') {
      setTypeWarning(false);
    }
  }, [specialType]);

  useEffect(() => {
    if (specialChannel.length !== 0) setChannelWarning(false);
  }, [specialChannel]);

  const formattedDate = (dateString) => {
    if (dateString.length == 0) return '';
    let date = new Date(dateString);
    let options = {
      month: 'short',
      day: 'numeric',
      year: 'numeric',
      timeZone: 'UTC',
    };
    return date.toLocaleDateString('en-US', options);
  };

  /**
   * date string format - '2024-05-17'
   * @param {String} dateString1
   * @param {String} dateString2
   * @returns true if dateString1 > dateString2
   */
  const compareDates = (dateString1, dateString2) => {
    return dateString1 >= dateString2;
    // const date1 = new Date(dateString1);
    // const date2 = new Date(dateString2);
    // if (date1 >= date2) return true;
    // else return false;
  };

  const handleAddConcession = async () => {
    let isActive = true;
    if (
      specialChannel.includes('ads') &&
      promotions.filter(
        (item) =>
          item.is_active &&
          item.is_gads_channel &&
          compareDates(item.schedule_end, strToday),
      ).length > 0
    )
      isActive = false;
    if (
      specialChannel.includes('gmb') &&
      promotions.filter(
        (item) =>
          item.is_active &&
          item.is_gbp_channel &&
          compareDates(item.schedule_end, strToday),
      ).length > 0
    )
      isActive = false;
    try {
      let formData,
        bedroomType = '-1',
        extensionType = '';
      setPromotionLoadingFlag(true);
      if (promotionExtensionType == 'MD')
        extensionType = PromotionExtensionType.MONETARY_DISCOUNT;
      if (promotionExtensionType == 'PD')
        extensionType = PromotionExtensionType.PERCENT_DISCOUNT;
      if (promotionExtensionType == 'UMD')
        extensionType = PromotionExtensionType.UP_TO_MONETARY_DISCOUNT;
      if (promotionExtensionType == 'UPD')
        extensionType = PromotionExtensionType.UP_TO_PERCENT_DISCOUNT;
      if (selectedPlans.length < 6) {
        bedroomType = '';
        selectedPlans.map((item) => {
          if (item == 'Studio') bedroomType += '0 ';
          else bedroomType += item[0] + ' ';
        });
        bedroomType = bedroomType.trimRight();
      }

      formData = new FormData();
      if (specialType == 'free-rent') {
        formData.append('promotion_type', PromotionType.FREE);
      } else if (specialType == 'fixed-off') {
        formData.append('promotion_type', PromotionType.XOFF);
      } else if (specialType == 'waved-fee') {
        formData.append('promotion_type', PromotionType.WAIV);
      } else if (specialType == 'gift-card') {
        formData.append('promotion_type', PromotionType.GIFT);
      } else if (specialType == 'custom') {
        formData.append('promotion_type', PromotionType.CUSTOM);
        formData.append('special_name', specialName);
      }

      formData.append('property', propertyId);
      formData.append(
        'time_unit',
        freeRentType === 'Week' ? TimeUnit.WEEK : TimeUnit.MONTH,
      );
      formData.append('amount_of_time_rent_free', parseInt(freeRentDuration));
      formData.append('money_amount_off', specialAmount);
      formData.append('schedule_start', startDate);
      formData.append('schedule_end', endDate);
      formData.append('bedroom_type', bedroomType);
      formData.append('special_ad_customizer', adText);
      formData.append('promotion_extension_type', extensionType);
      formData.append('promotion_extension_value', promotionExtension);
      formData.append('promotion_extension_item', itemText);
      if (gbpPhotoFile) {
        formData.append('gbp_post_photo', gbpPhotoFile);
      } else if (uploadImage && uploadImage.startsWith('http')) {
        let serverFileName = serverFileNameFromURL(uploadImage);
        formData.append('gbp_post_photo', serverFileName);
      }
      formData.append('gbp_post_title', postTitle);
      if (moreDetailSwitch) {
        formData.append('gbp_post_details', details);
      } else {
        formData.append('gbp_post_details', '');
      }
      formData.append('is_gads_channel', specialChannel.includes('ads'));
      formData.append('is_gbp_channel', specialChannel.includes('gmb'));
      formData.append('is_active', isActive);
      formData.append('destination_url', 'https://' + destinationURL);
      const response1 = await apiAgent.post({
        path: `/property_promotions/`,
        data: formData,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      setPromotionLoadingFlag(false);
      fetchPropertyData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleDeleteConcession = async () => {
    try {
      setPromotionLoadingFlag(true);
      const response = await apiAgent.del({
        path: `/property_promotions/${selectedPromotion.id}/`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      setPromotionLoadingFlag(false);
      fetchPropertyData();
    } catch (error) {
      console.log(error);
    }
  };

  const handleEditConcession = async (status, item) => {
    if (status != undefined) {
      if (
        status &&
        item.is_gads_channel &&
        promotions.filter(
          (item) =>
            item.is_active &&
            item.is_gads_channel &&
            compareDates(item.schedule_end, strToday),
        ).length > 0
      ) {
        setAssigneeModal('concession');
        return;
      }
      if (
        status &&
        item.is_gbp_channel &&
        promotions.filter(
          (item) =>
            item.is_active &&
            item.is_gbp_channel &&
            compareDates(item.schedule_end, strToday),
        ).length > 0
      ) {
        setAssigneeModal('concession');
        return;
      }
      try {
        setPromotionLoadingFlag(true);
        const response1 = await apiAgent.patch({
          path: `/property_promotions/${item.id}/`,
          data: { is_active: status },
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        setPromotionLoadingFlag(false);
        fetchPropertyData();
      } catch (error) {
        console.log(error);
      }
      return;
    }
    try {
      let formData,
        bedroomType = '-1',
        extensionType = '';
      setPromotionLoadingFlag(true);

      if (promotionExtensionType == 'MD')
        extensionType = PromotionExtensionType.MONETARY_DISCOUNT;
      if (promotionExtensionType == 'PD')
        extensionType = PromotionExtensionType.PERCENT_DISCOUNT;
      if (promotionExtensionType == 'UMD')
        extensionType = PromotionExtensionType.UP_TO_MONETARY_DISCOUNT;
      if (promotionExtensionType == 'UPD')
        extensionType = PromotionExtensionType.UP_TO_PERCENT_DISCOUNT;
      if (selectedPlans.length < 6) {
        bedroomType = '';
        selectedPlans.map((item) => {
          if (item == 'Studio') bedroomType += '0 ';
          else bedroomType += item[0] + ' ';
        });
        bedroomType = bedroomType.trimRight();
      }

      formData = new FormData();
      formData.append(
        'time_unit',
        freeRentType === 'Week' ? TimeUnit.WEEK : TimeUnit.MONTH,
      );
      formData.append('amount_of_time_rent_free', parseInt(freeRentDuration));
      formData.append('money_amount_off', specialAmount);
      formData.append('schedule_start', startDate);
      formData.append('schedule_end', endDate);
      formData.append('bedroom_type', bedroomType);
      formData.append('special_ad_customizer', adText);
      formData.append('promotion_extension_type', extensionType);
      formData.append('promotion_extension_value', promotionExtension);
      formData.append('promotion_extension_item', itemText);
      if (gbpPhotoFile) {
        formData.append('gbp_post_photo', gbpPhotoFile);
      } else if (uploadImage && uploadImage.startsWith('http')) {
        if (uploadImage != selectedPromotion.gbp_post_photo) {
          let serverFileName = serverFileNameFromURL(uploadImage);
          formData.append('gbp_post_photo', serverFileName);
        }
      }
      formData.append('gbp_post_title', postTitle);
      if (moreDetailSwitch) {
        formData.append('gbp_post_details', details);
      } else {
        formData.append('gbp_post_details', '');
      }
      formData.append('is_gads_channel', specialChannel.includes('ads'));
      formData.append('is_gbp_channel', specialChannel.includes('gmb'));
      formData.append('destination_url', 'https://' + destinationURL);
      if (clearGbpPostFile) {
        formData.append('clear_gbp_post_photo', true);
        // const response = await apiAgent.post({
        //   path: `/property_promotions/${selectedPromotion.id}/clear_gbp_post`,
        //   options: apiAgent.popularOptions.onConfirmExceptDeclined,
        // });
      }
      const response = await apiAgent.patch({
        path: `/property_promotions/${selectedPromotion.id}/`,
        data: formData,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      setPromotionLoadingFlag(false);
      fetchPropertyData();
    } catch (error) {
      console.log(error);
    }
  };

  const getGAdsPromotionStatistics = async (promotionId, index) => {
    try {
      setCheckboxListPromotionGAdsFlag((prev) =>
        prev.map((item, id) => (id === index ? true : item)),
      );
      const response = await apiAgent.get({
        path: `property_promotions/${promotionId}/special_ad_customizer_gads_overview`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const resp = await response.json();
      let statistics = [];
      resp.ad_group_ad.map((item) => {
        statistics = statistics.concat(item.metrics);
      });

      // const respones = await apiAgent.get({
      //   path: `property_promotions/${promotionId}/promotion_gads_overview`,
      //   options: apiAgent.popularOptions.onConfirmExceptDeclined,
      // });
      // const resp = await respones.json();
      // let statistics = [];
      // resp.campaign_asset.map((item) => {
      //   statistics = statistics.concat(item.metrics);
      // });
      // resp.customer_asset.map((item) => {
      //   statistics = statistics.concat(item.metrics);
      // });
      setPromotionGAdsList((prev) =>
        prev.map((item, id) => (id === index ? statistics : item)),
      );
      setCheckboxListPromotionGAdsFlag((prev) =>
        prev.map((item, id) => (id === index ? false : item)),
      );
    } catch (error) {
      console.log(error);
    }
  };

  const getGBPPromotionStatistics = async (promotionId, index) => {
    try {
      setCheckboxListPromotionGBPFlag((prev) =>
        prev.map((item, id) => (id === index ? true : item)),
      );
      const respones = await apiAgent.get({
        path: `property_promotions/${promotionId}/promotion_gbp_overview`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const resp = await respones.json();
      setPromotionGBPList((prev) =>
        prev.map((item, id) => (id === index ? resp.metrics : item)),
      );
      setCheckboxListPromotionGBPFlag((prev) =>
        prev.map((item, id) => (id === index ? false : item)),
      );
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div className="grid">
        {loadingFlag || promotionLoadingFlag ? (
          <>
            <div className="my-8">
              <Skeleton
                containerClassName="w-full"
                height={36}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
                borderRadius={4}
              />
            </div>
            <div className="divide-y border rounded-lg">
              <div className="p-6 h-[88px]">
                <div className="flex flex-wrap justify-between items-center">
                  <div className="flex rounded">
                    <div className="rounded">
                      <Skeleton
                        width={187}
                        height={40}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                        borderRadius={4}
                      />
                    </div>
                  </div>
                  <div>
                    <Skeleton
                      width={187}
                      height={40}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                      borderRadius={4}
                    />
                  </div>
                </div>
              </div>
              {[...Array(3).keys()].map((item) => (
                <div className="p-6 h-[105px] flex flex-wrap justify-between items-center">
                  <div className="flex flex-wrap gap-4">
                    <div className="grid place-items-center">
                      <Skeleton
                        width={57}
                        height={57}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                        borderRadius={4}
                      />
                    </div>
                    <div className="grid content-between">
                      <div className="text-lg font-bold">
                        <Skeleton
                          width={198}
                          baseColor="rgba(202, 220, 237, 0.7)"
                          highlightColor="rgba(219, 230, 242, 1)"
                          borderRadius={4}
                        />
                      </div>
                      <div>
                        <Skeleton
                          width={182}
                          baseColor="rgba(202, 220, 237, 0.7)"
                          highlightColor="rgba(219, 230, 242, 1)"
                          borderRadius={4}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="flex flex-wrap gap-8">
                    <div className="flex flex-wrap gap-4 place-items-center text-[#3F3F46]">
                      <Skeleton
                        width={101}
                        height={24}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                        borderRadius={4}
                      />
                    </div>
                    <div className="relative h-full font-saans-trial">
                      <Skeleton
                        width={24}
                        height={24}
                        baseColor="rgba(202, 220, 237, 0.7)"
                        highlightColor="rgba(219, 230, 242, 1)"
                        borderRadius={4}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <>
            <div className="my-8">
              <span className="font-bold text-3xl">Specials</span>
            </div>
            <div className="divide-y border rounded-lg bg-white shadow-sm">
              <div className="p-6">
                <div className="flex flex-wrap justify-between items-center">
                  <div className="flex">
                    <Tabs
                      key="none"
                      radius="sm"
                      selectedKey={active}
                      onSelectionChange={(e) => {
                        setCheckboxListConcession((prev) =>
                          prev.map((item) => false),
                        );
                        setCheckboxListPromotionGAdsFlag((prev) =>
                          prev.map((item) => false),
                        );
                        setCheckboxListPromotionGBPFlag((prev) =>
                          prev.map((item) => false),
                        );
                        setActive(e);
                      }}
                      className="border h-[44px] rounded-xl text-base leading-6 font-medium"
                      classNames={{
                        tabList: 'p-[1px] gap-0',
                      }}
                    >
                      <Tab key="All" title="All" className="w-[130px] h-10" />
                      <Tab
                        key="Active"
                        title="Active"
                        className="w-[130px] h-10"
                      />
                      <Tab
                        key="Expired"
                        title="Expired"
                        className="w-[130px] h-10"
                      />
                    </Tabs>
                  </div>
                  <div>
                    <Button
                      variant="solid"
                      radius="sm"
                      onClick={() => {
                        // On Click Create Special
                        setShow(true);
                        setEditFlag(false);
                        setClearGbpPostFile(false);
                        if (editFlag) InitializeSpecialModal();
                      }}
                      className="w-[187px] h-[48px] bg-default-foreground text-white text-base leading-6 font-medium"
                      startContent={svgIcons.add_circle}
                    >
                      Create Special
                    </Button>
                  </div>
                </div>
              </div>
              {promotions &&
                promotions
                  .filter(
                    (item) =>
                      active == 'All' ||
                      (active == 'Active' && item.is_active) ||
                      (active == 'Expired' &&
                        !item.is_active &&
                        !compareDates(item.schedule_end, strToday)),
                  )
                  .map((item, index) => (
                    <>
                      <div className="p-6 h-[105px] flex flex-wrap justify-between items-center">
                        <div className="flex flex-wrap gap-6">
                          <div
                            onClick={() => {
                              if (!checkboxListConcession[index]) {
                                if (item.resource_name)
                                  getGAdsPromotionStatistics(item.id, index);
                                if (item.gbp_post_name)
                                  getGBPPromotionStatistics(item.id, index);
                              }
                              setCheckboxListConcession((prev) =>
                                prev.map((item, id) =>
                                  id === index ? !item : item,
                                ),
                              );
                            }}
                            className="w-10 h-10 bg-foreground-100 rounded-md grid items-center my-auto cursor-pointer"
                          >
                            <img
                              className="m-auto"
                              src={
                                checkboxListConcession[index]
                                  ? '/assets/images/arrow-down.png'
                                  : '/assets/images/arrow-right.png'
                              }
                              width={24}
                              alt=""
                            />
                          </div>
                          <div className="w-[2px] h-14 bg-default-100"></div>
                          <div className="flex gap-4">
                            <div
                              className={
                                item.promotion_type != PromotionType.CUSTOM
                                  ? 'border rounded w-[57px] h-[57px] grid place-items-center'
                                  : 'w-[57px] h-[57px] grid place-items-center'
                              }
                            >
                              {item.promotion_type == PromotionType.FREE && (
                                <img
                                  src="/assets/images/free-amount.png"
                                  width={33.31}
                                  alt=""
                                />
                              )}
                              {item.promotion_type == PromotionType.XOFF && (
                                <img
                                  src="/assets/images/x-off.png"
                                  width={33.31}
                                  alt=""
                                />
                              )}
                              {item.promotion_type == PromotionType.WAIV && (
                                <img
                                  src="/assets/images/waved-fee.png"
                                  width={33.31}
                                  alt=""
                                />
                              )}
                              {item.promotion_type == PromotionType.GIFT && (
                                <img
                                  src="/assets/images/gift-card.png"
                                  width={25.72}
                                  alt=""
                                />
                              )}
                              {item.promotion_type == PromotionType.CUSTOM && (
                                <img src="/assets/images/custom.png" alt="" />
                              )}
                            </div>
                            <div className="grid content-between">
                              <div className="flex gap-3">
                                <div className="text-lg font-bold flex items-center">
                                  {item.promotion_type == PromotionType.FREE &&
                                    `${item.amount_of_time_rent_free} ${
                                      item.time_unit == TimeUnit.WEEK
                                        ? 'Week'
                                        : 'Month'
                                    } Free`}
                                  {item.promotion_type == PromotionType.XOFF &&
                                    `$${parseFloat(
                                      item.money_amount_off,
                                    )} Off Move-In`}
                                  {item.promotion_type == PromotionType.WAIV &&
                                    'Waived Application Fee’s'}
                                  {item.promotion_type == PromotionType.GIFT &&
                                    `$${parseFloat(
                                      item.money_amount_off,
                                    )} Off Gift Card`}
                                  {item.promotion_type ==
                                    PromotionType.CUSTOM &&
                                    item.special_ad_customizer}
                                </div>
                                <div className="flex gap-2">
                                  {item.resource_name && (
                                    <div className="flex items-center gap-1 h-8 px-3 py-2 border-[1px] rounded-[34px]">
                                      <img
                                        src="/assets/images/GAds.png"
                                        width={13.171}
                                        alt=""
                                      />
                                      <div className="text-xs leading-4 font-medium">
                                        Google Ads
                                      </div>
                                    </div>
                                  )}
                                  {item.gbp_post_name && (
                                    <div className="flex items-center gap-1 h-8 px-3 py-2 border-[1px] rounded-[34px]">
                                      <img
                                        src="/assets/images/GMB.png"
                                        width={18}
                                        alt=""
                                      />
                                      <div className="text-xs leading-4 font-medium">
                                        Google Business Profile
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              <div>
                                {`${formattedDate(
                                  item.schedule_start,
                                )} - ${formattedDate(item.schedule_end)}`}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="flex flex-wrap gap-8">
                          <div className="flex flex-wrap gap-3 place-items-center text-[#3F3F46]">
                            {item.is_active ||
                            compareDates(item.schedule_end, strToday) ? (
                              <>
                                <div>
                                  {item.is_active ? 'Active' : 'Disabled'}
                                </div>
                                <Switch
                                  color="warning"
                                  isSelected={item.is_active}
                                  onValueChange={(e) => {
                                    handleEditConcession(e, item);
                                  }}
                                />
                              </>
                            ) : (
                              <div className="flex gap-2 items-center pr-2">
                                <div>Expired</div>
                                {svgIcons.expired}
                              </div>
                            )}
                          </div>
                          <div className="relative h-full font-saans-trial flex items-center">
                            <Dropdown>
                              <Dropdown.Toggle>
                                {(props) => (
                                  <button {...props}>
                                    {svgIcons.three_dots}
                                  </button>
                                )}
                              </Dropdown.Toggle>
                              <Dropdown.Menu>
                                {(menuProps, meta) => (
                                  <ul
                                    {...menuProps}
                                    className="fixed w-[128px] h-[88px] xs:w-auto bottom-0 xs:absolute xs:bottom-auto xs:top-6 right-2 z-10 rounded-lg bg-white border border-[#E9ECF1] shadow-[0_20.3px_37.71px_rgba(183, 188, 200, 0.11)] whitespace-nowrap flex"
                                    style={{
                                      boxShadow:
                                        '0px 20.3px 37.71px rgba(183, 188, 200, 0.11)',
                                      transition:
                                        'visibility 800ms, opacity 800ms, transform 800ms',
                                      visibility: meta.show
                                        ? 'visible'
                                        : 'hidden',
                                      opacity: meta.show ? '1' : '0',
                                      transform: meta.show
                                        ? 'translateY(6px)'
                                        : 'translateY(-4px)',
                                    }}
                                  >
                                    <div className="py-1 grid w-[128px]">
                                      <button
                                        onClick={() => {
                                          // On Click Edit Special
                                          // setSelectedFlag(true);
                                          // if (
                                          //   item.promotion_type ==
                                          //   PromotionType.FREE
                                          // ) {
                                          //   setTemplate(1);
                                          //   setPeriod(
                                          //     item.time_unit == TimeUnit.WEEK
                                          //       ? 'week'
                                          //       : 'month',
                                          //   );
                                          //   setAmount(
                                          //     item.amount_of_time_rent_free,
                                          //   );
                                          // }
                                          // if (
                                          //   item.promotion_type ==
                                          //   PromotionType.XOFF
                                          // ) {
                                          //   setTemplate(2);
                                          //   setDeducationAmount(
                                          //     item.money_amount_off,
                                          //   );
                                          // }
                                          // if (
                                          //   item.promotion_type ==
                                          //   PromotionType.WAIV
                                          // ) {
                                          //   setTemplate(3);
                                          // }
                                          // if (
                                          //   item.promotion_type ==
                                          //   PromotionType.GIFT
                                          // ) {
                                          //   setTemplate(4);
                                          //   setDeducationAmount(
                                          //     item.money_amount_off,
                                          //   );
                                          //   setGiftCardType(
                                          //     item.gift_card_type,
                                          //   );
                                          // }
                                          // handleStartDateChange(
                                          //   item.schedule_start,
                                          // );
                                          // handleEndDateChange(
                                          //   item.schedule_end,
                                          // );
                                          setDestinationURL(
                                            (
                                              item.destination_url ||
                                              property.homepage_link
                                            ).split('://')[1],
                                          );
                                          setEditFlag(true);
                                          setSpecialPage(1);

                                          let channels = [];
                                          if (item.is_gads_channel)
                                            channels.push('ads');
                                          if (item.is_gbp_channel)
                                            channels.push('gmb');
                                          setSpecialChannel(channels);

                                          if (
                                            item.promotion_type ===
                                            PromotionType.FREE
                                          )
                                            setSpecialType('free-rent');
                                          else if (
                                            item.promotion_type ===
                                            PromotionType.XOFF
                                          )
                                            setSpecialType('fixed-off');
                                          else if (
                                            item.promotion_type ===
                                            PromotionType.WAIV
                                          )
                                            setSpecialType('waved-fee');
                                          else if (
                                            item.promotion_type ===
                                            PromotionType.GIFT
                                          )
                                            setSpecialType('gift-card');
                                          else if (
                                            item.promotion_type ===
                                            PromotionType.CUSTOM
                                          )
                                            setSpecialType('custom');
                                          else setSpecialType('');

                                          if (
                                            item.promotion_extension_type ===
                                            PromotionExtensionType.MONETARY_DISCOUNT
                                          )
                                            setPromotionExtensionType('MD');
                                          else if (
                                            item.promotion_extension_type ===
                                            PromotionExtensionType.PERCENT_DISCOUNT
                                          )
                                            setPromotionExtensionType('PD');
                                          else if (
                                            item.promotion_extension_type ===
                                            PromotionExtensionType.UP_TO_MONETARY_DISCOUNT
                                          )
                                            setPromotionExtensionType('UMD');
                                          else if (
                                            item.promotion_extension_type ===
                                            PromotionExtensionType.UP_TO_PERCENT_DISCOUNT
                                          )
                                            setPromotionExtensionType('UPD');
                                          else setPromotionExtensionType('');

                                          if (item.time_unit === TimeUnit.MONTH)
                                            setFreeRentType('Month');
                                          else setFreeRentType('Week');

                                          setFreeRentDuration(
                                            item.amount_of_time_rent_free,
                                          );
                                          setSpecialName(item.special_name);
                                          setAdText(item.special_ad_customizer);
                                          setSpecialAmount(
                                            item.money_amount_off,
                                          );
                                          setPromotionExtension(
                                            parseFloat(
                                              item.promotion_extension_value,
                                            ),
                                          );
                                          setItemText(
                                            item.promotion_extension_item,
                                          );
                                          setGbpPhotoFile(null);

                                          var file = item.gbp_post_photo;
                                          if (file) {
                                            getImageData(file).then((size) => {
                                              if (size) {
                                                setImageSize(size);
                                                setLoadingProgress(100);
                                              }
                                            });
                                            setImageName(file.split('/').pop());
                                            setUploadImage(file);
                                            setLoadingProgress(100);
                                            // setImageSize(file.size);
                                          }
                                          if (item.bedroom_type === '-1') {
                                            setSelectedPlans([
                                              'Studio',
                                              '1 BR',
                                              '2 BR',
                                              '3 BR',
                                              '4 BR',
                                              '5 BR',
                                            ]);
                                            setAllFloorplanChecked(true);
                                          } else {
                                            let plans = [];
                                            item.bedroom_type
                                              .split(' ')
                                              .map((val, ind) => {
                                                if (val.length) {
                                                  let value = parseInt(val);
                                                  if (value) {
                                                    plans.push(`${value} BR`);
                                                  } else {
                                                    plans.push('Studio');
                                                  }
                                                }
                                              });
                                            setSelectedPlans(plans);
                                            setAllFloorplanChecked(false);
                                          }

                                          setPostTitle(item.gbp_post_title);
                                          setDetails(item.gbp_post_details);
                                          if (
                                            item.gbp_post_details.length > 0
                                          ) {
                                            setMoreDetailSwitch(true);
                                          }
                                          handleStartDateChange(
                                            item.schedule_start,
                                          );
                                          handleEndDateChange(
                                            item.schedule_end,
                                          );

                                          meta.toggle();
                                          setSelectedPromotion(item);
                                          setShow(true);
                                        }}
                                        className="bg-[#FAFAFA] text-medium flex items-center indent-2 h-[41px] p-3"
                                      >
                                        Edit
                                      </button>
                                      <button
                                        onClick={() => {
                                          meta.toggle();
                                          setSelectedPromotion(item);
                                          setModal(true);
                                        }}
                                        className="text-[#EB4261] flex items-center indent-2 h-[41px] p-3"
                                      >
                                        Delete
                                      </button>
                                    </div>
                                  </ul>
                                )}
                              </Dropdown.Menu>
                            </Dropdown>
                          </div>
                        </div>
                      </div>
                      {checkboxListConcession[index] ? (
                        <div className="bg-default-50 border-x-1 p-6">
                          {disabledChannels.length == 2 ||
                          (!item.resource_name && !item.gbp_post_name) ? (
                            disabledChannels.length == 2 ? (
                              <div className="grid place-items-center gap-4 py-[46px]">
                                <div className="w-[72px] h-[72px] border-[1px] rounded-lg p-4">
                                  {svgIcons.ranking}
                                </div>
                                <div>
                                  You haven’t connected any platform to Flair
                                  yet.{' '}
                                  <span
                                    className="underline text-primary cursor-pointer"
                                    onClick={() =>
                                      navigator(
                                        `/properties/${propertyId}/integrations`,
                                      )
                                    }
                                  >
                                    Click Here for Integrations
                                  </span>
                                </div>
                              </div>
                            ) : (
                              <div className="grid place-items-center gap-4 py-[46px]">
                                <div className="w-[72px] h-[72px] border-[1px] rounded-lg p-4">
                                  {svgIcons.ranking}
                                </div>
                                <div>
                                  This special offer hasn’t been published to
                                  any channel yet.
                                </div>
                              </div>
                            )
                          ) : (
                            <div className="grid gap-4">
                              {item.resource_name &&
                                (checkboxListPromotionGAdsFlag[index] ? (
                                  <div className="skeleton-background border-1 rounded-lg">
                                    <div className="flex p-4 justify-between">
                                      <div>
                                        <Skeleton
                                          width={212}
                                          height={24}
                                          baseColor="rgba(202, 220, 237, 0.7)"
                                          highlightColor="rgba(219, 230, 242, 1)"
                                          borderRadius={4}
                                        />
                                      </div>
                                      <div>
                                        <Skeleton
                                          width={458}
                                          height={24}
                                          baseColor="rgba(202, 220, 237, 0.7)"
                                          highlightColor="rgba(219, 230, 242, 1)"
                                          borderRadius={4}
                                        />
                                      </div>
                                    </div>
                                    <div className="h-22 border-t-1 p-4">
                                      <div>
                                        <Skeleton
                                          height={64}
                                          baseColor="rgba(202, 220, 237, 0.7)"
                                          highlightColor="rgba(219, 230, 242, 1)"
                                          borderRadius={12}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="bg-white border-1 rounded-lg">
                                    <div className="flex p-4 justify-between">
                                      <div className="flex gap-2 items-center">
                                        <div className="flex items-center w-8 h-8 px-[6.4px] border-[1px] rounded">
                                          <img
                                            src="/assets/images/GAds.png"
                                            alt=""
                                          />
                                        </div>
                                        <div className="text-base leading-6 font-semibold">
                                          Google Ads
                                        </div>
                                      </div>
                                      {promotionGAdsList[index].length == 0 && (
                                        <div className="flex items-center gap-1">
                                          {svgIcons.danger}
                                          Your special doesn't have any stats
                                          yet, please check back here later 📈
                                        </div>
                                      )}
                                    </div>
                                    <div className="grid grid-flow-col h-22 border-t-1">
                                      <div className="p-4">
                                        <div
                                          className={
                                            promotionGAdsList[index].length == 0
                                              ? 'grid gap-3 opacity-40 custom-mix-blend-luminosity'
                                              : 'grid gap-3'
                                          }
                                        >
                                          <div className="flex gap-2 min-w-[134px]">
                                            <div>
                                              <img
                                                src="/assets/images/ranking.png"
                                                alt=""
                                              />
                                            </div>
                                            <div>Impressions</div>
                                          </div>
                                          <div className="text-lg mono font-bold">
                                            {promotionGAdsList[index].length ==
                                            0
                                              ? 0
                                              : promotionGAdsList[index]
                                                  .reduce(
                                                    (sum, item) =>
                                                      (sum +=
                                                        parseInt(
                                                          item.impressions,
                                                        ) || 0),
                                                    0,
                                                  )
                                                  .toLocaleString('en-US')}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="p-4 border-l-1">
                                        <div
                                          className={
                                            promotionGAdsList[index].length == 0
                                              ? 'grid gap-3 opacity-40 custom-mix-blend-luminosity'
                                              : 'grid gap-3'
                                          }
                                        >
                                          <div className="flex gap-2 min-w-[134px]">
                                            <div>
                                              <img
                                                src="/assets/images/send.png"
                                                alt=""
                                              />
                                            </div>
                                            <div>Clicks</div>
                                          </div>
                                          <div className="text-lg mono font-bold">
                                            {promotionGAdsList[index].length ==
                                            0
                                              ? 0
                                              : promotionGAdsList[index]
                                                  .reduce(
                                                    (sum, item) =>
                                                      (sum +=
                                                        parseInt(item.clicks) ||
                                                        0),
                                                    0,
                                                  )
                                                  .toLocaleString('en-US')}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="p-4 border-l-1">
                                        <div
                                          className={
                                            promotionGAdsList[index].length == 0
                                              ? 'grid gap-3 opacity-40 custom-mix-blend-luminosity'
                                              : 'grid gap-3'
                                          }
                                        >
                                          <div className="flex gap-2 min-w-[134px]">
                                            <div>
                                              <img
                                                src="/assets/images/3Dcube.png"
                                                alt=""
                                              />
                                            </div>
                                            <div>CTR</div>
                                          </div>
                                          <div className="text-lg mono font-bold">
                                            {promotionGAdsList[index].length ==
                                              0 ||
                                            promotionGAdsList[index].reduce(
                                              (sum, item) =>
                                                (sum +=
                                                  parseInt(item.impressions) ||
                                                  0),
                                              0,
                                            ) == 0
                                              ? 0
                                              : (
                                                  (promotionGAdsList[
                                                    index
                                                  ].reduce(
                                                    (sum, item) =>
                                                      (sum +=
                                                        parseInt(item.clicks) ||
                                                        0),
                                                    0,
                                                  ) *
                                                    100) /
                                                  promotionGAdsList[
                                                    index
                                                  ].reduce(
                                                    (sum, item) =>
                                                      (sum +=
                                                        parseInt(
                                                          item.impressions,
                                                        ) || 0),
                                                    0,
                                                  )
                                                ).toFixed(2)}
                                            %
                                          </div>
                                        </div>
                                      </div>
                                      <div className="p-4 border-l-1">
                                        <div
                                          className={
                                            promotionGAdsList[index].length == 0
                                              ? 'grid gap-3 opacity-40 custom-mix-blend-luminosity'
                                              : 'grid gap-3'
                                          }
                                        >
                                          <div className="flex gap-2 min-w-[134px]">
                                            <div>
                                              <img
                                                src="/assets/images/flash.png"
                                                alt=""
                                              />
                                            </div>
                                            <div>Conversions</div>
                                          </div>
                                          <div className="text-lg mono font-bold">
                                            {promotionGAdsList[index].length ==
                                            0
                                              ? 0
                                              : promotionGAdsList[index]
                                                  .reduce(
                                                    (sum, item) =>
                                                      (sum +=
                                                        parseInt(
                                                          item.conversions,
                                                        ) || 0),
                                                    0,
                                                  )
                                                  .toLocaleString('en-US')}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="p-4 border-l-1">
                                        <div
                                          className={
                                            promotionGAdsList[index].length == 0
                                              ? 'grid gap-3 opacity-40 custom-mix-blend-luminosity'
                                              : 'grid gap-3'
                                          }
                                        >
                                          <div className="flex gap-2 min-w-[134px]">
                                            <div>
                                              <img
                                                src="/assets/images/computing.png"
                                                alt=""
                                              />
                                            </div>
                                            <div>Cost</div>
                                          </div>
                                          <div className="text-lg mono font-bold">
                                            $
                                            {promotionGAdsList[index].length ==
                                            0
                                              ? 0
                                              : promotionGAdsList[index]
                                                  .reduce(
                                                    (sum, item) =>
                                                      (sum +=
                                                        parseInt(
                                                          item.costMicros /
                                                            1000000,
                                                        ) || 0),
                                                    0,
                                                  )
                                                  .toLocaleString('en-US')}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="p-4 border-l-1">
                                        <div
                                          className={
                                            promotionGAdsList[index].length == 0
                                              ? 'grid gap-3 opacity-40 custom-mix-blend-luminosity'
                                              : 'grid gap-3'
                                          }
                                        >
                                          <div className="flex gap-2 min-w-[134px]">
                                            <div>
                                              <img
                                                src="/assets/images/crown.png"
                                                alt=""
                                              />
                                            </div>
                                            <div>Conversion Rate</div>
                                          </div>
                                          <div className="text-lg mono font-bold">
                                            {promotionGAdsList[index].length ==
                                              0 ||
                                            promotionGAdsList[index].reduce(
                                              (sum, item) =>
                                                (sum +=
                                                  parseInt(item.interactions) ||
                                                  0),
                                              0,
                                            ) == 0
                                              ? 0
                                              : (
                                                  (promotionGAdsList[
                                                    index
                                                  ].reduce(
                                                    (sum, item) =>
                                                      (sum +=
                                                        parseInt(
                                                          item.conversions,
                                                        ) || 0),
                                                    0,
                                                  ) *
                                                    100) /
                                                  promotionGAdsList[
                                                    index
                                                  ].reduce(
                                                    (sum, item) =>
                                                      (sum +=
                                                        parseInt(
                                                          item.interactions,
                                                        ) || 0),
                                                    0,
                                                  )
                                                ).toFixed(2)}
                                            %
                                          </div>
                                        </div>
                                      </div>
                                      <div className="p-4 border-l-1">
                                        <div
                                          className={
                                            promotionGAdsList[index].length == 0
                                              ? 'grid gap-3 opacity-40 custom-mix-blend-luminosity'
                                              : 'grid gap-3'
                                          }
                                        >
                                          <div className="flex gap-2 min-w-[134px]">
                                            <div>
                                              <img
                                                src="/assets/images/chart.png"
                                                alt=""
                                              />
                                            </div>
                                            <div>Cost Per Conversion</div>
                                          </div>
                                          <div className="text-lg mono font-bold">
                                            $
                                            {promotionGAdsList[index].length ==
                                              0 ||
                                            promotionGAdsList[index].reduce(
                                              (sum, item) =>
                                                (sum +=
                                                  parseInt(item.impressions) ||
                                                  0),
                                              0,
                                            ) == 0
                                              ? 0
                                              : parseFloat(
                                                  (
                                                    promotionGAdsList[
                                                      index
                                                    ].reduce(
                                                      (sum, item) =>
                                                        (sum +=
                                                          parseInt(
                                                            item.costMicros,
                                                          ) || 0),
                                                      0,
                                                    ) /
                                                    1000000 /
                                                    promotionGAdsList[
                                                      index
                                                    ].reduce(
                                                      (sum, item) =>
                                                        (sum +=
                                                          parseInt(
                                                            item.conversions,
                                                          ) || 0),
                                                      0,
                                                    )
                                                  ).toFixed(2),
                                                ).toLocaleString('en-US')}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              {item.gbp_post_name &&
                                (checkboxListPromotionGBPFlag[index] ? (
                                  <div className="skeleton-background border-1 rounded-lg">
                                    <div className="flex p-4 justify-between">
                                      <div>
                                        <Skeleton
                                          width={212}
                                          height={24}
                                          baseColor="rgba(202, 220, 237, 0.7)"
                                          highlightColor="rgba(219, 230, 242, 1)"
                                          borderRadius={4}
                                        />
                                      </div>
                                      <div>
                                        <Skeleton
                                          width={458}
                                          height={24}
                                          baseColor="rgba(202, 220, 237, 0.7)"
                                          highlightColor="rgba(219, 230, 242, 1)"
                                          borderRadius={4}
                                        />
                                      </div>
                                    </div>
                                    <div className="h-22 border-t-1 p-4">
                                      <div>
                                        <Skeleton
                                          height={64}
                                          baseColor="rgba(202, 220, 237, 0.7)"
                                          highlightColor="rgba(219, 230, 242, 1)"
                                          borderRadius={12}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                ) : (
                                  <div className="bg-white border-1 rounded-lg">
                                    <div className="flex p-4 justify-between">
                                      <div className="flex gap-2 items-center">
                                        <div className="flex items-center w-8 h-8 px-1 border-[1px] rounded">
                                          <img
                                            src="/assets/images/GMB.png"
                                            alt=""
                                          />
                                        </div>
                                        <div className="text-base leading-6 font-semibold">
                                          Google Business Profile
                                        </div>
                                      </div>
                                      {promotionGBPList[index].length == 0 && (
                                        <div className="flex items-center gap-1">
                                          {svgIcons.danger}
                                          Your special doesn't have any stats
                                          yet, please check back here later 📈
                                        </div>
                                      )}
                                    </div>
                                    <div className="grid grid-flow-col auto-cols-fr h-22 border-t-1">
                                      <div className="p-4">
                                        <div
                                          className={
                                            promotionGBPList[index].length == 0
                                              ? 'grid gap-3 opacity-40 custom-mix-blend-luminosity'
                                              : 'grid gap-3'
                                          }
                                        >
                                          <div className="flex gap-2">
                                            <div>
                                              <img
                                                src="/assets/images/ranking.png"
                                                alt=""
                                              />
                                            </div>
                                            <div className="text-default-500">
                                              Impressions
                                            </div>
                                          </div>
                                          <div className="text-lg mono font-bold">
                                            {promotionGBPList[index].length == 0
                                              ? 0
                                              : promotionGBPList[index][0]
                                                  .impressions}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="p-4 border-l-1">
                                        <div
                                          className={
                                            promotionGBPList[index].length == 0
                                              ? 'grid gap-3 opacity-40 custom-mix-blend-luminosity'
                                              : 'grid gap-3'
                                          }
                                        >
                                          <div className="flex gap-2">
                                            <div>
                                              <img
                                                src="/assets/images/send.png"
                                                alt=""
                                              />
                                            </div>
                                            <div>Clicks</div>
                                          </div>
                                          <div className="text-lg mono font-bold">
                                            {promotionGBPList[index].length == 0
                                              ? 0
                                              : promotionGBPList[index][0]
                                                  .clicks}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="p-4 border-l-1">
                                        <div
                                          className={
                                            promotionGBPList[index].length == 0
                                              ? 'grid gap-3 opacity-40 custom-mix-blend-luminosity'
                                              : 'grid gap-3'
                                          }
                                        >
                                          <div className="flex gap-2">
                                            <div>
                                              <img
                                                src="/assets/images/3Dcube.png"
                                                alt=""
                                              />
                                            </div>
                                            <div>CTR</div>
                                          </div>
                                          <div className="text-lg mono font-bold">
                                            {promotionGBPList[index].length == 0
                                              ? 0
                                              : promotionGBPList[index][0].ctr}
                                            %
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                            </div>
                          )}
                        </div>
                      ) : (
                        ''
                      )}
                    </>
                  ))}
            </div>
          </>
        )}
      </div>
      <SpecialModal
        isOpen={show}
        onClose={() => {
          // On Close Modal
          setShow(false);
          InitializeSpecialModal();
          setSpecialPage(1);
        }}
        onBack={() => setSpecialPage((page) => page - 1)}
        handleConfirm={() => {
          if (specialPage < 3) {
            if (specialPage === 1) {
              if (specialType === '') setTypeWarning(true);
              if (specialChannel.length === 0) setChannelWarning(true);
              if (specialType === '' || specialChannel.length === 0) return;
              if (!editFlag) setFreeRentType('Month');
            }
            if (specialPage === 2) {
              // On Click Next on Page 2
              if (startDate === '' || endDate === '') {
                setDateWarning(1);
                return;
              }
              if (startDate !== endDate && compareDates(startDate, endDate)) {
                setDateWarning(2);
                return;
              }
              if (specialType === 'custom' && specialName === '') {
                // TODO: Show warning
                return;
              }
              if (specialType === 'free-rent') {
                if (freeRentDuration <= 0) {
                  return;
                }
              }

              if (specialType === 'gift-card' || specialType === 'fixed-off') {
                if (specialAmount <= 0) {
                  return;
                }
              }

              var text, ad_text, post_title;
              if (specialType === 'free-rent') {
                if (freeRentType === 'Week') {
                  text = `${freeRentDuration} Week${
                    freeRentDuration !== 1 ? 's' : ''
                  } Rent`;
                  ad_text = `Get ${freeRentDuration} Week${
                    freeRentDuration !== 1 ? 's' : ''
                  } Free`;
                  post_title = `Get ${freeRentDuration} Week${
                    freeRentDuration !== 1 ? 's' : ''
                  } Free for a Limited Time!`;
                } else if (freeRentType === 'Month') {
                  text = `${freeRentDuration} Month${
                    freeRentDuration !== 1 ? 's' : ''
                  } Rent`;
                  ad_text = `Get ${freeRentDuration} Month${
                    freeRentDuration !== 1 ? 's' : ''
                  } Free`;
                  post_title = `Get ${freeRentDuration} Month${
                    freeRentDuration !== 1 ? 's' : ''
                  } Free for a Limited Time!`;
                }
                if (!editFlag) {
                  setPromotionExtensionType('PD');
                  setPromotionExtension(100);
                }
              } else if (specialType === 'fixed-off') {
                text = 'Move In';
                ad_text = `$${parseFloat(specialAmount)} Off Move In`;
                post_title = `Get $${parseFloat(
                  specialAmount,
                )} Off Move In for a Limited Time!`;
                if (!editFlag) {
                  setPromotionExtensionType('MD');
                  setPromotionExtension(specialAmount);
                }
              } else if (specialType === 'waved-fee') {
                text = 'Application Fees';
                ad_text = 'No Application Fee';
                post_title = `No Application Fee for a Limited Time!`;
                if (!editFlag) {
                  setPromotionExtensionType('PD');
                  setPromotionExtension(100);
                }
              } else if (specialType === 'gift-card') {
                text = 'Gift Card';
                ad_text = `Get $${parseFloat(
                  specialAmount,
                )} Gift Card At Move In`;
                post_title = `Get $${parseFloat(
                  specialAmount,
                )} Gift Card At Move In!`;
                if (!editFlag) {
                  setPromotionExtensionType('MD');
                  setPromotionExtension(parseFloat(specialAmount));
                }
              } else {
                // custom
                text = '';
                if (specialName.length <= 30) {
                  ad_text = specialName;
                } else {
                  ad_text = '';
                }
                post_title = '';
                if (!editFlag) {
                  setPromotionExtensionType('PD');
                  setPromotionExtension(0);
                }
              }
              if (!editFlag) {
                setItemText(text);
                setAdText(ad_text);
                setPostTitle(post_title);
              }
            }
            setSpecialPage((page) => page + 1);
          }
          if (specialPage === 3) {
            // On Click Publish on Page 3
            if (!editFlag) handleAddConcession();
            else handleEditConcession();
            setSpecialPage(1);
            setShow(false);
            InitializeSpecialModal();
          }
        }}
        page={specialPage}
        content={
          specialPage === 1 ? (
            <div className="grid gap-y-6 max-h-[568px] overflow-y-auto">
              <div
                className={`grid bg-white rounded-xl shadow-sm border-1 ${
                  channelWarning ? 'border-[#EB903A]' : ''
                }`}
              >
                <div className="flex gap-2 border-b-1 pb-5 items-center p-5 justify-between">
                  <div>
                    <img
                      className="object-cover rounded m-auto"
                      src={
                        specialChannel.length === 0
                          ? '/../../assets/images/unselected.png'
                          : '/../../assets/images/selected.png'
                      }
                      alt=""
                    />
                  </div>
                  <div className="text-lg leading-7 font-semibold grow">
                    Select Your Channels
                  </div>
                  {channelWarning && (
                    <div className="flex gap-2 items-center">
                      <div className="flex w-4 h-4 items-center">
                        <img src="/../../assets/images/danger.png" alt="" />
                      </div>
                      <div className="text-small leading-7">
                        This field is required, please fill it out before
                        clicking next.
                      </div>
                    </div>
                  )}
                </div>
                <div className="gap-4 justify-between grid lg:grid-cols-2 gap-y-2 gap-x-4 p-5">
                  <div
                    className={`flex gap-4 p-5 items-center min-w-[380px] rounded-lg cursor-pointer border ${
                      disabledChannels.includes('ads')
                        ? 'grayscale pointer-events-none'
                        : 'hover:bg-default-50'
                    }`}
                    onClick={() => {
                      if (specialChannel.includes('ads')) {
                        setSpecialChannel((channel) =>
                          channel.length === 1 ? [] : ['gmb'],
                        );
                      } else {
                        setSpecialChannel((channel) =>
                          channel.length === 1 ? ['ads', 'gmb'] : ['ads'],
                        );
                      }
                    }}
                  >
                    <div className="flex w-20 h-20 border rounded-lg items-center">
                      <img
                        className="h-10 object-cover rounded m-auto"
                        src="/../../assets/images/GAds.png"
                        alt=""
                      />
                    </div>
                    <div className="grid grid-cols-1 gap-1 grow">
                      <div className="flex items-center justify-between">
                        <div className="text-xl leading-7 font-semibold grow">
                          Google Ads
                        </div>
                        <div className="w-5 h-5">
                          <Checkbox
                            color="secondary"
                            isSelected={specialChannel.includes('ads')}
                            onChange={() => {
                              setSpecialChannel((channel) =>
                                channel.includes('ads')
                                  ? channel.length === 1
                                    ? []
                                    : ['gmb']
                                  : channel.length === 1
                                  ? ['ads', 'gmb']
                                  : ['ads'],
                              );
                            }}
                          ></Checkbox>
                        </div>
                      </div>
                      <div className="text-base leading-6 font-normal w-[274px]">
                        Post this Special in Search Ads and Promotion
                        Extensions.
                      </div>
                    </div>
                  </div>
                  <div
                    className={`flex gap-4 p-5 items-center min-w-[380px] rounded-lg cursor-pointer border ${
                      disabledChannels.includes('gmb')
                        ? 'grayscale pointer-events-none'
                        : 'hover:bg-default-50'
                    }`}
                    onClick={() => {
                      if (specialChannel.includes('gmb')) {
                        setSpecialChannel((channel) =>
                          channel.length === 1 ? [] : ['ads'],
                        );
                      } else {
                        setSpecialChannel((channel) =>
                          channel.length === 1 ? ['ads', 'gmb'] : ['gmb'],
                        );
                      }
                    }}
                  >
                    <div className="w-20 h-20 border rounded-lg flex items-center">
                      <img
                        className="h-10 object-cover rounded m-auto"
                        src="/../../assets/images/GMB.png"
                        alt=""
                      />
                    </div>
                    <div className="grid grid-cols-1 gap-1 grow">
                      <div className="flex items-center justify-between">
                        <div className="text-xl leading-7 font-semibold grow">
                          Google My Business
                        </div>
                        <div className="w-5 h-5">
                          <Checkbox
                            color="secondary"
                            isSelected={specialChannel.includes('gmb')}
                            onChange={() => {
                              setSpecialChannel((channel) =>
                                channel.includes('gmb')
                                  ? channel.length === 1
                                    ? []
                                    : ['ads']
                                  : channel.length === 1
                                  ? ['ads', 'gmb']
                                  : ['gmb'],
                              );
                            }}
                          ></Checkbox>
                        </div>
                      </div>
                      <div className="text-base leading-6 font-normal w-[195px]">
                        Create a Google Post for this Special.
                      </div>
                    </div>
                  </div>

                  {disabledChannels.length !== 2 &&
                    disabledChannels.includes('ads') && (
                      <div className="flex gap-2">
                        <div className="flex w-4 h-4">
                          <img src="/../../assets/images/danger.png" alt="" />
                        </div>
                        <div className="text-small w-[350px]">
                          You haven’t connected this platform to Flair yet.{' '}
                          <span
                            onClick={() =>
                              navigator(
                                `/properties/${propertyId}/integrations`,
                              )
                            }
                            className="text-small underline text-[#7384F5] font-medium cursor-pointer"
                          >
                            Click Here for Integrations
                          </span>
                        </div>
                      </div>
                    )}
                  {disabledChannels.length !== 2 &&
                    disabledChannels.includes('gmb') && <div></div>}

                  {disabledChannels.length !== 2 &&
                    disabledChannels.includes('gmb') && (
                      <div className="flex gap-2">
                        <div className="flex w-4 h-4">
                          <img src="/../../assets/images/danger.png" alt="" />
                        </div>
                        <div className="text-small w-[350px]">
                          You haven’t connected this platform to Flair yet.{' '}
                          <span
                            onClick={() =>
                              navigator(
                                `/properties/${propertyId}/integrations`,
                              )
                            }
                            className="text-small underline text-[#7384F5] font-medium cursor-pointer"
                          >
                            Click Here for Integrations
                          </span>
                        </div>
                      </div>
                    )}
                </div>
                {disabledChannels.length === 2 && (
                  <div className="flex gap-2 px-5 pb-5">
                    <div className="flex w-4 h-4">
                      <img src="/../../assets/images/danger.png" alt="" />
                    </div>
                    <div className="text-small">
                      You haven’t connected any accounts to Flair yet.{' '}
                      <span
                        onClick={() =>
                          navigator(`/properties/${propertyId}/integrations`)
                        }
                        className="text-small underline text-[#7384F5] font-medium cursor-pointer"
                      >
                        Click Here for Integrations
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`grid bg-white rounded-xl shadow-sm border-1 ${
                  typeWarning ? 'border-[#EB903A]' : ''
                }`}
              >
                <div className="flex justify-between gap-2 border-b-1 p-5 items-center">
                  <div>
                    <img
                      className="object-cover rounded m-auto"
                      src={
                        specialType === ''
                          ? '/../../assets/images/unselected.png'
                          : '/../../assets/images/selected.png'
                      }
                      alt=""
                    />
                  </div>
                  <div className="grow text-lg leading-7 font-semibold">
                    Select Your Special Type
                  </div>
                  {typeWarning && (
                    <div className="flex gap-2 items-center">
                      <div className="flex w-4 h-4 items-center">
                        <img src="/../../assets/images/danger.png" alt="" />
                      </div>
                      <div className="text-small leading-7">
                        This field is required, please fill it out before
                        clicking next.
                      </div>
                    </div>
                  )}
                </div>
                <div className="gap-4 justify-between grid lg:grid-cols-2 gap-y-4 gap-x-5 p-5">
                  <div
                    className={`justify-between flex gap-4 px-5 py-4 items-center min-w-[380px] rounded-lg cursor-pointer border ${
                      editFlag
                        ? 'grayscale pointer-events-none'
                        : 'hover:bg-default-50'
                    }`}
                    onClick={() => {
                      if (specialType === 'free-rent') {
                        setSpecialType('');
                      } else {
                        setSpecialType('free-rent');
                      }
                    }}
                  >
                    <div className="flex w-[44px] h-[44px] border rounded-lg items-center">
                      <img
                        className="object-cover rounded m-auto"
                        src="/../../assets/images/free-rent.svg"
                        alt=""
                      />
                    </div>
                    <div className="text-base leading-6 font-medium grow">
                      Free Rent (i.e. 2 Months Free)
                    </div>
                    <div className="w-5 h-5">
                      <Checkbox
                        color="secondary"
                        radius="full"
                        isSelected={specialType === 'free-rent'}
                        onChange={() => {
                          setSpecialType((type) =>
                            type === 'free-rent' ? '' : 'free-rent',
                          );
                        }}
                      ></Checkbox>
                    </div>
                  </div>
                  <div
                    className={`justify-between flex gap-4 px-5 py-4 items-center min-w-[380px] rounded-lg cursor-pointer border ${
                      editFlag
                        ? 'grayscale pointer-events-none'
                        : 'hover:bg-default-50'
                    }`}
                    onClick={() => {
                      if (specialType === 'fixed-off') {
                        setSpecialType('');
                      } else {
                        setSpecialType('fixed-off');
                      }
                    }}
                  >
                    <div className="flex w-[44px] h-[44px] border rounded-lg items-center">
                      <img
                        className="object-cover rounded m-auto"
                        src="/../../assets/images/fixed-off.png"
                        alt=""
                      />
                    </div>
                    <div className="text-base leading-6 font-medium grow">
                      Fixed $ Off (i.e. $500 Off Move-In)
                    </div>
                    <div className="w-5 h-5">
                      <Checkbox
                        color="secondary"
                        radius="full"
                        isSelected={specialType === 'fixed-off'}
                        onChange={() => {
                          setSpecialType((type) =>
                            type === 'fixed-off' ? '' : 'fixed-off',
                          );
                        }}
                      ></Checkbox>
                    </div>
                  </div>
                  <div
                    className={`justify-between flex gap-4 px-5 py-4 items-center min-w-[380px] rounded-lg cursor-pointer border ${
                      editFlag
                        ? 'grayscale pointer-events-none'
                        : 'hover:bg-default-50'
                    }`}
                    onClick={() => {
                      if (specialType === 'waved-fee') {
                        setSpecialType('');
                      } else {
                        setSpecialType('waved-fee');
                      }
                    }}
                  >
                    <div className="flex w-[44px] h-[44px] border rounded-lg items-center">
                      <img
                        className="w-[23px] h-[23px] object-cover rounded m-auto"
                        src="/../../assets/images/waved-fee.png"
                        alt=""
                      />
                    </div>
                    <div className="text-base leading-6 font-medium grow">
                      Waived Application Fees
                    </div>
                    <div className="w-5 h-5">
                      <Checkbox
                        color="secondary"
                        radius="full"
                        isSelected={specialType === 'waved-fee'}
                        onChange={() => {
                          setSpecialType((type) =>
                            type === 'waved-fee' ? '' : 'waved-fee',
                          );
                        }}
                      ></Checkbox>
                    </div>
                  </div>
                  <div
                    className={`justify-between flex gap-4 px-5 py-4 items-center min-w-[380px] rounded-lg cursor-pointer border ${
                      editFlag
                        ? 'grayscale pointer-events-none'
                        : 'hover:bg-default-50'
                    }`}
                    onClick={() => {
                      if (specialType === 'gift-card') {
                        setSpecialType('');
                      } else {
                        setSpecialType('gift-card');
                      }
                    }}
                  >
                    <div className="flex w-[44px] h-[44px] border rounded-lg items-center">
                      <img
                        className="w-[23px] h-[23px] object-cover rounded m-auto"
                        src="/../../assets/images/gift-card.png"
                        alt=""
                      />
                    </div>
                    <div className="text-base leading-6 font-medium grow">
                      Gift Card
                    </div>
                    <div className="w-5 h-5">
                      <Checkbox
                        color="secondary"
                        radius="full"
                        isSelected={specialType === 'gift-card'}
                        onChange={() => {
                          setSpecialType((type) =>
                            type === 'gift-card' ? '' : 'gift-card',
                          );
                        }}
                      ></Checkbox>
                    </div>
                  </div>
                  <div
                    className={`justify-between flex gap-4 px-5 py-4 items-center min-w-[380px] rounded-lg cursor-pointer border ${
                      editFlag
                        ? 'grayscale pointer-events-none'
                        : 'hover:bg-default-50'
                    }`}
                    onClick={() => {
                      if (specialType === 'custom') {
                        setSpecialType('');
                      } else {
                        setSpecialType('custom');
                      }
                    }}
                  >
                    <div className="flex w-[44px] h-[44px] border rounded-lg items-center">
                      <img
                        className="object-cover rounded m-auto"
                        src="/../../assets/images/custom.svg"
                        alt=""
                      />
                    </div>
                    <div className="text-base leading-6 font-medium grow">
                      Custom
                    </div>
                    <div className="w-5 h-5">
                      <Checkbox
                        color="secondary"
                        radius="full"
                        isSelected={specialType === 'custom'}
                        onChange={() => {
                          setSpecialType((type) =>
                            type === 'custom' ? '' : 'custom',
                          );
                        }}
                      ></Checkbox>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : specialPage === 2 ? (
            <div className="grid gap-y-6 max-h-[568px] overflow-y-auto">
              {specialType === 'custom' && (
                <div className="grid bg-white rounded-xl shadow-sm border-1">
                  <div className="grid grid-cols-2 gap-5 pb-5 items-center p-5">
                    <div className="flex items-center gap-2">
                      <div>
                        <img
                          className="object-cover rounded m-auto"
                          src={
                            specialName !== ''
                              ? '/../../assets/images/selected.png'
                              : '/../../assets/images/unselected.png'
                          }
                          alt=""
                        />
                      </div>
                      <div className="text-lg leading-7 font-semibold">
                        Name Your Special
                      </div>
                    </div>
                    <div className="grid gap-y-2">
                      <input
                        type="text"
                        placeholder="Custom Special"
                        value={specialName}
                        onChange={(e) => {
                          setSpecialName(e.target.value);
                        }}
                        className="p-[14px] border-1 shadow-sm text-gray-900 text-base rounded-lg focus:ring-[#7384F5] focus:border-[#7384F5] block w-full"
                      />
                    </div>
                  </div>
                </div>
              )}
              <div
                className={`grid bg-white rounded-xl shadow-sm border-1 ${
                  dateWarning ? 'border-[#EB903A]' : ''
                }`}
              >
                <div className="flex gap-2 justify-between border-b-1 pb-5 items-center p-5">
                  <div>
                    <img
                      className="object-cover rounded m-auto"
                      src={
                        startDate !== '' &&
                        endDate !== '' &&
                        (!compareDates(startDate, endDate) ||
                          startDate === endDate)
                          ? '/../../assets/images/selected.png'
                          : '/../../assets/images/unselected.png'
                      }
                      alt=""
                    />
                  </div>
                  <div className="text-lg leading-7 font-semibold grow">
                    Select Your Start and End Dates
                  </div>
                  {dateWarning !== 0 && (
                    <div className="flex gap-2 items-center">
                      <div className="flex w-4 h-4 items-center">
                        <img src="/../../assets/images/danger.png" alt="" />
                      </div>
                      <div className="text-small leading-7">
                        {dateWarning === 1
                          ? 'This field is required, please fill it out before clicking next.'
                          : 'Start date needs to be earlier than End date.'}
                      </div>
                    </div>
                  )}
                </div>
                <div className=" p-5">
                  <div className="gap-4 rounded-lg grid lg:grid-cols-2 gap-y-2 gap-x-5 p-5 bg-[#FAFAFA]">
                    <label className="flex flex-col">
                      <div className="text-sm leading-5 font-medium">
                        Start date
                      </div>
                      <input
                        type="date"
                        className="form-input px-3 py-2 mt-1 border rounded-lg text-base leading-6 font-normal"
                        value={startDate}
                        onChange={(e) => {
                          handleStartDateChange(e.target.value);
                          setDateWarning(0);
                        }}
                        min={todayDate.format('YYYY-MM-DD')}
                      />
                    </label>
                    <label className="flex flex-col">
                      <div className="text-sm leading-5 font-medium">
                        End date
                      </div>
                      <input
                        type="date"
                        className="form-input px-3 py-2 mt-1 border rounded-lg text-base leading-6 font-normal"
                        value={endDate}
                        onChange={(e) => {
                          handleEndDateChange(e.target.value);
                          setDateWarning(0);
                        }}
                        min={todayDate.format('YYYY-MM-DD')}
                      />
                    </label>
                  </div>
                </div>
              </div>

              {specialType === 'free-rent' ? (
                <div className="grid bg-white rounded-xl shadow-sm border-1">
                  <div className="flex gap-2 border-b-1 pb-5 items-center p-5">
                    <div>
                      <img
                        className="object-cover rounded m-auto"
                        src={
                          freeRentType !== '' && freeRentDuration > 0
                            ? '/../../assets/images/selected.png'
                            : '/../../assets/images/unselected.png'
                        }
                        alt=""
                      />
                    </div>
                    <div className="text-lg leading-7 font-semibold">
                      Select How Many Weeks or Months Free Below
                    </div>
                  </div>
                  <div className="gap-4 grid lg:grid-cols-2 gap-y-2 gap-x-6 m-5 p-4 bg-[#FAFAFA] rounded-lg">
                    <div className="grid gap-y-2">
                      <div className="text-sm leading-5 font-medium">
                        Select weeks or months
                      </div>
                      <div className="grid gap-x-2 grid-cols-2">
                        <div
                          className="flex bg-white rounded-xl shadow-sm px-4 py-4 border-1 hover:bg-default-50 cursor-pointer"
                          onClick={() => {
                            setFreeRentType('Week');
                          }}
                        >
                          <div className="grow">Week(s)</div>
                          <div className="w-5 h-5">
                            <Checkbox
                              color="secondary"
                              radius="full"
                              isSelected={freeRentType === 'Week'}
                              onChange={() => {
                                setFreeRentType('Week');
                              }}
                            ></Checkbox>
                          </div>
                        </div>
                        <div
                          className="flex bg-white rounded-xl shadow-sm px-4 py-4 border-1 hover:bg-default-50 cursor-pointer"
                          onClick={() => {
                            setFreeRentType('Month');
                          }}
                        >
                          <div className="grow">Month(s)</div>
                          <div className="w-5 h-5">
                            <Checkbox
                              color="secondary"
                              radius="full"
                              isSelected={freeRentType === 'Month'}
                              onChange={() => {
                                setFreeRentType('Month');
                              }}
                            ></Checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="grid gap-y-2">
                      <div className="text-sm leading-5 font-medium lg:pl-6">
                        How many weeks or months?
                      </div>
                      <div className="lg:border-l-1 lg:pl-6">
                        <div
                          className={`flex border-1 shadow-sm px-[17px] py-[11px] h-[54px] bg-white rounded-lg ${
                            freeRentDurationFocused && 'border-[#7384F5]'
                          }`}
                        >
                          <input
                            type="number"
                            value={freeRentDuration}
                            placeholder="0"
                            onChange={(e) => {
                              setFreeRentDuration(e.target.value);
                            }}
                            onFocus={() => {
                              setFreeRentDurationFocused(true);
                            }}
                            onBlur={() => {
                              setFreeRentDurationFocused(false);
                            }}
                            className="text-gray-900 text-base leading-6 font-normal w-full"
                          />
                          <div className="grid">
                            <div
                              className="flex items-end cursor-pointer"
                              onClick={() => {
                                setFreeRentDuration((val) => parseInt(val) + 1);
                              }}
                            >
                              <img
                                src="/../../assets/images/bt-up.png"
                                alt=""
                              />
                            </div>
                            <div
                              className="cursor-pointer"
                              onClick={() => {
                                setFreeRentDuration((val) => parseInt(val) - 1);
                              }}
                            >
                              <img
                                src="/../../assets/images/bt-down.png"
                                alt=""
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                (specialType === 'gift-card' ||
                  specialType === 'fixed-off') && (
                  <div className="grid gap-y-6">
                    <div className="flex justify-between bg-white rounded-xl border-1 shadow-sm gap-2 items-center p-5">
                      <div>
                        <img
                          className="object-cover rounded m-auto"
                          src={
                            specialAmount
                              ? '/../../assets/images/selected.png'
                              : '/../../assets/images/unselected.png'
                          }
                          alt=""
                        />
                      </div>
                      <div className="grow text-lg leading-7 font-semibold">
                        {specialType === 'gift-card'
                          ? 'Card Amount'
                          : 'Concession Amount'}
                      </div>
                      <div
                        className={`flex rounded-xl shadow-sm border-1 items-center ${
                          amountFocus ? 'border-[#7384F5]' : ''
                        }`}
                      >
                        <div className="bg-[#FAFAFA] rounded-l-xl border-r-1 px-4 py-[10px] w-[54px] text-base leading-6 font-normal text-center">
                          $
                        </div>
                        <div className="px-4 py-3">
                          <input
                            type="number"
                            className={`w-[97px] ${
                              specialAmount ? '' : 'text-[#A1A1AA]}'
                            }`}
                            onFocus={() => {
                              setAmountFocus(true);
                            }}
                            onBlur={() => {
                              setAmountFocus(false);
                            }}
                            placeholder="0"
                            value={specialAmount}
                            onChange={(e) => {
                              setSpecialAmount(e.target.value);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )
              )}
              <div className="grid grid-cols-2 bg-white rounded-xl shadow-sm gap-5 p-5 border-1">
                <div className="flex items-center gap-2">
                  <div>
                    <img
                      className="object-cover rounded m-auto"
                      src={
                        selectedPlans.length === 0
                          ? '/../../assets/images/unselected.png'
                          : '/../../assets/images/selected.png'
                      }
                      alt=""
                    />
                  </div>
                  <div className="text-lg leading-7 font-semibold">
                    Select Floor Plans This Special Applies To
                  </div>
                </div>
                <div>
                  <Select
                    variant="bordered"
                    placeholder={
                      <div className="flex gap-1">
                        {allFloorplanChecked
                          ? 'All Floor Plans'
                          : selectedPlans.map((item, index) => (
                              <Chip
                                key={index}
                                onClose={() => {
                                  let results = selectedPlans.filter(
                                    (plan) => plan != item,
                                  );
                                  setAllFloorplanChecked(
                                    results.length == floorPlans.length,
                                  );
                                  setSelectedPlans(results);
                                }}
                                variant="bordered"
                              >
                                {item}
                              </Chip>
                            ))}
                      </div>
                    }
                    color="secondary"
                    listboxProps={{
                      itemClasses: {
                        base: [
                          'data-[hover=true]:bg-white',
                          'data-[selectable=true]:focus:bg-white',
                        ],
                      },
                    }}
                  >
                    <SelectItem
                      key="argentina"
                      textValue="a"
                      isReadOnly
                      hideSelectedIcon
                      className="hover:bg-white"
                    >
                      <div className="border-b-1 mb-2 pb-2">
                        <Checkbox
                          value="All Floor Plans"
                          color="secondary"
                          isSelected={allFloorplanChecked}
                          onValueChange={setAllFloorplanChecked}
                          onClick={() => {
                            !allFloorplanChecked
                              ? setSelectedPlans(floorPlans)
                              : setSelectedPlans([]);
                          }}
                        >
                          All Floor Plans
                        </Checkbox>
                      </div>
                      <CheckboxGroup
                        value={selectedPlans}
                        onValueChange={(e) => {
                          setSelectedPlans(e);
                          setAllFloorplanChecked(e.length == floorPlans.length);
                        }}
                      >
                        {floorPlans.map((item) => (
                          <Checkbox color="default" value={item}>
                            {item}
                          </Checkbox>
                        ))}
                      </CheckboxGroup>
                    </SelectItem>
                  </Select>
                </div>
              </div>
            </div>
          ) : (
            <div className="grid gap-y-6 max-h-[568px] overflow-y-auto">
              <div className="text-3xl leading-9 font-semibold">
                Approve & Publish
              </div>
              {specialChannel.includes('ads') && (
                <div className="bg-white rounded-xl shadow-sm border-1">
                  <div className="flex gap-2.5 border-b-1 pb-5 items-center p-5">
                    <div className="flex w-10 h-10 border rounded-lg items-center">
                      <img
                        className="w-4 h-5 object-cover rounded m-auto"
                        src="/../../assets/images/GAds.png"
                        alt=""
                      />
                    </div>
                    <div className="text-lg leading-7 font-semibold">
                      Google Ads
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-2 items-center min-w-[380px]">
                    <div className="grid border-r-1 px-5 py-6 gap-y-5">
                      <div className="grid gap-y-1 items-center">
                        <div className="text-sm leading-5 font-medium">
                          Ad Text
                        </div>
                        <input
                          type="text"
                          placeholder="Text"
                          value={adText}
                          onChange={(e) => {
                            if (e.target.value.length <= 30)
                              setAdText(e.target.value);
                          }}
                          onFocus={() => setIsAdTextFocused(true)}
                          onBlur={() => setIsAdTextFocused(false)}
                          className="p-[14px] border-1 shadow-sm text-gray-900 text-base rounded-lg focus:ring-[#7384F5] focus:border-[#7384F5] block w-full"
                        />
                        <div className="flex justify-end text-tiny text-[#71717A]">
                          {adText.length}/30
                        </div>
                      </div>
                      <div className="border-b-1"></div>
                      <div className="grid gap-y-1 items-center">
                        <div className="text-sm leading-5 font-medium">
                          Promotion Extension
                        </div>
                        <div className="flex gap-4 justify-between">
                          <div className="grow">
                            <Select
                              listboxProps={{
                                itemClasses: {
                                  base: [
                                    'data-[hover=true]:bg-[#FAFAFA]',
                                    'data-[selectable=true]:focus:bg-[#FAFAFA]',
                                  ],
                                },
                              }}
                              radius="sm"
                              color="secondary"
                              variant="bordered"
                              aria-label="a"
                              placeholder="None"
                              selectedKeys={[promotionExtensionType]}
                              onChange={(e) => {
                                setPromotionExtensionType(e.target.value);
                              }}
                            >
                              <SelectItem
                                key="MD"
                                textValue="Monetary discount"
                              >
                                <div className="text-medium">
                                  Monetary discount
                                </div>
                              </SelectItem>
                              <SelectItem key="PD" textValue="Percent discount">
                                <div className="text-medium">
                                  Percent discount
                                </div>
                              </SelectItem>
                              <SelectItem
                                key="UMD"
                                textValue="Up to monetary discount"
                              >
                                <div className="text-medium">
                                  Up to monetary discount
                                </div>
                              </SelectItem>
                              <SelectItem
                                key="UPD"
                                textValue="Up to percent discount"
                              >
                                <div className="text-medium">
                                  Up to percent discount
                                </div>
                              </SelectItem>
                            </Select>
                          </div>
                          <div
                            className={`flex border-1 shadow-sm rounded-lg items-center ${
                              promotionExtensionFocus && 'border-[#7384F5]'
                            }`}
                          >
                            <input
                              type="number"
                              placeholder="0"
                              value={promotionExtension}
                              onChange={(e) => {
                                setPromotionExtension(e.target.value);
                              }}
                              onFocus={() => {
                                setPromotionExtensionFocus(true);
                              }}
                              onBlur={() => {
                                setPromotionExtensionFocus(false);
                              }}
                              className="mx-4 my-3 w-[46px] text-gray-900 text-base"
                            />
                            <div className="bg-[#FAFAFA] px-4 py-[14px] rounded-r-lg border-l-1">
                              {promotionExtensionType !== '' &&
                              (promotionExtensionType === 'MD' ||
                                promotionExtensionType === 'UMD')
                                ? '$'
                                : '%'}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="grid gap-y-1 items-center">
                        <div className="text-sm leading-5 font-medium">
                          Item
                        </div>
                        <input
                          type="text"
                          placeholder="Text"
                          value={itemText}
                          onChange={(e) => {
                            if (e.target.value.length <= 20)
                              setItemText(e.target.value);
                          }}
                          className="p-[14px] border-1 shadow-sm text-gray-900 text-base rounded-lg focus:ring-[#7384F5] focus:border-[#7384F5] block w-full"
                        />
                        <div className="flex justify-end text-tiny text-[#71717A]">
                          {itemText.length}/20
                        </div>
                      </div>
                      <div className="grid gap-y-1 items-center">
                        <div className="text-sm leading-5 font-medium">
                          Destination URL
                        </div>
                        <div
                          className={`flex border-1 shadow-sm rounded-lg items-center ${
                            gAdURLFocused && 'border-[#7384F5]'
                          }`}
                        >
                          <div className="bg-[#FAFAFA] p-[14px] rounded-l-lg border-r-1">
                            https://
                          </div>
                          <input
                            type="text"
                            placeholder=""
                            value={destinationURL}
                            onChange={(e) => {
                              setDestinationURL(e.target.value);
                            }}
                            onFocus={() => {
                              setGAdURLFocused(true);
                            }}
                            onBlur={() => {
                              setGAdURLFocused(false);
                            }}
                            className="px-4 py-3 text-gray-900 text-base w-full rounded-lg"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bg-[#FAFAFA] h-full flex items-center p-10 rounded-r-xl">
                      {!adText && !isAdTextFocused ? (
                        <div className="grid w-full gap-3">
                          <img
                            src="/../../assets/images/promotion-preview.png"
                            alt=""
                          ></img>
                          <div className="text-center text-[#A1A1AA] text-sm leading-5 font-medium">
                            See your preview here
                          </div>
                        </div>
                      ) : (
                        <div className="w-full p-5 bg-white mx-auto border-1 rounded-lg">
                          <div className="grid gap-1">
                            <div className="text-xs leading-4 font-bold">
                              Sponsored
                            </div>
                            <div className="flex items-center gap-2">
                              <div className="p-[6px] rounded-full bg-[#EAEAEA]">
                                {svgIcons.vector}
                              </div>
                              <div className="text-xs leading-4 font-normal">
                                {destinationURL}/
                              </div>
                            </div>
                            <div className="mt-3 text-base text-wrap break-words leading-6 font-medium lg:w-[350px]">
                              {adText.length ? adText : 'Ad  Text'}
                            </div>
                            {promotionExtensionType !== '' && (
                              <div className="flex gap-2 p-3 mt-1 bg-[#F4F4F5] rounded-lg break-words">
                                {svgIcons.tag}
                                {promotionExtensionType === 'PD' &&
                                  promotionExtension !== 0 && (
                                    <div className="text-wrap lg:w-[302px]">{`${promotionExtension}% off ${itemText}`}</div>
                                  )}
                                {promotionExtensionType === 'UPD' &&
                                  promotionExtension !== 0 && (
                                    <div className="text-wrap lg:w-[302px]">{`Up to ${promotionExtension}% off ${itemText}`}</div>
                                  )}
                                {promotionExtensionType === 'MD' &&
                                  promotionExtension !== 0 && (
                                    <div className="text-wrap lg:w-[302px]">{`$${promotionExtension} off ${itemText}`}</div>
                                  )}
                                {promotionExtensionType === 'UMD' &&
                                  promotionExtension !== 0 && (
                                    <div className="text-wrap lg:w-[302px]">{`Up to $${promotionExtension} off ${itemText}`}</div>
                                  )}
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              )}
              {specialChannel.length === 2 && (
                <div className="border-t-1"></div>
              )}
              {specialChannel.includes('gmb') && (
                <div className="bg-white rounded-xl shadow-sm border-1">
                  <div className="flex gap-2.5 border-b-1 pb-5 items-center p-5">
                    <div className="flex w-10 h-10 border rounded-lg items-center">
                      <img
                        className="w-4 h-5 object-cover rounded m-auto"
                        src="/../../assets/images/GMB.png"
                        alt=""
                      />
                    </div>
                    <div className="text-lg leading-7 font-semibold">
                      Google Business Profile
                    </div>
                  </div>
                  <div className="grid lg:grid-cols-2 items-center min-w-[380px]">
                    <div className="grid gap-y-6 px-5 py-6 h-[520px] overflow-y-auto">
                      <div className="grid gap-y-5">
                        <div className="grid gap-y-3 items-center">
                          {/* Gbp Photo Upload Input */}
                          <div className="text-sm leading-5 font-medium">
                            Upload Image
                          </div>
                          {imageName ? (
                            <div className="rounded-lg border-1 px-[14px] py-3 flex justify-between items-center">
                              <div className="flex gap-3 items-center grow">
                                <div>
                                  <img
                                    className="w-10 h-10 rounded-lg"
                                    src={uploadImage}
                                    alt=""
                                  />
                                </div>
                                <div className="grid">
                                  <div className="text-sm break-all leading-5 font-medium lg:w-[160px]">
                                    {imageName}
                                  </div>
                                  {imageSize !== 0 && (
                                    <div className="text-tiny text-gray-700">
                                      {fileSizeFormatter(imageSize)}
                                    </div>
                                  )}
                                </div>
                              </div>
                              {loadingProgress === 100 || imageSize === 0 ? (
                                <div
                                  className="cursor-pointer"
                                  onClick={() => {
                                    // Delete Gbp Image
                                    setUploadImage(null);
                                    setGbpPhotoFile(null);
                                    setImageName('');
                                    setImageSize(0);
                                    setClearGbpPostFile(true);
                                    setLoadingProgress(0);
                                  }}
                                >
                                  <img
                                    src="/../../assets/images/trash.svg"
                                    alt=""
                                  />
                                </div>
                              ) : (
                                <div className="flex gap-4">
                                  <div className="flex gap-2 items-center">
                                    <div className="lg:w-[106px]">
                                      <Progress
                                        size="sm"
                                        aria-label="loading"
                                        color="success"
                                        value={loadingProgress}
                                      />
                                    </div>
                                    <div className="text-gray-700">{`${loadingProgress}%`}</div>
                                  </div>
                                  <div
                                    className="cursor-pointer w-5 y-5"
                                    onClick={() => {
                                      // Cancel Gbp Photo
                                      setUploadImage(null);
                                      setImageName('');
                                      setImageSize(0);
                                      setLoadingProgress(0);
                                    }}
                                  >
                                    <img
                                      src="/../../assets/images/cancel.png"
                                      alt=""
                                    />
                                  </div>
                                </div>
                              )}
                            </div>
                          ) : (
                            <div className="border-1 border-dashed rounded-lg relative bg-[#FAFAFA]">
                              <input
                                type="file"
                                className="cursor-pointer relative block opacity-0 w-full h-20 z-50"
                                accept=".svg,.png,.jpg,.jpeg,.gif"
                                onChange={(event) => {
                                  // On Click Upload Gbp Image
                                  const file = event.target.files[0];
                                  const filename_check =
                                    file.name.toLowerCase();
                                  if (
                                    !filename_check.endsWith('.svg') &&
                                    !filename_check.endsWith('.png') &&
                                    !filename_check.endsWith('.jpg') &&
                                    !filename_check.endsWith('.jpeg') &&
                                    !filename_check.endsWith('.gif')
                                  )
                                    return;
                                  if (file) {
                                    setImageName(file.name);
                                    setImageSize(file.size);
                                    setGbpPhotoFile(file);
                                    setClearGbpPostFile(false);
                                  }
                                  const reader = new FileReader();

                                  reader.onload = (e) => {
                                    // On Complete Gbp Image
                                    const src = e.target.result;
                                    setUploadImage(src);
                                    setLoadingProgress(100);
                                  };

                                  reader.onloadstart = () => {
                                    setLoadingProgress(0); // Reset progress when loading starts
                                  };

                                  reader.onprogress = (event) => {
                                    if (event.lengthComputable) {
                                      const progress =
                                        (event.loaded / event.total) * 100;
                                      setLoadingProgress(Math.round(progress)); // Update progress
                                    }
                                  };

                                  if (file) {
                                    reader.readAsDataURL(file);
                                  }
                                }}
                              ></input>
                              <div className="grid gap-y-2 text-center items-center absolute top-5 right-0 left-0 m-auto">
                                <div className="text-xs leading-4 font-normal">
                                  <span className="font-semibold">
                                    Click to upload{' '}
                                  </span>
                                  or drag and drop
                                </div>
                                <div className="text-xs leading-4 font-normal">
                                  SVG, PNG, JPG or GIF (MAX. 800x400px)
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="grid gap-y-1 items-center">
                          <div className="text-sm leading-5 font-medium">
                            Post Title
                          </div>
                          <input
                            type="text"
                            placeholder="Text"
                            value={postTitle}
                            onChange={(e) => {
                              if (e.target.value.length <= 58)
                                setPostTitle(e.target.value);
                            }}
                            onFocus={() => setIsPostTitleFocused(true)}
                            onBlur={() => setIsPostTitleFocused(false)}
                            className="p-[14px] border-1 shadow-sm text-gray-900 text-base rounded-lg focus:ring-[#7384F5] focus:border-[#7384F5] block w-full"
                          />
                          <div className="flex justify-end text-tiny text-[#71717A]">
                            {postTitle.length}/58
                          </div>
                        </div>
                        <div className="grid gap-y-1 items-center">
                          <div className="text-sm leading-5 font-medium">
                            Destination URL
                          </div>
                          <div
                            className={`flex border-1 shadow-sm rounded-lg items-center ${
                              gBpURLFocused && 'border-[#7384F5]'
                            }`}
                          >
                            <div className="bg-[#FAFAFA] p-[14px] rounded-l-lg border-r-1">
                              https://
                            </div>
                            <input
                              type="text"
                              placeholder=""
                              value={destinationURL}
                              onChange={(e) => {
                                setDestinationURL(e.target.value);
                              }}
                              onFocus={() => {
                                setGBpURLFocused(true);
                              }}
                              onBlur={() => {
                                setGBpURLFocused(false);
                              }}
                              className="px-4 py-3 text-gray-900 text-base w-full rounded-lg"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="border-t-1"></div>
                      <div className="grid gap-y-3">
                        <div className="flex justify-between items-center">
                          <div className="flex items-center gap-2">
                            <div className="text-sm leading-5 font-medium">
                              Add more details
                            </div>
                            <div className="text-tiny text-[#6B7280]">
                              (Optional)
                            </div>
                          </div>
                          <div>
                            <Switch
                              color="secondary"
                              isSelected={moreDetailSwitch}
                              onValueChange={(val) => {
                                setMoreDetailSwitch(val);
                              }}
                            />
                          </div>
                        </div>
                        {moreDetailSwitch && (
                          <div>
                            <textarea
                              placeholder="Write text here ..."
                              draggable={false}
                              value={details}
                              onChange={(e) => {
                                setDetails(e.target.value);
                              }}
                              style={{ resize: 'none' }}
                              className="p-[14px] border-1 shadow-sm text-gray-900 text-base rounded-lg focus:ring-[#7384F5] focus:border-[#7384F5] block w-full h-[140px]"
                            />
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="bg-[#FAFAFA] border-l-1 h-full flex items-center p-10 rounded-r-xl">
                      <div className="grid w-full gap-3">
                        <div className="grid border-1 rounded-lg">
                          <div className="flex justify-between p-5 bg-white rounded-t-lg items-center">
                            {uploadImage || postTitle || isPostTitleFocused ? (
                              <div className="flex gap-3 grow">
                                <div>
                                  <img
                                    src="/../../assets/images/Avatar.png"
                                    alt=""
                                  ></img>
                                </div>
                                <div className="grid">
                                  <div>{property.name}</div>
                                  <div className="text-sm leading-5 font-normal text-[#636567]">
                                    {`${formattedDate(startDate)}`}
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <img
                                  src="/../../assets/images/GBP-preview-header.png"
                                  alt=""
                                ></img>
                              </div>
                            )}
                            <div>
                              <img
                                src="/../../assets/images/dots-vertical.png"
                                alt=""
                              ></img>
                            </div>
                          </div>
                          <div className="lg:w-[390px] lg:h-[279px] flex items-center justify-center border-y-1">
                            {uploadImage ? (
                              <img
                                src={uploadImage}
                                alt=""
                                className="max-w-full max-h-full"
                              ></img>
                            ) : (
                              <img
                                src="/../../assets/images/scribble.variable.png"
                                alt=""
                                className="max-w-full max-h-full"
                              ></img>
                            )}
                          </div>
                          <div className="p-5 bg-white rounded-b-lg">
                            {uploadImage || postTitle || isPostTitleFocused ? (
                              <div className="grid gap-y-2">
                                <div className="grid gap-y-2">
                                  <div className="flex justify-between items-center gap-2">
                                    <div
                                      // style={{ fontFamily: 'Saans TRIAL' }}
                                      className="text-2xl text-wrap break-words leading-8 font-medium grow lg:w-[318px]"
                                    >
                                      {postTitle === ''
                                        ? 'Post title'
                                        : postTitle}
                                    </div>
                                    <div className="w-6 y-6">
                                      <img
                                        src="/../../assets/images/GBP-tag.png"
                                        alt=""
                                      ></img>
                                    </div>
                                  </div>
                                  <div className="text-base leading-6 font-normal">
                                    {`${
                                      formattedDate(startDate).split(',')[0]
                                    } - ${
                                      formattedDate(endDate).split(',')[0]
                                    }`}
                                  </div>
                                </div>
                                <div className="flex justify-end">
                                  <img
                                    src="/../../assets/images/GBP-frame.png"
                                    alt=""
                                  ></img>
                                </div>
                              </div>
                            ) : (
                              <img
                                src="/../../assets/images/GBP-preview-footer.png"
                                alt=""
                              ></img>
                            )}
                          </div>
                        </div>
                        {!(uploadImage || postTitle || isPostTitleFocused) && (
                          <div className="text-center text-[#A1A1AA] text-sm leading-5 font-medium">
                            See your preview here
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )
        }
      />
      <EditAssigneeModal
        show={assigneeModal == 'concession'}
        onHide={() => setAssigneeModal(false)}
        onClose={() => setAssigneeModal(false)}
        contentClassName="p-10 rounded-2xl"
        content={
          <div className="font-saans-trial">
            <div className="text-2xl leading-7 font-semibold">
              You can only have one active concession per channel
            </div>
            <div className="text-lg leading-7 mt-2">
              You can only have 1 active concession per channel at a time.
              Please pause or deactivate a concession before adding a new one.
            </div>
            <div className="flex mt-8">
              <Button
                variant="solid"
                radius="sm"
                onClick={() => {
                  setAssigneeModal(false);
                }}
                className="w-full h-[52px] bg-[#242424] text-white text-base leading-6 font-medium"
              >
                Okay
              </Button>
            </div>
          </div>
        }
      />
      <ConfirmDeletionModal
        show={modal}
        onHide={() => setModal(false)}
        onClose={() => setModal(false)}
        title="Success"
        txt="The user was successfully added"
        modalConfirmBtn="Back to User Management"
        contentClassName="px-10 py-12 rounded-2xl"
        content={
          <div className="font-saans-trial">
            <img src="/assets/images/confirm.png" width={80} alt="" />
            <div className="text-3xl leading-9 font-semibold mt-6">
              Confirm deletion
            </div>
            <div className="text-lg leading-7 mt-4">
              Are you sure you want to delete this concession?
            </div>
            <div className="grid grid-cols-2 gap-2 mt-8">
              <button
                onClick={() => setModal(false)}
                className="h-[52px] flair-btn bg-white font-medium rounded-lg border border-#A1A1AA"
              >
                Cancel
              </button>
              <button
                onClick={() => {
                  handleDeleteConcession();
                  setModal(false);
                }}
                className="h-[52px] flair-btn bg-[#EB4261] font-medium text-white rounded-lg"
              >
                Delete
              </button>
            </div>
          </div>
        }
      />
    </>
  );
}
