import AsyncLock from 'async-lock';
import uxDialog from './uxdialog';

const lock = new AsyncLock();

class UxQueue {
  ConfirmKeys = {
    Redirect: 'Redirect',
    Retry: 'Retry',
  };
  async confirm(key, message) {
    const localStorageKey = 'UxQueue.UserDecision.' + key;
    // console.trace('@confirm: start:', key, message)
    const decision = await lock.acquire(localStorageKey, async () => {
      // Inside Mutex
      let prevDecision = localStorage.getItem(localStorageKey);
      if (prevDecision) {
        try {
          prevDecision = JSON.parse(prevDecision);
        } catch {
          prevDecision = null;
        }
      }
      // console.log('@confirm: prevDecision:', key, message, prevDecision)
      const now = Date.now();
      if (prevDecision && Math.abs(now - prevDecision.time) < 60_000) {
        // console.log('using previous decision', prevDecision)
        return prevDecision.decision;
      } else {
        // show confirm, wait for it to close
        // console.log('@confirm: showing confirm input:', key, message)
        const decision = await uxDialog.confirm({ text: message });
        let newDecision = { decision, time: now };
        localStorage.setItem(localStorageKey, JSON.stringify(newDecision));
        return decision;
      }
    });
    // console.log('@confirm: returning decision:', key, message, decision)
    return decision;
  }

  /**
   * Not implemented! Please use .prompt({title, message, ...})
   * showPrompt() only show prompt and don't close prompt automatically.
   * @param {*} request
   */
}

const uxQueue = new UxQueue();

export { UxQueue };
export default uxQueue;
