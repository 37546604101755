import React, { useState } from 'react';
import { ModalCentered } from './ModalCentered';

export default function AcceptBudgetModal(props) {
  const [value, setValue] = useState();
  return (
    <ModalCentered
      {...props}
      dialogClassName="sm:w-[448px]"
      formGroup={
        <>
          <div className="relative">
            <input
              type="number"
              placeholder={props.value}
              onChange={(e) => {
                setValue(e.target.value);
              }}
              style={{
                border: '1px solid #CED3DB',
                borderRadius: '4px',
                background: 'white',
              }}
              className="flair-input px-6 text-left text-xl"
            />
          </div>
          <div className="mt-8 grid gap-4 grid-rows-2 sm:gap-[12px] sm:grid-cols-2 sm:grid-rows-1">
            <button
              className="flair-btn flair-btn-md flair-btn-secondary"
              onClick={props.onClose}
            >
              Back
            </button>
            <button
              className="flair-btn flair-btn-md flair-btn-primary"
              onClick={() => {
                props.handleAccept(value);
              }}
            >
              Confirm
            </button>
          </div>
        </>
      }
    />
  );
}
