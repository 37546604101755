import Invitation from './Invitation';

export default function Settings() {
  return (
    <div className="pt-8 xs:pt-10">
      <h5 className="px-6 xs:px-0">Settings</h5>
      <Invitation />
    </div>
  );
}
