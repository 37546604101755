import React from 'react';
import Helmet from 'react-helmet';
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import moment from 'moment';
import { DateTime } from 'luxon';
import $ from 'jquery';
import { formatPeriod } from '../../utils/times';

const currentYear = new Date().getFullYear();
const fromMonth = new Date(2019, 0);
const toMonth = new Date(currentYear, 11);
const todayDate = moment();
const yesterdayDate = moment().subtract(1, 'days');
const last7Date = moment().subtract(6, 'days');
const last14Date = moment().subtract(13, 'days');
const last30Date = moment().subtract(29, 'days');
const lastWeekFirstDate = moment().subtract(1, 'week').startOf('week');
const lastWeekLastDate = moment().subtract(1, 'week').endOf('week');
const lastMonthFirstDate = moment().subtract(1, 'month').startOf('month');
const lastMonthLastDate = moment().subtract(1, 'month').endOf('month');

const todayDateStr = todayDate._d.toLocaleDateString();
const yesterdayDateStr = yesterdayDate._d.toLocaleDateString();
const last7DateStr = last7Date._d.toLocaleDateString();
const last14DateStr = last14Date._d.toLocaleDateString();
const last30DateStr = last30Date._d.toLocaleDateString();
const lastWeekFirstDateStr = lastWeekFirstDate._d.toLocaleDateString();
const lastWeekLastDateStr = lastWeekLastDate._d.toLocaleDateString();
const lastMonthFirstDateStr = lastMonthFirstDate._d.toLocaleDateString();
const lastMonthLastDateStr = lastMonthLastDate._d.toLocaleDateString();
let _fromStr = '';
let _toStr = '';
let _fromRightStr = '';
let _toRightStr = '';
let screenWidth = window.innerWidth;

function YearMonthForm({ date, localeUtils, onChange }) {
  //month, year dropdown
  const months = localeUtils.getMonths();
  const years = [];
  for (let i = fromMonth.getFullYear(); i <= toMonth.getFullYear(); i += 1) {
    years.push(i);
  }

  const handleChange = (e) => {
    const { year, month } = e.target.form;
    onChange(new Date(year.value, month.value));
  };

  return (
    <form className="DayPicker-Caption">
      <select
        name="month"
        onChange={handleChange}
        value={date.getMonth()}
        style={{ marginRight: '28px', height: '24px', fontWeight: 'bold' }}
      >
        {months.map((month, i) => (
          <option key={month} value={i}>
            {month}
          </option>
        ))}
      </select>
      <select
        name="year"
        onChange={handleChange}
        value={date.getFullYear()}
        style={{ height: '24px', fontWeight: 'bold' }}
      >
        {years.map((year) => (
          <option key={year} value={year}>
            {year}
          </option>
        ))}
      </select>
    </form>
  );
}
export default class DayPickerBody extends React.Component {
  constructor(props) {
    super(props);
    if (props.onClickApply) {
      this.onClickApply = props.onClickApply;
    }
    this.handleDayClickLeft = this.handleDayClickLeft.bind(this);
    this.handleDayClickRight = this.handleDayClickRight.bind(this);
    this.handleDayCursorEnterLeft = this.handleDayCursorEnterLeft.bind(this);
    this.handleDayCursorEnterRight = this.handleDayCursorEnterRight.bind(this);
    this.handleYearMonthChangeLeft = this.handleYearMonthChangeLeft.bind(this);
    this.handleYearMonthChangeRight =
      this.handleYearMonthChangeRight.bind(this);
    this.setDateRange = this.setDateRange.bind(this); //added by arasen
    this.handleClickCompare = this.handleClickCompare.bind(this);
    this.invertNumOfMons = this.invertNumOfMons.bind(this);
    this.updateHighlightedRange = this.updateHighlightedRange.bind(this);
    this.handleClickClear = this.handleClickClear.bind(this);
    this.patchColoringDays = this.patchColoringDays.bind(this);
    this.patchColoringDaysMain = this.patchColoringDaysMain.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);

    this.state = {
      // from: last7Date._d,
      // to: todayDate._d,
      // enteredToLeft: todayDate._d,
      from: null,
      to: null,
      enteredToLeft: null,
      fromRight: null,
      toRight: null,
      enteredToRight: null,
      monthLeft: null,
      monthRight: null,
      dayPickerRightHide: true,
      periodDateLeftShow: true,
      periodDateRightShow: true,
      periodDateLeft: formatPeriod(
        DateTime.fromJSDate(last14Date._d),
        DateTime.fromJSDate(todayDate._d),
      ),
      periodDateRight: null,
      numOfMons: 2,
      isCloseDropdown: false,
      isToday: false,
      isYesterday: false,
      isLast7: false,
      isLast14: false,
      isLast30: false,
      isLastWeek: false,
      isLastMonth: false,
      isCustom: false,
      focusOnLeft: true,
      focusOnRight: false,
      isDesktopWidth: true,
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.updateWindowDimensions);
    if (screenWidth < 996) {
      this.setState({ numOfMons: 1, isDesktopWidth: false });
    } else {
      this.setState({ numOfMons: 2, isDesktopWidth: true });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  componentDidUpdate() {
    this.patchColoringDays();
  }

  updateWindowDimensions() {
    screenWidth = window.innerWidth;
    if (screenWidth < 996) {
      this.setState({ numOfMons: 1, isDesktopWidth: false });
    } else if (!this.state.dayPickerRightHide) {
      this.setState({ numOfMons: 1, isDesktopWidth: true });
    } else {
      this.setState({ numOfMons: 2, isDesktopWidth: true });
    }
  }

  handleYearMonthChangeLeft(month) {
    this.setState({ monthLeft: month });
  }

  handleYearMonthChangeRight(month) {
    this.setState({ monthRight: month });
  }

  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    // debugger
    return !from || isBeforeFirstDay || isRangeSelected;
  }

  handleDayCursorEnterLeft(dayLeft) {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, dayLeft)) {
      this.setState({
        enteredToLeft: dayLeft,
      });
    }
  }

  handleDayCursorEnterRight(dayRight) {
    const { fromRight, toRight } = this.state;
    if (!this.isSelectingFirstDay(fromRight, toRight, dayRight)) {
      this.setState({
        enteredToRight: dayRight,
      });
    }
  }

  invertNumOfMons(num) {
    if (num === 2) return 1;
    if (window.innerWidth < 996) return 1;
    else return 2;
  }
  patchColoringDays() {
    // Wait for the automatical change of month to take place.
    setTimeout(this.patchColoringDaysMain, 1);
  }
  patchColoringDaysMain() {
    // patch to DayPicker, add .DayPicker-Day--selected class to the day tiles between start day and end day
    const clearColoring = () => {
      let prevTiles = $(
        '.SelectableRight .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end)',
      );
      prevTiles.removeClass('DayPicker-Day--selected');
    };
    const getTileSelectorForDate = (date) => {
      const monthNames = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
      ];
      const dd = (num) => {
        if (num <= 9) return '0' + num;
        else return '' + num;
      };
      return `.SelectableRight div[aria-label~='${
        monthNames[date.getMonth()]
      }'][aria-label~='${dd(
        date.getDate(),
      )}'][aria-label~='${date.getFullYear()}']`;
    };
    const getNextDay = (current) => {
      const dtCurrent = DateTime.fromJSDate(current);
      const dtNextDay = dtCurrent.plus({ days: 1 });
      return dtNextDay.toJSDate();
    };
    const updateColoring = (from, to) => {
      let day = getNextDay(from);
      let tileSelectors = [];
      while (day < to) {
        tileSelectors.push(getTileSelectorForDate(day));
        day = getNextDay(day);
      }
      $(tileSelectors.join(',')).addClass('DayPicker-Day--selected');
    };
    clearColoring();
    if (this.state.fromRight && this.state.toRight) {
      updateColoring(this.state.fromRight, this.state.toRight);
      // setTimeout(updateColoring(this.state.fromRight, this.state.toRight), 1)
    }
  }

  handleClickCompare(e) {
    if (e.target.checked)
      this.setState({
        isFixedRange: false,
        numOfMons: this.invertNumOfMons(this.state.numOfMons),
        // fromRight: null,
        // toRight: null,
        // enteredToRight: null,
        dayPickerRightHide: false,
        periodDateLeftShow: false,
        isToday: false,
        isYesterday: false,
        isLast7: false,
        isLast14: false,
        isLast30: false,
        isLastWeek: false,
        isLastMonth: false,
        isCustom: false,
        focusOnLeft: false,
        focusOnRight: true,
      });
    else
      this.setState({
        isFixedRange: false,
        numOfMons: this.invertNumOfMons(this.state.numOfMons),
        dayPickerRightHide: true,
        periodDateLeftShow: true,
        isToday: false,
        isYesterday: false,
        isLast7: false,
        isLast14: false,
        isLast30: false,
        isLastWeek: false,
        isLastMonth: false,
        isCustom: false,
        focusOnLeft: true,
        focusOnRight: false,
        fromRight: false,
      });
  }

  handleDayClickLeft(day) {
    const { from, to } = this.state;
    this.setState({
      isFixedRange: false,
      periodDateLeftShow: false,
      isCustom: true,
      focusOnLeft: true,
      focusOnRight: false,
    });
    if (this.isSelectingFirstDay(from, to, day)) {
      _fromStr = day.toLocaleDateString();
      this.setState({
        from: day,
        to: null,
        enteredToLeft: null,
        isFixedRange: false,
        isToday: _fromStr === todayDateStr,
        isYesterday: _fromStr === yesterdayDateStr,
        isLast7: false,
        isLast14: false,
        isLast30: false,
        isLastWeek: false,
        isLastMonth: false,
        periodDateLeft: DateTime.fromJSDate(day).toFormat('LLL d, y'),
      });
    } else {
      _toStr = day.toLocaleDateString();
      this.setState({
        to: day,
        enteredToLeft: day,
        isToday: false,
        isYesterday: false,
        isLast7: _fromStr === last7DateStr && _toStr === todayDateStr,
        isLast14: _fromStr === last14DateStr && _toStr === todayDateStr,
        isLast30: _fromStr === last30DateStr && _toStr === todayDateStr,
        isLastWeek:
          _fromStr === lastWeekFirstDateStr && _toStr === lastWeekLastDateStr,
        isLastMonth:
          _fromStr === lastMonthFirstDateStr && _toStr === lastMonthLastDateStr,
        periodDateLeft: formatPeriod(
          DateTime.fromJSDate(this.state.from),
          DateTime.fromJSDate(day),
        ),
      });
    }
  }

  handleDayClickRight(dayRight) {
    const { fromRight, toRight } = this.state;
    this.setState({
      isFixedRange: false,
      periodDateRightShow: false,
      isCustom: true,
      focusOnLeft: false,
      focusOnRight: true,
    });
    if (this.isSelectingFirstDay(fromRight, toRight, dayRight)) {
      _fromRightStr = dayRight.toLocaleDateString();
      this.setState({
        fromRight: dayRight,
        toRight: null,
        enteredToRight: null,
        isFixedRange: false,
        isToday: _fromRightStr === todayDateStr,
        isYesterday: _fromRightStr === yesterdayDateStr,
        isLast7: false,
        isLast14: false,
        isLast30: false,
        isLastWeek: false,
        isLastMonth: false,
        periodDateRight: DateTime.fromJSDate(dayRight).toFormat('LLL d, y'),
      });
    } else {
      _toRightStr = dayRight.toLocaleDateString();
      this.setState({
        toRight: dayRight,
        enteredToRight: dayRight,
        isToday: false,
        isYesterday: false,
        isLast7: _fromRightStr === last7DateStr && _toRightStr === todayDateStr,
        isLast14:
          _fromRightStr === last14DateStr && _toRightStr === todayDateStr,
        isLast30:
          _fromRightStr === last30DateStr && _toRightStr === todayDateStr,
        isLastWeek:
          _fromRightStr === lastWeekFirstDateStr &&
          _toRightStr === lastWeekLastDateStr,
        isLastMonth:
          _fromRightStr === lastMonthFirstDateStr &&
          _toRightStr === lastMonthLastDateStr,
        periodDateRight: formatPeriod(
          DateTime.fromJSDate(this.state.fromRight),
          DateTime.fromJSDate(dayRight),
        ),
      });
    }
  }

  updateHighlightedRange() {
    // selected range of focused picker
    let from, to;
    // if (!this.state.focusOnSecond) {
    //   from = this.state.from
    //   to = this.state.to
    // }
    // else {
    // from = this.picker2.current.state.from
    // to = this.picker2.current.state.to
    // }
    let rangeHighlightOptions = [
      'isToday',
      'isYesterday',
      'isLast7',
      'isLast14',
      'isLast30',
      'isLastWeek',
      'isLastMonth',
    ];
    for (const option of rangeHighlightOptions) {
      const dictval = {};
      dictval[option] = false;
      this.setState(dictval);
    }
    // update state
    if (from) {
      from = moment(from)._d;
    }
    if (to) {
      to = moment(to)._d;
    }
    if (from === todayDate._d) {
      this.setState({ isToday: true });
    } else if (from === yesterdayDate._d) {
      this.setState({ isYesterday: true });
    } else if (from === last7Date._d && to === todayDate._d) {
      this.setState({ isLast7: true });
    } else if (from === last14Date._d && to === todayDate._d) {
      this.setState({ isLast14: true });
    } else if (from === last30Date._d && to === todayDate._d) {
      this.setState({ isLast30: true });
    } else if (from === lastWeekFirstDate._d && to === lastWeekLastDate._d) {
      this.setState({ isLastWeek: true });
    } else if (from === lastMonthFirstDate._d && to === lastMonthLastDate._d) {
      this.setState({ isLastMonth: true });
    } else {
      this.setState({ isCustom: true });
    }
  }

  setDateRange(e) {
    this.setState({ isFixedRange: true });
    switch (e.target.value) {
      case 'Today':
        this.setState({
          isToday: true,
          isYesterday: false,
          isLast7: false,
          isLast14: false,
          isLast30: false,
          isLastWeek: false,
          isLastMonth: false,
        });
        if (this.state.focusOnLeft) {
          this.setState({
            from: todayDate._d,
            to: todayDate._d,
            enteredToLeft: todayDate._d,
            periodDateLeftShow: true,
            periodDateLeft: DateTime.fromJSDate(todayDate._d).toFormat(
              'LLL d, y',
            ),
            monthLeft: todayDate._d,
            monthRight: todayDate._d,
          });
        }
        if (this.state.focusOnRight) {
          this.setState({
            fromRight: todayDate._d,
            toRight: todayDate._d,
            enteredToRight: todayDate._d,
            periodDateRightShow: true,
            periodDateRight: DateTime.fromJSDate(todayDate._d).toFormat(
              'LLL d, y',
            ),
            monthRLeft: todayDate._d,
            monthRight: todayDate._d,
          });
        }
        if (this.state.dayPickerRightHide)
          this.setState({ monthLeft: lastMonthFirstDate._d });
        break;
      case 'Yesterday':
        this.setState({
          isToday: false,
          isYesterday: true,
          isLast7: false,
          isLast14: false,
          isLast30: false,
          isLastWeek: false,
          isLastMonth: false,
        });
        if (this.state.focusOnLeft) {
          this.setState({
            from: yesterdayDate._d,
            to: yesterdayDate._d,
            enteredToLeft: yesterdayDate._d,
            periodDateLeftShow: true,
            periodDateLeft: DateTime.fromJSDate(yesterdayDate._d).toFormat(
              'LLL d, y',
            ),
            monthLeft: yesterdayDate._d,
          });
        }
        if (this.state.focusOnRight) {
          this.setState({
            fromRight: yesterdayDate._d,
            toRight: yesterdayDate._d,
            enteredToRight: yesterdayDate._d,
            periodDateRightShow: true,
            periodDateRight: DateTime.fromJSDate(yesterdayDate._d).toFormat(
              'LLL d, y',
            ),
            monthRight: yesterdayDate._d,
          });
        }
        if (this.state.dayPickerRightHide)
          this.setState({ monthLeft: lastMonthFirstDate._d });
        break;
      case 'Last7':
        this.setState({
          isToday: false,
          isYesterday: false,
          isLast7: true,
          isLast14: false,
          isLast30: false,
          isLastWeek: false,
          isLastMonth: false,
        });
        if (this.state.focusOnLeft) {
          this.setState({
            from: last7Date._d,
            to: todayDate._d,
            enteredToLeft: todayDate._d,
            periodDateLeftShow: true,
            periodDateLeft: formatPeriod(
              DateTime.fromJSDate(last7Date._d),
              DateTime.fromJSDate(todayDate._d),
            ),
            monthLeft: last7Date._d,
          });
        }
        if (this.state.focusOnRight) {
          this.setState({
            fromRight: last7Date._d,
            toRight: todayDate._d,
            enteredToRight: todayDate._d,
            periodDateRightShow: true,
            periodDateRight: formatPeriod(
              DateTime.fromJSDate(last7Date._d),
              DateTime.fromJSDate(todayDate._d),
            ),
            monthRight: last7Date._d,
          });
        }
        // if (this.state.dayPickerRightHide)
        //   this.setState({monthLeft: lastMonthFirstDate._d})
        break;
      case 'Last14':
        this.setState({
          isToday: false,
          isYesterday: false,
          isLast7: false,
          isLast14: true,
          isLast30: false,
          isLastWeek: false,
          isLastMonth: false,
        });

        if (this.state.focusOnLeft) {
          this.setState({
            from: last14Date._d,
            to: todayDate._d,
            enteredToLeft: todayDate._d,
            periodDateLeftShow: true,
            periodDateLeft: formatPeriod(
              DateTime.fromJSDate(last14Date._d),
              DateTime.fromJSDate(todayDate._d),
            ),
            monthLeft: last14Date._d,
          });
        }
        if (this.state.focusOnRight) {
          this.setState({
            fromRight: last14Date._d,
            toRight: todayDate._d,
            enteredToRight: todayDate._d,
            periodDateRightShow: true,
            periodDateRight: formatPeriod(
              DateTime.fromJSDate(last14Date._d),
              DateTime.fromJSDate(todayDate._d),
            ),
            monthRight: last14Date._d,
          });
        }
        // if (this.state.dayPickerRightHide)
        //   this.setState({monthLeft: lastMonthFirstDate._d})
        break;
      case 'Last30':
        this.setState({
          isToday: false,
          isYesterday: false,
          isLast7: false,
          isLast14: false,
          isLast30: true,
          isLastWeek: false,
          isLastMonth: false,
        });

        if (this.state.focusOnLeft) {
          this.setState({
            from: last30Date._d,
            to: todayDate._d,
            enteredToLeft: todayDate._d,
            periodDateLeftShow: true,
            periodDateLeft: formatPeriod(
              DateTime.fromJSDate(last30Date._d),
              DateTime.fromJSDate(todayDate._d),
            ),
            monthLeft: last30Date._d,
          });
        }
        if (this.state.focusOnRight) {
          this.setState({
            fromRight: last30Date._d,
            toRight: todayDate._d,
            enteredToRight: todayDate._d,
            periodDateRightShow: true,
            periodDateRight: formatPeriod(
              DateTime.fromJSDate(last30Date._d),
              DateTime.fromJSDate(todayDate._d),
            ),
            monthRight: last30Date._d,
          });
        }
        // if (this.state.dayPickerRightHide)
        //   this.setState({monthLeft: lastMonthFirstDate._d})
        break;
      case 'LastWeek':
        this.setState({
          isToday: false,
          isYesterday: false,
          isLast7: false,
          isLast14: false,
          isLast30: false,
          isLastWeek: true,
          isLastMonth: false,
        });
        if (this.state.focusOnLeft) {
          this.setState({
            from: lastWeekFirstDate._d,
            to: lastWeekLastDate._d,
            enteredToLeft: lastWeekLastDate._d,
            periodDateLeftShow: true,
            periodDateLeft: formatPeriod(
              DateTime.fromJSDate(lastWeekFirstDate._d),
              DateTime.fromJSDate(lastWeekLastDate._d),
            ),
            monthLeft: lastWeekFirstDate._d,
          });
        }
        if (this.state.focusOnRight) {
          this.setState({
            fromRight: lastWeekFirstDate._d,
            toRight: lastWeekLastDate._d,
            enteredToRight: lastWeekLastDate._d,
            periodDateRightShow: true,
            periodDateRight: formatPeriod(
              DateTime.fromJSDate(lastWeekFirstDate._d),
              DateTime.fromJSDate(lastWeekLastDate._d),
            ),
            monthRight: lastWeekFirstDate._d,
          });
        }
        // if (this.state.dayPickerRightHide)
        //   this.setState({monthLeft: lastMonthFirstDate._d})
        break;
      case 'LastMonth':
        this.setState({
          isToday: false,
          isYesterday: false,
          isLast7: false,
          isLast14: false,
          isLast30: false,
          isLastWeek: false,
          isLastMonth: true,
        });
        if (this.state.focusOnLeft) {
          this.setState({
            from: lastMonthFirstDate._d,
            to: lastMonthLastDate._d,
            enteredToLeft: lastMonthLastDate._d,
            periodDateLeftShow: true,
            periodDateLeft: formatPeriod(
              DateTime.fromJSDate(lastMonthFirstDate._d),
              DateTime.fromJSDate(lastMonthLastDate._d),
            ),
            monthLeft: lastMonthFirstDate._d,
          });
        }
        if (this.state.focusOnRight) {
          this.setState({
            fromRight: lastMonthFirstDate._d,
            toRight: lastMonthLastDate._d,
            enteredToRight: lastMonthLastDate._d,
            periodDateRightShow: true,
            periodDateRight: formatPeriod(
              DateTime.fromJSDate(lastMonthFirstDate._d),
              DateTime.fromJSDate(lastMonthLastDate._d),
            ),
            monthRight: lastMonthFirstDate._d,
          });
        }
        // if (this.state.dayPickerRightHide)
        //   this.setState({monthLeft: lastMonthFirstDate._d})
        break;
      default:
        return;
    }
  }

  handleClickClear() {
    this.setState({
      from: null,
      to: null,
      enteredToLeft: null,
      fromRight: '',
      toRight: '',
      enteredToRight: '',
      periodDateLeft: null,
    });
  }

  render() {
    const {
      from,
      to,
      enteredToLeft,
      fromRight,
      toRight,
      enteredToRight,
      dayPickerRightHide,
      periodDateLeftShow,
      numOfMons,
      isToday,
      periodDateLeft,
      isYesterday,
      isLast7,
      isLast14,
      isLast30,
      isLastWeek,
      isLastMonth,
      isCustom,
      focusOnLeft,
      focusOnRight,
      isDesktopWidth,
    } = this.state;
    const modifiersLeft = { start: from, end: enteredToLeft };
    const modifiersRight = { start: fromRight, end: enteredToRight };
    const selectedDaysLeft = [from, { from, to: enteredToLeft }];
    const selectedDaysRight = [
      fromRight,
      { fromRight, toRight: enteredToRight },
    ];

    return (
      <>
        <div className="flex flex-col justify-between">
          <div className="p-6 grid gap-2">
            <div className="radio-container pr-0 pl-6">
              <input
                type="radio"
                onChange={this.setDateRange}
                checked={isToday}
                id="today"
                value="Today"
                name="select_range"
              />
              <label htmlFor="today">Today</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            <div className="radio-container pr-0 pl-6">
              <input
                type="radio"
                onChange={this.setDateRange}
                checked={isYesterday}
                id="yesterday"
                value="Yesterday"
                name="select_range"
              />
              <label htmlFor="yesterday">Yesterday</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            <div className="radio-container pr-0 pl-6">
              <input
                type="radio"
                onChange={this.setDateRange}
                checked={isLast7}
                id="last7"
                value="Last7"
                name="select_range"
              />
              <label htmlFor="last7">Last 7 days</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            <div className="radio-container pr-0 pl-6">
              <input
                type="radio"
                onChange={this.setDateRange}
                checked={isLast14}
                id="last14"
                value="Last14"
                name="select_range"
              />
              <label htmlFor="last14">Last 14 days</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            <div className="radio-container pr-0 pl-6">
              <input
                type="radio"
                onChange={this.setDateRange}
                checked={isLast30}
                id="last30"
                value="Last30"
                name="select_range"
              />
              <label htmlFor="last30">Last 30 days</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            <div className="radio-container pr-0 pl-6">
              <input
                type="radio"
                onChange={this.setDateRange}
                checked={isLastWeek}
                id="lastweek"
                value="LastWeek"
                name="select_range"
              />
              <label htmlFor="lastweek">Last week</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            <div className="radio-container pr-0 pl-6">
              <input
                type="radio"
                onChange={this.setDateRange}
                checked={isLastMonth}
                id="lastmonth"
                value="LastMonth"
                name="select_range"
              />
              <label htmlFor="lastmonth">Last month</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
            <div className="radio-container pr-0 pl-6">
              <input
                type="radio"
                onChange={this.setDateRange}
                checked={
                  !(isToday | isYesterday) &
                  !(isLast7 | isLast14 | isLast30 | isLastWeek | isLastMonth) &
                  isCustom
                }
                id="custom"
                name="select_range"
              />
              <label htmlFor="custom">Custom</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
          </div>
          <div className="p-6 border-t border-t-[#E9ECF1]">
            <div className="radio-container pr-0 pl-6">
              <input
                onChange={this.setDateRange}
                type="checkbox"
                id="compare"
                onClick={(e) => {
                  this.handleClickCompare(e);
                }}
              />
              <label htmlFor="compare">Compare Period</label>
              <span className="radiomark left-0 right-auto after:left-0 after:right-auto" />
            </div>
          </div>
        </div>

        <div className="border-l border-l-[#E9ECF1]">
          <div className="pt-2 pr-1 flex">
            <div className="w-max">
              <DayPicker
                className="SelectableLeft"
                numberOfMonths={numOfMons}
                initialMonth={
                  new Date(new Date().getFullYear(), new Date().getMonth() - 1)
                }
                fixedWeeks
                selectedDays={selectedDaysLeft}
                modifiers={modifiersLeft}
                onDayClick={this.handleDayClickLeft}
                month={this.state.monthLeft}
                fromMonth={fromMonth} //First month of total range
                toMonth={toMonth}
                onDayCursorEnter={this.handleDayCursorEnterLeft}
                // disabledDays={isDisabled01&& {after: fromMonth, before: toMonth}}
                captionElement={({ date, localeUtils }) => (
                  <YearMonthForm
                    date={date}
                    localeUtils={localeUtils}
                    onChange={this.handleYearMonthChangeLeft}
                  />
                )}
                disabledDays={
                  !focusOnLeft & focusOnRight
                    ? [{ after: fromMonth, before: toMonth }]
                    : null
                }
              />
              <Helmet>
                <style>
                  {`
                .SelectableLeft .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                  background-color: rgba(255, 128, 95, 0.3) !important;
                  color: #161D37 !important;
                  border-radius: 3px !important;
                }
                .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
                  border-radius: 8px !important;
                }
                .SelectableLeft .DayPicker-Day--start:not(.DayPicker-Day--outside) {
                  border-radius: 8px !important;
                  background-color: rgba(255, 128, 95, 1);
                  color: white !important;   
                  font-weight: bold;           
                }
                .SelectableLeft .DayPicker-Day--end:not(.DayPicker-Day--outside) {
                  border-radius: 8px !important;
                  background-color: rgba(255, 128, 95, 1);
                  color: white !important;   
                  font-weight: bold;           
                }
                .SelectableLeft .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                  position: relative;
                  background-color: #FF7F5F !important;
                  color: white;
                  width: 13px;
                }
                .SelectableLeft .DayPicker-Day--selected.DayPicker-Day--disabled:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                  background-color: rgba(255, 128, 95, 0.1) !important;
                  color: rgba(22, 29, 55, 0.5) !important;
                }
                .SelectableLeft .DayPicker-Day--selected.DayPicker-Day--disabled.DayPicker-Day--start:not(.DayPicker-Day--outside) {
                  background-color: rgba(255, 128, 95, 0.5);
                }
                .SelectableLeft .DayPicker-Day--selected.DayPicker-Day--disabled.DayPicker-Day--end:not(.DayPicker-Day--outside) {
                  background-color: rgba(255, 128, 95, 0.5);
                }
                .SelectableLeft .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
                  opacity: 0.7;
                }
              `}
                </style>
              </Helmet>
            </div>
            <div
              style={
                dayPickerRightHide ? { display: 'none' } : { display: 'block' }
              }
            >
              <DayPicker
                className="SelectableRight"
                fixedWeeks
                selectedDays={selectedDaysRight}
                modifiers={modifiersRight}
                onDayClick={this.handleDayClickRight}
                onMonthChange={this.patchColoringDays}
                month={this.state.monthRight}
                fromMonth={fromMonth}
                toMonth={toMonth}
                onDayCursorEnter={this.handleDayCursorEnterRight}
                captionElement={({ date, localeUtils }) => (
                  <YearMonthForm
                    date={date}
                    localeUtils={localeUtils}
                    onChange={this.handleYearMonthChangeRight}
                  />
                )}
                disabledDays={
                  focusOnLeft & !focusOnRight
                    ? [{ after: fromMonth, before: toMonth }]
                    : null
                }
              />
              <Helmet>
                <style>
                  {`
              .SelectableRight .DayPicker-Day--selected:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                background-color: rgba(22, 29, 55, 0.4) !important;
                color: white !important;
                border-radius: 3px !important;
              }
              .DayPicker:not(.DayPicker--interactionDisabled) .DayPicker-Day:not(.DayPicker-Day--disabled):not(.DayPicker-Day--selected):not(.DayPicker-Day--outside):hover {
                border-radius: 8px !important;
              }
              .SelectableRight .DayPicker-Day--start:not(.DayPicker-Day--outside) {
                border-radius: 8px !important;
                background-color: rgba(22, 29, 55, 1);
                color: white !important; 
                font-weight: bold;              
              }
              .SelectableRight .DayPicker-Day--end:not(.DayPicker-Day--outside) {
                border-radius: 8px !important;
                background-color: rgba(22, 29, 55, 1);
                color: white !important;   
                font-weight: bold;            
              }
              .SelectableRight .DayPicker-Day--end:not(.DayPicker-Day--outside):hover {
                border-radius: 8px !important;
                background-color: rgba(22, 29, 55, 0.7) !important;
                color: white !important;              
              }
              .SelectableRight .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
                position: relative;
                background-color: rgba(22, 29, 55, 1) !important;
                color: white;
                width: 13px;
              }
              .SelectableRight .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside):hover {
                background-color: rgba(22, 29, 55, 0.5) !important;
              }
              .SelectableRight .DayPicker-Day--selected.DayPicker-Day--disabled:not(.DayPicker-Day--start):not(.DayPicker-Day--end):not(.DayPicker-Day--outside) {
                background-color: rgba(22, 29, 55, 0.05) !important;
                color: rgba(22, 29, 55, 0.5) !important;
              }
              .SelectableRight .DayPicker-Day--disabled.DayPicker-Day--start:not(.DayPicker-Day--outside) {
                background-color: rgba(22, 29, 55, 0.5);
              }
              .SelectableRight .DayPicker-Day--disabled.DayPicker-Day--end:not(.DayPicker-Day--outside) {
                background-color: rgba(22, 29, 55, 0.5) !important;
              }
              `}
                </style>
              </Helmet>
            </div>
          </div>
          <div className="pl-6 pr-5 pb-6 flex justify-between flex-wrap items-center gap-3">
            <div className="flex">
              <div
                style={
                  dayPickerRightHide
                    ? { display: 'flex' }
                    : !isDesktopWidth
                    ? { display: 'none' }
                    : { display: 'flex' }
                }
              >
                <div
                  style={
                    !periodDateLeftShow || periodDateLeft
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                >
                  Period:
                </div>
                <div className="flex indent-2">
                  <div
                    style={
                      periodDateLeftShow
                        ? { display: 'flex' }
                        : { display: 'none' }
                    }
                  >
                    {periodDateLeft ? periodDateLeft : ''}
                  </div>
                  <div
                    style={
                      periodDateLeftShow
                        ? { display: 'none' }
                        : { display: 'flex' }
                    }
                  >
                    {from
                      ? to
                        ? formatPeriod(
                            DateTime.fromJSDate(from),
                            DateTime.fromJSDate(to),
                          )
                        : `${DateTime.fromJSDate(from).toFormat('LLL d, y')}`
                      : 'Please select a day'}
                  </div>
                  {!dayPickerRightHide && fromRight ? <div>to</div> : <></>}
                </div>
              </div>

              <div
                className="indent-2"
                style={
                  dayPickerRightHide
                    ? { display: 'none' }
                    : isDesktopWidth
                    ? { display: 'none' }
                    : { display: 'flex' }
                }
              >
                <div
                  style={
                    !periodDateLeftShow || periodDateLeft
                      ? { display: 'flex' }
                      : { display: 'none' }
                  }
                >
                  Period:
                </div>
                <div>
                  <div
                    style={
                      periodDateLeftShow
                        ? { display: 'flex' }
                        : { display: 'none' }
                    }
                  >
                    {periodDateLeft ? periodDateLeft : ''}
                  </div>
                  <div
                    style={
                      periodDateLeftShow
                        ? { display: 'none' }
                        : { display: 'flex' }
                    }
                  >
                    {from
                      ? to
                        ? formatPeriod(
                            DateTime.fromJSDate(from),
                            DateTime.fromJSDate(to),
                          )
                        : `${DateTime.fromJSDate(from).toFormat('LLL d, y')}`
                      : 'Please select a day'}
                  </div>
                  {!dayPickerRightHide && fromRight ? <div>to</div> : <></>}
                </div>
              </div>

              <div className="indent-2">
                {fromRight
                  ? toRight
                    ? formatPeriod(
                        DateTime.fromJSDate(fromRight),
                        DateTime.fromJSDate(toRight),
                      )
                    : `${DateTime.fromJSDate(fromRight).toFormat('LLL d, y')}`
                  : from === ''
                  ? 'Please select a day'
                  : ''}{' '}
              </div>
            </div>

            <div className="grid xs:flex gap-3 ml-auto mr-0">
              <button
                className="flair-btn flair-btn-secondary flair-btn-sm w-[126px]"
                onClick={this.handleClickClear}
              >
                Clear
              </button>
              <button
                className="flair-btn flair-btn-primary flair-btn-sm w-[126px]"
                onClick={(e) => this.onClickApply(this.state)}
              >
                Apply
              </button>
            </div>
          </div>
        </div>
      </>
    );
  }
}
