import { useState } from 'react';
import Modal from 'react-bootstrap/Modal';

export default function UpdateEmailModal(props) {
  const [value, setValue] = useState('');
  const onChange = (e) => {
    setValue(e.target.value); // e.target.value is a string sans commas here
  };
  return (
    <Modal
      {...props}
      centered
      backdropClassName="opacity-30"
      dialogClassName="drop-shadow-[0_10px_20px_rgba(116,116,126,0.1)] w-full m-0 xs:m-auto xs:max-w-[500px]"
      contentClassName="border-0 rounded-2xl "
    >
      <div className="p-10">
        <h5 className="text-xl leading-7 font-semibold">
          Update Email Address
        </h5>
        <p className="m-auto text-lg leading-7 font-normal mt-4">
          Enter a new email address for {props.username} below
        </p>
        <div className="h-[52px] border py-3 rounded-lg mt-4 mb-8">
          <div className="text-base leading-6 font-normal">
            <input
              value={value}
              onChange={(e) => {
                onChange(e);
              }}
              type="text"
              placeholder="Email"
              className="w-full h-full px-4 text-left-center"
            />
          </div>
        </div>
        <div className="flex gap-3 flex-wrap xs:flex-row-reverse">
          <button
            className="flair-btn flair-btn-md bg-[#242424] w-full xs:w-[117px] h-[52px] rounded-lg border text-white"
            onClick={(e) => props.handleConfirm && props.handleConfirm(value)}
          >
            Update
          </button>
          <button
            onClick={props.onClose}
            className="flair-btn flair-btn-md bg-white w-full xs:w-[117px] h-[52px] rounded-lg border"
          >
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}
