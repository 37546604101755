import React, { useState, useRef } from 'react';
import { svgIcons } from '../svgIcons/svgIcons';

export default function UploadHighlightPhoto({
  title,
  btnCaption,
  name,
  imgSrc,
  onChooseFile,
}) {
  const [image, setImage] = useState('');
  const inputFile = useRef(null);
  const [preview, setPreview] = useState(false);

  const handleFileUpload = (e) => {
    const { files } = e.target;
    if (files && files.length) {
      setImage(files[0]);
      if (onChooseFile) onChooseFile(files[0]);
      setPreview(true);
    }
  };

  const onButtonClick = () => {
    inputFile.current.click();
  };

  return (
    <div className="flex flex-wrap gap-6 items-center">
      <div className="relative w-[110px] h-[82px]">
        <img
          src={preview ? URL.createObjectURL(image) : imgSrc}
          alt=""
          className="absolute top-0 left-0 w-full h-full rounded object-cover"
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/assets/images/empty.png';
          }}
        />
        <div className="h-full flex items-center justify-center rounded border-1 border-[#CED3DB] border-dashed bg-[#F2F5F9]">
          <div className="w-5 text-[#8C94A3]">{svgIcons.noimg}</div>
        </div>
      </div>

      <div className="flex flex-col justify-between">
        <div className="font-bold text-[20px] leading-[26px]">{title}</div>
        <p className="text-[#707787] mt-2">
          This photo will be used for a preview.
          <br />
          Supports PNG, JPEG and GIF
        </p>
      </div>
      <div>
        <button
          type="button"
          className="flair-btn flair-btn-secondary flair-btn-md w-[140px]"
          onClick={onButtonClick}
        >
          {btnCaption}
        </button>
      </div>
      <input
        style={{ display: 'none' }}
        accept=".PNG,.png, .JPEG, .jpeg, .JPG, .jpg, .GIF, .gif"
        ref={inputFile}
        onChange={handleFileUpload}
        type="file"
        name={name}
      />
    </div>
  );
}
