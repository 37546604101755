import Modal from 'react-bootstrap/Modal';
import { useSelector } from 'react-redux';
import { svgIcons } from '../svgIcons/svgIcons';
import { useState, useEffect, useRef } from 'react';
import Select from 'react-select';
import SuccessModal from '../../components/modalbootstrap/SuccessModal';
import { DropdownIndicator } from '../../components/select/Select';
import { apiAgent } from '../../utils/apicall';
import uxDialog from '../../utils/uxdialog';

export default function AddInvitationModal(props) {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const [companies, setCompanies] = useState([]);
  const nameRef = useRef();
  const emailRef = useRef();
  const companyRef = useRef();
  const permissionRef = useRef();
  const staffRef = useRef();
  const [nameIn, setNameIn] = useState(true);
  const [emailIn, setEmailIn] = useState(true);
  const [companyIn, setCompanyIn] = useState(true);
  const [permissionIn, setPermissionIn] = useState(true);
  const [modal, setModal] = useState(null);
  const PERMISSION_DICT = {
    perm0: 'admin',
    perm1: 'agency',
    perm2: 'reader',
  };
  const PERMISSION = [
    {
      value: 'perm0',
      label: 'Admin',
    },
    {
      value: 'perm1',
      label: 'Agency',
    },
    {
      value: 'perm2',
      label: 'View-Only',
    },
  ];

  const handleSaveConfirm = async () => {
    setNameIn(true);
    setEmailIn(true);
    setCompanyIn(true);
    setPermissionIn(true);
    if (!nameRef.current.value) {
      setNameIn(false);
      nameRef.current.focus();
    } else if (!emailRef.current.value) {
      setEmailIn(false);
      emailRef.current.focus();
    } else if (!companyRef.current.props.value) {
      setCompanyIn(false);
      companyRef.current.focus();
    } else if (!permissionRef.current.props.value.value) {
      setPermissionIn(false);
      permissionRef.current.focus();
    } else {
      let formData = {
        name: nameRef.current.value,
        email: emailRef.current.value,
        company_id: companyRef.current.props.value.value,
        permission_role:
          PERMISSION_DICT[permissionRef.current.props.value.value],
        is_staff: staffRef.current.checked,
      };
      try {
        const response = await apiAgent.post({
          path: `/invitations/add_invitation/`,
          data: formData,
          options: apiAgent.popularOptions.onConfirmExceptDeclined,
        });
        const response_data = await response.json();
        if (response_data.error == 'The user already joined') {
          await uxDialog.alert({
            text: 'This user already has access to Flair.',
          });
        } else if (response_data.error == 'The user is already invited')
          await uxDialog.alert({
            text: 'This user has already been invited to Flair. To resend the invitation, find the user invitation and click "resend invite".',
          });
        else {
          props.onClose();
          setModal('SuccessInvite');
        }
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    setNameIn(true);
    setEmailIn(true);
    setCompanyIn(true);
    setPermissionIn(true);

    setCompanies(props.companies);
  }, [props]);
  return (
    <>
      <Modal
        {...props}
        centered
        backdropClassName="opacity-30"
        dialogClassName="drop-shadow-[0_10px_20px_rgba(116,116,126,0.1)] w-full m-0 xs:m-auto xs:max-w-[576px] sm:max-w-[640px] md:max-w-[768px]"
        contentClassName="border-0 rounded-0 "
      >
        <button
          className={
            'absolute text-[#707787] top-5 right-5 hover:text-red-600 w-3'
          }
          onClick={props.onClose}
        >
          {svgIcons.cross}
        </button>
        <div className="p-10">
          <h5 className="text-center">Invite</h5>
          <p className="text-center max-w-[496px] m-auto">
            Insert the required information to add a user. After you hit save,
            they'll receive an email with an invite to join Flair
          </p>
          <div className="py-8 mb-8 border-b border-b-[#E9ECF1] grid grid-cols-1 xs:grid-cols-2 gap-6">
            <div className="">
              <label className="mb-1">Name</label>
              <input type="text" className="flair-input py-2" ref={nameRef} />
              {nameIn ? (
                <></>
              ) : (
                <p className="text-base text-[#e72727]">Input user name.</p>
              )}
            </div>
            <div className="">
              <label className="mb-1">Email address</label>
              <input
                type="text"
                className="flair-input py-2"
                value={props.email}
                ref={emailRef}
              />
              {emailIn ? (
                <></>
              ) : (
                <p className="text-base text-[#e72727]">Input user email.</p>
              )}
            </div>
            <div className="">
              <label className="mb-1">Company</label>
              <Select
                placeholder="Search company"
                options={companies.map((item) => ({
                  value: item.id,
                  label: item.name,
                }))}
                ref={companyRef}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                className="w-full"
                styles={{
                  control: (styles, state, base, css) => ({
                    ...base,
                    ...styles,
                    ...css,
                    boxShadow: styles.isFocused ? 'null' : 'null',
                    borderColor: state.isFocused ? '#687bfe' : '#cccccc',
                    backgroundColor: state.isFocused ? '#FFFFFF' : '#f5faff',
                    '&:hover': {
                      cursor: 'pointer',
                      borderColor: 'black',
                    },
                    borderRadius: '0.25rem',
                    outerHeight: '42px',
                    padding: '2px 12px',
                  }),
                  menu: (base) => ({
                    ...base,
                    borderRadius: 10,
                    marginTop: 5,
                    paddingTop: 5,
                    paddingBottom: 5,
                  }),
                  option: (base) => ({
                    ...base,
                    borderWidth: 0,
                    borderStyle: 'solid',
                    borderColor: '#161D37',
                    borderRadius: 10,
                  }),
                  menuList: (style) => ({
                    ...style,
                    padding: 0,
                    '::-webkit-scrollbar': {
                      width: '5px',
                      height: '100%',
                    },
                    '::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 5px #e6effb',
                      borderRadius: '10px',
                    },
                    '::-webkit-scrollbar-thumb': {
                      background: '#b0c5de',
                      borderRadius: '10px',
                    },
                    '::-webkit-scrollbar-thumb:hover': {
                      background: '#687bfe',
                    },
                  }),
                }}
              />
              {companyIn ? (
                <></>
              ) : (
                <p className="text-base text-[#e72727]">Select company.</p>
              )}
            </div>
            <div className="">
              <label className="mb-1">Permission Role</label>
              <Select
                placeholder="Search company"
                options={PERMISSION.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
                defaultValue={{
                  value: PERMISSION[0].value,
                  label: PERMISSION[0].label,
                }}
                ref={permissionRef}
                components={{
                  DropdownIndicator,
                  IndicatorSeparator: () => null,
                }}
                className="w-full"
                styles={{
                  control: (styles, state, base, css) => ({
                    ...base,
                    ...styles,
                    ...css,
                    boxShadow: styles.isFocused ? 'null' : 'null',
                    borderColor: state.isFocused ? '#687bfe' : '#cccccc',
                    backgroundColor: state.isFocused ? '#FFFFFF' : '#f5faff',
                    '&:hover': {
                      cursor: 'pointer',
                      borderColor: 'black',
                    },
                    borderRadius: '0.25rem',
                    outerHeight: '42px',
                    padding: '2px 12px',
                  }),
                  menu: (base) => ({
                    ...base,
                    borderRadius: 10,
                    marginTop: 5,
                    paddingTop: 5,
                    paddingBottom: 5,
                  }),
                  option: (base) => ({
                    ...base,
                    borderWidth: 0,
                    borderStyle: 'solid',
                    borderColor: '#161D37',
                    borderRadius: 10,
                  }),
                  menuList: (style) => ({
                    ...style,
                    padding: 0,
                    '::-webkit-scrollbar': {
                      width: '5px',
                      height: '100%',
                    },
                    '::-webkit-scrollbar-track': {
                      boxShadow: 'inset 0 0 5px #e6effb',
                      borderRadius: '10px',
                    },
                    '::-webkit-scrollbar-thumb': {
                      background: '#b0c5de',
                      borderRadius: '10px',
                    },
                    '::-webkit-scrollbar-thumb:hover': {
                      background: '#687bfe',
                    },
                  }),
                }}
              />
              {permissionIn ? (
                <></>
              ) : (
                <p className="text-base text-[#e72727]">
                  Input permission role.
                </p>
              )}
            </div>
            {userInfo.is_staff ? (
              <div className="flex items-center pl-5">
                <label className="check-container w-[18px] h-[18px] pl-0 mb-0 align-middle">
                  <input type="checkbox" ref={staffRef} />
                  <span className="checkmark"></span>
                </label>
                <span className="pl-[11px]">Staff user</span>
              </div>
            ) : (
              <div className="flex items-center pl-5">
                <label className="check-container w-[18px] h-[18px] pl-0 mb-0 align-middle">
                  <input disabled type="checkbox" ref={staffRef} />
                  <span className="checkmark"></span>
                </label>
                <span className="pl-[11px] text-[#999]">Staff user</span>
              </div>
            )}
          </div>
          <div className="flex gap-3 flex-wrap xs:flex-row-reverse">
            <button
              className="flair-btn flair-btn-primary flair-btn-md w-full xs:w-44"
              onClick={handleSaveConfirm}
            >
              Invite
            </button>
            <button
              onClick={props.onClose}
              className="flair-btn flair-btn-secondary flair-btn-md w-full xs:w-44"
            >
              Cancel
            </button>
          </div>
        </div>
      </Modal>
      <SuccessModal
        show={modal === 'SuccessInvite'}
        onHide={() => setModal(null)}
        onClose={() => setModal(null)}
        mark={{
          icon: svgIcons.success,
          parentClass: 'text-[#38D086] bg-[#38D086]/10',
          childClass: 'w-[30px] h-[17px]',
        }}
        title="Success"
        txt="The user was successfully added"
        modalConfirmBtn="Back to User Management"
        handleSuccess={() => {
          setModal(null);
        }}
      />
    </>
  );
}
