import React from 'react';
import Tag from './Tag';
import { BiEditAlt } from 'react-icons/bi';
import { useRef, useImperativeHandle } from 'react';

function TagsSimpleContainer({
  id,
  data,
  showEdit,
  onClickEdit,
  onRemoveItem,
  placeholder,
}) {
  const selfRef = useRef(null);

  return (
    <section
      className="inline-flex gap-2 items-center pt-2 pr-3 pb-3 pl-3 h-full text-base whitespace-normal flex-wrap whitespace-nowrap flex-shrink"
      ref={selfRef}
    >
      {data.length === 0 && (
        <div className="text-gray-400 text-sm">{placeholder || ''}</div>
      )}
      {data.map((tagData, index) => (
        <Tag data={tagData} key={tagData.id} onRemove={onRemoveItem} />
      ))}
      {
        <a
          href="#"
          onClick={(e) => {
            e.preventDefault();
            if (showEdit && onClickEdit) {
              onClickEdit(id, e, selfRef.current);
            }
          }}
          className={showEdit ? '' : 'invisible pointer-events-none'}
          aria-disabled={!showEdit}
        >
          <BiEditAlt />
        </a>
      }
    </section>
  );
}

export default TagsSimpleContainer;
