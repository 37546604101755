import { DateTime } from 'luxon';
import config from './config';
import { saveUserInfo } from './user';
import { apiAgent } from './apicall';

export const trim = (pathName) => {
  const dashStart = pathName.lastIndexOf('/');
  if (dashStart !== 0) return pathName.substring(0, dashStart);
  else return pathName;
};

export function commafy(num) {
  const str = isNaN(num) ? num : num.toString();
  var strs = str.split('.');
  strs[0] = strs[0].replace(/\B(?=(\d{3})+(?!\d))/g, ', ');
  if (strs.length > 1) strs[1] = strs[1].replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
  return strs.join('.');
}

export const handleLogout = async () => {
  try {
    // const response = await apiAgent.post({
    //   path: '/users/logout/',
    //   data: {},
    // });
    // if (!response.ok) {
    //   if (HTTP_STATUS.isClientError(response.status)) {
    //     const data = await response.json();
    //     if (
    //       await uxDialog.confirm({
    //         text: `Server declined with message\n ${data}\n Do you want to log out anyway?`,
    //       })
    //     ) {
    //       saveUserInfo(null);
    //       window.location.href = config.serverurl + 'users/logout_auth0/';
    //     }
    //   } else {
    //     if (
    //       await uxDialog.confirm({
    //         text: `Failed to end session on serverside. (Internal Server Error)\nDo you want to log out anyway?`,
    //       })
    //     ) {
    //       saveUserInfo(null);
    //       window.location.href = config.serverurl + 'users/logout_auth0/';
    //     }
    //   }
    //   return;
    // }
    saveUserInfo(null);
    window.location.href = config.serverurl + 'users/logout/';
  } catch (error) {
    console.log(error);
    // if (
    //   await uxDialog.confirm({
    //     text: `Failed to end session on serverside. (Communication failure)\nDo you want to log out anyway?`,
    //   })
    // ) {
    //   saveUserInfo(null);
    //   window.location.href = config.serverurl + 'users/logout_auth0/';
    // }
  }
};

export const pauseProject = async (id) => {
  try {
    const response = await apiAgent.post({
      path: `projects/${id}/pause`,
      data: {},
      options: apiAgent.popularOptions.onConfirmExceptDeclined,
    });
    const body = await response.json();
    console.log(body);
  } catch (error) {
    console.log(error);
  }
};

export const activateProject = async (id) => {
  try {
    const response = await apiAgent.post({
      path: `projects/${id}/activate`,
      data: {},
      options: apiAgent.popularOptions.onConfirmExceptDeclined,
    });
    const body = await response.json();
    console.log(body);
  } catch (error) {
    console.log(error);
  }
};

export const updateBudget = async (id, budgetPlan) => {
  try {
    const response = await apiAgent.patch({
      path: `projects/${id}`,
      data: {
        budget_plan: budgetPlan,
      },
      options: apiAgent.popularOptions.onConfirmExceptDeclined,
    });
    const body = await response.json();
    console.log(body);
  } catch (error) {
    console.log(error);
  }
};

// get dates between yyyy-MM-dd dates
export const getDateArr = ([startDate, endDate]) => {
  if (!endDate) return [startDate];
  const dateArr = [];
  for (
    let tomorrow = startDate;
    tomorrow <= endDate && endDate;
    tomorrow = DateTime.fromSQL(tomorrow)
      .plus({ days: 1 })
      .toFormat('yyyy-MM-dd')
  ) {
    dateArr.push(tomorrow);
  }
  return dateArr;
};

export const convertDateTime = (dateObj) => {
  if (!dateObj) return dateObj;
  return DateTime.fromJSDate(dateObj).set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0,
  });
};

export const convertNumberType = (num) => {
  let strTmp = parseFloat(num);
  if (parseFloat(strTmp.toFixed(2)) - parseInt(strTmp.toFixed(0)) === 0) {
    return parseInt(strTmp.toFixed(0)).toLocaleString('en-US');
  } else return parseFloat(strTmp.toFixed(2)).toLocaleString('en-US');
};

/**
 *
 * @param {*} character
 * @param {*} font
 * @returns width of the character
 */
export function characterWidth(character, font) {
  let canvas =
    characterWidth.canvas ||
    (characterWidth.canvas = document.createElement('canvas'));
  let context = canvas.getContext('2d');
  context.font = font;
  let metrics = context.measureText(character);
  return metrics.width;
}

export function getXOffset(character) {
  let width;
  if (character === 'I' || character === 'J') width = 45;
  else if (character === 'T' || character === 'Y') width = 47;
  else if (character === 'M' || character === 'W') width = 49;
  else width = 48;
  return (width - characterWidth(character, 'bold 24pt PublicSans')) / 2;
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function styleGoogleCustomerId(strValue) {
  strValue = '' + strValue;
  if (strValue.length !== 10) return strValue;
  return `${strValue.substr(0, 3)}-${strValue.substr(3, 3)}-${strValue.substr(
    6,
  )}`;
}
