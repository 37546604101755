import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { get } from 'lodash-es';
import { apiAgent } from '../../utils/apicall';
import { monthlyBudget } from '../../utils/project';
import { styleGoogleCustomerId } from '../../utils/publicFunctions';
import uxDialog from '../../utils/uxdialog';
import ReactTooltip from 'react-tooltip';
import LinesEllipsis from 'react-lines-ellipsis';
import ConfirmModal from '../modalbootstrap/ConfirmModal';
import SuccessModal from '../modalbootstrap/SuccessModal';
import { convertNumberType } from '../../utils/publicFunctions';
import { svgIcons } from '../svgIcons/svgIcons';
import { showLoading } from '../../features/loading/loadingSlice';

export default function PropertyTile({
  project: _project,
  property: _property,
  hideStar,
}) {
  const getPropertyProject = (_property, _project) => {
    let property = null,
      project = null;
    if (_project) {
      project = _project;
      property = _project.property;
    }
    if (_property) {
      property = _property;
      if (!project && property.project_set && property.project_set.length > 0) {
        project = property.project_set[0];
      }
    }
    return [property, project];
  };

  const getStatus = (property, project) => {
    let status = 'Pending';
    if (project) {
      if (project.gad_customer_id) {
        if (['4PAUS', '6TOAV'].includes(project.ads_status)) {
          status = 'Paused';
        } else if (['7ACTV', '5TOPS'].includes(project.ads_status)) {
          status = 'Live';
        }
      }
      // TODO: For intermediate states, repeat checking status from backend until stabilized.
    }
    return status;
  };

  const dispatch = useDispatch();
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  const [initProperty, initProject] = getPropertyProject(_property, _project);
  const initStatus = getStatus(initProperty, initProject);
  const [property, setProperty] = useState(initProperty);
  const [project, setProject] = useState(initProject);
  const [status, setStatus] = useState(initStatus);
  const [favorite, setFavorite] = useState(property.isFavorite);
  const [enabling, setEnabling] = useState(false);
  const [modalShow, setModalShow] = useState(null);

  useEffect(() => {
    setStatus(getStatus(property, project));
  }, [property, project]);

  const handleClickFavor = async () => {
    // determine action
    const action = favorite ? 'unfavor' : 'favor';
    // call /favor or /unfavor API
    try {
      const response = await apiAgent.post({
        path: `/properties/${property.id}/${action}/`,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      if (action === 'favor') {
        setFavorite(true);
      } else {
        setFavorite(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleMarkReady = async () => {
    try {
      const response = await apiAgent.patch({
        path: `/properties/${property.id}`,
        data: { is_ready: true },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const body = await response.json();
      setProperty(body);
      setTimeout(function () {
        setModalShow('readysuccess');
      }, 499);
    } catch (error) {
      console.log(error);
    }
  };

  const handleEnable = async () => {
    if (enabling) {
      return;
    }
    try {
      setEnabling(true);
      const userInput = await uxDialog.prompt({
        headerImagePath: '/assets/images/modal-adjust.svg',
        title: 'Set your monthly budget',
        message: 'Your monthly budget can be adjusted at any time.',
      });
      if (userInput === null) {
        setEnabling(false);
        return;
      }
      const dailyBudget = parseFloat((parseInt(userInput) / 30.4).toFixed(2));
      if (!dailyBudget) {
        await uxDialog.alert({
          text: 'The budget you inserted was invalid. Can you try again?',
        });
        setEnabling(false);
        return;
      }
      dispatch(showLoading('Saving your budget...'));
      const response = await apiAgent.post({
        path: `/properties/${property.id}/enable`,
        data: { budget_plan: dailyBudget },
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const body = await response.json();
      setProperty(body);
      const _project = get(body, 'project_set.0', null);
      if (_project) {
        setProject(_project);
      }
      // setEnabling(false);
      // setModalShow('enablesuccess');
    } catch (error) {
      // setEnabling(false);
      console.log(error);
    }
    dispatch(showLoading(false));
  };

  return (
    <div className="flex px-[12px] pt-[12px] pb-[16px] bg-white border border-[#f2f5f9] rounded-[4px] w-full relative shadow-[0_14px_26px_rgba(183,188,200,0.11)] 520w:block 520w:p-0 break-words">
      <div className="flex justify-center items-center mr-[12px] w-2/5 520w:w-full 520w:aspect-[23/14] bg-[#E6EFFB] relative">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={property.highlight_photo || '/assets/images/empty.png'}
          alt={property.name + ' property image'}
          onError={(e) => {
            e.target.onerror = null;
            e.target.src = '/assets/images/empty.png';
          }}
        />
        <div className="w-[41px] h-[36px] text-white">{svgIcons.card}</div>
      </div>
      <div className="absolute top-0 left-0 flex justify-between w-full p-[12px] 520w:p-[15px] ">
        <div className=""></div>
        {/* <Link to="#" onClick={handleClickFavor}>
          <img
            src={
              !hideStar
                ? favorite
                  ? '/assets/images/favorite.svg'
                  : '/assets/images/unfavorite.svg'
                : '/assets/images/favorite.svg'
            }
            alt=""
          />
        </Link> */}
      </div>
      <div className="w-3/5 520w:w-full 520w:px-[16px] 520w:pt-[12px] 520w:pb-[18px] font-bold">
        <div className="mb-[4px] text-[12px] text-black">
          <span className="text-[12px] text-[#808080] mr-[4px] leading-4">
            Google ID:
          </span>
          {project ? styleGoogleCustomerId(project.gad_customer_id) : 'N/A'}
        </div>
        <div className="w-full h-[42px] text-[#161d37] text-[16px] leading-5">
          <LinesEllipsis
            text={property.name}
            maxLine="2"
            ellipsis="…"
            trimRight
          />
        </div>
        <div className="flex items-center mt-[15px] text-[12px] leading-4">
          <div className="flex">
            <img
              src={
                status === 'Live'
                  ? '/assets/images/live-tile.svg'
                  : status === 'Paused'
                  ? '/assets/images/paused-tile.svg'
                  : '/assets/images/disabled-tile.svg'
              }
              alt=""
              width="13.33"
              height="13.33"
            />
            <span className="indent-1.5">{status}</span>
          </div>
          {project && (
            <div className="bg-[#e6effb] w-[4px] h-[4px] rounded-full mx-[9px]"></div>
          )}
          {project && (
            <div
              className="flex"
              data-for={`budget-${property.id}`}
              data-tip="This is your monthly<br/>budget for this property"
            >
              <img
                src="/assets/images/dollar.svg"
                alt=""
                width="13.33"
                height="13.33"
              />
              <span className="indent-1.5">
                ${convertNumberType(monthlyBudget(project.budget_plan))}
              </span>
              <ReactTooltip
                id={`budget-${property.id}`}
                place="right"
                type="light"
                effect="float"
                multiline={true}
                className="tooltip"
              />
            </div>
          )}
        </div>
        <div className="mt-5 flex">
          <Link
            to={status === 'Pending' ? '/' : '/project/' + project.id}
            onClick={(e) => {
              if (status === 'Pending') {
                e.preventDefault();
                if (property.is_ready) setModalShow('makeenable');
                else if (userInfo.is_staff) setModalShow('makeready');
              }
            }}
            className="flair-btn flair-btn-sm flair-btn-secondary w-1/2"
          >
            <span className="w-[13px]">{svgIcons.project}</span>Campaigns
          </Link>
          <Link
            to={property ? `/properties/preview/${property.id}` : '#'}
            className="flair-btn flair-btn-sm flair-btn-tertiary w-1/2"
          >
            <span className="w-[13px]">{svgIcons.details}</span>Info
          </Link>
        </div>
      </div>
      <ConfirmModal
        show={modalShow === 'makeenable'}
        onHide={() => setModalShow(null)}
        onClose={() => setModalShow(null)}
        mark={{
          icon: <img src="/assets/images/make-enable.png" />,
          parentClass: '',
          childClass: '',
        }}
        txt="You haven't set up campaigns for this property yet. Click confirm below to set up a custom strategy for this property."
        handleConfirm={() => {
          setModalShow(null);
          setTimeout(function () {
            handleEnable();
          }, 999);
        }}
      />

      <SuccessModal
        show={modalShow === 'enablesuccess'}
        onHide={() => setModalShow(null)}
        onClose={() => setModalShow(null)}
        mark={{
          icon: svgIcons.success,
          parentClass: 'text-[#38D086] bg-[#38D086]/10',
          childClass: 'w-[30px] h-[17px]',
        }}
        title="Success"
        txt="Campaigns for this property are set up successfully."
        modalConfirmBtn="Confirm"
        handleSuccess={() => {
          setModalShow(null);
        }}
      />

      <ConfirmModal
        show={modalShow === 'makeready'}
        onHide={() => setModalShow(null)}
        onClose={() => setModalShow(null)}
        mark={{
          icon: <img src="/assets/images/make-enable.png" />,
          parentClass: '',
          childClass: '',
        }}
        // header='Hey!'
        txt="We're still in the process of building out your ad strategy based the property details you provided. It will be ready soon, feel free to check back here later."
        // content2=''
        // // btn1='Confirm'
        // btn2='Cancel'
        handleConfirm={() => {
          setModalShow(null);
          handleMarkReady();
        }}
      />

      <SuccessModal
        show={modalShow === 'readysuccess'}
        onHide={() => setModalShow(null)}
        onClose={() => setModalShow(null)}
        mark={{
          icon: svgIcons.success,
          parentClass: 'text-[#38D086] bg-[#38D086]/10',
          childClass: 'w-[30px] h-[17px]',
        }}
        // header='Hey!'
        txt="Campaigns for this property are ready."
        // content2='You can do that by clicking confirm before'
        modalConfirmBtn="Confirm"
        // btn2='Cancel'
        handleSuccess={() => {
          setModalShow(null);
        }}
        // handleCancel={() => setModalShow(null)}
      />
    </div>
  );
}
