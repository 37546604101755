import React from 'react';
import { useSelector } from 'react-redux';
import {
  fetchAlertShow,
  fetchAlertRequest,
} from '../../features/uxdialog/uxalertSlice';
import ModalBase from '../modalbootstrap/ModalBase';

export default function AlertDialog() {
  const show = useSelector(fetchAlertShow);
  const request = useSelector(fetchAlertRequest);

  return (
    <ModalBase
      show={show}
      backdrop="static"
      contentClassName="p-[20px]"
      onClose={() => request.handleClose()}
      content={
        <>
          {/* <h2 className="flex leading-4 mb-4">Flair</h2> */}
          <p className="mt-6">{request.text}</p>
          <div className="mt-4 flex justify-end">
            <button
              className="flair-btn flair-btn-sm flair-btn-primary w-[100px]"
              onClick={() => request.handleClose()}
            >
              OK
            </button>
          </div>
        </>
      }
    />
  );
}
