import React from 'react';
import Skeleton from 'react-loading-skeleton';

export default function ProjectSkeleton() {
  return (
    <>
      <div className="px-5 xs:px-10 py-5 skeleton-background text-base leading-[17.6px] flex items-center border-b border-[#dddddd]">
        <Skeleton
          width={240}
          baseColor="rgba(202, 220, 237, 0.7)"
          highlightColor="rgba(219, 230, 242, 1)"
        />
      </div>
      <div className="px-5 xs:px-10 py-4 xs:py-8 skeleton-background flex flex-wrap gap-8 justify-between">
        <div className="grid gap-2 items-center md:flex md:gap-8 relative w-full md:w-auto md:min-w-[600px] md:max-w-[1000px]">
          <div className="w-inherit aspect-[5/2] md:aspect-square md:h-full rounded">
            <Skeleton
              containerClassName="h-full"
              className="h-full"
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </div>

          <div className="flex gap-4">
            <div className="grid gap-3">
              <h5>
                <Skeleton
                  width={336}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </h5>
              <div className="font-medium text-base leading-[20.8px]">
                <Skeleton
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
              <div className="font-medium text-base leading-[20.8px]">
                <Skeleton
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            </div>
            <div className="absolute md:static top-0 left-2 flex justify-center items-center px-6 h-9 rounded-[34px] mt-1">
              <Skeleton
                width={100}
                height={36}
                borderRadius={32}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
          </div>
        </div>
        <div className="flex flex-row-reverse mr-0 ml-auto">
          <Skeleton
            width={160}
            height={42}
            borderRadius={32}
            baseColor="rgba(202, 220, 237, 0.7)"
            highlightColor="rgba(219, 230, 242, 1)"
          />
        </div>
      </div>
      <div className="px-[20px] xs:px-[40px] pt-10 pb-12 grid gap-10">
        <div className="grid gap-6">
          <div className="font-bold text-[28px] leading-[36.4px]">
            <Skeleton
              width={185}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </div>
          <div className="grid gap-4 md:flex">
            <div className="overflow-hidden md:overflow-visible flex gap-3 md:grid">
              <div className="py-[19px] px-[18px] rounded-lg skeleton-background md:px-[26px] md:py-6 grid md:flex md:items-center gap-[11px] md:gap-[17px] min-w-[150px] md:min-w-[233px]">
                <Skeleton
                  borderRadius={9999}
                  width={32}
                  height={32}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <div className="grid gap-[6px]">
                  <div className="text-[#515868] text-[14px] font-normal leading-[18.2px]">
                    <Skeleton
                      width={129}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <div className="font-semibld text-[18px] leading-[23.4px]">
                    <Skeleton
                      width={129}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
              </div>
              <div className="py-[19px] px-[18px] rounded-lg skeleton-background md:px-[26px] md:py-6 grid md:flex md:items-center gap-[11px] md:gap-[17px] min-w-[150px] md:min-w-[233px]">
                <Skeleton
                  borderRadius={9999}
                  width={32}
                  height={32}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <div className="grid gap-[6px]">
                  <div className="text-[#515868] text-[14px] font-normal leading-[18.2px]">
                    <Skeleton
                      width={129}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <div className="font-semibld text-[18px] leading-[23.4px]">
                    <Skeleton
                      width={129}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
              </div>
              <div className="py-[19px] px-[18px] rounded-lg skeleton-background md:px-[26px] md:py-6 grid md:flex md:items-center gap-[11px] md:gap-[17px] min-w-[150px] md:min-w-[233px]">
                <Skeleton
                  borderRadius={9999}
                  width={32}
                  height={32}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <div className="grid gap-[6px]">
                  <div className="text-[#515868] text-[14px] font-normal leading-[18.2px]">
                    <Skeleton
                      width={129}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                  <div className="font-semibld text-[18px] leading-[23.4px]">
                    <Skeleton
                      width={129}
                      baseColor="rgba(202, 220, 237, 0.7)"
                      highlightColor="rgba(219, 230, 242, 1)"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="skeleton-background py-6 px-8 w-full flex flex-col gap-4">
              <div className="flex gap-2 items-center">
                <Skeleton
                  borderRadius={9999}
                  width={24}
                  height={24}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <Skeleton
                  width={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
              <Skeleton
                containerClassName="h-full"
                className="h-full"
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <div className="flex gap-2 items-center">
                <Skeleton
                  borderRadius={9999}
                  width={16}
                  height={16}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <Skeleton
                  width={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="grid gap-4">
          <div className="flex justify-between items-center gap-3">
            <div className="font-bold text-[28px] leading-[36.4px]">
              <Skeleton
                width={185}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </div>
            <Skeleton
              borderRadius={32}
              width={130}
              height={42}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
            />
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
            <div className="skeleton-background py-6 px-8 w-full flex flex-col gap-4">
              <div className="flex gap-2 items-center">
                <Skeleton
                  borderRadius={9999}
                  width={24}
                  height={24}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <Skeleton
                  width={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
              <Skeleton
                height={129}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <div className="flex gap-2 items-center">
                <Skeleton
                  borderRadius={9999}
                  width={16}
                  height={16}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <Skeleton
                  width={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            </div>
            <div className="skeleton-background py-6 px-8 w-full flex flex-col gap-4">
              <div className="flex gap-2 items-center">
                <Skeleton
                  borderRadius={9999}
                  width={24}
                  height={24}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <Skeleton
                  width={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
              <Skeleton
                height={129}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <div className="flex gap-2 items-center">
                <Skeleton
                  borderRadius={9999}
                  width={16}
                  height={16}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <Skeleton
                  width={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            </div>
            <div className="skeleton-background py-6 px-8 w-full flex flex-col gap-4">
              <div className="flex gap-2 items-center">
                <Skeleton
                  borderRadius={9999}
                  width={24}
                  height={24}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <Skeleton
                  width={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
              <Skeleton
                height={129}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <div className="flex gap-2 items-center">
                <Skeleton
                  borderRadius={9999}
                  width={16}
                  height={16}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <Skeleton
                  width={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            </div>
            <div className="skeleton-background py-6 px-8 w-full flex flex-col gap-4">
              <div className="flex gap-2 items-center">
                <Skeleton
                  borderRadius={9999}
                  width={24}
                  height={24}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <Skeleton
                  width={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
              <Skeleton
                height={129}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <div className="flex gap-2 items-center">
                <Skeleton
                  borderRadius={9999}
                  width={16}
                  height={16}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <Skeleton
                  width={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            </div>
            <div className="skeleton-background py-6 px-8 w-full flex flex-col gap-4">
              <div className="flex gap-2 items-center">
                <Skeleton
                  borderRadius={9999}
                  width={24}
                  height={24}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <Skeleton
                  width={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
              <Skeleton
                height={129}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <div className="flex gap-2 items-center">
                <Skeleton
                  borderRadius={9999}
                  width={16}
                  height={16}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <Skeleton
                  width={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            </div>
            <div className="skeleton-background py-6 px-8 w-full flex flex-col gap-4">
              <div className="flex gap-2 items-center">
                <Skeleton
                  borderRadius={9999}
                  width={24}
                  height={24}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <Skeleton
                  width={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
              <Skeleton
                height={129}
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
              <div className="flex gap-2 items-center">
                <Skeleton
                  borderRadius={9999}
                  width={16}
                  height={16}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
                <Skeleton
                  width={129}
                  baseColor="rgba(202, 220, 237, 0.7)"
                  highlightColor="rgba(219, 230, 242, 1)"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
