import React, { useState } from 'react';
import { ModalCentered } from './ModalCentered';

export default function LinkModal(props) {
  return (
    <ModalCentered
      {...props}
      dialogClassName="sm:w-[448px]"
      formGroup={
        <>
          <p className="font-normal text-sm leading-tight">Link to share</p>
          <input
            className="flair-input px-3 py-[10px]"
            type="text"
            value={props.linkUrl}
            readOnly
          />
          <div className="mt-8">
            <button
              className="flair-btn flair-btn-md flair-btn-secondary"
              onClick={() => {
                props.handleConfirm && props.handleConfirm();
                props.onClose();
              }}
            >
              Copy Link
            </button>
          </div>
        </>
      }
    />
  );
}
