import { Modal, ModalContent, Button } from '@nextui-org/react';

export default function SpecialModal(props) {
  return (
    <Modal size={'5xl'} hideCloseButton={true} {...props}>
      <ModalContent>
        <div className="flex relative items-center justify-between px-10 py-5">
          <div className="flex gap-4 my-auto text-base leading-6 font-medium">
            <div>{props.page} / 3</div>
            <div className="pl-4 border-l-1">Create Special</div>
          </div>
          <div className="flex items-center my-auto">
            <Button
              isIconOnly
              variant="light"
              radius="full"
              onClick={props.onClose}
            >
              <img src="/assets/images/close.png" width={40} alt="" />
            </Button>
          </div>
          <div
            className={`absolute bottom-0 left-0 h-[2px] bg-[#7384F5] ${
              props.page === 1
                ? 'w-[114px]'
                : props.page === 2
                ? 'w-[470px]'
                : 'w-[900px]'
            }`}
          ></div>
        </div>
        <div className="bg-slate-50 px-10 py-8 border-t-1">{props.content}</div>
        <div className="border-t-1 h-[100px] px-10 py-6 flex justify-between gap-5 bg-white">
          {props.page === 1 ? (
            <Button
              variant="bordered"
              radius="sm"
              onClick={props.onClose}
              className="h-[52px] w-full text-base leading-6 font-medium"
            >
              Cancel
            </Button>
          ) : (
            <Button
              variant="bordered"
              radius="sm"
              onClick={props.onBack}
              className="h-[52px] w-full text-base leading-6 font-medium"
            >
              <div className="flex items-center gap-x-3">
                <div>
                  <img
                    className="object-cover rounded m-auto"
                    src="/../../assets/images/back.svg"
                    alt=""
                  />
                </div>
              </div>
              <div>Back</div>
            </Button>
          )}
          <Button
            variant="solid"
            radius="sm"
            onClick={props.handleConfirm}
            className="h-[52px] w-full bg-[#242424] text-white text-base leading-6 font-medium"
          >
            {props.page === 3 ? (
              'Publish'
            ) : (
              <div className="flex items-center gap-x-3">
                <div>Next</div>
                <div>
                  <img
                    className="object-cover rounded m-auto"
                    src="/../../assets/images/next.svg"
                    alt=""
                  />
                </div>
              </div>
            )}
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
}
