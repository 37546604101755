import { Link } from 'react-router-dom';
import { svgIcons } from '../svgIcons/svgIcons';
import { REPORT_LIST } from '../../utils/constants';

export default function ReportSidebar(props) {
  return (
    <div
      className={`bg-white xs:border-t border-[#E9ECF1] fixed w-full md:w-auto top-0 md:top-[82px] md:sticky z-30 md:z-10 h-full md:h-[calc(100vh-82px)] transition-[left] duration-500 ease-in-out ${
        props.toggle ? 'left-0' : '-left-full'
      }`}
    >
      <div className="flex md:hidden items-center justify-between px-5 py-6">
        <Link to="https://www.getflair.io">
          <img width={72} src="/assets/images/logo.png" />
        </Link>
        <button
          className="w-6 h-6 flex items-center justify-center"
          onClick={props.onClose}
        >
          <div className="w-3">{svgIcons.cross}</div>
        </button>
      </div>
      <p className="leading-[28.6px] text-[22px] xs:leading-[26px] xs:text-xl font-bold px-5 mt-6">
        Reports
      </p>
      <div className="grid gap-4 mt-8 xs:mt-7">
        {REPORT_LIST.map((item, index) => (
          <Link
            key={index}
            to={item + props.location.search}
            onClick={() => {
              props.onClose();
            }}
            className={
              (item === props.location.pathname.split('/').pop() &&
                'bg-[#E6EFFB]') +
              ' block font-medium hover:text-[#161D37] leading-[20.8px] text-base py-4 xs:py-2 px-6 pr-10 whitespace-nowrap capitalize'
            }
          >
            {item.replace('-', ' ')}
          </Link>
        ))}
      </div>
      <div className="px-5 mt-8 md:hidden">
        <button
          className="flair-btn flair-btn-primary flair-btn-md"
          onClick={props.onShare}
        >
          <div className="w-5 h-5 flex items-center justify-center">
            <div className="w-[12.67px] md:w-[15.83px]">{svgIcons.share}</div>
          </div>
          Share
        </button>
      </div>
    </div>
  );
}
