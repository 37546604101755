import React, { useState } from 'react';
import { ModalCentered } from './ModalCentered';
import { svgIcons } from '../svgIcons/svgIcons';
import { InputNumberCommas } from 'react-number-format-with-commas';

export default function InputModal(props) {
  const [value, setValue] = useState('');
  const onChange = (e) => {
    setValue(e.target.value); // e.target.value is a string sans commas here
  };
  return (
    <ModalCentered
      {...props}
      dialogClassName="sm:w-[432px]"
      formGroup={
        <>
          <div className="relative">
            <InputNumberCommas
              value={value}
              onChange={(e) => {
                onChange(e);
              }}
              className="InputNumberCommas flair-input px-12 text-right text-xl"
              placeholder={props.inputPlaceholder}
            />
            <div className="flair-input-icon w-[12px] text-[#707787]">
              {svgIcons.dollar}
            </div>
          </div>
          <div className="mt-8 grid gap-4 grid-rows-2 sm:gap-[12px] sm:grid-cols-2 sm:grid-rows-1">
            <button
              className="flair-btn flair-btn-md flair-btn-primary"
              onClick={(e) => props.handleConfirm && props.handleConfirm(value)}
            >
              Confirm
            </button>
            <button
              className="flair-btn flair-btn-md flair-btn-secondary"
              onClick={props.onClose}
            >
              Cancel
            </button>
          </div>
        </>
      }
    />
  );
}
