import { createSlice } from '@reduxjs/toolkit';

export const uxPromptSlice = createSlice({
  name: 'uxPrompt',

  initialState: {
    show: false,
    request: {},
    response: { confirmed: false, value: '' },
  },

  reducers: {
    showPrompt: (state, action) => {
      state.show = true;
      state.request = action.payload;
    },
    hidePrompt: (state, action) => {
      state.show = false;
    },
  },
});

export const { showPrompt, hidePrompt } = uxPromptSlice.actions;
export default uxPromptSlice.reducer;

export const fetchShow = (state) => {
  return state.uxPrompt.show;
};

export const fetchPromptRequest = (state) => {
  return state.uxPrompt.request;
};
