import React, { useEffect, useRef, useState } from 'react';
import ConfirmModal from '../../../components/modalbootstrap/ConfirmModal';
import SuccessModal from '../../../components/modalbootstrap/SuccessModal';
import { apiAgent } from '../../../utils/apicall';
import UploadHighlightPhoto from '../../../components/imageupload/UploadHighlightPhoto';
import UploadPhoto from '../../../components/imageupload/UploadPhoto';
import uxDialog from '../../../utils/uxdialog';
import { svgIcons } from '../../../components/svgIcons/svgIcons';
import { Link } from 'react-router-dom';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useSelector } from 'react-redux';

let _item;
let formData;
let _emptyProperty = {
  name: '',
  // homepage_link: '',
  tracking_phone: '',
  concessions: '',
  pet_friendly: false,
  propertyphoto_set: [
    {
      name: '',
      photo: '',
    },
  ],
  propertyamenity_set: [
    {
      name: '',
      description: '',
    },
  ],
  propertyunit_set: [
    {
      bedrooms: '',
      bathrooms: '',
      floor_area: '',
      starting_rent: '',
    },
  ],
  address: '',
  city: '',
  state: '',
  country_code: '',
  postal_code: '',
  homepage_link: 'https://',
};

export default function AddProperty() {
  const userInfo = useSelector((state) => state.userInfo.userInfo);
  // UI Ref
  const formElement = useRef(null);
  // UI States
  const [property, setProperty] = useState(_emptyProperty);
  // const [timeZoneArr, setTimeZoneArr] = useState([])
  // const [provinceArray, setProvinceArray] = useState([])
  const [modalShow, setModalShow] = useState(null);
  const [isHaveHighlightPhoto, setIsHaveHighlightPhoto] = useState(false);
  const [isHavePhoto, setIsHavePhoto] = useState(false);
  const [isHaveName, setIsHaveName] = useState(false);
  const [isHaveBedroom, setIsHaveBedroom] = useState(false);
  const [isHaveBathroom, setIsHaveBathroom] = useState(false);
  const [isHaveFloorArea, setIsHaveFloorArea] = useState(false);
  const [companies, setCompanies] = useState([]);

  // Utility functions
  const fetchCompanies = async () => {
    try {
      const response = await apiAgent.get({
        path: 'companies',
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      const companiesBody = await response.json();
      setCompanies(companiesBody);
    } catch (error) {
      console.log(error);
    }
  };

  const fetchIsStaff = async () => {
    try {
      await uxDialog.alert({
        text: 'You should belong to a company or a staff member to create property.',
      });
      window.location.href = '/dashboard';
    } catch (error) {
      console.log(error);
    }
  };
  // Event Handlers
  useEffect(() => {
    if (userInfo.is_staff) {
      fetchCompanies();
    } else {
      if (userInfo.company) _emptyProperty.company = userInfo.company.id;
      else {
        fetchIsStaff();
      }
    }
    setProperty(_emptyProperty);

    // Set page title and save navigation history
    const pageTitle = 'Add Property';
    window.document.title = `${pageTitle}`;
  }, []);

  // useEffect(() => {
  //   if (property)
  //     setProvinceArray(ProvinceNames[property.country_code])
  // }, [property])

  // UI Event Handlers
  const handleSubmit = async (e) => {
    e.preventDefault();
    formData = new FormData(formElement.current);
    formData.append('address', 'flat #1');
    formData.append('city', 'Street #1');
    formData.append('state', 'New York');
    formData.append('country_code', 'US');
    formData.append('postal_code', '123');

    if (!isHaveHighlightPhoto)
      await uxDialog.alert({ text: 'Highlight Photo is required!' });
    else if (!isHaveName)
      await uxDialog.alert({ text: 'Property Name is required!' });
    else if (!isHavePhoto)
      await uxDialog.alert({ text: 'Property Photo is required!' });
    else if (!isHaveBedroom)
      await uxDialog.alert({ text: 'Property Bedroom is required!' });
    else if (!isHaveBathroom)
      await uxDialog.alert({ text: 'Property Bathroom is required!' });
    else if (!isHaveFloorArea)
      await uxDialog.alert({ text: 'Property Floorarea is required!' });
    else setModalShow('SaveProperty');
  };

  const [nextNewPhotoId, setNextNewPhotoId] = useState(-1);
  const handleAddPropertyPhoto = () => {
    setIsHavePhoto(false);
    let photoSet = property.propertyphoto_set;
    photoSet.push({
      id: nextNewPhotoId,
      name: '',
      photo: '',
    });
    setProperty({ ...property, propertyphoto_set: photoSet });
    setNextNewPhotoId(nextNewPhotoId + 1);
  };

  const [nextNewAmenityId, setNextNewAmenityId] = useState(-1);
  const handleAddPropertyAmenity = () => {
    let amenitySet = property.propertyamenity_set;
    amenitySet.push({
      id: nextNewAmenityId,
      name: '',
      description: '',
    });
    setProperty({ ...property, propertyamenity_set: amenitySet });
    setNextNewAmenityId(nextNewAmenityId + 1);
  };

  const [nextNewUnitId, setNextNewUnitId] = useState(-1);
  const handleAddPropertyUnit = () => {
    let unitSet = property.propertyunit_set;
    unitSet.push({
      id: nextNewUnitId,
      bedrooms: '',
      bathrooms: '',
      floor_area: '',
      starting_rent: '',
    });
    setProperty({ ...property, propertyunit_set: unitSet });
    setNextNewUnitId(nextNewUnitId + 1);
  };

  const handleDeletePhoto = () => {
    let photoSet = property.propertyphoto_set;
    const index = photoSet.indexOf(_item);
    photoSet.splice(index, 1);
    setProperty({ ...property, propertyphoto_set: photoSet });
  };

  const handleDeleteAmenity = () => {
    let amenitySet = property.propertyamenity_set;
    const index = amenitySet.indexOf(_item);
    amenitySet.splice(index, 1);
    setProperty({ ...property, propertyamenity_set: amenitySet });
  };

  const handleDeleteUnit = () => {
    let unitSet = property.propertyunit_set;
    const index = unitSet.indexOf(_item);
    unitSet.splice(index, 1);
    setProperty({ ...property, propertyunit_set: unitSet });
  };

  const handleDeletePhotoConfirm = () => {
    handleDeletePhoto();
    setModalShow(null);
  };

  const handleDeleteAmenityConfirm = () => {
    handleDeleteAmenity();
    setModalShow(null);
  };

  const handleDeleteUnitConfirm = () => {
    handleDeleteUnit();
    setModalShow(null);
  };

  const handleResetConfirm = () => {
    setProperty(_emptyProperty);
    setModalShow(null);
  };

  const handleCancel = () => {
    setModalShow('ResetProperty');
  };

  const handleSaveConfirm = async () => {
    try {
      const response = await apiAgent.post({
        path: '/properties/',
        data: formData,
        options: apiAgent.popularOptions.onConfirmExceptDeclined,
      });
      console.log(response);
    } catch (error) {
      console.log(error);
    }
    setTimeout(function () {
      setModalShow('SaveSuccess');
    }, 499);
  };

  return (
    <div className="px-0 xs:px-10">
      <Link
        to="/dashboard"
        className="px-5 xs:px-0 py-8 font-semibold text-base leading-[17.6px] flex items-center"
      >
        <div className="w-[6px] h-[10px]">{svgIcons.left_arrow}</div>
        <div className="indent-3">Back to dashboard</div>
      </Link>
      <div className="grid gap-4 px-5 xs:px-0">
        <h5>Add property</h5>
        <p>Please, fill in the form to add a new property.</p>
      </div>
      <form onSubmit={handleSubmit} ref={formElement}>
        <Tabs forceRenderTabPanel={true}>
          <TabList className="xs:hidden pl-5 font-medium text-[14px] leading-[18.2px] flex gap-8 mt-6 border-b border-b-[#E9ECF1] whitespace-nowrap overflow-scroll">
            <Tab
              className="pb-5 bg-transparent"
              selectedClassName="border-b-4 border-b-[#687BFE]"
            >
              General
            </Tab>
            <Tab
              className="pb-5 bg-transparent"
              selectedClassName="border-b-4 border-b-[#687BFE]"
            >
              Photos
            </Tab>
            <Tab
              className="pb-5 bg-transparent"
              selectedClassName="border-b-4 border-b-[#687BFE]"
            >
              Community Amenities
            </Tab>
            <Tab
              className="pb-5 bg-transparent"
              selectedClassName="border-b-4 border-b-[#687BFE]"
            >
              Property Floorplans
            </Tab>
          </TabList>
          <TabPanel
            className="tab-panel white-background mt-[6px] xs:mt-6"
            selectedClassName="block"
          >
            <UploadHighlightPhoto
              title="Highlight photo"
              btnCaption="Browse..."
              imgSrc="/assets/images/tile-noimg.svg"
              name="highlight_photo"
              onChooseFile={() => {
                setIsHaveHighlightPhoto(true);
              }}
            />
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8 border-t pt-8 border-t-[#E9ECF1]">
              <div className="grid gap-2">
                <div className="text-[14px] leading-[18.2px]">
                  Property name
                </div>
                <input
                  className="flair-input"
                  type="text"
                  value={property.name}
                  onChange={(e) => {
                    setProperty({ ...property, name: e.target.value });
                    setIsHaveName(true);
                  }}
                  name="name"
                />
              </div>
              {userInfo.is_staff ? (
                <div className="grid gap-2">
                  <div className="text-[14px] leading-[18.2px]">Company</div>
                  <select
                    name="company"
                    className="flair-input"
                    type="text"
                    onChange={(e) =>
                      setProperty({ ...property, company: e.target.value })
                    }
                  >
                    {companies.map((company) => (
                      <option key={'company' + company.id} value={company.id}>
                        {' '}
                        {company.name}{' '}
                      </option>
                    ))}
                  </select>
                </div>
              ) : (
                <input
                  value={userInfo.company.id}
                  name="company"
                  type="hidden"
                />
              )}
            </div>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8 border-t pt-8 border-t-[#E9ECF1]">
              <div className="grid gap-2">
                <div className="text-[14px] leading-[18.2px]">Website URL</div>
                <input
                  className="flair-input"
                  placeholder="https://"
                  type="text"
                  value={property.homepage_link}
                  onChange={(e) => {
                    setProperty({ ...property, homepage_link: e.target.value });
                  }}
                  name="homepage_link"
                />
              </div>
              <div className="grid gap-2">
                <div className="text-[14px] leading-[18.2px]">
                  Tracking phone number
                </div>
                <input
                  className="flair-input"
                  type="text"
                  value={property.tracking_phone}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      tracking_phone: e.target.value,
                    });
                  }}
                  name="tracking_phone"
                />
              </div>
            </div>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8 border-t pt-8 border-t-[#E9ECF1]">
              <div className="grid gap-2">
                <div className="text-[14px] leading-[18.2px]">Concessions</div>
                <input
                  className="flair-input"
                  type="text"
                  value={property.concessions}
                  onChange={(e) => {
                    setProperty({ ...property, concessions: e.target.value });
                  }}
                  name="concessions"
                />
              </div>
            </div>
            <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 mt-8 border-t pt-8 border-t-[#E9ECF1]">
              <label className="check-container text-base leading-5 font-normal mb-0">
                Pet friendly
                <input
                  type="checkbox"
                  className="checkbox"
                  id="pet-friendly"
                  checked={property ? property.pet_friendly : false}
                  onChange={(e) => {
                    setProperty({
                      ...property,
                      pet_friendly: e.target.checked,
                    });
                  }}
                  name="pet_friendly"
                />
                <span className="checkmark" />
              </label>
            </div>
          </TabPanel>
          <TabPanel
            className="tab-panel white-background px-0"
            selectedClassName="block"
          >
            <div className="font-bold text-[20px] leading-[26px] px-5 xs:px-8 mb-6">
              Photos
            </div>
            {property.propertyphoto_set.map((item, index) => {
              return (
                <div key={'property-photo-' + item.id}>
                  {parseInt(item.id) > 0 && (
                    <input
                      type="hidden"
                      name={`propertyphoto_set[${index}]pk`}
                      value={item.id}
                    />
                  )}
                  <div className="bg-[#F2F5F9] border-t border-t-[#E9ECF1] px-5 xs:px-8 py-2 flex justify-between items-center">
                    <p>
                      <span className="text-[#707787]">Property photo: </span>
                      &nbsp;
                      <span style={{ fontWeight: 'bold' }}>
                        {property && item.name}
                      </span>
                    </p>
                    {property.propertyphoto_set.length > 1 && (
                      <div className="flex gap-4">
                        <Link
                          to={'#'}
                          className="font-semibold text-[14px] leading-[15.4px] flex indent-2 text-[#EE380D]"
                          onClick={() => {
                            _item = item;
                            setModalShow('DeletePhoto');
                          }}
                        >
                          <div className="w-[15px] h-[15px]">
                            {svgIcons.delete}
                          </div>
                          Delete
                        </Link>
                      </div>
                    )}
                  </div>
                  <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 border-t px-5 xs:px-8 py-10 border-t-[#E9ECF1]">
                    <div>
                      <div className="grid gap-2">
                        <div className="text-[14px] leading-[18.2px]">
                          Photo name
                        </div>
                        <input
                          className="flair-input"
                          type="text"
                          value={item.name}
                          onChange={(e) => {
                            let photoSet = property.propertyphoto_set;
                            photoSet[index].name = e.target.value;
                            setProperty({
                              ...property,
                              propertyphoto_set: photoSet,
                            });
                          }}
                          name={`propertyphoto_set[${index}]name`}
                        />
                      </div>
                    </div>

                    <div className="photos-input-category-container" hidden>
                      <div className="mainfill-input-title">Image category</div>
                      {/* <select className='mainfill-input' type='text'>
                          <option value='*-*'>Random</option>
                        </select> */}
                      <input
                        className="mainfill-input"
                        type="text"
                        value="*-*"
                        onChange={(e) => {}}
                        name={`propertyphoto_set[${index}]category`}
                      />
                    </div>
                    <div className="md:col-start-2 md:col-end-4 grid gap-2">
                      <div className="text-[14px] leading-[18.2px]">
                        Photo file
                      </div>
                      <div className="relative">
                        <div className="flex items-center absolute top-0 left-4 h-full ">
                          <div className="w-[15px] text-[#8C94A3]">
                            {svgIcons.noimg}
                          </div>
                        </div>
                        <UploadPhoto
                          btnCaption="Browse..."
                          imageName={item.photo.substring(
                            item.photo.lastIndexOf('/') + 1,
                          )}
                          onChooseFile={() => {
                            setIsHavePhoto(true);
                          }}
                          name={`propertyphoto_set[${index}]photo`}
                        />
                      </div>
                      <div className="font-normal text-[14px] leading-[18.2px] text-[#707787]">
                        Supports PNG, JPEG, GIF files
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
            {property.propertyphoto_set[property.propertyphoto_set.length - 1]
              .name &&
              isHavePhoto && (
                <div className="px-5 xs:px-8 pt-6 border-t border-t-[#E9ECF1]">
                  <button
                    className="flex items-center indent-2 text-[#687BFE]"
                    onClick={handleAddPropertyPhoto}
                    type="button"
                  >
                    <div className="w-9 h-9 rounded-full bg-[#687BFE]/[0.2] text-[#687BFE] flex items-center justify-center indent-2">
                      <div className="w-[9px] h-[9px]">{svgIcons.plus}</div>
                    </div>
                    Add another property photo
                  </button>
                </div>
              )}
          </TabPanel>
          <TabPanel
            className="tab-panel white-background px-0"
            selectedClassName="block"
          >
            <div className="font-bold text-[20px] leading-[26px] px-5 xs:px-8 mb-6">
              Community Amenities
            </div>
            {property &&
              property.propertyamenity_set.map((item, index) => {
                return (
                  <div key={'property-amenity-' + item.id}>
                    {parseInt(item.id) > 0 && (
                      <input
                        type="hidden"
                        name={`propertyamenity_set[${index}]pk`}
                        value={item.id}
                      />
                    )}
                    <div className="bg-[#F2F5F9] border-t border-t-[#E9ECF1] px-5 xs:px-8 py-2 flex justify-between items-center">
                      <p>
                        <span className="text-[#707787]">
                          Property amenity:{' '}
                        </span>
                        &nbsp;
                        {property && item.name}
                      </p>
                      {property.propertyamenity_set.length > 1 && (
                        <Link
                          to={'#'}
                          className="font-semibold text-[14px] leading-[15.4px] flex indent-2 text-[#EE380D]"
                          onClick={() => {
                            _item = item;
                            setModalShow('DeleteAmenity');
                          }}
                        >
                          <div className="w-[15px] h-[15px]">
                            {svgIcons.delete}
                          </div>
                          Delete
                        </Link>
                      )}
                    </div>
                    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 border-t px-5 xs:px-8 py-8 border-t-[#E9ECF1]">
                      <div className="grid gap-2">
                        <div className="text-[14px] leading-[18.2px]">
                          Amenity name
                        </div>
                        <input
                          className="flair-input"
                          type="text"
                          value={item.name}
                          onChange={(e) => {
                            let amenitySet = property.propertyamenity_set;
                            amenitySet[index].name = e.target.value;
                            setProperty({
                              ...property,
                              propertyamenity_set: amenitySet,
                            });
                          }}
                          name={`propertyamenity_set[${index}]name`}
                        />
                      </div>
                      <div className="grid gap-2">
                        <div className="text-[14px] leading-[18.2px]">
                          Amenity description
                        </div>
                        <input
                          className="flair-input"
                          type="text"
                          value={item.description}
                          onChange={(e) => {
                            let amenitySet = property.propertyamenity_set;
                            amenitySet[index].description = e.target.value;
                            setProperty({
                              ...property,
                              propertyamenity_set: amenitySet,
                            });
                          }}
                          name={`propertyamenity_set[${index}]description`}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            {property.propertyamenity_set[
              property.propertyamenity_set.length - 1
            ].name && (
              <div className="px-5 xs:px-8 pt-6 border-t border-t-[#E9ECF1]">
                <button
                  className="flex items-center indent-2 text-[#687BFE]"
                  onClick={handleAddPropertyAmenity}
                  type="button"
                >
                  <div className="w-9 h-9 rounded-full bg-[#687BFE]/[0.2] text-[#687BFE] flex items-center justify-center indent-2">
                    <div className="w-[9px] h-[9px]">{svgIcons.plus}</div>
                  </div>
                  Add another property amenity
                </button>
              </div>
            )}
          </TabPanel>
          <TabPanel
            className="tab-panel white-background px-0"
            selectedClassName="block"
          >
            <div className="font-bold text-[20px] leading-[26px] px-5 xs:px-8 mb-2">
              Floor Plans
            </div>
            <p className="mb-6 px-5 xs:px-8">
              Insert your floor plan types here.
            </p>
            {property &&
              property.propertyunit_set.map((item, index) => {
                return (
                  <div key={'property-photo-' + item.id}>
                    {parseInt(item.id) > 0 && (
                      <input
                        type="hidden"
                        name={`propertyunit_set[${index}]pk`}
                        value={item.id}
                      />
                    )}
                    <div className="bg-[#F2F5F9] border-t border-t-[#E9ECF1] px-5 xs:px-8 py-2 flex justify-between items-center">
                      <p>
                        <span className="text-[#707787]">
                          Property floorplan:{' '}
                        </span>
                        &nbsp;
                        {property && `floorplan: #${index + 1}`}
                      </p>
                      {property.propertyunit_set.length > 1 && (
                        <Link
                          to={'#'}
                          className="font-semibold text-[14px] leading-[15.4px] flex indent-2 text-[#EE380D]"
                          onClick={() => {
                            _item = item;
                            setModalShow('DeleteUnit');
                          }}
                        >
                          <div className="w-[15px] h-[15px]">
                            {svgIcons.delete}
                          </div>
                          Delete
                        </Link>
                      )}
                    </div>
                    <div className="grid gap-6 md:grid-cols-2 lg:grid-cols-3 border-t px-5 xs:px-8 py-8 border-t-[#E9ECF1]">
                      <div className="grid gap-2">
                        <div className="text-[14px] leading-[18.2px]">
                          Bedrooms
                        </div>
                        <input
                          className="flair-input"
                          type="number"
                          value={item.bedrooms}
                          onChange={(e) => {
                            let unitSet = property.propertyunit_set;
                            unitSet[index].bedrooms = e.target.value.toString();
                            setProperty({
                              ...property,
                              propertyunit_set: unitSet,
                            });
                            setIsHaveBedroom(true);
                          }}
                          name={`propertyunit_set[${index}]bedrooms`}
                        />
                      </div>
                      <div className="grid gap-2">
                        <div className="text-[14px] leading-[18.2px]">
                          Bathrooms
                        </div>
                        <input
                          className="flair-input"
                          type="number"
                          value={item.bathrooms}
                          onChange={(e) => {
                            let unitSet = property.propertyunit_set;
                            unitSet[index].bathrooms =
                              e.target.value.toString();
                            setProperty({
                              ...property,
                              propertyunit_set: unitSet,
                            });
                            setIsHaveBathroom(true);
                          }}
                          name={`propertyunit_set[${index}]bathrooms`}
                        />
                      </div>
                      <div className="grid gap-2">
                        <div className="text-[14px] leading-[18.2px]">
                          Floor area (sq.ft.)
                        </div>
                        <input
                          className="flair-input"
                          type="number"
                          value={item.floor_area}
                          onChange={(e) => {
                            let unitSet = property.propertyunit_set;
                            unitSet[index].floor_area =
                              e.target.value.toString();
                            setProperty({
                              ...property,
                              propertyunit_set: unitSet,
                            });
                            setIsHaveFloorArea(true);
                          }}
                          name={`propertyunit_set[${index}]floor_area`}
                        />
                      </div>
                    </div>
                  </div>
                );
              })}
            {(property.propertyunit_set[property.propertyunit_set.length - 1]
              .bedrooms ||
              property.propertyunit_set[property.propertyunit_set.length - 1]
                .bathrooms ||
              property.propertyunit_set[property.propertyunit_set.length - 1]
                .floor_area) && (
              <div className="px-5 xs:px-8 pt-6 border-t border-t-[#E9ECF1]">
                <button
                  className="flex items-center indent-2 text-[#687BFE]"
                  onClick={handleAddPropertyUnit}
                  type="button"
                >
                  <div className="w-9 h-9 rounded-full bg-[#687BFE]/[0.2] text-[#687BFE] flex items-center justify-center indent-2">
                    <div className="w-[9px] h-[9px]">{svgIcons.plus}</div>
                  </div>
                  Add another property floorplan
                </button>
              </div>
            )}
          </TabPanel>
        </Tabs>

        <div className="px-5 xs:px-0 pt-10 pb-12 grid gap-4 xs:flex xs:flex-row-reverse">
          <button
            type="submit"
            className="flair-btn flair-btn-primary flair-btn-lg xs:w-[220px]"
          >
            Save
          </button>
          <button
            className="flair-btn flair-btn-secondary flair-btn-lg xs:w-[220px]"
            type="button"
            onClick={() => {
              handleCancel();
              setModalShow('ResetProperty');
            }}
          >
            Cancel
          </button>
        </div>
      </form>
      <ConfirmModal
        show={modalShow === 'DeletePhoto'}
        onHide={() => setModalShow(null)}
        onClose={() => setModalShow(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Delete Property Photo"
        txt="Are you sure you want to delete?"
        handleConfirm={handleDeletePhotoConfirm}
      />
      <ConfirmModal
        show={modalShow === 'DeleteAmenity'}
        onHide={() => setModalShow(null)}
        onClose={() => setModalShow(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Delete Property Amenity"
        txt="Are you sure you want to delete?"
        handleConfirm={handleDeleteAmenityConfirm}
      />
      <ConfirmModal
        show={modalShow === 'DeleteUnit'}
        onHide={() => setModalShow(null)}
        onClose={() => setModalShow(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Delete Property Unit"
        txt="Are you sure you want to delete?"
        handleConfirm={handleDeleteUnitConfirm}
      />
      <ConfirmModal
        show={modalShow === 'SaveProperty'}
        onHide={() => setModalShow(null)}
        onClose={() => setModalShow(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Save Property"
        txt="Are you sure you want to save?"
        handleConfirm={handleSaveConfirm}
      />
      <ConfirmModal
        show={modalShow === 'ResetProperty'}
        onHide={() => setModalShow(null)}
        onClose={() => setModalShow(null)}
        mark={{
          icon: svgIcons.warning,
          parentClass: 'text-[#EE380D] bg-[#EE380D]/10',
          childClass: 'w-[28px] h-[28px]',
        }}
        title="Reset Property"
        txt="Are you sure you want to reset?"
        handleConfirm={handleResetConfirm}
      />
      <SuccessModal
        show={modalShow === 'SaveSuccess'}
        onHide={() => setModalShow(null)}
        onClose={() => setModalShow(null)}
        mark={{
          icon: svgIcons.success,
          parentClass: 'text-[#38D086] bg-[#38D086]/10',
          childClass: 'w-[30px] h-[17px]',
        }}
        title="Success"
        txt="A new property was successfully added"
        modalConfirmBtn="Confirm"
        handleSuccess={() => {
          setModalShow(null);
        }}
      />
    </div>
  );
}
