import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import Select, { components } from 'react-select';
import { svgIcons } from '../svgIcons/svgIcons';

const pageCountValues = [
  { value: 5, label: '5' },
  { value: 10, label: '10' },
  { value: 15, label: '15' },
  { value: 20, label: '20' },
];

export default function OffsetPaginator({ onSelectPage, total }) {
  // let manualGotoPage
  const [pageSize, setPageSize] = useState(10);
  const [pageCount, setPageCount] = useState(Math.ceil(total / 10));
  const [currentPage, setCurrentPage] = useState(0);
  const [manualGotoPage, setManualGotoPage] = useState(1);

  const [pageCountValue, setPageCountValue] = useState(pageCountValues[1]);
  useEffect(() => {
    handlePageSizeUpdate(pageCountValue.value);
  }, [pageCountValue]);
  const handlePageSizeUpdate = (value) => {
    const newPageSize = value;
    setPageSize(newPageSize);
    const itemOffset = pageSize * currentPage;
    const newPageNumber = parseInt(Math.floor(itemOffset / newPageSize));
    setCurrentPage(newPageNumber);
    setPageCount(Math.ceil(total / newPageSize));
  };
  const handleChangePage = (e) => {
    const _currentPage = parseInt(e.selected);
    setCurrentPage(_currentPage);
    setManualGotoPage(e.selected + 1);
  };
  const handleManualChangePage = () => {
    if (manualGotoPage <= pageCount) {
      const _currentPage = manualGotoPage - 1;
      setCurrentPage(_currentPage);
    }
  };

  useEffect(() => {
    //
    const _pageCount = Math.ceil(total / pageSize);
    // if new page count is less than current page, change current page
    if (_pageCount < currentPage) {
      setCurrentPage(_pageCount);
    }
    setPageCount(_pageCount);
  }, [total]);

  useEffect(() => {
    onSelectPage({ offset: currentPage * pageSize, size: pageSize });
  }, [currentPage, pageSize]);

  return (
    <div className="pt-8 pb-12 flex flex-col gap-4 md:flex-row md:flex-wrap md:justify-end items-center">
      <ReactPaginate
        onPageChange={handleChangePage}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        breakLabel="..."
        previousLabel={<div className="w-[6px]">{svgIcons.left_arrow}</div>}
        nextLabel={<div className="w-[6px]">{svgIcons.right_arrow}</div>}
        containerClassName="flex gap-2 h-[35px] text-[14px] leading-[1.3] font-normal"
        pageClassName="flex items-center px-3 border-b-[3px] border-transparent"
        previousClassName="flex items-center px-3 border-b-[3px] border-transparent"
        nextClassName="flex items-center px-3 border-b-[3px] border-transparent"
        breakClassName="flex items-center px-3 border-b-[3px] border-transparent"
        activeClassName="border-b-[#687BFE] text-[#687BFE]"
        renderOnZeroPageCount={null}
        forcePage={currentPage}
      />
      <div className="flex items-center">
        <div className="md:pl-4 pr-4 md:border-l border-l-[#E9ECF1] font-medium text-[14px] leading-[18.2px]">
          Go to page
        </div>
        <input
          className="w-10 h-10 pl-2 border border-[#F2F5F9] rounded"
          type="number"
          onChange={(e) => setManualGotoPage(e.target.value)}
          id="pageVal"
          value={!pageCount ? 0 : manualGotoPage}
          onKeyPress={(e) => {
            if (e.key === 'Enter') {
              handleManualChangePage();
            }
          }}
        />
        <div className="px-4 border-r border-r-[#E9ECF1] mr-4">
          of {pageCount}
        </div>

        <Select
          components={{ DropdownIndicator, IndicatorSeparator: () => null }}
          defaultValue={pageCountValues[1]}
          onChange={setPageCountValue}
          options={pageCountValues}
          clearable={true}
          menuPlacement="top"
        />
      </div>
    </div>
  );
}

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <svg
          width="6"
          height="3"
          viewBox="0 0 6 3"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M3 3.00018L0 0L1 6.07967e-06L3 0H5H6L3 3.00018Z"
            fill="#161D37"
          />
        </svg>
      </components.DropdownIndicator>
    )
  );
};
