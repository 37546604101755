import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';

const arr = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function PropertyTileSkeleton() {
  return arr.map((item) => {
    return (
      <div
        className="flex px-[12px] pt-[12px] pb-[16px] skeleton-background rounded w-full relative 520w:block 520w:p-0"
        key={item}
      >
        <div className="flex justify-center items-center mr-[12px] w-2/5 520w:w-full 520w:aspect-[23/14]">
          <div className="absolute top-0 left-0 flex justify-between w-full p-[12px] 520w:p-[15px]">
            <div className=""></div>
            {/* <img src="/assets/images/star-skeleton.svg" alt="" /> */}
          </div>
        </div>
        <div className="w-3/5 520w:w-full 520w:px-[16px] 520w:pt-[12px] 520w:pb-[18px]">
          <div className="mb-[4px] text-[12px]">
            <span className="text-[12px] mr-[4px]">
              <Skeleton
                baseColor="rgba(202, 220, 237, 0.7)"
                highlightColor="rgba(219, 230, 242, 1)"
              />
            </span>
          </div>
          <div className="w-full h-[42px]">
            <Skeleton
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
              height={20}
            />
          </div>
          <div className="flex items-center mt-[15px] text-[12px] gap-4">
            <Skeleton
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
              width={62}
            />
            <Skeleton
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
              width={62}
            />
          </div>
          <div className="mt-[20px] flex items-center">
            <Skeleton
              borderRadius={32}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
              height={36}
              width={100}
            />
            <Skeleton
              containerClassName="mx-auto"
              borderRadius={20}
              baseColor="rgba(202, 220, 237, 0.7)"
              highlightColor="rgba(219, 230, 242, 1)"
              width={62}
            />
          </div>
        </div>
      </div>
    );
  });
}
